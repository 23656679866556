import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import { CategoryAPI } from '../../../(apis)/(shared-apis)/academics/category-apis';
import AddCategoryModal from './components/add-category-modal';
import { TableTop } from './components/table-top-view';
import TableView from './components/table-viewer';
import { CategoryDefaultValues } from './components/utlis';
import { CategoryInterface } from './interfaces';

export default function Page() {


  const [categories, setCategories] = useState<CategoryInterface[]>([CategoryDefaultValues]);
  const [originalCategories, setOriginalCategories] = useState<CategoryInterface[]>([CategoryDefaultValues]);
  const [isLoading, setisLoading] = useState(false)
  const [showModel, setShowModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [clickRowData, setClickRowData] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [institutesData, setInstitutesData] = useState([]);

  const GetCategories = async () => {
    setisLoading(true)
    const response = await CategoryAPI("getCategories")
    const data = response.data.data.filter((item: any) => {
      const entityMatches = item.metadata.entity === process.env.REACT_APP_ENTITY || item.metadata.entity === process.env.REACT_APP_ENTITY?.replace("-", " ");
      const categoryMatches = item.metadata.category === process.env.REACT_APP_CATEGORY;
      return entityMatches && categoryMatches;
    });
    
    setCategories(data)
    setOriginalCategories(data)
    setisLoading(false)
  }
  const getInstituteData = async () => {
    try {
     
      const res = await apiRequest("getinstitutes");
      if (res?.data?.isSuccess) {
        const resData = res?.data?.institutes;
        if (resData?.length > 0) {
          const formatedData = resData.map((item) => {
            return {
                ...item,
                label: item.instituteName,
                value: item.instituteName,
            };
          });
          setInstitutesData(formatedData);
        } else {
          setInstitutesData([]);
       message.error("No Institute Found");
        }
        
      }
    } catch (error) {
    message.error(error?.response?.data?.message || error?.message);
      console.log("error,", error);
   
    }
  };

  useEffect(() => {
    GetCategories()
    getInstituteData();
  }, []);

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setCategories(originalCategories);
      return;
    }
    const filteredOrders = originalCategories.filter((cat: any) => {
      return cat.categoryName.toLowerCase().includes(
        e.target.value.toLowerCase()
      );
    });
    setCategories(filteredOrders);
  };



  return (
    <div className="w-[95%] mx-auto h-full">
      <Toaster />
      <div className='flex justify-between'>
        <div>
      <h1 className="text-3xl text-gray-700 font-bold">Category Manager</h1>

        </div>
        <div>
          <ButtonFill handleClick={()=>{
            setShowModel(true)
            setIsEditMode(false)
            setClickRowData(null)
          }}>
          Create Category
          </ButtonFill>
        </div>

      </div>
      <section className="my-5 antialiased">
        <div className="mx-auto">
    
          {isLoading ? (
            "Loading..."
          ) : (
            <div className="bg-white shadow-md sm:rounded-lg">
              <TableTop
                searchQuery={searchQuery}
                handleSearchQueryChange={handleSearchQueryChange}
              />
              <TableView
                categories={categories}
                setIsEditMode={setIsEditMode}
                setCategories={setCategories}
                setOriginalCategories={setOriginalCategories}
                setShowModel={setShowModel}
                setClickRowData={setClickRowData}
              />
            </div>
          )}
        </div>
      </section>


      <AddCategoryModal
        setCategories={setCategories}
        ShowModel={showModel}
        setShowModel={setShowModel}
        isEditMode={isEditMode}
        setIsEditMode={setIsEditMode}
        clickRowData={clickRowData}
        institutesData={institutesData}

      />

    </div>


  )
}
