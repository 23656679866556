export const initialValues = {
    topicName: "",
    topicEditId: "",
    logo: null,
    subTopics: [
      {
        subTopicName: "",
      },
    ],
    subject: "",
    modules: [],
  };