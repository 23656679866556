import React from 'react'
import { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import CustomBadges from '../../../../shared-components/ui/CustomBadges';
import { Skeleton } from 'antd';

export default function TableView({ attempts, loading }: any) {

    const PerPage = 100;
    const [currentPage, setCurrentPage] = useState(1);
  
  
    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    }

    const FormatAccountCreatedDate = (date: string) => {
      const newDate = new Date(date);
      const day = newDate.getDate();
      const month = newDate.getMonth() + 1;
      const year = newDate.getFullYear();
      return `${day}-${month}-${year}`;
    }

    const GetFeaturesPurchase = (featuresPurchase: any) => {
      return featuresPurchase.map((feature) => ({
        planName: feature.planName,
      }));
    };

  
  
    return <div className="overflow-x-auto">
      <Toaster />
      {loading ?
        <Skeleton active paragraph={{ rows: 10 }} className="mb-5" />
        :
        <>
          <table className="w-full mb-20 text-sm text-left text-gray-500">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th scope="col" className="px-4 py-4">
                  Full Name
                </th>
                <th scope="col" className="px-4 py-4">
                  Email
                </th>
                <th scope="col" className="px-4 py-4">
                  Phone
                </th>
                <th scope="col" className="px-4 py-4">
                  City
                </th>
                <th scope="col" className="px-4 py-4">
                  Province
                </th>
                <th scope="col" className="px-4 py-4">
                  Correct/Incorrect/Skipped
                </th>
                <th scope="col" className="px-4 py-4">
                  Time Taken
                </th>
                <th scope="col" className="px-4 py-4">
                  Mode
                </th>
                <th scope="col" className="px-4 py-4">
                  Education Level
                </th>
                <th scope="col" className="px-4 py-4">
                  Account Created
                </th>
                <th scope="col" className="px-4 py-4">
                  Features Purchased
                </th>
              </tr>
            </thead>
            <tbody>
              {attempts?.slice((currentPage - 1) * PerPage, currentPage * PerPage)?.map((attempt: any) => (
                <tr className="border-b" key={attempt?._id}>
                  <td className="px-4 py-3">{attempt?.fullname}</td>
                  <td className="px-4 py-3 text-left"> {attempt?.username}</td>
                  <td className="px-4 py-3 text-left">{attempt?.phone}</td>
                  <td className="px-4 py-3 text-left">{attempt?.city}</td>
                  <td className="px-4 py-3 text-left">{attempt?.province}</td>
                  <td className="px-4 py-3 text-center">
                    <div className="flex items-center space-x-2">
                      <CustomBadges label={attempt.correctAttempts || 0} type="success" showIcon={false} />
                      <CustomBadges label={attempt.incorrectAttempts || 0} type="error" showIcon={false} />
                      <CustomBadges label={attempt.skippedAttempts || 0} type="warning" showIcon={false} />
                    </div>
                  </td>
                  <td className="px-4 py-3 text-left">
                    {(attempt?.totalTimeTaken / 60).toFixed(2)} mins
                  </td>
                  <td className="px-4 py-3 text-left">
                    <CustomBadges
                      label={attempt?.attemptMode}
                      type={attempt?.attemptMode === "TESTMODE" ? "success" : "info"}
                      showIcon={false} />
                  </td>
                  <td className="px-4 py-3">
                  {attempt?.info?.educationSystem
                    ? attempt.info.educationSystem
                    : (attempt?.info?.exam
                      ? attempt.info.exam.map((ex) => ex).join(', ')
                      : 'No information available')}
                    {attempt.info.year && <span> - {attempt.info.year}</span>}
                </td>
                <td className="px-4 py-3">{FormatAccountCreatedDate(attempt.accountcreateddate)}</td>
                <td className="px-4 py-3">
                  {attempt.featuresPurchased && GetFeaturesPurchase(attempt.featuresPurchased).map((feature, index) => (
                    <div key={index} className='flex flex-row justify-between gap-1'>
                      <p>{feature.planName}</p>
                    </div>
                  ))}
                </td>


                </tr>
              ))}
            </tbody>
          </table>
          <nav
            className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
            aria-label="Table navigation"
          >
            <span className="text-sm font-normal text-gray-500 space-x-2">
              <span> Showing</span>
              <span className="font-semibold text-gray-900 ">
                {currentPage * PerPage - PerPage + 1}
              </span>
              <span> of</span>
              <span className="font-semibold text-gray-900">
                {attempts.length}
              </span>
            </span>
            <ul className="inline-flex items-stretch -space-x-px">
              <li>
                <a
                  className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                      clipRule="evenodd" />
                  </svg>
                </a>
              </li>
              {Array.from({
                length: Math.ceil(attempts.length / PerPage),
              }).map((_, index) => (
                <li key={index}>
                  <a
                    className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 ${currentPage === index + 1
                      ? "font-semibold text-primary-600 bg-primary-50"
                      : ""}`}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
              <li>
                <a
                  className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  <span className="sr-only">Next</span>
                  <svg
                    className="w-5 h-5"
                    aria-hidden="true"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clipRule="evenodd" />
                  </svg>
                </a>
              </li>
            </ul>
          </nav>
  
        </>}
    </div>;
  }
  
