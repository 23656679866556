const convertToFormData = (data: Record<string, any>): FormData => {
  const formData = new FormData();
  const appendData = (key: string, value: any) => {
    if (value != null) {
      // Convert objects and arrays to JSON strings
      if (value instanceof File || value instanceof Blob) {
        formData.append(key, value);
      } else if (typeof value === "object" && !Array.isArray(value)) {
        formData.append(key, JSON.stringify(value));
      } else if (Array.isArray(value)) {
        // Handle arrays as JSON strings
        formData.append(key, JSON.stringify(value));
      } else {
        // Convert other types to strings
        formData.append(key, value.toString());
      }
    }
  };

  // Iterate over the object entries
  Object.entries(data).forEach(([key, value]) => appendData(key, value));

  return formData;
};

export { convertToFormData };
