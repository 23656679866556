import { apiRequest } from '(apis)/api-interface/api-handler';
import { message, notification } from 'antd'; // Assuming you're using Ant Design for notifications
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AnnouncementModel from './announcement-modal';
import CustomButton from 'shared-components/form-fields/custom-button';
import { PlusOutlined } from '@ant-design/icons';

interface Announcement {
  _id: string;
  AnnouncementTitle: string;
  AnnouncementDescription: string;
  AnnouncementLinks?: string[];
  AnnouncementDate: string;
  isPublished: boolean;
}

const Announcements: React.FC = () => {
  const [announcementsModel, setAnnouncementsModel] = useState<boolean>(false);
  const [announcements, setAnnouncements] = useState<Announcement[]>([]);
  const { id } = useParams<{ id: string }>();
  const getAnnouncements = async () => {
    try {
      const response = await apiRequest("getAnnouncements",null,[id]);
      if (response.data.success) {
        setAnnouncements(response.data.announcements);
      }
    } catch (error) {
      console.error('Error fetching announcements:', error);
    }
  };

  useEffect(() => {
    getAnnouncements();
  }, [id]);

  const handlePublishedChange = async (announcementId: string) => {
    console.log("announcementId kk",announcementId)
    try {
      const payload = { AnnouncementID: announcementId }
      const response = await apiRequest("publishAnnouncement",payload);
      if (response.data.success) {
        message.success('Announcement Published Successfully');
        const newAnnouncements = announcements.map((announcement) => {
          if (announcement._id === announcementId) {
            return { ...announcement, isPublished: !announcement.isPublished };
          }
          return announcement;
        });
        setAnnouncements(newAnnouncements);
      }
    } catch (error) {
 
      message.error(error?.response?.data?.message || error?.message);
      console.error('Error publishing announcement:', error);
    }
  };

  return (
    <>
      <div className='mt-8'>
        <div className='flex justify-between items-center'>
          <h1 className='text-2xl font-bold'>Announcements</h1>
         
          <CustomButton
            text='Add Announcement'
            icon={<PlusOutlined />}
            onClick={() => setAnnouncementsModel(true)}
          />
        </div>

        <div className='mt-4'>
          {announcements?.length === 0 && (
            <h1 className='text-lg font-bold'>No Announcements</h1>
          )}
          {announcements?.map((announcement, index) => (
            <div
              key={index}
              className='flex justify-between items-center bg-gray-50 p-4 rounded mb-2 shadow-md'
            >
              <div>
                <h1 className='text-lg font-bold'>{announcement?.AnnouncementTitle}</h1>
                <p
                  className='text-sm'
                  dangerouslySetInnerHTML={{ __html: announcement?.AnnouncementDescription }}
                ></p>
                <div className='flex flex-row items-center mb-2'>
                  {announcement?.AnnouncementLinks?.map((link, linkIndex) => (
                    <button
                      key={linkIndex}
                      className='bg-rose-500 text-white px-2 rounded border-none mr-2 hover:bg-rose-400'
                      onClick={() => window.open(link, '_blank')}
                    >
                      Button {linkIndex + 1}
                    </button>
                  ))}
                </div>
                <div className='text-sm text-gray-500 font-bold'>
                  Posted on: {new Date(announcement?.AnnouncementDate).toDateString()}
                </div>
              </div>
              <label className='flex items-center justify-center cursor-pointer'>
                <div className='relative'>
                  <input
                    type='checkbox'
                    className='sr-only'
                    checked={!announcement?.isPublished}
                    onChange={() => handlePublishedChange(announcement._id)}
                  />
                  <div
                    className={`block ${
                      announcement?.isPublished ? 'bg-green-400' : 'bg-gray-200'
                    } w-12 h-6 rounded-full`}
                  ></div>
                  <div
                    className={`dot absolute ${
                      announcement?.isPublished ? 'left-1 top-1' : 'left-7 top-1'
                    } bg-white w-4 h-4 rounded-full transition`}
                  ></div>
                </div>
              </label>
            </div>
          ))}
        </div>
      </div>

      {announcementsModel && (
        <AnnouncementModel setAnnouncementsModel={setAnnouncementsModel}getAnnouncements={getAnnouncements} />
      )}
    </>
  );
};

export default Announcements;
