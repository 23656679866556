export const   initialValues = {
    courseName: "",
    videoFile: null,
    pdfFile: null,
    videoPreview: "",
    RepresentativeLink: "",
    courseOutlinePdf: "",
    selectedTeacher: [],
    courseOutline: "",
    selectedDuration: {
      startDate: null,
      endDate: null,
    },
    courseXplans: [],
    tag: "",
    desc: "",
    colorCode: "",
    tagDetails: [
      {
        description: "",
        logo: "",
        isActive: true,
        position: "",
      },
    ],
  }