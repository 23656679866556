import {  message,  } from 'antd';
import { FormikHelpers, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { apiRequest } from '../../(apis)/api-interface/api-handler';
import { AddFeatureProps, FormValues } from './types';
import CustomAntdModal from '../../shared-components/modal/CustomAntdModal';
import CustomFormField from 'shared-components/form-fields/custom-form-field';



const FeatureModal: React.FC<AddFeatureProps> = ({
  setShowModal,
  showModal,
  isEditMode,
  getAllCourseFeatures,
  setIsEditMode,
  clickedFeatureData,
}) => {
  const [iconPreviewUrl, setIconPreviewUrl] = useState<string | null>(null);



  const validationSchema = Yup.object().shape({
    featureName: Yup.string().required('Feature Name is required'),
    desc: Yup.string()
      .required('Feature Description is required')
      .min(10, 'Minimum 10 characters')
      .max(200, 'Maximum 200 characters'),
      icon: isEditMode ? Yup.mixed().nullable() : Yup.mixed().required('Icon is required').test('fileType', 'Icon must be a JPEG or PNG image', (value:any) => {
        if (!value) return false; // Reject null values
        const supportedFormats = ['image/jpeg', 'image/png'];
        return supportedFormats.includes(value?.iconData?.type);
      }),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      featureName: clickedFeatureData?.featureName || '',
      desc: clickedFeatureData?.desc || '',
      link: clickedFeatureData?.link || '',
      icon: null,
      editFeatureId: clickedFeatureData?._id || '',
    },
    validationSchema,
    onSubmit: async (values, { resetForm }: FormikHelpers<FormValues>) => {
      try {
        const { featureName, desc, icon, editFeatureId ,link} = values;
        const payload = { featureName, desc, icon ,link};

        const res = isEditMode
          ? await apiRequest("updateCourseFeature",payload, [editFeatureId as string])
          : await apiRequest("addCourseFeature",payload);

        if (res?.status === 200) {
          
          message.success(res?.data?.message);
          resetForm();
          getAllCourseFeatures();
          setIsEditMode(false);
          setShowModal(false);
        }
      } catch (error) {
        console.error('Error:', error);

        message.error(error?.response?.data?.message || error.message);
      } 
    },
  });
const {  touched, errors, handleSubmit, resetForm } = formik;
  useEffect(() => {
    if(isEditMode){
      if (clickedFeatureData) {
        formik.setValues({
          featureName: clickedFeatureData?.featureName || '',
          desc: clickedFeatureData?.desc || '',
          link: clickedFeatureData?.link || '',
          icon: {
            iconData: null,
            iconUrl: clickedFeatureData?.iconUrl || null,
          },
          editFeatureId: clickedFeatureData?._id || '',
        });
        setIconPreviewUrl(clickedFeatureData?.iconUrl || null);
      }
    }else{
      resetForm();
    }
   
  }, [clickedFeatureData, isEditMode]);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0] || null;
    if (file) {
      const iconUrl = URL.createObjectURL(file);
      setIconPreviewUrl(iconUrl);

      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result as string;
        formik.setFieldValue('icon', {
          iconData: {
            data: base64Data,
            type: file.type,
          },
          iconUrl: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const clearSelectedIcon = () => {
    formik.setFieldValue('icon', null);
    setIconPreviewUrl(null);
  };

  return (
    <CustomAntdModal  isOpenModal={showModal}
    title={isEditMode ? `Edit Feature` : `Add Feature `}
    setIsOpenModal={setShowModal} formik={formik}>
      <div className='m-4'>
          <form onSubmit={handleSubmit} encType="multipart/form-data" className='mx-4'>
            <div className='flex flex-col'>
              <div className='flex flex-col md:flex-row'>
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                
                  <CustomFormField type='text' name="featureName" formik={formik} label="Feature Name" />
                </div>
              </div>
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                
                  <CustomFormField type='text' name="link" formik={formik} label="Link" />
                </div>
              </div>

              </div>
              <div className="md:p-2 w-full">
                <div className="relative">
                  <CustomFormField require={false} type='textarea' name="desc" formik={formik} label="Description" />
                </div>
              </div>
              <div className="p-2">
                <div className="relative">
                  <div className="flex flex-col">
                    <h6 className="text-md font-bold text-gray-900 mt-2.5">Upload Icon</h6>
                    <div className="flex flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                      <div className="flex flex-col items-center gap-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                        </svg>
                        <button
                          className="border px-3 py-2 bg-gray-100 text-sm rounded-lg"
                          onClick={() => document.getElementById('icon')?.click()}
                        >
                          {iconPreviewUrl ? 'Change Icon' : 'Upload Icon'}
                        </button>
                      </div>
                      {iconPreviewUrl && (
                        <div className="flex flex-col items-center">
                          <img
                            src={iconPreviewUrl}
                            alt="Icon Preview"
                            className="h-20 w-20 object-contain rounded-full"
                          />
                          <button
                            className="text-xs text-red-500 mt-2 underline cursor-pointer"
                            onClick={clearSelectedIcon}
                          >
                            Remove Icon
                          </button>
                        </div>
                      )}
                      <input
                        id="icon"
                        type="file"
                        name="icon"
                        onChange={handleFileChange}
                        hidden
                        accept="image/png, image/jpeg"
                      />
                      {touched.icon && errors.icon && (
                        <div className="text-red-500 text-sm"> {errors?.icon as string}</div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          
          </form>
      </div>
    </CustomAntdModal>
  );
};

export default FeatureModal;
