import { SearchIcon } from "../../../../shared-components/ui/Icons";

interface TableTopProps {
  searchQuery: string;
  handleSearchQueryChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}


export const TableTop = ({
  searchQuery,
  handleSearchQueryChange,
}: TableTopProps) => {

  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
    </div>
  );
};