import { useFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";

import VisibilityIcon from "@material-ui/icons/Visibility";
import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon } from "shared-components/ui/Icons";
import { apiRequest } from "(apis)/api-interface/api-handler";

const Lectures = ({
  scheduledData,
  id,
  getScheduleData,
  loading,
  setLoading,
}) => {
  const [isAddingLecture, setIsAddingLecture] = useState(false);
  const [isEditMode, setIsIsEditMode] = useState(false);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);
  const initialValues = {
    lectureName: "",
    lectureLink: null,
    lectureId: null,
    video: null,
  };
  const validationSchema = Yup.object().shape({
    lectureName: isEditMode
      ? Yup.string()
          .required("Name is required")
          .test("unique", "Lecture name already exist.", function (value) {
            const existingLecturesExceptCurrent =
              scheduledData?.lectureLinks?.filter(
                (lectureId) => lectureId._id !== values?.lectureId
              );
            const existingLectureNames = existingLecturesExceptCurrent?.map(
              (lecture) => lecture.lectureName
            );
            return !existingLectureNames?.includes(value);
          })
      : Yup.string()
          .required("Name is required")
          .test("unique", "Lecture name already exist.", function (value) {
            const existingLectureNames = scheduledData?.lectureLinks?.map(
              (lecture) => lecture.lectureName
            );
            return !existingLectureNames?.includes(value);
          }),
    lectureLink: Yup.string().nullable().url("Invalid URL format"),
    video: Yup.mixed()
      .nullable()
      .test("fileOrLink", function (value: any) {
        const { lectureLink, video } = this.parent;
        if (!lectureLink && !video) {
          return this.createError({
            message: "At least one of video or lecture link is required.", // Custom message when neither video nor lecture link is provided
          });
        }
        if (lectureLink && video) {
          return this.createError({
            message:
              "Please choose either a video file or a lecture link, but not both.", // Custom message when both video and lecture link are provided
          });
        }
        if (video) {
          const fileType = value && value.type;
          const fileSize = value && value.size;
          if (!["video/mp4"].includes(fileType)) {
            return this.createError({
              message: "Please upload a video file (MP4).",
            });
          }
          if (fileSize && fileSize > 500 * 1024 * 1024) {
            // 100MB in bytes
            return this.createError({
              message: "Video size must be less than or equal to 500MB.",
            });
          }
        }
        return true;
      }),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsAddingLecture(true);
        const { lectureName, lectureLink, video } = values;
        const formData = new FormData();
        formData.append("lectureName", lectureName || "");
        formData.append("lectureLink", lectureLink || "");
        formData.append("video", video || "");
        let res;
        if (isEditMode) {
          res = await apiRequest("updateManageScheduleLecture", formData, [
            id,
            values?.lectureId,
          ]);
        } else {
          res = await apiRequest("addManageScheduleLecture", formData, [id]);
        }

        if (res?.status === 200) {
          setIsAddingLecture(false);
          setIsIsEditMode(false);
          message.success(res?.data?.message);
          getScheduleData().then(() => console.log());
          setFieldValue("lectureLink", "");

          clearSelectedVideo();
          resetForm();
        }
      } catch (error) {
        setIsAddingLecture(false);
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { values, handleSubmit, setFieldValue, setValues, touched, errors } =
    formik;
  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];

    if (file) {
      setFieldValue("video", file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const clearSelectedVideo = () => {
    setFieldValue("video", null);
    setFieldValue("lectureLink", null);
    setVideoPreviewUrl(null);
    const fileInput = document.getElementById("video") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
    const lectureLink = document.getElementById(
      "lectureLink"
    ) as HTMLInputElement;
    if (lectureLink) {
      lectureLink.value = "";
    }
  };

  const handleLectureIsPublish = async (data) => {
    try {
      const res = await apiRequest("updateManageSchedulePublishLecture", null, [
        id,
        data?.id,
      ]);
      if (res?.status === 200) {
        getScheduleData().then(() => console.log());
        message.success(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    if (isEditMode) {
      setVideoPreviewUrl(values?.lectureLink);
    }
  }, [values?.lectureLink, isEditMode]);
  const handleEdit = (data) => {
    clearSelectedVideo();
    // setVideoPreviewUrl(null)
    setValues({
      ...data,
      lectureName: data?.lectureName || "",
      lectureLink: data?.lectureLink || "",
      lectureId: data?.id || "",
    });
    // setVideoPreviewUrl(data?.lectureLink)
    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsIsEditMode(true);
  };
  interface DataType {
    lectureName: string;
    isPublished: boolean;
    // noteLink: string;
    // Add other fields as needed
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "LECTURE NAME",
      dataIndex: "lectureName",
      key: "lectureName",
      width: 200,
    },
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",
      align: "center",
      width: 50,
      render: (data) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handleLectureIsPublish(data)}
          />
        </div>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      width: 100,
      render: (data) => (
        <div className="flex gap-4 justify-center">
          <div onClick={() => handleEdit(data)}>
            <EditIcon />
          </div>
          <a rel="noreferrer" href={`${data?.lectureLink}`} target="_blank">
            <VisibilityIcon />
          </a>
        </div>
      ),
    },
  ];

  const formattedLectureData = scheduledData?.lectureLinks?.map(
    ({ lectureLink, lectureName, isPublished, _id }, i) => {
      return {
        key: i + 1,
        lectureLink,
        lectureName,
        isPublished: { isPublished, id: _id },
        id: _id,
      };
    }
  );

  return (
    <div className="">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="rounded-lg shadow p-4 bg-white "
      >
        <div className="flex flex-row  w-full">
          <div className="w-4/12">
            <div className="md:p-2 w-full">
              <div className="relative">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="lectureName"
                  label="Lecture Name"
                />
              </div>
              <div className="relative">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="lectureLink"
                  label="Lecture Link"
                />
              </div>
            </div>
          </div>
          <div className="w-1/12 text-center">
            <div className="flex justify-center items-center h-full">OR</div>
          </div>
          <div className="w-7/12 flex gap-3">
            <div className="w-1/2">
              <div className="flex  h-full  flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                <div className="flex flex-col items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  <button
                    className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                    onClick={(event) => {
                      event.preventDefault();
                      document.getElementById("video").click();
                    }}
                  >
                    Upload Video
                  </button>
                  <p>File accepted: MP4 (Max.500MB)</p>
                  <input
                    type="file"
                    id="video"
                    name="video"
                    accept="video/*"
                    className="hidden"
                    onChange={handleFileChange}
                  />
                  {videoPreviewUrl && (
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                      onClick={clearSelectedVideo}
                    >
                      Remove Video
                    </button>
                  )}
                </div>
              </div>

              {touched?.video && errors?.video && (
                <div className="text-red-700">{errors?.video as string}</div>
              )}
            </div>
            <div className="w-1/2">
              {videoPreviewUrl ? (
                <div className="flex flex-col items-center gap-2">
                  <video
                    controls
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "240px",
                      width: "100%",
                    }}
                  >
                    <source src={videoPreviewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <>
                  <div className="flex h-full flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                    <div className="flex flex-col items-center gap-2">
                      <p>Video Preview</p>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          {touched?.video && errors?.video && (
            <div className="text-red-700">{errors?.video as string}</div>
          )}
        </div>
        <CustomButton
          disabled={isAddingLecture}
          onClick={()=>formik.submitForm()}
          text={`${
            isAddingLecture
              ? "Adding..."
              : isEditMode
              ? "Edit Confirm"
              : "Add Lecture"
          }`}
        />
      </form>

      <Loader loading={loading} />
      {formattedLectureData?.length > 0 && (
        <GlobalTable
          columns={columns}
          data={formattedLectureData}
          bordered={true}
          searchPlaceholder="Search Lectures"
          loading={loading}
        />
      )}
    </div>
  );
};

export default Lectures;
