import * as Yup from "yup";
import { commonValidationSchema } from "../../components/validation-schema";
export const  mnemonicsSchema = Yup.object().shape({
  ...commonValidationSchema.fields,
  videoUrl: Yup.string().nullable(),
  imageUrl: Yup.string().nullable(),
  thumbnailUrl: Yup.string().nullable(),
  thumbnailImage: Yup.mixed().required("Thumbnail is required"),
  image: Yup.mixed().nullable().test("oneOfRequired", "Only one of image, video link, or video file can be selected", function (value:any) {
    const { videoLink, videoFile } = this.parent;
    if (value?.length>0 && (videoLink || videoFile?.length > 0)) {
      return this.createError({
        message: "You can only select one: image, video link, or video file.1",
      });
    }
    if (!value?.length && !videoLink && !videoFile?.length) {
      return this.createError({
        message: "At least one of image, video link, or video file is required.",
      });
    }
    return true;
  }),
  videoLink: Yup.string()
    .url("Invalid URL")
    .nullable()
    .test("oneOfRequired", "Only one of image, video link, or video file can be selected", function (value:any) {
      const { image, videoFile } = this.parent;
      if (value && (image?.length>0 || videoFile?.length>0)) {
        return this.createError({
          message: "You can only select one: image, video link, or video file.2",
        });
      }
      if (!value && !image?.length && !videoFile?.length) {
        return this.createError({
          message: "At least one of image, video link, or video file is required.",
        });
      }
      
      return true;
    }),
  videoFile: Yup.mixed()
    .nullable()
    .test("fileFormat", "Please upload a video file.", function (file:any) {
      const { image, videoLink } = this.parent;
      
      if (file?.length >0 && (image?.length>0 || videoLink)) {
        return this.createError({
          message: "You can only select one: image, video link, or video file.3",
        });
      }
      
      if (!file?.length && !image?.length && !videoLink) {
        return this.createError({
          message: "At least one of image, video link, or video file is required.",
        });
      }
      if (file?.length > 0) {
        if (!file[0]?.type.startsWith("video/mp4")) {
          return this.createError({
            message: "File must be a video mp4.",
          });
        }
        if (file[0]?.size > 100 * 1024 * 1024) {
          return this.createError({
            message: "File size must be less than 100MB.",
          });
        }
      }

      return true;
    })
})
