import { useParams } from "react-router-dom";
import CourseTabs from "./course-Tabs";
interface ManageCourseParams {
  [key: string]: any;
}
const EditCourse = () => {
  const { id } = useParams<ManageCourseParams>();
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const access = params.get("access");
  return (
    <div>
      <CourseTabs access={access} />
    </div>
  );
};

export default EditCourse;
