import React, { useState } from "react";

import { ExclamationCircleOutlined } from "@ant-design/icons";
import {
  AutoComplete,
  Form,
  Input,
  Modal,
  Select,
  message
} from "antd";
import { AccessAPI } from "../../../(apis)/(shared-apis)/admin-access-apis";
import toast from "react-hot-toast";

const { confirm } = Modal;

export default function AddNewEmployee({
  modalVisible,
  onCloseModal,
  existingAdminUsers,
  usersEmails,
}) {
  const [form] = Form.useForm();

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const onFinish = (values: { username: string; dept: string; role: string }) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Confirm you want to add a user",
      okText: "Add",
      okType: "primary",
      cancelText: "Back",
      onOk() {
        var data = form.getFieldsValue();
        data.username = data.username.toLowerCase();
        SetWaitingOnResponse(true);
        AccessAPI("addAdminUser", [], { username: data.username, dept: data.dept, role: data.role })
          .then((res) => {
            toast.success("User added successfully");
            SetWaitingOnResponse(false);
            onCloseModal();
            form.resetFields();
          })
          .catch(() => {
            message.warning(
              "A Server/Network Error Occured, please check your connection"
            );
            SetWaitingOnResponse(false);
          });
      },
      onCancel() {},
    });
  };

  if (waitingOnResponse) {
    return (
      <Modal
        title={`Loading`}
        forceRender
        visible={true}
        width="400px"
        okButtonProps={{ hidden: true }}
        cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        Loading{" "}
      </Modal>
    );
  }

  return (
    <div>
      <Modal
        title={`Add new admin user`}
        forceRender
        visible={modalVisible}
        onCancel={onCloseModal}
        okText="Add User"
        cancelText="Cancel"
        width="600px"
        okButtonProps={{ hidden: true }}
        // cancelButtonProps={{ hidden: true }}
        maskClosable={false}
      >
        <Form
          name="basic"
          form={form}
          initialValues={{ remember: true }}
          onFinish={onFinish}
          //onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout={"vertical"}
          size="large"
          onChange={() => {
            // SetMessage(form.getFieldsValue());
            console.log(form.getFieldsValue());
            //console.log(form.getFieldsError())
            // console.log(form.isFieldsTouched(["SchoolColor2"]));
          }}
        >
          <Form.Item
            label="User Name"
            name="username"
            rules={[
              {
                required: true,
                message: "Please input a User Name",
              },
              { type: "email", message: "Please enter a valid email" },
              {
                message: "This username is already an admin user",
                validator: (_, value) => {
                  if (
                    !existingAdminUsers
                      .map((u) => u.username.toUpperCase().trim())
                      .includes(value.toUpperCase().trim())
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("This user name already exists");
                  }
                },
              },
              {
                message: "This email is not registered on PreMed.PK",
                validator: (_, value) => {
                  if (
                    usersEmails
                      .map((u) => u?.toUpperCase().trim())
                      .includes(value.toUpperCase().trim())
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject(
                      "This email is not registered on PreMed.PK"
                    );
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Department"
            name="dept"
            rules={[
              {
                required: true,
                message: "Please input the Department",
              },
            ]}
          >
            <Select
              showSearch
              options={[
                { value: "Core Team", label: "Core Team" },
                { value: "Education", label: "Education" },
                { value: "Marketing", label: "Marketing" },
                { value: "Other", label: "Other" },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Role"
            name="role"
            rules={[
              {
                required: true,
                message: "Please enter a Role",
              },
            ]}
          >
            <AutoComplete
              placeholder="Eg. Associate or Manager"
              options={[
                { value: "Associate" },
                { value: "Manager" },
                { value: "Fixer" },
                { value: "Uploader" },
              ]}
            />
          </Form.Item>
          <Form.Item shouldUpdate={true}>
            {() => {
              return (
                <button
                  // htmlType="submit"
                  // disabled={
                  //   form.getFieldsError().filter(({ errors }) => errors.length)
                  //     .length
                  // }
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded hover:shadow-lg hover:text-white"
                >
                  Add new user
                </button>
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
