import React from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableQuestion from './draggable-question'

const PublishedTable = ({ questions, setQuestions, deckInformation, getQuestions, deckName }) => {

    const moveQuestion = (fromIndex: number, toIndex: number) => {
      const updatedQuestions = [...questions];
      const [movedQuestion] = updatedQuestions.splice(fromIndex, 1);
      updatedQuestions.splice(toIndex, 0, movedQuestion);
      setQuestions(updatedQuestions);
    };
  
  
    return (<DndProvider backend={HTML5Backend}>
      <div className="mx-auto p-12 pt-0">
        <div className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold">
          <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-4 text-left">
                    SR#
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Question Id
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Question Text
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Tags
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Published
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Preview
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Edit
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
                {questions &&
                  questions.map((question, index) => (
                    <DraggableQuestion
                      key={question._id}
                      question={question}
                      index={index}
                      setQuestions={setQuestions}
                      questions={questions}
                      deckId={deckInformation._id}
                      getQuestions={getQuestions}
                      deckName={deckName}
                      moveQuestion={moveQuestion}
                    />
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </DndProvider>
    )
  }

export default PublishedTable