import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import EditSchedule from './edit-schedule';
import ScheduleTabs from './schedule-tabs';


interface ScheduleData {
  // Define the structure of scheduledData based on the expected API response
  // Example:
  topic: string;
  date: string;
  markedValue: string;
  subject: string;
  // Add more fields as necessary
}

const ManageSchedule: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const [scheduledData, setScheduledData] = useState<ScheduleData[]>([]);
  const getScheduleData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getScheduleById", null,[id]);
      if (res?.status === 200) {
        const resData = res?.data?.data;
        setScheduledData(resData);
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getScheduleData().then(() => console.log());
  }, []);

  return (
    <div>
      <div className="mx-auto flex flex-col gap-4 w-3/4 ">
        <EditSchedule 
          scheduledData={scheduledData} 
          id={id} 
          getScheduleData={getScheduleData} 
        //   loading={loading} 
        //   setLoading={setLoading} 
        />
     
        <ScheduleTabs 
          getScheduleData={getScheduleData} 
          scheduledData={scheduledData} 
          id={id} 
          loading={loading} 
          setLoading={setLoading} 
        />
      </div>
    </div>
  );
};

export default ManageSchedule;
