import React from "react";
import { useState, useEffect, useContext } from "react";
// @ts-ignore
import styles from "./styles.module.css";
// import { notification } from "antd";

import { useLocation } from "react-router-dom";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useParams } from "react-router-dom";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";
import Loading from "shared-components/ui/Loading";

export default function ExpertDashboardSubject() {
  const { subject } = useParams();
  const { user } = useContext(UserContext);
  const [Doubts, setDoubts] = useState([]);
  const [loading, setloading] = useState<boolean>(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get("email");

  const fetchDoubts = async () => {
    try {
      var subjectLowercase = subject?.toLowerCase();
      if (subjectLowercase === "logical reasoning") {
        subjectLowercase = subjectLowercase.replace(/\s+/g, "-");
      }
      
      setloading(true);
      const response = await apiRequest("GetExpertSolvedDoubts", {
        subject: subjectLowercase,
        username: email ? email : user.username,
      });
      const data = response.data;
      setDoubts(data.result);
      setloading(false);
    } catch (error) {
      setloading(false);
      console.error("Error fetching doubts:", error);
    }
  };

  useEffect(() => {
    fetchDoubts();
  }, []);

  return (
    <>
      {loading && <Loading />}
      <div className="flex justify-between items-center flex-col">
        <h3 className="text-center mt-2">Expert Dashboard</h3>

        <div className="container my-4">
          <div className="bg-rose-100 p-4 rounded-lg flex justify-between items-center text-red-600 font-bold mb-2">
            Questions solved by {email}
          </div>
          <TableContainer className="bg-white rounded-lg">
            <Table className="min-w-full">
              <TableHead className="bg-rose-500">
                <TableRow className="bg-rose-500 p-4 mb-2 rounded-md">
                  <TableCell
                    className="text-left text-red-500!important"
                    style={{ width: "45%" }}
                  >
                    Tags
                  </TableCell>
                  <TableCell
                    className="text-left text-red-500"
                    style={{ width: "45%" }}
                  >
                    Question Text
                  </TableCell>
                  <TableCell className="text-left text-red-500">
                    View Video
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Doubts && Doubts.length === 0 ? (
                  <div className="container text-center my-4">
                    <h5>No Doubts Solved Yet</h5>
                  </div>
                ) : (
                  <>
                    {Doubts &&
                      Doubts.map((doubt) => (
                        <TableRow key={doubt.id} className="table-row">
                          <TableCell id={styles.TagCell}>
                            {doubt.Tags.map((tag) => (
                              <div className="bg-rose-50 px-2 py-1 rounded-md mr-2 text-sm text-red-500 border border-rose-500">
                                {tag.name}
                              </div>
                            ))}
                          </TableCell>
                          <TableCell
                            id="table-cell"
                            style={{ cursor: "pointer" }}
                          >
                            {doubt.QuestionText.slice(0, 55)
                              ?.replace("<p>", "")
                              .replace("</p>", "")
                              .replace("<span", "")
                              .replace("</span>", "") + "..."}
                          </TableCell>
                          <TableCell className="flex justify-center items-center">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="currentColor"
                              className="w-6 h-6 text-gray-500 hover:text-rose-700"
                              onClick={() => {
                                window.open(`${doubt?.videoLink}`, "_blank");
                              }}
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
                              />
                            </svg>
                          </TableCell>
                        </TableRow>
                      ))}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>
    </>
  );
}
