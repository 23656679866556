import { CustomBundleAPI } from '(apis)/(shared-apis)/custom-bundle-apis';
import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Overview from './components/overview';

export default function PackageEdit() {
  const { id } = useParams();
  const [bundleData, setbundleData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const fetchPackage = () => {
    setIsLoading(true);
    CustomBundleAPI("getBundleById", [id]).then((response) => {
      setbundleData(response.data.package);
      setIsLoading(false);
    }).catch((error) => {
      console.error("Error getting package data:", error);
      setIsLoading(false);
      toast.error("Failed to fetch package");
    });
  }
  useEffect(() => {
    fetchPackage();
  }, []);



  return (
    <div className="w-full pb-20 px-5">
      {isLoading ? <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ top: "50%" }}>
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div> :
        <Overview
          bundleData={bundleData}
          setbundleData={setbundleData}
          id={id}
          fetchPackage={fetchPackage}
        />

      }


      <Toaster />
    </div>
  )
}


