import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload } from 'antd';
import { FormikProps } from 'formik';
import React from 'react';
import CustomFormikError from './CustomFormikError';
import { getErrorString } from "../../utility/form/functions";
import LabelComponent from './LabelComponent';

interface CustomVideoFieldProps {
  label: string;
  formik: FormikProps<any>;
  name: string;
  require?: boolean;
  isMultiple?: boolean;
}

const CustomVideoField: React.FC<CustomVideoFieldProps> = ({ label, formik, name, require = false, isMultiple = false }) => {
    const touched = !!formik.touched[name];
    const error = getErrorString(formik.errors[name]);
  return (
    <>
      <div className="flex flex-col gap-3">
        <LabelComponent value={label} require={require} />
        <Upload
          listType="text"
          fileList={formik.values[name] || []}
          onChange={(info) => {
            formik.setFieldValue(name, info.fileList);
          }}
          accept="video/mp4"
          beforeUpload={() => false}
          multiple={isMultiple}
          className="w-full"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>
            Click to Upload
          </Button>
        </Upload>
      </div>
      <CustomFormikError
        name={name}
        touched={touched}
        error={error}
      />
    </>
  );
}

export default CustomVideoField;
