
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { apiRequest } from "../../(apis)/api-interface/api-handler";
import { message } from "antd";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import { FormValues, TeacherModalProps } from "./types";
import CustomFormField from "shared-components/form-fields/custom-form-field";
const TeacherModal: React.FC<TeacherModalProps> = ({
  setShowModal,
  showModal,
  isEditMode,
  getAllTeachers,
  setIsEditMode,
  clickedTeacherData,
}) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState<string | null>(null);
  const validationSchema = Yup.object().shape({
    teacherName: Yup.string()
      .required("Teacher Name is required")
      .min(4, "minimum 4 characters"),
    teacherIntroduction: Yup.string()
      .required("Teacher Introduction is required")
      .min(50, "minimum 50 characters")
      .max(200, "maximum 200 characters"),
    image: isEditMode
      ? Yup.mixed().required("Image is required")
      : Yup.mixed()
          .required("Image is required")
          .test("fileType", "Image must be a JPEG or PNG image", (value:any) => {
            if (!value) return false;
            const supportedFormats = ["image/jpeg", "image/png"];
            return supportedFormats.includes(value?.imageData?.type || "");
          }),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      teacherName: "",
      teacherIntroduction: "",
      image: null,
      teacherEditId: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
      
        const { teacherName, teacherIntroduction, image, teacherEditId } = values;
        const payload = {
          teacherName,
          teacherIntroduction,
          image,
        };
        let res;
        if (isEditMode) {
          res = await apiRequest("updateTeacher",payload, [teacherEditId]);
        } else {
          res = await apiRequest("addTeacher",payload);
        }
        if (res?.status === 200) {
          message.success(res?.data?.message);
          resetForm();
          getAllTeachers();
          setShowModal(false);
          setIsEditMode(false);
       
        }
      } catch (error) {
        console.error(error);
        message.error(error?.response?.data?.message || error.message);
     
      }
    },
  });
  const {
    resetForm,
    handleSubmit,
    setFieldValue,
    setValues,
    touched,
    errors,

  } = formik;
  useEffect(() => {
    if (!isEditMode) {
      resetForm();
      setImagePreviewUrl(null);
    }
    if(clickedTeacherData){
      setValues({
        teacherName: clickedTeacherData?.name || "",
        teacherIntroduction: clickedTeacherData?.expandData || "",
        image: {
          imageData: null,
          imageUrl: clickedTeacherData?.imageUrl || null,
        },
        teacherEditId: clickedTeacherData?._id || "",
      });
      setImagePreviewUrl(clickedTeacherData?.imageUrl || null);
    }
  },  [clickedTeacherData, isEditMode, setValues, resetForm]);
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.currentTarget.files?.[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setImagePreviewUrl(imageUrl);
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Data = reader.result;
        setFieldValue("image", {
          imageData: {
            data: base64Data,
            type: file.type,
          },
          imageUrl: null,
        });
      };
      reader.readAsDataURL(file);
    }
  };

  const clearSelectedImage = () => {
    setFieldValue("image", null);
    setImagePreviewUrl(null);
    const fileInput = document.getElementById("image") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  return (
    <CustomAntdModal isOpenModal={showModal}
    title={isEditMode ? `Edit Teacher` : `Add Teacher `}
    setIsOpenModal={setShowModal} formik={formik}>
      <div className="m-4">
      <form onSubmit={handleSubmit} encType="multipart/form-data" className="mx-4">
            <div className="flex flex-col ">
              <CustomFormField  name="teacherName" label="Teacher Name" type="text"  formik={formik}/>
              <div className="md:p-2 w-full">
              <CustomFormField  name="teacherIntroduction" label="Introduction" type="textarea"  formik={formik}/>
              </div>
              <div className="p-2">
                <div className="relative">
                  <div className="flex flex-col">
                    <h6 className="text-md font-bold text-gray-900 mt-2.5">
                      Upload Image
                    </h6>
                    <div className="flex flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                      <div className="flex flex-col items-center gap-2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-8 h-8"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                          />
                        </svg>
                        <button
                          className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                          onClick={(event) => {
                            event.preventDefault();
                            document.getElementById("image")?.click();
                          }}
                        >
                          Upload Image
                        </button>
                        <p>File accepted: jpeg,png</p>
                        <input
                          type="file"
                          id="image"
                          name="image"
                          accept="image/jpeg, image/png"
                          className="hidden"
                          onChange={handleFileChange}
                        />
                        {imagePreviewUrl && (
                          <div className="relative">
                            <img src={imagePreviewUrl} alt="preview" className="w-24 h-24 object-cover" />
                            <button
                              type="button"
                              onClick={clearSelectedImage}
                              className="absolute top-0 right-0 p-1 bg-red-500 text-white rounded-full"
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                strokeWidth={1.5}
                                stroke="currentColor"
                                className="w-4 h-4"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  d="M6 18L18 6M6 6l12 12"
                                />
                              </svg>
                            </button>
                          </div>
                        )}
                      </div>
                    </div>
                    {touched.image && errors.image && (
                      <div className="text-red-500 text-sm mt-1">
                      {errors.image as string}
                      </div>
                    )}
                  </div>
                </div>
              </div>
           
            </div>
          </form>
      </div>
    </CustomAntdModal>
  );
};

export default TeacherModal;
