import { ApiInterface } from "interfaces/global-interfaces";

export const courseAssignmentApis: ApiInterface[] = [
    {
        url: "/api/course-assignments/get/:id",
        method: "GET",
        name: "getCourseAssignments",
    },
    {
        url: "/api/course-assignments/getById/:id",
        method: "GET",
        name: "getCourseAssignmentById",
    },
    {
        url: "/api/course-assignments/add/:id",
        method: "POST",
        name: "addCourseAssignment",
        contentType: 'multipart/form-data',  // Replaced headers with contentType
    },
    {
        url: "/api/course-assignments/updateActive/:id",
        method: "PUT",
        name: "updateCourseAssignmentActive",
    },
    {
        url: "/api/course-assignments/update/:id",
        method: "PUT",
        name: "updateCourseAssignment",
        contentType: 'multipart/form-data',  // Replaced headers with contentType
    },
    {
        url: "/api/course-assignments/fetchSubmittedAssignmentsByAssignmentId/:id",
        method: "GET",
        name: "fetchSubmittedAssignmentsByAssignmentId",
    },
    {
        url: "/api/course-assignments/updateAssignmentsGradeByAssignmentIdUserId/:userId/:assignmentId",
        method: "PUT",
        name: "updateAssignmentsGradeByAssignmentIdUserId",
    },
];
