import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

// Define the types for your data
interface MonthlySignUpsBarChartData {
  name: string;
  value: number;
}

// Define the types for your props
interface MonthlySignUpsBarChartProps {
  data: MonthlySignUpsBarChartData[];
}

const MonthlySignUpsBarChart: React.FC<MonthlySignUpsBarChartProps> = ({ data: barChartData }) => {
  return (
    <ResponsiveContainer width="100%" height={500}>
      <BarChart
        data={barChartData}
        margin={{
          top: 20, right: 30, left: 20, bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="value" fill="#8884d8" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default MonthlySignUpsBarChart;
