import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalTable from "shared-components/table/GlobalTable";
import { UserContext } from "state-management/user-context";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import PaymentView from "../employee/view-modal";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  type: Yup.string().required("Type is required"),
});
const ServiceProviders = () => {
  const [addPopup, setAddPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [ServiceProviderData, setServiceProviderData] = useState([]);
  const [viewModel, setViewModal] = useState(false);
  const [currentServiceProviderData, setCurrentServiceProviderData] = useState(
    []
  );
  const { user } = useContext(UserContext);
  const fetchServiceProviders = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllServiceProviders");
      if (res.status === 200) {
        if (res?.data?.serviceProviders?.length > 0) {
          const updatedRow = res?.data?.serviceProviders?.map((item) => {
            return {
              ...item,
              key: item._id,
              id: item._id,
            };
          });
          setServiceProviderData(updatedRow);
        } else {
          setServiceProviderData([]);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchServiceProviders();
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      editServiceProviderId: "",
      type: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Add your submission logic here
      const payload = {
        ...values,
        addedBy: user?.fullname,
      };
      try {
        const { editServiceProviderId } = values;
        let res;
        if (isEditMode) {
          res = await apiRequest("updateServiceProvider", payload, [
            editServiceProviderId,
          ]);
        } else {
          res = await apiRequest("addServiceProvider", payload);
        }
        console.log("res: " + res);
        if (res.data?.isSuccess) {
          fetchServiceProviders();
          message.success(res?.data?.message);
          setAddPopup(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { handleSubmit, setValues } = formik;
  const handleEdit = (record) => {
    const { id } = record;
    setValues({
      ...record,
      editServiceProviderId: id,
    });
    setIsEditMode(true);
    setAddPopup(true);
  };
  const columns = [
    {
      title: " Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>,
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Added By",
      dataIndex: "addedBy",
      key: "addedBy",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div
            className="text-xl flex gap-4"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
          <div
            className="text-xl flex gap-4"
            onClick={() => {
              setCurrentServiceProviderData(record);
              setViewModal(true);
            }}
          >
            <EyeIcon color="text-gray-800" size="w-6 h-6"/>
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="">
        <div className={`flex justify-end items-end  gap-4`}>
          <CustomButton
            text="Add New Service Provider"
            onClick={() => {
              // setOpen(true)
              setAddPopup(true);
              setIsEditMode(false);
              formik.resetForm();
            }}
            icon={<PlusOutlined />}
          />
        </div>
        <div>
          <GlobalTable
            loading={isLoading}
            columns={columns}
            data={ServiceProviderData}
          />
        </div>
      </div>
      <CustomAntdModal
        isOpenModal={addPopup}
        title={isEditMode ? `Edit Service Provider` : `Add Service Provider `}
        setIsOpenModal={setAddPopup}
        formik={formik}
        width={800}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col  gap-2">
            <div className="w-full ">
              <CustomFormField
                type="text"
                name="name"
                formik={formik}
                label="Service Provider Name"
              />
            </div>
          </div>
          <div className="w-full ">
            <CustomFormField
              type="select"
              name="type"
              formik={formik}
              label="Type"
              options={[
                {
                  label: "Monthly",
                  value: "Monthly",
                },
                {
                  label: "Per Year",
                  value: "Per Year",
                },
              ]}
            />
          </div>
        </form>
      </CustomAntdModal>
      <PaymentView isServiceProvider={true} isLoading={isLoading}  viewModel={viewModel} setViewModal={setViewModal} data={currentServiceProviderData} />
    </div>
  );
};

export default ServiceProviders;
