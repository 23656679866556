import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, Table, message } from "antd";
import { FormikProps, useFormik } from "formik";
import { useEffect, useState } from "react";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton, { ButtonFill } from "shared-components/ui/CustomButtons";
import { EditIcon } from "shared-components/ui/Icons";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { initialValues, } from "./initialValues";
import TopicsModal from "./modal";
import { validationSchema } from "./schema";

// Type for Topic data
interface Topic {
  topicName: string;
  _id: string;
  subTopics: { subTopicName: string }[];
  Subject: string;
  isPublished: { isPublished: boolean; id: string };
  logoUrl: string;
  questionsCount: number;
  modules: string[];
  expandData: any;
}
const Topics = () => {
  const [openModel, setOpenModel] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [topicsData, setTopicsData] = useState<Topic[]>([]);
  const [subjects, setSubjects] = useState<[]>([]);
  const [modulesData, setModulesData] = useState<{ value: string; label: string }[]>([]);
  const [medschoolSubjects, setMedschoolSubjects] = useState<{ _id: string; name: string }[]>([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [
          disciplinesResponse,
          subjectsResponse,
          topicsResponse,
          modulesResponse,
        ] = await Promise.all([
          apiRequest("getAllDisciplines"),
          apiRequest("getallsubjects"),
          apiRequest("getalltopics"),
          apiRequest("getAllModules"),
        ]);

        // Handle disciplines response
        if (disciplinesResponse.status === 200) {
          const modules = disciplinesResponse?.data?.disciplines?.map(
            (item: any) => ({
              _id: item._id,
              name: item?.disciplineName,
            })
          );
          setMedschoolSubjects(modules || []);
        }

        // Handle subjects response
        if (subjectsResponse.status === 200) {
          setSubjects(subjectsResponse.data);
        } else {
          message.error("Failed to fetch subjects.");
        }

        // Handle topics response
        if (topicsResponse?.status === 200) {
          setTopicsData(topicsResponse?.data?.data);
        } else {
          message.error("Failed to fetch topics.");
        }

        if (modulesResponse.status === 200) {
          const data = modulesResponse?.data?.modules || [];
          const filteredModules = data?.map((module: any) => ({
            value: module.moduleName,
            label: module.moduleName,
          }));
          setModulesData(filteredModules);
        } else {
          message.error("Failed to fetch modules.");
        }

        setLoading(false);
      } catch (error: any) {
        setLoading(false);
        console.error("Error fetching data:", error);
        message.error(error.message || "Failed to fetch data.");
      }
    };

    fetchData();
  }, []);

  const handleOpenModel = () => {
    setOpenModel(true);
    formik.resetForm();
  };

  const getTopicsData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getalltopics");
      if (res?.status === 200) {
        const resData = res?.data?.data;
        setTopicsData(resData);
        setLoading(false);
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
    }
  };

  const formik = useFormik<any>({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setLoading(true);
        const { topicName, subTopics, subject, modules } = values;
        const base64logo = await identifyAndConvertNewImagesToBase64(values?.logo);
        const logoUrl = filteroutGeneratedImagesUrls(values?.logo);
        const payload = {
          topicName,
          modules,
          subTopics,
          logoUrl: logoUrl?.[0],
          logo: base64logo?.[0],
          metadata: {
            entity: process.env.REACT_APP_ENTITY.replace("-"," "),
            category: process.env.REACT_APP_CATEGORY,
          },
          Subject: subject,
        };
        let res;
        if (isEditMode) {
          res = await apiRequest("updateTopic", payload, [values?.topicEditId]);
        } else {
          res = await apiRequest("addTopic", payload);
        }
        if (res?.status === 200) {
          setLoading(false);
          setIsEditMode(false);
          setOpenModel(false);
          setFieldValue("subTopics", [
            {
              subTopicName: "",
            },
          ]);
          getTopicsData();
          message.success(res?.data?.message);
          resetForm();
        }
      } catch (error: any) {
        message.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    },
  });

  const { setFieldValue, setValues, resetForm } = formik;

  const handleEdit = (data) => {
    resetForm();
    const { topicName, id, logoUrl, expandData,  modules, Subject } =
      data;
    const imgType = logoUrl
      ?.substring(logoUrl?.lastIndexOf("/") + 1)
      ?.split(".")
      ?.pop();
    const formatedLogo = {
      uid: 1,
      name: logoUrl,
      status: "done",
      url: logoUrl,
      type: `image/${imgType}`,
      isOldFile: true,
    };
    const arraySubTopics = expandData.split(", ");
    const formatedSubTopics = arraySubTopics?.map((subTopic) => {
      return {
        subTopicName: subTopic,
      };
    });
    setIsEditMode(true);
    setOpenModel(true);
    setValues({
      topicName: topicName,
      topicEditId: id,
      modules,
      subTopics: formatedSubTopics,
      subject: Subject,
      logo: [formatedLogo],
      metadata: {
        entity: data?.entity,
        category: data?.category,
      },
      // board: data?.board,
    });
  };

  const handleTopicsIsPublish = async (data: { id: string; isPublished: boolean }) => {
    try {
      setLoading(true);
      const res = await apiRequest("updateTopicPublish", null, [data.id]);
      if (res?.status === 200) {
        setLoading(false);
        getTopicsData();
        message.success(res?.data?.message);
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message);
      setLoading(false);
    }
  };

  const columns = [
    {
      title: "Logo",
      dataIndex: "logoUrl",
      key: "logoUrl",
      render: (pictureUrl: string) => {
        if (pictureUrl) {
          return (
            <Image
              width={50}
              height={50}
              className="w-10 h-10 rounded-full border cursor-pointer object-cover"
              src={pictureUrl}
            />
          );
        }
        return null;
      },
    },
    {
      title: "Topic NAME",
      dataIndex: "topicName",
      key: "topicName",
      width: 200,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Sub Topics",
      dataIndex: "subTopics",
      key: "subTopics",
      flex: 1,
    },
    {
      title: "Assigned_to",
      dataIndex: "Subject",
      key: "Subject",
      width: 200,
    },
    {
      title: "Questions",
      dataIndex: "questionsCount",
      key: "questionsCount",
      width: 200,
    },
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",
      align: "center",
      width: 100,
      render: (data: { isPublished: boolean; id: string }) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data.isPublished}
            onChange={() => handleTopicsIsPublish(data)}
          />
        </div>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      width: 100,
      render: (data: Topic) => (
        <div className="flex gap-4 justify-center">
          <div>
            <div onClick={() => handleEdit(data)}>
              <EditIcon />
            </div>
          </div>
        </div>
      ),
    },
  ];

  const formatedTopicData = topicsData?.map((topic:any) => {
    const { topicName, _id, subTopics, Subject, isPublished } = topic;
    const subTopicNames = subTopics
      ?.map((subTopic) => subTopic?.subTopicName?.trim())
      ?.join(", ");
    return {
      ...topic,
      key: _id + 1,
      id: _id,
      topicName: topicName?.charAt(0)?.toUpperCase() + topicName?.slice(1),
      expandData: subTopicNames,
      entity: topic?.metadata?.entity,
      category: topic?.metadata?.category,
      subTopics:
        subTopicNames?.length > 80
          ? `${subTopicNames?.slice(0, 80)}...`
          : subTopicNames,
      Subject: Subject,
      isPublished: { isPublished, id: _id },
      // board: topic?.board,
    };
  });

  return (
    <>
      <div className="flex justify-end">
          <ButtonFill
            handleClick={handleOpenModel}
            >
              Add Topic
            </ButtonFill>
    
      </div>
      <TopicsModal
        openModel={openModel}
        setOpenModel={setOpenModel}
        formik={formik as FormikProps<any>}
        subjects={subjects}
        modulesData={modulesData}
        isEditMode={isEditMode}
        medschoolSubjects={medschoolSubjects}
      />
      <div>
        <GlobalTable data={formatedTopicData} columns={columns} loading={loading} />
      </div>
    </>
  );
};

export default Topics;
