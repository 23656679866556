

import React from 'react'
import VaultTable from '../../components/vault-table'

const CheatSheets = () => {
  return (
    <div>
      <VaultTable addApi="addcheatsheet" getApi="getcheatsheets" updateApi="updatecheatsheet" publishApi="updatecheatsheetpublish" vaultType="CheatSheet" />
    </div>
  )
}

export default CheatSheets

