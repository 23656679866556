import React from 'react'
import { useEffect, useState } from 'react';
import { UserAPI } from '../../../(apis)/(shared-apis)/user-apis';

const ReferalManager = () => {

    const [refloading, setRefLoading] = useState(false);
    const [referrals, setReferrals] = useState([]);
  
    const GetAllReferrals = async () => {
      setRefLoading(true);
      try {
        const response = await UserAPI('getUsersReferrals');
        setReferrals(response.data.referrals)
      } catch (error) {
        console.error(error);
      }
      setRefLoading(false);
    }
  
    useEffect(() => {
      GetAllReferrals();
    }, []);
  
  
    return (
      <>
        <h1 className='text-3xl text-gray-700 font-bold'>Referral Manager</h1>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            <div className="bg-white shadow-md sm:rounded-lg">
              {refloading ? (
                <div className="flex items-center justify-center">
                  <div className="absolute text-primary font-bold"> Loading... </div>
                </div>
              ) : (
  
                <>
  
                  <div className="bg-white shadow-md sm:rounded-lg mt-4">
                    <div className="overflow-x-auto">
                      <table className="w-full mb-20 text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                          <tr>
                            <th scope="col" className="px-4 py-4">SR#</th>
                            <th scope="col" className="px-4 py-4">Referral</th>
                            <th scope="col" className="px-4 py-4">Count</th>
                          </tr>
                        </thead>
                        <tbody>
                          {referrals.map((referral, index) => (
                            <tr className="border-b" key={index}>
                              <td className="px-4 py-3">{index + 1}</td>
                              <td className="px-4 py-3">{referral.referral}</td>
                              <td className="px-4 py-3">{referral.count}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
  
  
                </>
              )}
            </div>
          </div>
        </section>
      </>
    );
  }

export default ReferalManager