import axios from "axios";
import { backendUrl } from "../../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

 export const ReferenceApis:ApiInterface[] = [
    {
        url: "/api/referencepdfs",
        method: "GET",
        name: "getReferences", 
    },
    {
        url: "/api/referencepdfs/ispublished",
        method: "PUT",
        name: "updateReference",
    },
    {
        url: "/api/referencepdfs/create",
        method: "POST",
        name: "createReference",
    },
    {
        url: '/api/referencepdfs/update',
        method: "PUT",
        name: "updatePdf"
    }
]
    
export function ReferenceAPI(name: string, params: string[] = [""], data: any = null) {
    const api = ReferenceApis.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

