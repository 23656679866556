export function getTagColor(index: number): string {
    const colors = [
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
    ];
    return colors[index % colors?.length];
  }