import React, { useEffect, useState } from "react";
import { formatNumberWithCommas } from "shared-pages/latest-commission/utils";
import YearlySignUpsChart from "./components/YearlySignUpsChart";
import QuarterlySignUpsChart from "./components/QuarterlySignUpsChart";
import MonthlySignUpsChart from "./components/MonthlySignUpsChart";
import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";

const ViewStatistics = () => {
  const [stats, setStats] = useState<null | any>(null);

  const [isLoading, setIsLoading] = useState(true);
  const fetchSignupStatistics = async () => {
    try {
      setIsLoading(true);
      const response = await BasicGetApi("getSignupStatistics");
      if (response && response?.data?.isSuccess) {
        const data = response?.data;
        console.log("Response: ", response, data);
        setStats(data);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSignupStatistics();
  }, []);

  return (
    <>
      <div className="mx-10 font-bold text-2xl">PreMed.PK Statistics</div>
      {isLoading ? (
        <h1 className="text-3xl font-semibold text-gray-700 text-center mt-8">
          Loading...
        </h1>
      ) : (
        <>
          {" "}
          <div className="">
            <div className="flex  flex-wrap gap-2"></div>
            <div className="text-primary font-semibold text-center my-2 text-3xl">
              Overall Stats
            </div>

            <div className="container items-center px-4 py-8 m-auto mt-5">
              <div className="flex flex-wrap items-center justify-center pb-3  lg:mx-0">
                {[
                  { label: "Total Users", value: stats?.totalUsers },
                  { label: "Today Signups", value: stats?.todaySignups },
                  { label: "Last Week Signups", value: stats?.lastWeekSignups },
                  { label: "This Month Signups", value: stats?.monthlySignups },
                  {
                    label: "Last Month Signups",
                    value: stats?.lastMonthSignups,
                  },
                ]?.map((item, index) => {
                  return <RenderStatCard key={index} item={item} />;
                })}
              </div>
            </div>

            <div className="shadow-xl p-4 rounded-xl">
              <h1 className="p-8 font-bold text-xl">Yearly Sign-Ups Data</h1>
              <div style={{ height: 400 }}>
                {stats && <YearlySignUpsChart data={stats} />}
              </div>
            </div>
            <div className="shadow-xl p-4 rounded-xl">
              <h1 className="p-8 font-bold text-xl">Monthly Sign-Ups Data</h1>{" "}
              <div style={{ height: 400 }}>
                {stats && <QuarterlySignUpsChart data={stats} />}
              </div>
            </div>
            <div className="shadow-xl p-4 rounded-xl">
              <h1 className="p-8 font-bold text-xl">Quarterly Sign-Ups Data</h1>
              <div style={{ height: 400 }}>
                {stats && <MonthlySignUpsChart data={stats} />}{" "}
              </div>
            </div>

            {/* <div className="subTableHeading">Today's Statistics</div>

            <div className="parentContainer"></div>
            <div className="subTableHeading">Signs - Last 7 days</div>
            <div className="parentContainer">
            
            </div> */}
          </div>
        </>
      )}
    </>
  );
};

const RenderStatCard = ({
  item,
  key,
}: {
  item: { label: string; value: number };
  key: number;
}) => {
  return (
    <div
      className="w-full md:max-w-xs p-2 lg:w-1/3 md:w-1/2 cursor-pointer"
      key={key}
    >
      <div className="flex flex-col px-6 py-10 overflow-hidden bg-white hover:bg-gradient-to-br hover:from-purple-400 hover:via-blue-400 hover:to-blue-500 rounded-xl shadow-lg duration-300 hover:shadow-2xl group transition-all ease-in-out">
        <div className="flex flex-row justify-between items-center">
          <div className="px-4 py-4 bg-gray-300  rounded-xl bg-opacity-30">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 group-hover:text-gray-50"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M10.293 15.707a1 1 0 010-1.414L14.586 10l-4.293-4.293a1 1 0 111.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
              <path
                fill-rule="evenodd"
                d="M4.293 15.707a1 1 0 010-1.414L8.586 10 4.293 5.707a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
        </div>
        <h1 className="text-3xl sm:text-4xl xl:text-5xl font-bold text-gray-700 mt-12 group-hover:text-gray-50">
          {formatNumberWithCommas(item?.value)}
        </h1>
        <div className="flex flex-row justify-between group-hover:text-gray-200">
          <p className="font-semibold text-lg text-gray-800">{item?.label}</p>
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-indigo-600 group-hover:text-gray-200"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fill-rule="evenodd"
                d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                clip-rule="evenodd"
              />
            </svg>
          </span>
        </div>
      </div>
    </div>
  );
};
export default ViewStatistics;
