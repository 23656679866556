import { Button, Checkbox, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { HolderOutlined } from "@ant-design/icons";
import ReactDragListView from "react-drag-listview";
interface Column {
  title: string;
  key?: string;
  dataIndex?: string;
  // Add more column properties if needed
}
interface FilterColumnsModalProps {
  columns?: any[];
  otherColumns?: any[];
  setAfterArrangeColumns: (columns: any[]) => void;
  setIsModalVisible: (visible: boolean) => void;
  isModalVisible: boolean;
}

const FilterColumnsModal: React.FC<FilterColumnsModalProps> = ({
  columns,
  otherColumns,
  setAfterArrangeColumns,
  setIsModalVisible,
  isModalVisible,
}) => {
  const [modalColumns, setModalColumns] = useState<Column[]>([]);
  const [checkedList, setCheckedList] = useState<string[]>([]);

  useEffect(() => {
    const allColumns = otherColumns ? [...columns, ...otherColumns]: columns;
    setModalColumns(allColumns);
    setCheckedList(columns?.map((col:any) => col?.key));
  }, [columns, otherColumns]);

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleSave = () => {
    const selectedColumns = modalColumns.filter((col:any) =>
      checkedList.includes(col?.key)
    );
    setAfterArrangeColumns(selectedColumns);
    setIsModalVisible(false);
  };

  const handleDragEnd = (fromIndex: number, toIndex: number) => {
    const newColumns = [...modalColumns];
    const [movedColumn] = newColumns.splice(fromIndex, 1);
    newColumns.splice(toIndex, 0, movedColumn);
    setModalColumns(newColumns);
  };

  const handleCheckboxChange = (value: string) => {
    const newCheckedList = checkedList.includes(value)
      ? checkedList.filter((item) => item !== value)
      : [...checkedList, value];
    setCheckedList(newCheckedList);
  };

  const handleResetColumns = () => {
    setAfterArrangeColumns(columns);
    setCheckedList(columns?.map((col:any) => col.key));
    setIsModalVisible(false);
  };

  return (
    <div>
      <Modal
        title="Manage Columns"
        visible={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="reset" type="primary" onClick={handleResetColumns}>
            Reset
          </Button>,
          <Button key="apply" type="primary" onClick={handleSave}>
            Apply
          </Button>,
        ]}
      >
        <ReactDragListView.DragColumn
          onDragEnd={handleDragEnd}
          nodeSelector="th"
        >
          <div className="flex flex-col gap-3 justify-start items-start">
            {modalColumns.map((col:any) => (
              <th key={col.dataIndex}>
                <Button
                  type="text"
                  size="small"
                  icon={<HolderOutlined />}
                  style={{ cursor: "move" }}
                >
                  <Checkbox
                    value={col.key}
                    checked={checkedList.includes(col?.key)}
                    onChange={() => handleCheckboxChange(col?.key)}
                  >
                    {col.title}
                  </Checkbox>
                </Button>
              </th>
            ))}
          </div>
        </ReactDragListView.DragColumn>
      </Modal>
    </div>
  );
};

export default FilterColumnsModal;
