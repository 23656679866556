import { useState } from "react";
import toast from "react-hot-toast";
import {
    ButtonFill,
    ButtonOutlined,
} from "../../../shared-components//ui/CustomButtons";
import { Input, Label } from "../../../shared-components//ui/CustomForm";
import { Select } from "antd";
import CustomDropDown from "shared-components/ui/CustomDropDown";
import { createAdminUser } from "(apis)/(shared-apis)/auth-apis";
// {
//     "username": "salisbinsalman0@gmail.com",
//     "password": "11221122",
//     "fullname": "Salis Salman",
//     "dept": "Tech",
//     "role": "CTO"
//   }
interface FormProps {    
    username: string;
    password: string;
    fullname: string;
    dept: string;
    role: string; 
}


export const AddUserForm = (props) => {
    const InitialErrors = {
        username: {
            error: false,
            message: "",
        },
        password: {
            error: false,
            message: "",
        },
        fullname: {
            error: false,
            message: "",
        },
        dept: {
            error: false,
            message: "",
        },
        role: {
            error: false,
            message: "",
        },
    };
    const [errors, setErrors] = useState<any>(InitialErrors);
    const initialState = {
        username: "",
        password: "",
        fullname: "",
        dept: "",
        role: "",
    };
    const [formData, setFormData] = useState<FormProps>(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const validateForm = () => {
        const newErrors = { ...errors };
        let isError = false;
        if (!formData.username) {
            newErrors.username.error = true;
            newErrors.username.message = "Email is required";
            isError = true;
        } else {
            newErrors.username.error = false;
            newErrors.username.message = "";
        }
        if (!formData.password) {
            newErrors.password.error = true;
            newErrors.password.message = "Password is required";
            isError = true;
        } else {
            newErrors.password.error = false;
            newErrors.password.message = "";
        }
        if (!formData.fullname) {
            newErrors.fullname.error = true;
            newErrors.fullname.message = "Full Name is required";
            isError = true;
        } else {
            newErrors.fullname.error = false;
            newErrors.fullname.message = "";
        }
        if (!formData.dept) {
            newErrors.dept.error = true;
            newErrors.dept.message = "Department is required";
            isError = true;
        } else {
            newErrors.dept.error = false;
            newErrors.dept.message = "";
        }
        if (!formData.role) {
            newErrors.role.error = true;
            newErrors.role.message = "Role is required";
            isError = true;
        } else {
            newErrors.role.error = false;
            newErrors.role.message = "";
        }



        setErrors(newErrors);
        return isError;
    };

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const resetAll = () => {
        setFormData(initialState);
        props?.setModal(false);
    };

    const Submit = async () => {
        if (validateForm()) {
            return;
        }
        setDisableCreateButton(true);
        for (let key in formData) {
            formData[key] = formData[key].trim();
        }

        try {
            setIsLoading(true);
            let response = null;
            response = await createAdminUser(formData);
            if (response.data.success) {
                toast.success("Added Successfully 🚀");
                resetAll();
                props?.setModal(false);
            }
            else {
                toast.error(response.data.status);
            }
        } catch (error) {
            console.log("Add Discipline Error: ", error);
        } finally {
            setIsLoading(false);
        }

        setDisableCreateButton(false);
    };


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Create and Add User
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="space-y-2">
                        <div className="space-y-2">
                            <Label>Email</Label>
                            <Input
                                type="text"
                                placeholder="Enter Email"
                                value={formData.username}
                                name="username"
                                onChange={handleInputChange}
                                isError={errors.username.error}
                                errorMessage={errors.username.message}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Password</Label>
                            <Input
                                type="password"
                                placeholder="Enter Password"
                                value={formData.password}
                                name="password"
                                onChange={handleInputChange}
                                isError={errors.password.error}
                                errorMessage={errors.password.message}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Full Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter Full Name"
                                value={formData.fullname}
                                name="fullname"
                                onChange={handleInputChange}
                                isError={errors.fullname.error}
                                errorMessage={errors.fullname.message}
                            />
                        </div>
                        <div className="space-y-2">
                            <CustomDropDown
                                label="Select Department"
                                options={["Core Team", "Education", "Marketing", "Other"]}
                                onSelect={(value) => handleInputChange("dept", value)}
                                value={formData.dept}
                                isError={errors.dept.error}
                                errorMessage={errors.dept.message}
                            />
                        </div>

                        <div className="space-y-2">
                            <CustomDropDown
                                label="Select Role"
                                options={["Associate", "Manager", "Fixer", "Uploader"]}
                                onSelect={(value) => handleInputChange("role", value)}
                                value={formData.role}
                                isError={errors.role.error}
                                errorMessage={errors.role.message}
                            />
                        </div>


                        <div className="flex items-center justify-end gap-2">
                            <ButtonOutlined
                                handleClick={() => {
                                    props?.setModal(false);
                                }}
                            >
                                Cancel
                            </ButtonOutlined>

                            <ButtonFill isLoading={isLoading} handleClick={Submit} disabled={disableCreateButton}>
                                Create User
                            </ButtonFill>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default AddUserForm;
