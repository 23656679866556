import { ApiInterface } from "../api-interface/types";

const FeatureEndpoint = "api/features";

export const featureApis: ApiInterface[] = [
  {
    url: `/${FeatureEndpoint}/get`,
    method: "GET",
    name: "getFeatures",
  },
  {
    url: `/${FeatureEndpoint}/getById/:id`,
    method: "GET",
    name: "getCourseFeatureById",
  },
  {
    url: `/${FeatureEndpoint}/add`,
    method: "POST",
    name: "addCourseFeature",
  },
  {
    url: `/${FeatureEndpoint}/update/:id`,
    method: "PUT",
    name: "updateCourseFeature",
  },
];
