import React, { useEffect } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ReferencesView from "./reference-view";
import { Form, Input, Button, Space } from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

interface Page3Props {
  mode?: string;
  references?: any;
  setReferences?: any;
  form: any;
  setForm: any;
  modules: any;
  explanationText?: string;
}

export default function Page3({
  mode,
  references,
  setReferences,
  form,
  setForm,
  modules,
  explanationText,
}: Page3Props) {
  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => {
      setForm({
        ...form,
        ExplanationImage: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  const UploadImage = () => {
    document.getElementById("ExplanationImage").click();
  };

  useEffect(() => {
    if (explanationText) {
      setForm({
        ...form,
        ExplanationText: explanationText,
      });
    }
  }, [explanationText]);
  const onBookReferenceChange = (index: number, value: string) => {
    const updatedReferences = [...(form.bookReferences || [])];
    updatedReferences[index] = value;
    setForm({
      ...form,
      bookReferences: updatedReferences,
    });
  };

  return (
    <div className="mx-auto p-12 pt-0">
      <div
        className={`overflow-hidden bg-gray-50 shadow-md sm:rounded-lg text-semibold p-4`}
      >
        <div className="flex flex-row flex-wrap items-center justify-between">
          <h6 className="text-md font-semibold text-rose-500 mt-2">
            Explanation Text
          </h6>
        </div>
        <ReactQuill
          className="optionExp bg-white rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
          theme="snow"
          modules={modules}
          onChange={(html) => {
            setForm({
              ...form,
              ExplanationText: html,
            });
          }}
          value={form.ExplanationText}
          style={{ color: "black" }}
          placeholder="Enter Explanation Text"
        />
        <div className="flex flex-col flex-wrap align-middle gap-2 mt-2">
          <div className="flex flex-col flex-wrap justify-between">
            <h6 className="text-md font-bold text-rose-500 mt-2">
              Upload Explanation Image
            </h6>
            <div
              className={`border border-gray-300 !border-dashed rounded-md p-4 flex justify-center items-center gap-2 ${
                form.ExplanationImage
                  ? "bg-green-50 w-fit gap-5 flex-row justify-around"
                  : "bg-white flex-col"
              }`}
            >
              <div className="flex flex-col flex-wrap items-center gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-8 h-8"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                  />
                </svg>
                <button
                  className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                  onClick={UploadImage}
                >
                  Upload Image
                </button>
                <input
                  type="file"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                  id="ExplanationImage"
                  name="ExplanationImage"
                />
                {form.ExplanationImage && (
                  <button
                    className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                    onClick={() => {
                      setForm({ ...form, ExplanationImage: "" });
                    }}
                  >
                    Remove Image
                  </button>
                )}
              </div>
              {form.ExplanationImage && (
                <div className="flex flex-row flex-wrap items-center gap-2">
                  <img src={form.ExplanationImage} className="w-40 h-40" />
                </div>
              )}
            </div>
          </div>
        </div>
        {process.env.REACT_APP_CATEGORY === "MEDSCHOOL" ? (
          <>
          
            <Form
      initialValues={{ bookReferences: form.bookReferences || [] }}
      onValuesChange={(_, allValues) => {
        setForm(allValues);
      }}
    >
      <h6 className="text-md font-bold text-rose-500 mt-2">Book Reference</h6>
      <Form.List name="bookReferences">
        {(fields, { add, remove }) => (
          <>
            {fields.map((field, index) => (
              <Space key={field.key} style={{ display: "flex", marginBottom: 8 }} align="baseline">
                <Form.Item
                  {...field}
                  name={[field.name]}
                  rules={[{ required: true, message: "Please enter book reference" }]}
                >
                  <Input
                  className="!min-w-full"
                    placeholder="Enter Book Reference"
                    onChange={(e) => onBookReferenceChange(index, e.target.value)}
                  />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(field.name)} />
              </Space>
            ))}
            <Form.Item>
              <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                Add Book Reference
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </Form>
          </>
        ) : (
          <ReferencesView
            mode={mode}
            references={references}
            setReferences={setReferences}
          />
        )}
      </div>
    </div>
  );
}
