import { ReactElement } from "react";
import Dashboard from "shared-pages/dashboard/Dashboard";
import Features from "shared-pages/feature";
import Hierarchy from "shared-pages/hierarchy";
import MarketingDownload from "shared-pages/marketing-download";
import OrderManager from "shared-pages/order-management/page";
import Path from "./paths";

import AddFlashcard from "shared-pages/flashcard/addFlashcard";
import EditFlashcard from "shared-pages/flashcard/editFlashcard";
import FlashcardReport from "shared-pages/flashcard/reports";
import SelectFlashcard from "shared-pages/flashcard/selectFlashcard";
import ScholarShip from "shared-pages/scholarship/ScholarShip";
import Statistics from "shared-pages/statistics";
import Teacher from "shared-pages/teacher";
import Team from "shared-pages/team";
import ManageDepartment from "shared-pages/team/ManageDepartment";
import ManageTestimonials from "shared-pages/testimonials";
import Topics from "shared-pages/topics";
import University from "shared-pages/university";
import CheatSheets from "shared-pages/vault/pages/cheatsheets";
import EssentialStuff from "shared-pages/vault/pages/essentials";
import Mnemonics from "shared-pages/vault/pages/mnemonics";
import Notes from "shared-pages/vault/pages/notes";
import Shortlistings from "shared-pages/vault/pages/shortlistings";
import SnapCourses from "shared-pages/vault/pages/snapcourses";
import TopicalGuides from "shared-pages/vault/pages/topicalguides";
import VerifyScreen from "../shared-pages/order-management/verify-screen";

import FilterQuestions from "shared-pages/academics/filter-questions/filter-questions";
import CAManager from "shared-pages/ca";
import ManageAssignment from "shared-pages/courses/pages/assignments";
import EditCourse from "shared-pages/courses/pages/course-management/edit";
import Courses from "shared-pages/courses/pages/course-management/view-add";
import CoursesManager from "shared-pages/courses/pages/course-manager-stats/page";
import ManageSchedule from "shared-pages/courses/pages/schedule-management";
import Gallery from "shared-pages/gallery";
import ManageInstitution from "shared-pages/institute";
import ManageJobs from "shared-pages/jobs/ManageJobs";
import AddNewJob from "shared-pages/jobs/components/AddNewJob";
import EditJob from "shared-pages/jobs/components/EditJob";
//import MyBundles from "shared-pages/my-bundles";
import MyBundles from "shared-pages/my-own-bundles/page";
//import EditMyBundles from "shared-pages/my-bundles/edit";
import EditMyBundles from "shared-pages/my-own-bundles/bundles-edit";
import SendNotification from "shared-pages/notification/sendNotification";
import EditPackage from "shared-pages/plans-management/package-edit";
import PlansManager from "shared-pages/plans-management/page";
import Renewals from "shared-pages/renewals";
import CategoryDeckgroupsManager from "../shared-pages/academics/category-deckgroups/page";
import CategoryManager from "../shared-pages/academics/category-manager/page";
import DeckStatistics from "../shared-pages/academics/deck-statistics/page";
import CategoryDeckgroupsDecksManager from "../shared-pages/academics/deckgroup-decks/page";
import DeckManager from "../shared-pages/academics/decks-manager/page";
import ReportQuestion from "../shared-pages/academics/question-reporting/page";
import AddQuestion from "../shared-pages/academics/questions/add-question";
import EditQuestion from "../shared-pages/academics/questions/edit-question";
import ReferenceManager from "../shared-pages/academics/reference-management/page";
import ConversationsManagement from "../shared-pages/conversions/page";
import DisciplineManager from "../shared-pages/disciplines-management/page";
import BankHistory from "../shared-pages/finance-managment/history-manager";
import BankManager from "../shared-pages/finance-managment/page";
import ModulesManager from "../shared-pages/modules-manager/page";
import OrderManagerStats from "../shared-pages/order-management/order-manager-stats/page";
import HeadQuarters from "../shared-pages/permission-management/page";
import UserManagement from "../shared-pages/user-management/page";
// export
import AdminManagerPage from "shared-pages/doubtquestion/pages/admin-manager";
import ExpertDashboard from "shared-pages/doubtquestion/pages/expert-dashboard";
import ExpertDashboardSubject from "shared-pages/doubtquestion/pages/expert-dashboard-subject";
import ExpertSubject from "shared-pages/doubtquestion/pages/expert-subject";
import ExpertSubjectManage from "shared-pages/doubtquestion/pages/expert-subject-manage";
import Experts from "shared-pages/doubtquestion/pages/experts";
import ManageExperts from "shared-pages/doubtquestion/pages/manage-experts";
import ExpertSolveRecordView from "shared-pages/doubtquestion/pages/solve-doubt";
import VideoInterface from "shared-pages/doubtquestion/pages/video-interface";

// Commission
import LatestCommissionDashboard from "shared-pages/latest-commission/dashboard/latest-commission-dashboard";
import ReimbursementRequests from "shared-pages/latest-commission/reimbursement/reimbursement-requests";
import UpdateAssociates from "shared-pages/latest-commission/update-users/update-associates";
import UpdateHeads from "shared-pages/latest-commission/update-users/update-heads";
// import PromoteHeadToManager from "shared-pages/latest-commission/promote-users/head-to-manager";
import PromoteHeadToManager from "shared-pages/latest-commission/promote-users/head-to-manager";
import TransferUsersScreen from "shared-pages/latest-commission/transfer-users/transfer-users-screen";
import UpdateManagers from "shared-pages/latest-commission/update-users/update-managers";
import ManageNationalDirectors from "shared-pages/latest-commission/update-users/update-national-directors";
import ManageProvincialDirectors from "shared-pages/latest-commission/update-users/update-provincial-directors";
import ViewUserDetail from "shared-pages/latest-commission/view-user-detail/view-user-detail";
import ViewStatistics from "shared-pages/view-statistics";
import GenerateUrl from "shared-pages/generate-url";

import EmployeeProviderFinance from "shared-pages/finance-managment/employees-providers";
import ManagePayments from "shared-pages/finance-managment/employees-providers/payments";
import Revenue from "shared-pages/revenue/page";

export interface Route {
  roles?: string[];
  path: string;
  component: ReactElement;
}


const VaultRoutes: Route[] = [
  { path: Path.notes, component: <Notes /> },
  { path: Path.shortlistings, component: <Shortlistings /> },
  { path: Path.mnemonics, component: <Mnemonics /> },
  { path: Path.cheatsheets, component: <CheatSheets /> },
  { path: Path.essentials, component: <EssentialStuff /> },
  { path: Path.snapCourses, component: <SnapCourses /> },
  { path: Path.topicalGuides, component: <TopicalGuides /> },
]

const FlashcardRoutes: Route[] = [
  { path: Path.selectFlashcard, component: <SelectFlashcard /> },
  { path: Path.addFlashcard, component: <AddFlashcard /> },
  { path: Path.editFlashcard, component: <EditFlashcard /> },
  { path: Path.flashcardReport, component: <FlashcardReport /> },
]


const MarketingRoutes: Route[] = [
  { path: Path.marketingDownload, component: <MarketingDownload /> },
  { path: Path.statistics, component: <Statistics /> },
  { path: Path.conversationsManagement, component: <ConversationsManagement /> },
  { path: Path.ViewStatistics, component: <ViewStatistics /> },
]

const FinanceRoutes: Route[] = [
  { path: Path.manageOrders, component: <OrderManager /> },
  { path: Path.verify, component: <VerifyScreen /> },
  { path: Path.bankManager, component: <BankManager /> },
  { path: Path.bankHistory, component: <BankHistory /> },
  { path: Path.manageOrderStats, component: <OrderManagerStats /> },
  { path: Path.usermanagement, component: <UserManagement /> },
  { path: Path.employeeProviderFinance, component: <EmployeeProviderFinance />,},
  { path: Path.managePayments, component: <ManagePayments />,},
  { path: Path.revenue , component: <Revenue/> },
  { path: Path.courseManager, component: <CoursesManager /> },
]

const CoursesRoutes: Route[] = [
  { path: Path.renewals, component: <Renewals /> },
  { path: Path.courses, component: <Courses /> },
  { path: Path.courseSchedule, component: <ManageSchedule /> },
  { path: Path.editCourse, component: <EditCourse /> },
  { path: Path.manageAssignments, component: <ManageAssignment /> },  
]

const ExpertRoutes: Route[] = [
  { path: Path.experts, component: <Experts /> },
  { path: Path.expertSubject, component: <ExpertSubject /> },
  { path: Path.adminManager, component: <AdminManagerPage /> },
  { path: Path.manageExperts, component: <ManageExperts /> },
  { path: Path.expertDashboard, component: <ExpertDashboard /> },
  { path: Path.expertDashboardSubject, component: <ExpertDashboardSubject /> },
  { path: Path.expertSubjectManage, component: <ExpertSubjectManage /> },
  { path: Path.solveDoubtById, component: <ExpertSolveRecordView /> },
  { path: Path.videoInterface, component: <VideoInterface /> },
]

const AcademicRoutes: Route[] = [
  { path: Path.topics, component: <Topics /> },
  { path: Path.categoryManager, component: <CategoryManager />, roles: ["Deck Manager", 'Category Access'] },
  { path: Path.categoryDeckgroups, component: <CategoryDeckgroupsManager />, roles: ["Deck Manager", 'Category Access'] },
  { path: Path.categoryDeckgroupDecks, component: <CategoryDeckgroupsDecksManager />, roles: ["Deck Manager", 'Category Access'] },
  { path: Path.deckManager, component: <DeckManager />, roles: ["Deck Manager", 'Category Access'] },
  { path: Path.addQuestion, component: <AddQuestion />, roles: ["Deck Manager", 'Add Question'] },
  { path: Path.editQuestion, component: <EditQuestion />, roles: ["Deck Manager", 'Add Question', 'Category Access'] },
  { path: Path.editQuestionReport, component: <EditQuestion />, roles: ["Deck Manager", 'Add Question', 'Category Access'] },
  { path: Path.questionDetails, component: <ReportQuestion />, roles: ["Deck Manager", 'Add Question', 'Category Access'] },
  { path: Path.editQuestionReport, component: <EditQuestion />, roles: ["Deck Manager", 'Add Question', 'Category Access'] },
  { path: Path.modulesManagement, component: <ModulesManager /> },
  { path: Path.disciplineManagement, component: <DisciplineManager /> },
  { path: Path.deckStatistics, component: <DeckStatistics /> },
  { path: Path.referenceManagement, component: <ReferenceManager /> },
  { path: Path.filterQuestions, component: <FilterQuestions /> },
]

const CommissionRoutes: Route[] = [
  { path: Path.latestCommissionDashboard, component: <LatestCommissionDashboard /> },
  { path: Path.ManageAssociates, component: <UpdateAssociates /> },
  { path: Path.ManageHeads, component: <UpdateHeads /> },
  { path: Path.ManageManagers, component: <UpdateManagers /> },
  { path: Path.ManageNationalDirectors, component: <ManageNationalDirectors /> },
  { path: Path.ManageProvincialDirectors, component: <ManageProvincialDirectors /> },
  { path: Path.ReimbursementRequests, component: <ReimbursementRequests /> },
  { path: Path.transferUsers, component: <TransferUsersScreen /> },
  { path: Path.ViewCommissionUserDetail, component: <ViewUserDetail /> },
  { path: Path.PromoteHeadToManager, component: <PromoteHeadToManager /> },
]


const PlansRoutes: Route[] = [
  { path: Path.plansManager, component: <PlansManager /> },
  { path: Path.editPackage, component: <EditPackage /> },
  { path: Path.myOwnBundles, component: <MyBundles /> },
  { path: Path.editCustomBundles, component: <EditMyBundles /> },
]

const OtherRoutes: Route[] = [
  { path: Path.scholarShip, component: <ScholarShip/> },
  { path: Path.sendNotification, component: <SendNotification/> },
  { path: Path.features, component: <Features/> },
  { path: Path.teachers, component: <Teacher/> }, 
  { path: Path.hierarchy, component: <Hierarchy/> },
  { path: Path.universities, component: <University/> },
  { path: Path.team, component: <Team/> },
  { path: Path.department, component: <ManageDepartment/> },
  { path: Path.testimonials, component: <ManageTestimonials/> },
  { path: Path.caManager, component: <CAManager/> },
  { path: Path.gallery, component: <Gallery/> },
  { path: Path.institute, component: <ManageInstitution/> },
  { path: Path.manageJobs, component: <ManageJobs/> },
  { path: Path.editJob, component: <EditJob/> },
  { path: Path.addNewJob, component: <AddNewJob/> },  
  { path: Path.generateURL, component: <GenerateUrl/> },
]    

let Routes = [];
if (process.env.REACT_APP_CATEGORY === "FINANCE") {
  Routes = [
    ...FinanceRoutes,
  ];
} else {
  Routes = [
    ...CoursesRoutes,
    ...VaultRoutes,
    ...FlashcardRoutes,
    ...MarketingRoutes,
    ...CoursesRoutes,
    ...ExpertRoutes,
    ...AcademicRoutes,
    ...CommissionRoutes,
    ...PlansRoutes,
    ...OtherRoutes
  ];
}

const sharedPages = [
  { path: Path.dashboard, component: <Dashboard/> },
  { path: Path.headQuarters, component: <HeadQuarters/> },

]

Routes.push(...sharedPages);

export const routes: Route[] = Routes;
