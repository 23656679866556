import React, { useEffect, useState } from "react";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import toast from "react-hot-toast";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { Select } from "antd";
import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import { getUsersOfSpecificRole, ROlES } from "../utils";

export const CommissionUserForm = (props) => {
  console.log("Report to User: ", props?.reportToUsers);
  const InitialErrors = {
    entityError: {
      error: false,
      message: "",
    },
    categoryError: {
      error: false,
      message: "",
    },
    roleError: {
      error: false,
      message: "",
    },
    userNameError: {
      error: false,
      message: "",
    },
    reportToError: {
      error: false,
      message: "",
    },
  };

  const [errors, setErrors] = useState(InitialErrors);

  const roleHierarchy = {
    ASSOCIATE: "HEAD",
    HEAD: "MANAGER",
    MANAGER: "PROVINCIAL_DIRECTOR",
    PROVINCIAL_DIRECTOR: "NATIONAL_DIRECTOR",
    NATIONAL_DIRECTOR: null,
  };
  const defaultCommisionRate = {
    ASSOCIATE: 10,
    HEAD: 2.5,
    MANAGER: 1,
    PROVINCIAL_DIRECTOR: 0.25,
    NATIONAL_DIRECTOR: "",
  };

  const initialState = {
    _id: "",
    name: "",
    userName: "",
    email: "",
    reportToId: "",
    reportToEmail: "",
    role: props?.label ? props?.label : "ASSOCIATE",
    reportTo: props?.label
      ? roleHierarchy[props?.label]
      : roleHierarchy["HEAD"],
    commissionPercentage: props?.label
      ? defaultCommisionRate[props?.label]
      : defaultCommisionRate["ASSOCIATE"],
    reportToName: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [reportToEmails, setReportToEmails] = useState([]);

  useEffect(() => {
    if (props?.commissionUser) {
      console.log("Commission User: ", props?.commissionUser);
      setFormData(props?.commissionUser);
    }
  }, [props?.commissionUser]);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleRoleSelect = (selectedRole) => {
    setFormData({
      ...formData,
      role: selectedRole,
      reportTo: roleHierarchy[selectedRole],
      commissionPercentage: defaultCommisionRate[selectedRole],
    });
  };
  const getUserInfoByEmail = (email) => {
    const user = props?.allAdminUsers?.find((user) => user?.username === email);
    return user;
  };

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const resetAll = () => {
    setFormData(initialState);
    props?.setModal(false);
    setConfirmationModal(false);
  };
  const Submit = async () => {
    // if (validateForm()) {
    //   return;
    // }

    const newUser = getUserInfoByEmail(formData.email);
    const reportedUser = getUserInfoByEmail(formData.reportToEmail);
    // setDisableCreateButton(true);
    console.log(
      "inside onsubtmit: ",
      formData.email,
      formData.reportToEmail,
      reportedUser
    );
    try {
      // setIsLoading(true);
      const data: {
        userName: string;
        name: string;
        email: string;
        role: string;
        commissionPercentage: string | number;
        reportToId?: string;
        reportToName?: string;
        reportToEmail?: string;
        reportTo?: string;
        userId?: string;
      } = {
        userName: formData.name,
        name: formData.name,
        email: formData.email,
        role: formData.role,

        commissionPercentage: formData.commissionPercentage,
      };
      if (reportedUser?.fullname && reportedUser?.fullname?.trim() !== "") {
        data.reportToId = reportedUser?._id;
        data.reportToName = reportedUser?.fullname;
        data.reportToEmail = reportedUser?.username;
      }
      if (formData.role !== "NATIONAL_DIRECTOR") {
        data.reportTo = roleHierarchy[formData.role];
      }
      data.userId = newUser._id;

      console.log("🔰-formData: ", data);

      // return;
      let response = null;
      if (props?.mode === "edit") {
        const id = formData?._id;
        response = await CommissionAPI("updateCommissionUserById", [id], data);
        // response = await updateCommissionUserById(formData._id, data);
      } else {
        response = await CommissionAPI("addCommissionUser", [], data);
        // response = await addCommissionUserApi(data);
      }
      if (response) {
        const message = response?.data?.message;
        props?.mode === "edit"
          ? toast.success(message ? message : "Updated Successfully 🚀")
          : toast.success(message ? message : "Added Successfully 🚀");
        resetAll();
        console.log("response: ", response);
      }
    } catch (error) {
      console.log("Add Commission User Error: ", error);
    } finally {
      setIsLoading(false);
    }

    setDisableCreateButton(false);
  };
  console.log("🔰-formData: ", formData);

  const formattedUserEmail =
    props?.allAdminUsers?.map((user) => user?.username) || [];

  useEffect(() => {
    let formattedReportToUsers =
      props?.allAdminUsers?.map((user) => user?.username) || [];

    if (props?.allCommissionUsers) {
      // we have to set report to emails in dropdown according to the role we are adding
      // =====================
      // ===FOR ASSOCIATES====
      // =====================
      if (props?.label === ROlES.ASSOCIATE) {
        let users = getUsersOfSpecificRole(
          props?.allCommissionUsers,
          ROlES.HEAD
        );
        formattedReportToUsers = users?.map((user) => user?.email) || [];
        // =====================
        //  =======HEADS========
        // =====================
      } else if (props?.label === ROlES.HEAD) {
        let users = getUsersOfSpecificRole(
          props?.allCommissionUsers,
          ROlES.MANAGER
        );
        formattedReportToUsers = users?.map((user) => user?.email) || [];
      }
    }
    setReportToEmails(formattedReportToUsers);
  }, [props?.allAdminUsers]);

  // useEffect(() => {
  //   console.log("props?.allCommissionUsers: ", props?.allCommissionUsers);
  //   let formattedReportToUsers =
  //     props?.allAdminUsers?.map((user) => user?.username) || [];
  //   if (props?.mode === "add") {
  //     // const commissionUserEmails
  //   }
  //   setReportToEmails(formattedReportToUsers);
  // }, [props?.allAdminUsers, props?.mode]);

  const handleUserEmailChange = (mail) => {
    const user = getUserInfoByEmail(mail);
    console.log("user", user);
    if (user) {
      const updatedFormData = { ...formData, email: mail, name: user.fullname };
      setFormData(updatedFormData);
    }
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props?.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {props?.mode === "add"
                ? "Add New Commission User"
                : "Edit Commission User"}
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                props?.setModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-2">
            <div className="">
              <Label>User Email</Label>
              <Select
                showSearch
                onChange={(selectedEmail) => {
                  handleUserEmailChange(selectedEmail);
                }}
                // g
                size={"large"}
                // disabled={isLoading}
                className="w-full"
                value={formData.email}
              >
                {formattedUserEmail?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="space-y-2">
              <Label>User Name</Label>
              <Input
                type="text"
                value={formData.name}
                name="name"
                disabled={true}
                // onChange={handleInputChange}
                isError={errors?.userNameError?.error}
                errorMessage={errors?.userNameError?.message}
              />
            </div>

            <div className="flex items-center space-x-3 w-full">
              <CustomDropDown
                label="Role"
                options={[
                  "ASSOCIATE",
                  "HEAD",
                  "MANAGER",
                  "PROVINCIAL_DIRECTOR",
                  "NATIONAL_DIRECTOR",
                ]}
                onSelect={handleRoleSelect}
                disabled={true}
                width="w-full"
                value={formData.role}
                isError={errors?.roleError?.error}
                errorMessage={errors?.roleError?.message}
              />
            </div>
            <div className="flex items-center space-x-3 w-full">
              <CustomDropDown
                label="Report to Role"
                options={[
                  "ASSOCIATE",
                  "HEAD",
                  "MANAGER",
                  "PROVINCIAL_DIRECTOR",
                  "NATIONAL_DIRECTOR",
                ]}
                onSelect={(selectedRole) =>
                  setFormData({
                    ...formData,
                    reportTo: selectedRole,
                  })
                }
                width="w-full"
                disabled={true}
                value={formData?.reportTo}
                isError={errors?.reportToError?.error}
                errorMessage={errors?.reportToError?.message}
              />
            </div>

            <div className="">
              <Label>Report User Email</Label>
              <Select
                showSearch
                onChange={(selectedEmail) => {
                  handleInputChange("reportToEmail", selectedEmail);
                }}
                // g
                size={"large"}
                // disabled={isLoading}
                className="w-full"
                value={formData.reportToEmail}
              >
                {reportToEmails?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>

            <div className="space-y-2">
              <Label>Commission Percentage</Label>
              <Input
                type="number"
                placeholder="Enter Commission Percentage"
                value={formData.commissionPercentage}
                name="commissionPercentage"
                onChange={handleInputChange}
              />
            </div>

            <div className="flex items-center justify-end gap-2">
              <ButtonOutlined
                handleClick={() => {
                  if (
                    props?.commissionUser &&
                    JSON.stringify(formData) !==
                      JSON.stringify(props?.commissionUser)
                  ) {
                    setConfirmationModal(true);
                  } else if (
                    formData.userName !== "" ||
                    formData.role !== "" ||
                    formData.commissionPercentage !== 0
                  ) {
                    setConfirmationModal(true);
                  } else {
                    props?.setModal(false);
                  }
                }}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={disableCreateButton}
              >
                {props?.mode === "add" ? "Create" : "Update"}
              </ButtonFill>
            </div>
          </div>
          <ConfirmationModal
            active={confirmationModal}
            onCancel={() => {
              setConfirmationModal(false);
            }}
            onConfirm={() => {
              setFormData(initialState);
              props?.setModal(false);
              setConfirmationModal(false);
            }}
            message={"Are you sure you want to leave this page?"}
          />
        </div>
      </div>
    </div>
  );
};

export default CommissionUserForm;
