import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import { Select } from "antd";
import { useEffect, useState } from "react";

const InstitutesDropDown = ({ value, getValue, noLabel = false }) => {
  const [allInstitutes, setAllInstitutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchAllInstitues = async () => {
    try {
      setIsLoading(true);
      const response = await BasicGetApi("getAllInstituteNames");
      if (response) {
        const data =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];
        const formattedData = data?.map((item) => item?.instituteName);
        setAllInstitutes(formattedData);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInstitues();
  }, []);

  return (
    <div className="my-2">
      <Select
        showSearch
        placeholder="Select University"
        onChange={(selectedValue) => {
          getValue("university", selectedValue);
        }}
        size={"large"}
        disabled={isLoading}
        className="w-full"
        value={value}
      >
        {allInstitutes?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default InstitutesDropDown;
