import  { useContext, useState } from "react";
import "./careers.css";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { Cancel } from "@material-ui/icons";
import { UserContext } from "../../../state-management/user-context";

import { JobsApi } from "(apis)/(shared-apis)/job-apis";
import toast from "react-hot-toast";

const EditJob = () => {
  const context = useContext(UserContext);
  const { user } = context;

  const initialErrorState = {
    backgroundImage: false,
    iconImage: false,
  };

  const imgSizes = {
    bg: {
      width: 1920,
      height: 360,
    },
    icon: {
      width: 72,
      height: 72,
    },
  };

  const [error, setError] = useState(initialErrorState);
  const [fetchedJob, setFetchedJob] = useState(null);
  const [imagesUrl, setImagesUrl] = useState({
    prevBackgroundImage: "",
    prevIconImage: "",
    selectedBackgroundImage: "",
    selectedIconImage: "",
  });

  const { id } = useParams();
  const [formData, setFormData] = useState({
    department: "",
    formLink: "",
    backgroundImage: null,
    iconImage: null,
  });

  useEffect(() => {
    const fetchJobData = async () => {
      try {
        const jobId = id;
        const response = await JobsApi("getJobById", [jobId]);
        // const response = await axios.get(`${rootPath}/api/job/id/${jobId}`);
        console.log("Response:", response);
        setFetchedJob(response?.data?.data);
      } catch (error) {
        console.error("Error fetching job:", error);
      }
    };

    fetchJobData();
  }, []);

  useEffect(() => {
    if (fetchedJob) {
      setFormData({
        department: fetchedJob.department,
        formLink: fetchedJob.formLink,
        backgroundImage: null,
        iconImage: null,
      });
      setImagesUrl({
        ...imagesUrl,
        prevBackgroundImage: fetchedJob.backgroundImageURL,
        prevIconImage: fetchedJob.iconImageURL,
      });
    }
  }, [fetchedJob]);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const imageFile = files[0];
      const image = new Image();
      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;

        setError(initialErrorState);

        if (
          name === "backgroundImage" &&
          (imageWidth !== imgSizes.bg.width ||
            imageHeight !== imgSizes.bg.height)
        ) {
          setError((prevError) => ({
            ...prevError,
            backgroundImage: true,
          }));
          return;
        }
        if (
          name === "iconImage" &&
          (imageWidth !== imgSizes.icon.width ||
            imageHeight !== imgSizes.icon.width)
        ) {
          setError((prevError) => ({
            ...prevError,
            iconImage: true,
          }));
          return;
        }

        setFormData((prevData) => ({ ...prevData, [name]: imageFile }));

        if (name === "backgroundImage") {
          setImagesUrl({
            ...imagesUrl,
            selectedBackgroundImage: URL.createObjectURL(imageFile),
          });
        }

        if (name === "iconImage") {
          setImagesUrl({
            ...imagesUrl,
            selectedIconImage: URL.createObjectURL(imageFile),
          });
        }
      };

      image.src = URL.createObjectURL(imageFile);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { department, formLink, backgroundImage, iconImage } = formData;

    if (!department || !formLink) {
      toast.error("Please fill in all the fields");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("department", department);
    formDataToSend.append("username", user?.username);
    formDataToSend.append("formLink", formLink);
    formDataToSend.append("backgroundImage", backgroundImage);
    formDataToSend.append("iconImage", iconImage);

    try {
      const response = await JobsApi("editJob", [id]);

      console.log("Job Updated Successfully 🚀 :", response.data);

      // Reset the form after successful submission
      setFormData({
        department: "",
        formLink: "",
        backgroundImage: null,
        iconImage: null,
      });

      //  Reset Error States
      setError(initialErrorState);
      toast.success("Success message! Job Updated Successfully 🚀");
    } catch (error) {
      console.error("Error creating job:", error);
    }
  };

  const removeNewImg = (type: any) => {
    if (type === "bg") {
      setFormData({ ...formData, backgroundImage: null });
      setImagesUrl({ ...imagesUrl, selectedBackgroundImage: "" });
    } else {
      setFormData({ ...formData, iconImage: null });
      setImagesUrl({ ...imagesUrl, selectedIconImage: "" });
    }
  };

  return (
    <div>
      <div className="careers-formbold-main-wrapper">
        <div className="careers-formbold-form-wrapper">
          <form onSubmit={handleSubmit}>
            <h1 className="mmy-6 text-lg">Edit Job </h1>
            <div className="careers-formbold-mb-5">
              <label
                className="careers-formbold-form-label careers-formbold-form-label-2"
                htmlFor="department"
              >
                Department:
              </label>
              <input
                type="text"
                name="department"
                id="department"
                placeholder="Enter department name here"
                className="careers-formbold-form-input"
                value={formData.department}
                onChange={handleInputChange}
              />
            </div>
            <div className="careers-formbold-mb-5 ">
              <label
                className="careers-formbold-form-label careers-formbold-form-label-2"
                htmlFor="formLink"
              >
                Form Link:
              </label>
              <input
                type="text"
                name="formLink"
                id="formLink"
                placeholder="Paste google form link here"
                className="careers-formbold-form-input"
                value={formData.formLink}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-6 pt-4">
              <label className="careers-formbold-form-label careers-formbold-form-label-2">
                Upload New Background Image:
              </label>

              <p
                className={`careers-dimensionError ${
                  error.backgroundImage && "active"
                }`}
              >
                Background Image dimensions must be {imgSizes.bg.width} x{" "}
                {imgSizes.bg.height}
              </p>

              <div className="careers-formbold-mb-5 careers-formbold-file-input">
                <input
                  type="file"
                  name="backgroundImage"
                  id="backgroundImage"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                <label htmlFor="backgroundImage">
                  <div>
                    <span className="careers-formbold-browse"> Browse </span>
                  </div>
                </label>
              </div>

              {formData.backgroundImage && (
                <p className="imageSelectedAlert">
                  Selected Background Image: {formData.backgroundImage.name}
                </p>
              )}
            </div>

            {imagesUrl.prevBackgroundImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <label className="careers-formbold-form-label careers-formbold-form-label-2">
                  Current Background Image:
                </label>

                <div className="imageSnap">
                  <img
                    src={imagesUrl.prevBackgroundImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}
            {imagesUrl.selectedBackgroundImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <div className="lableTray">
                  <label className="careers-formbold-form-label careers-formbold-form-label-2">
                    New Background:
                  </label>

                  <Cancel
                    style={{ cursor: "pointer" }}
                    onClick={() => removeNewImg("bg")}
                  />
                </div>
                <div className="imageSnap">
                  <img
                    src={imagesUrl.selectedBackgroundImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}

            <div className="mb-6 pt-4">
              <label className="careers-formbold-form-label careers-formbold-form-label-2">
                Upload New Icon:
              </label>

              <p
                className={`careers-dimensionError ${
                  error?.iconImage && "active"
                }`}
              >
                Icon dimensions must be {imgSizes.icon.width} x{" "}
                {imgSizes.icon.height}
              </p>

              <div className="careers-formbold-mb-5 careers-formbold-file-input">
                <input
                  type="file"
                  name="iconImage"
                  id="iconImage"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                <label htmlFor="iconImage">
                  <div>
                    <span className="careers-formbold-browse"> Browse </span>
                  </div>
                </label>
              </div>
              {formData.iconImage && (
                <p className="imageSelectedAlert">
                  Selected Icon: {formData.iconImage.name}
                </p>
              )}
            </div>

            {imagesUrl.prevIconImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <label className="careers-formbold-form-label careers-formbold-form-label-2">
                  Current Icon:
                </label>
                <div className="iconSnap">
                  <img
                    src={imagesUrl.prevIconImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}
            {imagesUrl.selectedIconImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <div className="lableTray">
                  <label className="careers-formbold-form-label careers-formbold-form-label-2">
                    New Icon:
                  </label>

                  <Cancel
                    style={{ cursor: "pointer" }}
                    onClick={() => removeNewImg("icon")}
                  />
                </div>
                <div className="iconSnap">
                  <img
                    src={imagesUrl.selectedIconImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}
            <div>
              <button
                className="careers-formbold-btn careers-w-full"
                type="submit"
                style={{ marginTop: "5rem" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditJob;
