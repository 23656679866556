import React, { useState, useEffect } from 'react'
import { notification } from 'antd';
import { backendUrl } from "../../constants/config";
import { FlashcardsAPI } from "../../(apis)/(shared-apis)/flashcard-apis";

export default function SelectFlashcard() {

    const [SelectedSubject, setSelectedSubject] = useState(null);
    const [SelectedChapter, setSelectedChapter] = useState(null);
    const [SelectedChapterId, setSelectedChapterId] = useState(null);
    const [FlashCards, setFlashCards] = useState([]);

    useEffect(() => {

        const FetchData = async () => {
            const response = await FlashcardsAPI('GetNewFlashCards', "", null, {
                'Cache-Control': 'no-cache, no-store, must-revalidate',
                Pragma: 'no-cache',
                Expires: '0',
              });

            if (!response) {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch data',
                });
                return;
            }
            const data = await response;
            setFlashCards(data.FindFlashcards);
        }

        FetchData();

    }, []);

    const FindTotalQuestions = (data) => {
        let totalQuestions = 0;
        data?.map((card) => {
            totalQuestions += card.questions.length;
        });
        return totalQuestions;
    };

    return (
        <>
            {SelectedChapterId && (
                <div className='flex justify-center ml-[4%] bg-rose-500 text-center items-center hover:bg-rose-600 cursor-pointer rounded-xl shadow-2xl text-white w-[15%] p-2 mt-[2%]' onClick={() => {
                    window.location.href = `/addFlashcard/${SelectedChapterId}`;
                }}>
                    Add Flashcard to Chapter
                </div>
            )}
            <div className="mt-[30px] min-h-10 h-screen pb-[100px] relative">
                <div className="bg-gradient-to-r from-[#ffffff] via-[#000000] to-[#ffffff] min-h-[1px] opacity-[0.5] w-[100%] sticky top-[10%] left-[0] z-[50]">
                    {/* Content */}
                </div>
                <table className="w-[94%] ml-[3%] mr-[3%]">
                    <tr className="text-[25px] bg-rose-500 font-[700] text-white">
                        <th className="text-left w-[30%]"><div className="ml-[6%]">
                            Decks  </div></th>
                        <th className="w-[10%]">Total Questions</th>
                    </tr>
                    {FlashCards?.length === 0 ? (
                        <div className="text-[20px] font-[500] text-black">No Flashcards Found</div>
                    ) : (
                        FlashCards?.map((card, index) => (
                            <tbody key={index}>
                                <tr className="text-[20px] font-[500] text-black hover:bg-rose-300 cursor-pointer">
                                    <td className="text-left w-[30%] h-[10%]">
                                        <div
                                            className="ml-[3%] flex items-center font-[700]"
                                            onClick={() => {
                                                if (SelectedSubject === card.Subject) {
                                                    setSelectedSubject(null);
                                                } else {
                                                    setSelectedSubject(card.Subject);
                                                }
                                            }}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                className="icon icon-tabler icon-tabler-plus cursor-pointer"
                                                width="30"
                                                height="30"
                                                viewBox="0 0 24 24"
                                                strokeWidth="2.5"
                                                stroke="#ec5863"
                                                fill="none"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            >
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M12 5l0 14" />
                                                <path d="M5 12l14 0" />
                                            </svg>
                                            <div className="ml-2 text-[28px] font-[600] cursor-pointer hover:underline">
                                                {card.Subject}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="text-center text-[28px] font-[500] text-[#EC5863]">
                                        {FindTotalQuestions(card?.SubjectData)}
                                    </td>
                                </tr>
                                {SelectedSubject === card.Subject &&
                                    card?.SubjectData?.map((subjectData, subIndex) => (
                                        <React.Fragment key={subIndex}>
                                            <tr className="text-[20px] font-[500] hover:bg-rose-300 text-black mt-4">
                                                <td className="text-left w-[30%]">
                                                    <div
                                                        className="ml-[6%] flex items-center font-[600]"

                                                    >
                                                        <input
                                                            type="checkbox"
                                                            className='w-[20px] h-[20px] border-2 border-red-700 bg-red-400 checked:bg-green-500 checked:border-green-900'
                                                            onChange={() => {
                                                                if (SelectedChapterId === subjectData._id) {
                                                                    setSelectedChapterId(null);
                                                                } else {
                                                                    setSelectedChapterId(subjectData._id);
                                                                }
                                                            }}
                                                        />
                                                        <div className="ml-2 text-[28px] font-[500] cursor-pointer hover:underline" onClick={() => {
                                                            if (SelectedChapter) {
                                                                setSelectedChapter(null);
                                                            } else {
                                                                setSelectedChapter(subjectData.questions);
                                                            }
                                                        }}>
                                                            {subjectData.ChapterName}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="text-center text-[24px] font-[500] text-[#EC5863]">
                                                    {subjectData.questions.length}
                                                </td>
                                            </tr>
                                            {SelectedChapter && SelectedChapter === subjectData.questions && (
                                                subjectData.questions.map((chapter, chapterIndex) => (
                                                    <tr key={chapterIndex} className="text-[20px] hover:bg-rose-300 font-[500] text-black mt-4">
                                                        <td className="text-left w-[30%]">
                                                            <div
                                                                className="ml-[9%] flex items-center font-[500]"
                                                                onClick={() => {
                                                                    window.location.href = `/editFlashcard/${chapter._id}/false/false`;
                                                                }}
                                                            >

                                                                <div className="text-[20px] font-[400] cursor-pointer hover:underline">
                                                                    {chapter.question}
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            )}
                                        </React.Fragment>
                                    ))
                                }


                            </tbody>
                        ))
                    )}
                </table>
            </div>
        </>
    )
}
