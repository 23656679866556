import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "../../interfaces/global-interfaces";

const APIs: ApiInterface[] = [
    {
      url: "/api/flashcard/get-flashcards",
      method: "POST",
      name: "GetLatestFlashcards",
    },
    {
      url: "/api/flashcard/handleFlashcards",
      method: "POST",
      name: "HandleFlashcards",
    },
    {
      url: "/api/flashcard/GetNewFlashCards",
      method: "GET",
      name: "GetNewFlashCards",
    },
    {
      url: "/api/flashcard/GetChapterQuestions",
      method: "PUT",
      name: "GetChapterQuestions",
    },
    {
      url: "/api/flashcard/AddFlashcardUser",
      method: "PUT",
      name: "AddFlashcardUser",
    },
    {
      url: "/api/flashcard/GetUserFlashcards",
      method: "PUT",
      name: "GetUserFlashcardsByChapter",
    },
    {
      url: "/api/flashcard/HideFlashcard",
      method: "PUT",
      name: "HideFlashcard",
    },
    {
      url: "/api/flashcard/GetHiddenQuestions",
      method: "PUT",
      name: "GetHiddenQuestions",
    },
    {
      url: "/api/flashcard/GetRandomQuestions",
      method: "GET",
      name: "GetRandomQuestions",
    },
    {
      url: "/api/flashcard/DeleteFromHiddenQuestions",
      method: "PUT",
      name: "DeleteFromHiddenQuestions",
    },
    {
      url: "/api/flashcard/EditFlashcard",
      method: "PUT",
      name: "EditFlashcard",
    },
    {
      url: "/api/flashcard/GetQuestionById",
      method: "GET",
      name: "GetQuestionById",
    },
    {
      url: "/api/flashcard/GetQuestionById/:id",
      method: "GET",
      name: "GetQuestionByIdAdmin",
    },
    {
      url: "/api/flashcard/AddFlashcardtoChapter",
      method: "PUT",
      name: "AddFlashcardtoChapter",
    },
    {
      url: "/api/flashcard/AddReport",
      method: "PUT",
      name: "AddReport",
    },
    {
      url: "/api/flashcard/GetPendingReports",
      method: "GET",
      name: "GetPendingReports",
    },
    {
      url: "/api/flashcard/ChangeStatusReport",
      method: "PUT",
      name: "ChangeStatusReport",
    },
  ];

  
  export async function FlashcardsAPI(name: string, params: string = "", data: any = null, customHeaders: Record<string, string> = {}) {
    const api = APIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = `${backendUrl}${api.url}${params ? `/${encodeURIComponent(params)}` : ""}`;
  
    const options: RequestInit = {
      method: api.method,
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
    };
  
    if (api.method !== "GET" && data) {
      options.body = JSON.stringify(data);
    }
  
    const response = await fetch(url, options);
  
    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }
  
    return response.json();
  }