import React, { useEffect } from 'react'
import { useState } from 'react'
import toast, { Toaster } from 'react-hot-toast'
import CustomDropDown from '../../../shared-components/ui/CustomDropDown'
import { ReferenceAPI } from '../../../(apis)/(shared-apis)/academics/reference-apis';
import { Switcher } from '../../../shared-components/ui/CustomForm';
import { ManageIcon } from '../../../shared-components/ui/Icons';
import { EditIcon } from '../../../shared-components/ui/Icons';
import { ButtonFill } from '../../../shared-components/ui/CustomButtons';
import CreateReferenceModal from './components/add-reference-modal';
import { apiRequest } from '(apis)/api-interface/api-handler';

export default function Page() {

    interface FilterForm {
        selectedBoard: string,
        subjects: string,
        status: string,
    }

    const [filterForm, setFilterForm] = useState<FilterForm>({
        selectedBoard: "All Boards",
        subjects: "All",
        status: "All",
    })

    const [boards, setBoards] = useState([
        "All Boards",
        "Sindh",
        "Punjab",
        "Federal",
        "KPK",
        "Balochistan",
    ]);
    const [status, setStatus] = useState([
        "All",
        "Published",
        "Unpublished",
    ]);


    const [subjects, setSubjects] = useState([])
    const GetSubjects = async () => {
        try {
            const response = await apiRequest("getSubjects");
            setSubjects(response.data.map((subject: any) => subject.name));
        }
        catch (error) {
            console.log(error);
        }
    }

    const [references, setReferences] = useState([])
    const [filteredReferences, setFilteredReferences] = useState([])
    const GetReferences = async () => {
        try {
            const response = await ReferenceAPI("getReferences");
            setReferences(response.data.data);
            setFilteredReferences(response.data.data);
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        GetReferences();
        GetSubjects();
    }, [])

    const FilterReferences = () => {
        let filteredReferences = references.filter((reference: any) => {
            if (filterForm.selectedBoard !== "All Boards" && reference.board !== filterForm.selectedBoard) {
                return false;
            }
            if (filterForm.subjects !== "All" && reference.subject !== filterForm.subjects) {
                return false;
            }
            if (filterForm.status !== "All" && (filterForm.status === "Published" ? !reference.isPublished : reference.isPublished)) {
                return false;
            }
            return true;
        })
        setFilteredReferences(filteredReferences);
    }
    const ResetFilter = () => {
        setFilterForm({
            selectedBoard: "All Boards",
            subjects: "All",
            status: "All",
        })
        setFilteredReferences(references);
    }

    const handleStatusChanged = async (updatedData: any) => {
        try {
            if (
                updatedData.isPublished !== null &&
                updatedData.id !== null &&
                updatedData.editedBy !== null
            ) {
                const response = await ReferenceAPI("updateReference", [updatedData.id], { isPublished: updatedData.isPublished, editedBy: updatedData.editedBy });
                if (response.data.success) {
                    toast.success("Publishing status toggled successfully");
                    const updatedReferences = references.map((reference) =>
                        reference._id === updatedData.id
                            ? {
                                ...reference,
                                isPublished: !reference.isPublished,
                                editedBy: updatedData.editedBy,
                            }
                            : reference
                    );
                    setReferences(updatedReferences);
                }
            } else {
                toast.error("Error toggling publishing status");
                return;
            }
        } catch (error) {
            toast.error("Error toggling publishing status");
        }
    };

    const [addModal, setAddModal] = useState(false);
    const [mode , setMode] = useState<string>("add");
    const [editData, setEditData] = useState<any>(null);
    return (
        <div>
            <Toaster />

            <div className="w-full pb-20 px-5">
                <div className="flex flex-col pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700">Reference Management</h2>

                    <div className="flex items-end gap-5 justify-between mt-8">
                    <div className="flex items-end gap-5 justify-start mt-8">
                        <CustomDropDown
                            label="Filter Boards"
                            options={boards}
                            onSelect={(selectedBoard) => setFilterForm({ ...filterForm, selectedBoard })}
                            width="min-w-[16rem]"
                            value={filterForm.selectedBoard}
                        />
                        <CustomDropDown
                            label="Filter Subjects"
                            options={subjects}
                            onSelect={(selectedSubject) => setFilterForm({ ...filterForm, subjects: selectedSubject })}
                            width="min-w-[16rem]"
                            value={filterForm.subjects}
                        />
                        <CustomDropDown
                            label="Status"
                            options={status}
                            onSelect={(selectedStatus) => setFilterForm({ ...filterForm, status: selectedStatus })}
                            width="min-w-[16rem]"
                            value={filterForm.status}
                        />

                        <ButtonFill
                            handleClick={FilterReferences}
                            disabled={references.length === 0}
                        >
                            Filter References
                        </ButtonFill>
                        <ButtonFill
                            handleClick={ResetFilter}
                            disabled={references.length === 0}
                        >
                            Reset Filter
                        </ButtonFill>


                    </div>

                    <div className="flex items-end gap-5 justify-end mt-8">
                        <ButtonFill
                            handleClick={() => { 
                                setMode("add")
                                setAddModal(true) }}
                        >
                            Add Reference
                        </ButtonFill>
                    </div>
                </div>

                    <div className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold mt-4">
                        <div className="overflow-x-auto">
                            <table className="w-full mb-20 text-sm text-left text-gray-500">
                                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                    <tr>
                                        <th scope="col" className="px-4 py-4 text-left">
                                            SR#
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Name
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Metadata
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Total Pages
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Board
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Subject
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Created By
                                        </th>
                                        <th scope="col" className="px-4 py-4">
                                            Edited By
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-center">
                                            Published
                                        </th>
                                        <th scope="col" className="px-4 py-4 text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
                                    {filteredReferences.length > 0 ? (
                                        filteredReferences.map((reference, index) => (
                                            <tr
                                                key={index}
                                                className="bg-white divide-y divide-gray-200 text-gray-800"
                                            >
                                                <td className="px-4 py-4">{index + 1}</td>
                                                <td className="px-4 py-4">{reference.pdfName}</td>
                                                <td className="px-4 py-4">{reference.pdfMetadata}</td>
                                                <td className="px-4 py-4">{reference.totalPages}</td>
                                                <td className="px-4 py-4">{reference.board}</td>
                                                <td className="px-4 py-4">{reference.subject}</td>
                                                <td className="px-4 py-4">{reference.createdBy}</td>
                                                <td className="px-4 py-4">{reference.editedBy}</td>
                                                <td className="px-4 py-4 text-center">
                                                    <Switcher
                                                        isActive={undefined}
                                                        for={reference._id}
                                                        onChange={() => {
                                                            handleStatusChanged({
                                                                isPublished: !reference.isPublished,
                                                                id: reference._id,
                                                                editedBy: "Admin"
                                                            });
                                                        }}
                                                        togglevalue={reference.isPublished}
                                                        size="small"
                                                    />
                                                </td>
                                                <td className="px-4 py-4 text-center">
                                                    <div className='flex gap-2'>
                                                        <button
                                                            className="open-form-button"
                                                            role="button"
                                                            onClick={() =>
                                                                window.open(
                                                                    reference.pdfLink,
                                                                    "_blank"
                                                                )
                                                            }
                                                        >
                                                            <ManageIcon size="w-6 h-6" />
                                                        </button>

                                                        <button
                                                            className="open-form-button"
                                                            role="button"
                                                            onClick={() => {
                                                                setMode("edit")
                                                                setEditData(reference)
                                                                setAddModal(true)
                                                            }}
                                                        >
                                                            <EditIcon size="w-6 h-6" />
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <tr>
                                            <td colSpan={11}>No reference PDFs available</td>
                                        </tr>
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            <CreateReferenceModal
                ShowModel={addModal}
                setShowModel={setAddModal}
                GetReferences={GetReferences}
                boards={boards}
                subjects={subjects}
                mode={mode}
                editData={editData}                
            />

        </div>
    )
}
