import { apiRequest } from "(apis)/api-interface/api-handler";
import { Select } from "antd";
import { useEffect, useState } from "react";

const BoardsDropDown = ({ value, getValue, noLabel = false }) => {
  const [allInstitutes, setAllInstitutes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchAllInstitues = async () => {
    try {
      setIsLoading(true);
      const response = await apiRequest("getHierarchies");
      if (response) {
        const data =
          response?.data && response?.data?.length > 0 ? response?.data : [];
        const formattedData = data?.map((item) => item?.board);
        setAllInstitutes(formattedData);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllInstitues();
  }, []);

  return (
    <div className="my-2">
      <Select
        showSearch
        placeholder="Select Board"
        onChange={(selectedValue) => {
          getValue("board", selectedValue);
        }}
        size={"large"}
        disabled={isLoading}
        className="w-full"
        value={value}
      >
        {allInstitutes?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default BoardsDropDown;
