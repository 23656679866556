import React, { useEffect, useMemo, useState } from "react";

import { Table, Input, Button, type TableProps } from "antd";
import { useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import "./styles.css";
import Loading from "shared-components/ui/Loading";

export default function ManageExperts() {
  const navigate = useNavigate();

  // const URL = GetDomain();
  const [Logs, SetLogs] = useState<Record<string, any> | null>(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedState, setSelectedState] = useState("Biology");

  const GetLogList = async () => {
    const Response = await apiRequest("ExpertLogs", { subject: selectedState });
    SetLogs(Response.data);
  };

  useEffect(() => {
    GetLogList();
  }, [selectedState]);

  const handleStateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedState(e.target.value);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const columns: TableProps<any>["columns"] = [
    {
      title: "SR#",
      dataIndex: "sr",
      key: "sr",
      className: "table-header",
    },
    {
      dataIndex: "email",
      key: "email",
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }) => (
        <div style={{ padding: 8 }}>
          <Input
            placeholder="Search email"
            value={selectedKeys[0]}
            onChange={(e) =>
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }
            onPressEnter={() => handleSearch(selectedKeys, confirm, "email")}
            style={{ width: "100%", marginBottom: 8, display: "block" }}
          />
          <Button
            onClick={() => handleReset(clearFilters)}
            size="small"
            style={{ width: "100%" }}
          >
            Reset
          </Button>
        </div>
      ),
      onFilter: (value: any, record: any) =>
        record.email.toLowerCase().includes(value.toLowerCase()),
      render: (text) =>
        searchedColumn === "email" ? (
          <span className="highlight">{text}</span>
        ) : (
          text
        ),
    },
    {
      title: "Solved",
      dataIndex: "upload",
      key: "upload",
      className: "upload",
    },
    {
      title: "Action",
      key: "action", // Define a key for the action column

      render: (text, record) => (
        <Button
          className="bg-red-500 hover:!bg-red-400 text-white hover:!text-white"
          onClick={() =>
            navigate(`/ExpertDashboard/${selectedState}?email=${record.email}`)
          }
        >
          Dashboard
        </Button>
      ),
    },
  ];

  const tableData = useMemo(
    () =>
      Logs
        ? Object?.keys(Logs)?.map((email, index) => ({
            key: index,
            sr: index + 1,
            email: email,
            upload: Logs[email].Count,
          }))
        : [],
    [Logs]
  );
  return (
    <>
      {!Logs && <Loading />}
      <div className="MarketingNav">Experts Manager Dashboard</div>

      <br />
      <br />
      <div className="MarketingNav-Wrapper">
        <div className="ContainerWrapper">
          <div className="radio-wrapper">
            <div className="radio-inputsM">
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  value="Biology"
                  checked={selectedState === "Biology"}
                  onChange={handleStateChange}
                />
                <span className="name">Biology</span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  value="Chemistry"
                  checked={selectedState === "Chemistry"}
                  onChange={handleStateChange}
                />
                <span className="name">Chemistry</span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  value="Physics"
                  checked={selectedState === "Physics"}
                  onChange={handleStateChange}
                />
                <span className="name">Physics</span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  value="English"
                  checked={selectedState === "English"}
                  onChange={handleStateChange}
                />
                <span className="name">English</span>
              </label>
              <label className="radio">
                <input
                  type="radio"
                  name="radio"
                  value="Logical Reasoning"
                  checked={selectedState === "Logical Reasoning"}
                  onChange={handleStateChange}
                />
                <span className="name">Logical Reasoning</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div id="MarketingTable-Wrapper">
        <div id="MarketingTable">
          <Table
            columns={columns}
            dataSource={tableData}
            pagination={false}
            className="custom-table"
            style={{
              width: "100%",
              overflowX: "auto",
              overflowY: "auto",
              height: "100%",
            }}
          />
        </div>
      </div>
    </>
  );
}
