import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

 export const disciplinesApis:ApiInterface[] = [
    {
        url: "/api/discipline",
        method: "GET",
        name: "getDisciplines",
    },
    {
        url: "/api/discipline/publish",
        method: "PUT",
        name: "updateDisciplinePublishedStatusById",
    },
    {
        url: "/api/discipline",
        method: "POST",
        name: "addDiscipline",
    },
    {
        url: "/api/discipline",
        method: "PUT",
        name: "updateDisciplineById",
    },    
]
  
export function DisciplinesAPI(name: string, params: string[] = [""], data: any = null) {
    const api = disciplinesApis.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

