import { ApiInterface } from "../api-interface/types";

export const marketingApis: ApiInterface[] = [

  {
    url: "/api/marketing/filter-all",
    method: "POST",
    name: "filterUserBYSelectedFields",
  },
  {
    url: "/api/marketing/get-skus",
    method: "GET",
    name: "getAllSKUs",
  },
];
