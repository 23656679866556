import React from "react";
import { DatePicker } from "antd";
import { FormikProps } from "formik";
import CustomFormikError from "./CustomFormikError";
import LabelComponent from "./LabelComponent";
import { getErrorString } from "../../utility/form/functions";
import dayjs, { Dayjs } from "dayjs";

interface CustomDatePickerProps {
  formik: FormikProps<any>;
  label: string;
  name: string;
  require?: boolean;
  disabled?: boolean;
  showTime?: boolean;
  format?: string;
}

const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  formik,
  label,
  name,
  require = true,
  disabled = false,
  showTime = false,
  format = "YYYY-MM-DD",
}) => {
  const touched = !!formik.touched[name];
  const error = getErrorString(formik.errors[name]);

  return (
    <div>
      <LabelComponent value={label} require={require} />
      <DatePicker
        className="w-full"
        showTime={showTime}
        format={format}
        value={formik.values[name] ? dayjs(formik.values[name]) : null}
        disabled={disabled}
        placeholder={`Select ${label}`}
        onChange={(date: Dayjs | null) => {
          formik.setFieldValue(name, date );
        }}
      />
      <CustomFormikError name={name} touched={touched} error={error} />
    </div>
  );
};

export default CustomDatePicker;
