interface FileInfo {
    uid: number | string;
    name: string;
    status: string;
    url: string;
    type: string;
    isOldFile: boolean;
  }
  
 export  const generateAntdImageObjectFromUrl = (imageUrl: string, uid: number | string = 1): FileInfo | null => {
    // if (!imageUrl) return null;
  
    const imgType =imageUrl &&   imageUrl?.substring(imageUrl?.lastIndexOf("/") + 1)?.split(".").pop();
  
    return {
      uid,
      name: imageUrl,
      status: "done",
      url: imageUrl,
      type: `image/${imgType}`,
      isOldFile: true,
    };
  };
  
  