import React, { useState , useEffect } from "react";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { ImagesMainTable } from "./table";
import AddImagesModal from "./add-modal";
const Gallery = () => {
    const [images,setimages]=useState([]);
    useEffect(()=>{
        apiRequest("getAllImageList")
        .then((response)=>{
          
          setimages(response.data.data)
          console.log("THis is the response",response.data.data)
        })
        .catch((error) => {
          console.log(error);
        });
      }, [])

    const [showModel, setShowModel] = useState(false);
  return (
    <>
     <div className="flex justify-center">
        <h2>Images Gallery</h2>
      </div>
    <div className="flex w-full justify-center">
    <ImagesMainTable images = {images} setimages = {setimages}  setShowModel={setShowModel}/>
    
        <AddImagesModal  setimages = {setimages} ShowModel={showModel}
    setShowModel={setShowModel}/>
    
    
    </div>
    </>
  )
}

export default Gallery