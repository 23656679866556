
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DraggableQuestion from './draggable-view'
import { DeckInterface } from '../interface'


export default function TableContainer({ decks, setDecks, GetDecks, moveQuestion, setMode, prepopulateData, setPopulated, setShowModel, deckGroupName, categoryName }) {
    return (
        <DndProvider backend={HTML5Backend}>
            <div className="overflow-x-auto">
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                        <tr>
                            <th scope="col" className="px-4 py-4 text-center">
                                Copy Deck ID
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Group Image
                            </th>
                            <th scope="col" className="px-4 py-4">
                                Group Name
                            </th>
                            <th scope="col" className="px-4 py-4 text-center">
                                Published/UnPublished/SAQS
                            </th>
                            <th scope="col" className="px-4 py-4 text-center">
                                Published
                            </th>
                            <th scope="col" className="px-2 py-4 text-center">
                                Questions
                            </th>
                            <th scope="col" className="px-2 py-4 text-center">
                                Preview
                            </th>
                            <th scope="col" className="px-2 py-4 text-center">
                                Edit
                            </th>

                        </tr>
                    </thead>

                    <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
                        {decks &&
                            decks.map((deck: DeckInterface, index: number) => (
                                <DraggableQuestion
                                    key={deck._id}
                                    deck={deck}
                                    index={index}
                                    moveQuestion={moveQuestion}
                                    decks={decks}
                                    setDecks={setDecks}
                                    GetDecks={GetDecks}
                                    setMode={setMode}
                                    setPopulated={setPopulated}
                                    prepopulateData={prepopulateData}
                                    setShowModel={setShowModel}
                                    deckGroupName={deckGroupName}
                                    categoryName={categoryName}
                                />
                            ))}
                    </tbody>
                </table>
            </div>
        </DndProvider>
    )
}

