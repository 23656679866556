const commonInitialValues = {
  topicName: "",
  province: "",
  subject: "",
  isPublished: "",
  thumbnailImage: null,
  paginations: [
    {
      subTopic: "",
      startPageNo: "",
      endPageNo: "",
    },
  ],
  board: "",
  access: "",
  description: "",
  universities: [],
  year: "",
  desc: "",
  editId: null,
};
const ShortlistingInitialValues = {
  ...commonInitialValues,
  image: "",
  imageUrl: "",
  pdfUrl: "",
  desc: "",
  pdfFile: null,
 
};
const MnemonicInitialValues = {
  ...commonInitialValues,
  subTopicName: "",
  image: "",
  imageUrl: "",
  thumbnailUrl: "",
  thumbnail: "",
  videoUrl: "",
  videoFile: null,

  videoLink: "",
};
export const NoteInitialValues = {
  ...commonInitialValues,
  pdfUrl: "",
  pdfFile: null,

  subTopicName: "",
};
const CheatsheetInitialValues = {
  ...commonInitialValues,
  subTopicName: "",
  image: "",
  imageUrl: "",
  pdfUrl: "",
  pdfFile: null,

};

const topicalGuidesInitialValues = {
  ...commonInitialValues,
  pdfUrl: "",
  subTopicName: "",
  pdf: null,
  topicalGuideId: null,
};
const snapCoursesInitialValues = {
  ...commonInitialValues,
  videoUrl: "",
  video: null,
  snapCourseId: null,
  learningObjectives: "",
  instructor: "",
  subTopicName: "",
};
const essentialStuffInitialValues = {
  category: "",
  province: "",
  isPublished: "",
  image: null,
  paginations: [
    {
      subTopic: "",
      startPageNo: null,
      endPageNo: null,
    },
  ],
  board: "",
  access: "",
  entity: "",
  description: "",
  pdf: null,
  essentialId: null,
  resourceName: "",
  resourceType: "",
  universities: [],
  year: "",
};
export {
  topicalGuidesInitialValues,
  essentialStuffInitialValues,
  snapCoursesInitialValues,
  CheatsheetInitialValues,
  MnemonicInitialValues,
  ShortlistingInitialValues,
};
