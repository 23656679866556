import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import CommonFAQS from 'shared-components/common-faqs';
import { UserContext } from 'state-management/user-context';
const FAQs = ({ id,getCourseData,courseData,loading,setLoading}) => {
  const {user} = useContext(UserContext);
  const history = useNavigate();
  // Formik setup
const formik  = useFormik({
    initialValues: {
      faqs: [{ question: '', answer: '' }],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!user?._id) {
          message.error('Please Login first');
          history('/login');
          return;
        }
        setLoading(true);
        const { faqs } = values;
        const faqData = faqs.map(faq => ({
          question: faq.question,
          answer: faq.answer
        }));
        const formData = new FormData();
        formData.append('faqs', JSON.stringify(faqData));
        // Update course API call
        const res = await apiRequest("updateCourse",formData, [id]);
        if (res?.status === 200) {
          setLoading(false);
          message.success(res?.data?.message);
          getCourseData();
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message || error.message);
        setLoading(false);
      }
    },
  });
  const {
    setValues,
  } = formik;
  useEffect(() => {
    if(courseData?.faqs?.length >0){
      setValues({
        faqs:courseData?.faqs || [{ question: '', answer: '' }],
      });

    }
  }, [courseData, setValues]);


  return (
    <div className='m-0'>
    
    <CommonFAQS
      formik={formik}
      data={courseData} />
    
    </div>
  );
};

export default FAQs;
