import { useState } from "react";

import QuestionTableView from "./component/question-table-view";
import { Input } from "../../../shared-components/ui/CustomForm";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";

import { Select } from "antd";
import { DeleteIcon } from "../../../shared-components/ui/Icons";
import { FilterQuestionApi } from "(apis)/(shared-apis)/filter-questions-apis";
import InstitutesDropDown from "./component/institutes-drop-down";
import BoardsDropDown from "./component/boards-drop-down";
import MetaCategoryDropDown from "./component/meta-category-drop-down";
import SubjectsDropDown from "./component/subject-drop-down";
import MetaEntityDropDown from "./component/meta-entity-drop-down";
import TopicsDropDown from "./component/topic-drop-down";
import ModuleDropDown from "./component/module-drop-down";
import SubTopicsDropDown from "./component/subtopic-drop-down";

const FilterQuestions = () => {
  const initalFilters = {
    author: "",
    tag: "",
    subject: "",
    category: "",
    university: "",
    published: "",
    topic: "",
    module: "",
    board: "",
    year: "",
    subtopic: "",
    entity: "",
    examType: "",
  };
  const [filters, setFilters] = useState(initalFilters);

  const [questions, setQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const handleInputChange = (name, value) => {
    setFilters((prevFilters) => ({ ...prevFilters, [name]: value }));
  };

  const handleFilter = async (e) => {
    e.preventDefault();
    try {
      setIsLoading(true);

      const response = await FilterQuestionApi(
        "getFilteredQuestions",
        [],
        null,
        filters
      );

      if (response) {
        setQuestions(response.data.questions);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };

  const distinctStatus = ["true", "false"];

  const setValue = (name, selectedValue) => {
    handleInputChange(name, selectedValue);
  };

  const resetValue = (name) => {
    handleInputChange(name, "");
  };

  const [selectedQuestionIds, setSelectedQuestionIds] = useState([]);
  const ExamTypes = [
    "Past Paper Question",
    "Practice Question",
    "Mock Test Question",
    "MODULE_EXAM",
    "PROFF_EXAM",
    "MID_SEMESTER",
    "FINAL_SEMESTER",
    "QUIZ",
    "BLOCK_EXAM",
    "VIVA",
  ];
  const isMedschool = process.env.REACT_APP_CATEGORY === "MEDSCHOOL";
  return (
    <div className="m-10 space-y-8">
      <h2 className="text-2xl font-bold text-gray-700">Filter Questions</h2>

      <form
        onSubmit={(e) => {
          handleFilter(e);
        }}
      >
        <div className="flex flex-wrap items-end gap-5 \">
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Author:</label>
              <button
                className=""
                onClick={() => {
                  resetValue("author");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <Input
              type="text"
              placeholder="Enter Author Name"
              value={filters.author}
              name="Author"
              onChange={handleInputChange}
              isError={false}
              errorMessage={""}
            />
          </div>

          {isMedschool ? (
            <div className="w-full md:min-w-[18rem]  md:max-w-fit">
              <div className="flex items-center justify-between">
                <label>Category:</label>
              </div>

              <Input
                type="text"
                placeholder="Category"
                value={"MEDSCHOOL"}
                disabled
                name="Author"
                onChange={handleInputChange}
                isError={false}
                errorMessage={""}
              />
            </div>
          ) : (
            <div className="w-full md:min-w-[18rem]  md:max-w-fit">
              <div className="flex items-center justify-between">
                <label>Category:</label>
                <button
                  className=""
                  type="button"
                  onClick={() => {
                    resetValue("category");
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
              <MetaCategoryDropDown
                value={filters.category}
                getValue={setValue}
                noLabel
              />
            </div>
          )}

          {isMedschool ? (
            <div className="w-full md:min-w-[18rem]  md:max-w-fit">
              <div className="flex items-center justify-between">
                <label>Entity:</label>
              </div>
              <Input
                type="text"
                placeholder="UNDERGRADUATE"
                value={"UNDERGRADUATE"}
                disabled
                name="Author"
                onChange={handleInputChange}
                isError={false}
                errorMessage={""}
              />
            </div>
          ) : (
            <div className="w-full md:min-w-[18rem]  md:max-w-fit">
              <div className="flex items-center justify-between">
                <label>Entity:</label>
                <button
                  className=""
                  type="button"
                  onClick={() => {
                    resetValue("entity");
                  }}
                >
                  <DeleteIcon />
                </button>
              </div>
              <MetaEntityDropDown
                value={filters.entity}
                getValue={setValue}
                noLabel
              />
            </div>
          )}

          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Tag:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("tag");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <Input
              type="text"
              placeholder="Tag"
              value={filters.tag}
              name="tag"
              onChange={handleInputChange}
              isError={false}
              errorMessage={""}
            />
          </div>
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Subject:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("subject");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <SubjectsDropDown
              value={filters.subject}
              noLabel
              getValue={setValue}
            />
          </div>
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Topic:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("topic");
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            <TopicsDropDown value={filters.topic} noLabel getValue={setValue} />
          </div>
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>SubTopic:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("topic");
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            <SubTopicsDropDown
              value={filters.subtopic}
              noLabel
              getValue={setValue}
              topicName={filters?.topic}
              category={filters?.category}
            />
          </div>
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Module:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("topic");
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            <ModuleDropDown
              value={filters.module}
              noLabel
              getValue={setValue}
            />
          </div>
          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Exam Type:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("examType");
                }}
              >
                <DeleteIcon />
              </button>
            </div>
            <div className="my-2">
              <Select
                showSearch
                placeholder="Exam Type"
                onChange={(selectedValue) => {
                  setValue("examType", selectedValue);
                }}
                // g
                size={"large"}
                disabled={isLoading}
                className="w-full"
                value={filters.examType}
              >
                {ExamTypes?.map((item) => (
                  <Select.Option key={item} value={item}>
                    {item}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>

          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            {/* <label>Category:</label> */}
            <div className="flex items-center justify-between">
              <label>Year:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("year");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <Input
              type="number"
              placeholder="Year"
              value={filters.year}
              name="year"
              onChange={handleInputChange}
              isError={false}
              errorMessage={""}
            />
          </div>

          <div className="w-full md:min-w-[18rem]  md:max-w-fit">
            {/* <label>Published:</label> */}
            <div className="flex items-center justify-between">
              <label>Published:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("published");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <CustomDropDown
              label="Published"
              value={filters.published}
              options={distinctStatus}
              all={true}
              noLabel
              onSelect={(selectedValue) => {
                setFilters({ ...filters, published: selectedValue });
              }}
            />
          </div>

          <div className="w-full md:min-w-[22rem] md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>University:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("university");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <InstitutesDropDown
              value={filters.university}
              // name="university"
              getValue={setValue}
              noLabel
            />
          </div>

          <div className="w-full md:min-w-[22rem] md:max-w-fit">
            <div className="flex items-center justify-between">
              <label>Board:</label>
              <button
                className=""
                type="button"
                onClick={() => {
                  resetValue("board");
                }}
              >
                <DeleteIcon />
              </button>
            </div>

            <BoardsDropDown value={filters.board} getValue={setValue} noLabel />
          </div>
        </div>

        <div className="my-7 space-x-2">
          <ButtonFill
            handleClick={() => {}}
            largeBtn
            isLoading={isLoading}
            type={"Submit"}
          >
            Filter
          </ButtonFill>

          <button
            type="button"
            className="text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg   me-2 mb-20 px-8 py-3"
            onClick={() => {
              setFilters(initalFilters);
            }}
          >
            Reset Filters
          </button>
        </div>
      </form>

      <QuestionTableView
        filteredProducts={questions}
        isLoading={isLoading}
        selectedQuestionIds={selectedQuestionIds}
        setSelectedQuestionIds={setSelectedQuestionIds}
        primaryButtonText={undefined}
      />
    </div>
  );
};

export default FilterQuestions;
