import { tag } from "constants/constantvalues";
import React from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalPublishButton from "shared-components/ui/CustomButtons";

import { FormikProps } from "formik";

interface PricingModalProps {
  formik: FormikProps<any>;
  isPricingAddModel: boolean;
  filteredbundlePoints: string[];
  filteredAccess: string[];
  setIsPricingAddModel: (value: boolean) => void;
  isEditMode: boolean;
}

const PricingModal: React.FC<PricingModalProps> = ({
  formik,
  isPricingAddModel,
  filteredbundlePoints,
  filteredAccess,
  setIsPricingAddModel,
  isEditMode,
}) => {
  const { values, handleSubmit, setFieldValue } = formik;
  const handleLifeTimeToggle = () => {
    if (values?.isLifeTime === true) {
      setFieldValue("planTime", "");
    }
    setFieldValue("isLifeTime", !values?.isLifeTime);
  };
  return (
    <div>
      <CustomAntdModal
        isOpenModal={isPricingAddModel}
        setIsOpenModal={setIsPricingAddModel}
        formik={formik}
        title={isEditMode ? "Edit Pricing Model" : "Add Pricing Model"}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full md:w-1/2">
              <div className="relative">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="planName"
                  label="Plan Name"
                />
              </div>
            </div>
            <div className=" md:p-2 w-full md:w-1/2">
              <label
                htmlFor="planName"
                className=" text-md font-bold text-gray-900 mb-4"
              >
                Plan Time<span className="text-red-700">*</span>
              </label>
              <div className="flex flex-col md:flex-row gap-2">
                <div
                  className={`relative  ${
                    values?.isLifeTime ? "w-full" : "w-1/2"
                  }`}
                >
                  <div className="flex justify-between text-center items-center my-1">
                    <div className="">
                      Life Time is {values?.isLifeTime ? "enabled" : "disabled"}
                    </div>
                    <div className="">
                      <GlobalPublishButton
                        isActive={values?.isLifeTime}
                        onChange={handleLifeTimeToggle}
                      />
                    </div>
                  </div>
                </div>
                {!values?.isLifeTime && (
                  <div className="relative w-1/2">
                    <CustomFormField
                      type="number"
                      formik={formik}
                      name="planTime"
                      label="Enter number of months"
                    />
                  </div>
                )}
              </div>
            </div>
            {/* Add other fields */}
          </div>
          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full md:w-1/2">
              <div className="relative">
                <CustomFormField
                  type="number"
                  formik={formik}
                  name="price"
                  label="Original Price"
                />
              </div>
            </div>
            <div className="md:p-2 w-full md:w-1/2">
              <div className="relative">
                <CustomFormField
                  type="number"
                  formik={formik}
                  name="discount"
                  label="Discount"
                />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full md:w-1/2">
              <div className="relative">
                <CustomFormField
                  type="select"
                  formik={formik}
                  name="selectedAccess"
                  mode="multiple"
                  label="Access"
                  options={filteredAccess?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </div>
            </div>
            <div className="md:p-2 w-full md:w-1/2  ">
              <CustomFormField
                type="select"
                formik={formik}
                name="tag"
                label="Tag"
                options={tag}
              />
            </div>
          </div>
          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full ">
              <div className="relative">
                <CustomFormField
                  formik={formik}
                  name="bundlePoints"
                  type="select"
                  label="Bundle Points"
                  mode="tags"
                  options={filteredbundlePoints?.map((item) => ({
                    label: item,
                    value: item,
                  }))}
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="relative">
              <CustomFormField
                type="textarea"
                formik={formik}
                name="desc"
                label="Description"
              />
            </div>
          </div>
        </form>
      </CustomAntdModal>
    </div>
  );
};

export default PricingModal;
