import { ApiInterface } from "../api-interface/types";

export const renewalRequestApis: ApiInterface[] = [
  {
    url: "/api/course-renewals/get",
    method: "GET",
    name: "getAllRenewalRequests",
  },
  {
    url: "/api/course-renewals/handle",
    method: "POST",
    name: "modifyRenewal",
    
  },
  {
    url: "/api/course-renewals/handleActivation",
    method: "PATCH",
    name: "handleSubscriptionActivation",
   
  }
];
