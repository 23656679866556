import React from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import SubtopicComponent from "./subtopic-component";
import { FormikProps } from "formik";

interface TopicsModalProps {
  openModel: boolean;
  isEditMode: boolean;
  setOpenModel: (isOpen: boolean) => void;
  formik: FormikProps<any>;
  modulesData: { value: string; label: string }[];
  medschoolSubjects?: { name: string }[];
  subjects?: { name: string }[];
}

const TopicsModal: React.FC<TopicsModalProps> = ({
  openModel,
  isEditMode,
  setOpenModel,
  formik,
  modulesData,
  medschoolSubjects,
  subjects,
}) => {
  return (
    <div>
      <CustomAntdModal
        isOpenModal={openModel}
        title={isEditMode ? `Edit Topic` : `Add Topic `}
        setIsOpenModal={setOpenModel}
        formik={formik}
      >
        <div className="flex flex-col gap-2">
          <CustomFormField
            name="topicName"
            label="Topic Name"
            type="text"
            formik={formik}
          />
          <div className="flex flex-col md:flex-row gap-2">
            {process.env.REACT_APP_CATEGORY === "MEDSCHOOL" && (
              <div className="md:p-2 w-full md:w-1/2">
                <CustomFormField
                  name="modules"
                  label="Modules"
                  type="select"
                  formik={formik}
                  options={modulesData}
                  mode="multiple"
                />
              </div>
            )}

            <div className="md:p-2 w-full md:w-1/2">
              <CustomFormField
                name="subject"
                label={
                  process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
                    ? "Discipline"
                    : "Subject"
                }
                type="select"
                formik={formik}
                options={
                  process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
                    ? medschoolSubjects?.map((subject) => ({
                        value: subject.name,
                        label: subject.name,
                      }))
                    : subjects?.map((subject) => ({
                        value: subject.name,
                        label: subject.name,
                      }))
                }
              />
            </div>
          </div>

          <SubtopicComponent formik={formik} />
          <div>
            <CustomFormField
              name="logo"
              label="Logo"
              type="image"
              formik={formik}
            />
          </div>
        </div>
      </CustomAntdModal>
    </div>
  );
};

export default TopicsModal;
