
import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "../../interfaces/global-interfaces";

export const orderApis: ApiInterface[] = [
  {
    url: "/api/orders/get-order-details",
    method: "GET",
    name: "getOrders",
  },
  {
    url: "/api/orders/changeStatusForBundlePurchase",
    method: "PATCH",
    name: "changeStatusForBundlePurchase",
  },
  {
    url: "/api/orders/get-order",
    method: "GET",
    name: "getOrder",
  },
  {
    url: "/api/orders/get-plan-subscription-name",
    method: "POST",
    name: "getPlanSubscriptionName",
  },
  {
    url: "/api/orders/DeleteOrder",
    method: "DELETE",
    name: "DeleteOrder",
  },
  {
    url: "/api/orders/update-coupon-code",
    method: "PUT",
    name: "updateCouponCode",
  },
  {
    url: "/api/orders/get-plan-skus",
    method: "GET",
    name: "getPlanSkus",
  },
  {
    url: "/api/orders/get-coupon-codes",
    method: "GET",
    name: "getCouponCodes",
  },
  {
    url: "/api/orders/get-all-orders",
    method: "POST",
    name: "getAllOrdersForFilter",
  },
  {
    url: "/api/orders/GetOrderByEmail",
    method: "GET",
    name: "GetOrderByEmail",
  }
];


export function OrderAPI(name: string, params: string = "", data: any = null) {
  const api = orderApis.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");
  console.log("data", data);

  return axios({
    method: api.method,
    data,
    url
  });
}