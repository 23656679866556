import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend } from 'recharts';
import { ArrowUpOutlined, ArrowDownOutlined } from '@ant-design/icons';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

interface PieChartComponentProps {
  year: string;
  data: { name: string; value: number }[];
  total: number;
  growth: number;
  growthQuantity: number;
}

const PieChartComponent: React.FC<PieChartComponentProps> = ({ year, data, total, growth, growthQuantity }) => {
  const getGrowthIndicator = (growth: number) => {
    if (growth > 0) {
      return (
        <span style={{ color: 'green' }}>
          <ArrowUpOutlined /> {growth}% ({growthQuantity})
        </span>
      );
    } else if (growth < 0) {
      return (
        <span style={{ color: 'red' }}>
          <ArrowDownOutlined /> {growth}% ({growthQuantity})
        </span>
      );
    } else {
      return (
        <span>
          {growth}% ({growthQuantity})
        </span>
      );
    }
  };

  return (
    <div className='p-2'>
      <div className='flex flex-col gap-1'>
        <div>Year: {year}</div>
        <div>Students: {total}</div>
        <div>Growth: {getGrowthIndicator(growth)}</div>
      </div>
      <PieChart width={600} height={250}>
        <Pie
          data={data}
          cx={200}
          cy={100}
          labelLine={false}
          label={({ name, percent, value }) => `${name}: ${(percent * 100).toFixed(1)}% (${value})`}
          outerRadius={80}
          fill="#8884d8"
          dataKey="value"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip />
        <Legend />
      </PieChart>
    </div>
  );
};

export default PieChartComponent;
