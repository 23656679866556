import React from 'react'
import ReactQuill from 'react-quill'

interface Page2Props {
    options: any;
    setOptions: React.Dispatch<React.SetStateAction<any>>;
    modules: any;
    numberOfOptions?: number;
    setNumberOfOptions?: React.Dispatch<React.SetStateAction<number>>;
}


export default function Page2({ options, setOptions,  modules, numberOfOptions, setNumberOfOptions }: Page2Props) {

    return (
        <>
            <div className="mx-auto p-12 pt-0">
                {/* If options lenght us 0 then do not show the options */}

                { options.length !== 0 && 
                Array.from({ length: numberOfOptions }).map((_, i) => (
                    <div className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-2
                        border-2 border-rose-200
                        `}>
                        <OptionViewerAndHandler key={i} options={options} setOptions={setOptions} index={i} modules={modules}  numberOfOptions={numberOfOptions} setNumberOfOptions={setNumberOfOptions}/>
                    </div>
                ))}
                <button className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                    const newOption = {
                        OptionText: '',
                        optionImage: '',
                        ExplanationText: '',
                        explanationImage: '',
                        IsCorrect: false
                    }
                    setOptions([...options, newOption])
                    setNumberOfOptions(numberOfOptions + 1)
                }}>Add Option</button>

            </div>
        </>)
}


interface OptionViewerAndHandlerProps {
    options: any;
    setOptions: React.Dispatch<React.SetStateAction<any>>;
    index: number;
    modules: any;
    numberOfOptions: number;
    setNumberOfOptions: React.Dispatch<React.SetStateAction<number>>;
}

const OptionViewerAndHandler = ({ options, setOptions, index, modules, numberOfOptions, setNumberOfOptions }: OptionViewerAndHandlerProps) => {

    return (
        <div className='flex flex-col justify-between'>
            <div className='flex flex-row justify-between'>
                <div className='flex flex-row justify-between'>
                <h6 className="text-md font-bold text-gray-900 mt-2">Option Letter: {String.fromCharCode(65 + index)}</h6>
                </div>
                <button className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500" onClick={() => {
                    const updatedOptions = [...options];
                    updatedOptions.splice(index, 1);
                    setOptions(updatedOptions);
                    setNumberOfOptions(numberOfOptions - 1)
                }}>Remove Option</button>
            </div>
            <h6 className="text-md font-bold text-gray-900 mt-2">Option Text</h6>
            <ReactQuill className="optionText bg-white rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" theme="snow" modules={modules}
                onChange={(html) => {
                    const updatedOptions = [...options];
                    updatedOptions[index].OptionText = html;
                    setOptions(updatedOptions);
                }}
                value={options[index].OptionText}
                id="OptionText"
                placeholder='Enter Option Text'
                style={{ color: 'black' }}
            />
            <h6 className="text-md font-bold text-gray-900 mt-2">Explanation Text</h6>
            <ReactQuill className="optionExp bg-white rounded-sm focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent" theme="snow" modules={modules} 
                 onChange={
                    (html) => {
                        const updatedOptions = [...options];
                        updatedOptions[index].ExplanationText = html;
                        setOptions(updatedOptions);
                    }}
                value={options[index].ExplanationText}
                style={{ color: 'black' }}
                placeholder='Enter Explanation Text'
            />
            <div className='flex flex-row flex-wrap items-center gap-2'>
                <h6 className="text-md font-bold text-gray-900 mt-2.5">Is Correct</h6>
                <input type="checkbox" name="IsCorrect"
                    checked={options[index].IsCorrect}
                    onChange={(e) => {
                        const updatedOptions = [...options];
                        updatedOptions[index].IsCorrect = e.target.checked;
                        setOptions(updatedOptions);
                    }}
                />
            </div>
            <div className='flex flex-row flex-wrap items-center gap-8'>
                <div className='flex flex-col'>
                    <h6 className="text-md font-bold text-gray-900 mt-2.5">Upload Option Image</h6>
                    <div className='flex flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md h-36 justify-center'>
                        <div className='flex flex-col items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                            </svg>
                            <button className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500" onClick={() => {
                                document.getElementById(`OptionImage${index}`).click();
                            }
                            }>Upload Image</button>
                            <input type="file" className="hidden" accept="image/png, image/jpeg" onChange={(e) => {
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const updatedOptions = [...options];
                                    updatedOptions[index].optionImage = reader.result;
                                    setOptions(updatedOptions);
                                };
                                reader.readAsDataURL(file);
                            }} id={`OptionImage${index}`} name={`OptionImage${index}`} />
                            {options[index].optionImage && (
                                <button className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500" onClick={() => {
                                    const updatedOptions = [...options];
                                    updatedOptions[index].optionImage = '';
                                    setOptions(updatedOptions);
                                }}>Remove Image</button>
                            )}
                        </div>
                        {options[index].optionImage && (
                            <div className='flex flex-col items-center gap-2'>
                                <img src={options[index].optionImage} alt="Option Image" className='w-32 h-32 object-contain' />
                            </div>
                        )}

                    </div>
                </div>
                <div className='flex flex-col'>
                    <h6 className="text-md font-bold text-gray-900 mt-2.5">Upload Explanation Image</h6>
                    <div className='flex flex-row flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md h-36 justify-center'>
                        <div className='flex flex-col items-center gap-2'>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-8 h-8">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5" />
                            </svg>
                            <button className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500" onClick={() => {
                                document.getElementById(`ExplanationImage${index}`).click();
                            }
                            }>Upload Image</button>
                            <input type="file" className="hidden" accept="image/png, image/jpeg" onChange={(e) => {
                                const file = e.target.files[0];
                                const reader = new FileReader();
                                reader.onloadend = () => {
                                    const updatedOptions = [...options];
                                    updatedOptions[index].explanationImage = reader.result;
                                    setOptions(updatedOptions);
                                };
                                reader.readAsDataURL(file);
                            }} id={`ExplanationImage${index}`} name={`ExplanationImage${index}`} />
                            {options[index].explanationImage && (
                                <button className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500" onClick={() => {
                                    const updatedOptions = [...options];
                                    updatedOptions[index].explanationImage = '';
                                    setOptions(updatedOptions);
                                }}>Remove Image</button>
                            )}
                        </div>
                        {options[index].explanationImage && (
                            <div className='flex flex-col items-center gap-2'>
                                <img src={options[index].explanationImage} alt="Explanation Image" className='w-32 h-32 object-contain' />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}
