

import React from 'react'
import VaultTable from '../../components/vault-table'

const Shortlistings = () => {
  return (
    <div>
      <VaultTable addApi="addshortlisting" getApi="getshortlistings" updateApi="updateshortlisting" publishApi="updateshortlistingpublish" vaultType="Shortlisting"  isPagenation={false}/>
    </div>
  )
}

export default Shortlistings

