import React from 'react';

interface LabelComponentProps {
  value: string;
  require: boolean;
}

const LabelComponent: React.FC<LabelComponentProps> = ({ value, require }) => {
  return (
    <label style={{ marginBottom: "5px" }}>
      {value} {require && <span className="text-red-500">*</span>}
    </label>
  );
}

export default LabelComponent;
