import { ApiInterface } from "interfaces/global-interfaces";

const apiURL = `/api/course-schedule`;

export const scheduleApis: ApiInterface[] = [
    {
        url: `${apiURL}/get/:id`,
        method: "GET",
        name: "getSchedule",
    },
    {
        url: `${apiURL}/getById/:id`,
        method: "GET",
        name: "getScheduleById",
    },
    {
        url: `${apiURL}/add`,
        method: "POST",
        name: "addSchedule",
    },
    {
        url: `${apiURL}/update-order`,
        method: "POST",
        name: "updateCourseScheduledOrder",
    },
    {
        url: `${apiURL}/updateActive/:id`,
        method: "PUT",
        name: "updateScheduleActive",
    },
    {
        url: `${apiURL}/update/:id`,
        method: "PUT",
        name: "updateSchedule",
    },
    {
        url: `${apiURL}/delete/:id`,
        method: "DELETE",
        name: "deleteSchedule",
    },

// manage schedule apis
{
    url: `${apiURL}/getById/:id`,
    method: "GET",
    name: "getManageScheduleById",
  },
  {
    url: `${apiURL}/addNotes/:id`,
    method: "PUT",
    name: "addManageScheduleNote",
    contentType: "multipart/form-data",
  },
  {
    url: `${apiURL}/updateNote/:id/:noteId`,
    method: "PUT",
    name: "updateManageScheduleNote",
    contentType: "multipart/form-data",
  },
  {
    url: `${apiURL}/updateIsPublishNotes/:scheduleCourseId/:noteId`,
    method: "PUT",
    name: "updateManageSchedulePublishNote",
  },
  {
    url: `${apiURL}/addLectures/:id`,
    method: "PUT",
    name: "addManageScheduleLecture",
    contentType: "multipart/form-data",
  },
  {
    url: `${apiURL}/updateLecture/:id/:lectureId`,
    method: "PUT",
    name: "updateManageScheduleLecture",
    contentType: "multipart/form-data",
  },
  {
    url: `${apiURL}/updateIsPublishLectures/:scheduleCourseId/:lectureId`,
    method: "PUT",
    name: "updateManageSchedulePublishLecture",
  },
  {
    url: `${apiURL}/addCourseDecks/:id`,
    method: "PUT",
    name: "addManageScheduleDeck",
  },
  {
    url: `${apiURL}/updateDeck/:id/:noteId`,
    method: "PUT",
    name: "updateManageScheduleDeck",
  },

];
