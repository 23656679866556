import { ApiInterface } from "../api-interface/types";

export const teacherApis: ApiInterface[] = [
    {
      url: `/api/teacher/get`,
      method: "GET",
      name: "getTeachers",
    },
    {
      url: `/api/teacher/getById/:id`,
      method: "GET",
      name: "getTeacherby",
    },
    {
      url: `/api/teacher/add`,
      method: "POST",
      name: "addTeacher",
    },
    {
      url: `/api/teacher/update/:id`,
      method: "PUT",
      name: "updateTeacher",
    },
  ];