import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, message, Space } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import { ManageIcon } from "./ui/Icons";

interface Testimonial {
  _id: string;
  name: string;
  score: number;
  examName: string;
  university: string;
  pictureUrl?: string;
  description?: string;
  tags?: string;
  videothumbnailUrl?: string;
  videoUrl?: string;
}

interface TestimonialsProps {
  data: Testimonial[];
  id: string;
  storedSelectedTestimonials: React.Key[]; // React.Key[] to handle selected keys
  getData: () => void;
  loading?: boolean;
  isMultiPartFormData?: boolean;
  updateSelectedTestimonialsApi: string;
}

const SelectTestimonials: React.FC<TestimonialsProps> = ({
  data,
  id,
  storedSelectedTestimonials,
  getData,
  updateSelectedTestimonialsApi,
  loading,
  isMultiPartFormData=false,
}) => {
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(
    storedSelectedTestimonials
  );

  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Score",
      dataIndex: "score",
      key: "score",
    },
    {
      title: "Exam Name",
      dataIndex: "examName",
      key: "examName",
    },
    {
      title: "University",
      dataIndex: "university",
      key: "university",
    },
    {
      title: "Text based Testimonials",
      colSpan: 3,
      dataIndex: "text",
      key: "text",
      children: [
        {
          dataIndex: "pictureUrl",
          key: "pictureUrl",
          render: (pictureUrl: string) => {
            if (pictureUrl) {
              return (
                <Image
                  width={50}
                  height={50}
                  src={pictureUrl}
                  alt="Profile"
                  className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                />
              );
            }
            return null;
          },
        },
        {
          dataIndex: "description",
          key: "description",
          render: (description: string) => {
            return (
              <div className="w-40" dangerouslySetInnerHTML={{ __html: description }} />
            );
          }
        },
        
        {
          dataIndex: "tags",
          key: "tags",
        },
      ],
    },
    {
      title: "Video based Testimonials",
      colSpan: 2,
      dataIndex: "video",
      key: "video",
      children: [
        {
          dataIndex: "videothumbnailUrl",
          key: "videothumbnailUrl",
          render: (url: string) => {
            if (url) {
              return (
                <Image
                  width={50}
                  height={50}
                  src={url}
                  alt="Video Thumbnail"
                  className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                />
              );
            }
            return null;
          },
        },
        {
          dataIndex: "videoUrl",
          key: "videoUrl",
          render: (url: string) => {
            if (url) {
              return (
                <a
                  href={url}
                  target="_blank"
                  rel="noreferrer"
                  className="text-blue-500"
                >
                  Watch Video
                </a>
              );
            }
            return null;
          },
        },
      ],
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: Testimonial) => (
        <Space size="middle">
          <div
            className="cursor-pointer "
            onClick={() => navigate("/manage/testimonials")}
          >
            <ManageIcon  size="w-6 h-6"/>
          </div>
        </Space>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys: React.Key[]) => {
      setSelectedRowKeys(newSelectedRowKeys);
    }, 
  };

  const handleSubmitSelectedTestinomials = async () => {
    try {
        const formData = new FormData();
        formData.append('selectedTestimonials', JSON.stringify(selectedRowKeys));
        const payload = isMultiPartFormData ? formData:  { selectedTestimonials: selectedRowKeys };
      const res = await apiRequest(
        updateSelectedTestimonialsApi,
        payload,
        [id]
      );
      if (res?.status === 200) {
        message.success(res?.data?.message || "Selected Testimonials updated");
        getData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
    }
  };

  return (
    <div>
      {data && (
        <GlobalTable
          rowSelectionProps={rowSelection}
          isRowSelection={true}
          data={data}
          columns={columns}
          bordered={true}
          loading={loading}
        />
      )}
      <div className="flex justify-end mt-10">
        <CustomButton text="Save" onClick={handleSubmitSelectedTestinomials} />
      </div>
    </div>
  );
};

export default SelectTestimonials;
