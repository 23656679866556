import React, { useEffect, useState, useCallback, useMemo } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Table, Image, message } from "antd";
import { apiRequest } from "../../(apis)/api-interface/api-handler";
import { EditIcon, EyeIcon } from "../../shared-components/ui/Icons";
import CustomButton from "../../shared-components/form-fields/custom-button";
import GlobalTable from "../../shared-components/table/GlobalTable";
import FeatureModal from "./modal";

// Define interfaces
interface CourseFeatureData {
  _id: string;
  desc: string;
  iconUrl: string;
  featureName: string;
  [key: string]: any; // Dynamic keys if any
}

const Features: React.FC = () => {
  const [courseFeatureData, setCourseFeatureData] = useState<
    CourseFeatureData[]
  >([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [clickedFeatureData, setClickedFeatureData] =
    useState<CourseFeatureData | null>(null);

  // Function to handle editing of a feature
  const handleEdit = useCallback((data: CourseFeatureData) => {
    setClickedFeatureData(data);
    setIsEditMode(true);
    setShowModal(true);
  }, []);

  // Fetch all course features
  const getAllCourseFeatures = useCallback(async () => {
    setLoading(true);
    try {
      const res = await apiRequest("getFeatures");
      if (res?.status === 200) {
        const formattedCourseFeatureData = res?.data?.courseFeatures?.map(
          (data: CourseFeatureData, i: number) => {
            const { desc, _id } = data;
            return {
              ...data,
              key: i + 1,
              intro: desc.length > 50 ? `${desc.slice(0, 50)}...` : desc,
              id: _id,
              expandData: desc,
            };
          }
        );
        setCourseFeatureData(formattedCourseFeatureData);
      } else {
        message.error("Failed to fetch course features.");
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    } finally {
      setLoading(false);
    }
  }, []);

  // useEffect to fetch data on component mount
  useEffect(() => {
    getAllCourseFeatures();
  }, [getAllCourseFeatures]);

  // Define columns with useMemo to avoid re-creation on every render
  const columns = useMemo(
    () => [
      {
        title: "Icon",
        dataIndex: "iconUrl",
        key: "iconUrl",
        width: 100,
        render: (iconUrl: string) => (
          <Image width={50} height={50} src={iconUrl} alt="feature" />
        ),
      },
      {
        title: "Feature Name",
        dataIndex: "featureName",
        key: "featureName",
        width: 100,
        headerStyle: { background: "red", color: "white" },
      },
      Table.EXPAND_COLUMN,
      {
        title: "Description",
        dataIndex: "intro",
        key: "intro",
        width: 200,
      },
      Table.SELECTION_COLUMN,
      {
        title: "Actions",
        key: "actions",
        width: 90,
        render: (data: CourseFeatureData) => (
          <div className="flex gap-4">
            <div onClick={() => handleEdit(data)}>
              <EditIcon size="w-6 h-6" />
            </div>
            <a href={data?.link} target="_blank" rel="noreferrer">
              <EyeIcon size="w-6 h-6" />
            </a>
          </div>
        ),
      },
    ],
    [handleEdit]
  );

  return (
    <>
      <div className="">
        <div className="flex justify-end mx-5">
          <CustomButton
            icon={<PlusOutlined />}
            text="Add New Feature"
            onClick={() => {
              setClickedFeatureData(null);
              setIsEditMode(false);
              setShowModal(true);
            }}
          />
        </div>

        <GlobalTable
          loading={loading}
          tableName={"Manage Features"}
          data={courseFeatureData}
          columns={columns}
          isExpandable={true}
        />

        <FeatureModal
          setShowModal={setShowModal}
          getAllCourseFeatures={getAllCourseFeatures}
          showModal={showModal}
          isEditMode={isEditMode}
          setIsEditMode={setIsEditMode}
          clickedFeatureData={clickedFeatureData}
        />
      </div>
    </>
  );
};

export default Features;
