import { BasicGetApi } from "(apis)/(shared-apis)/basic-get-apis";
import { Select } from "antd";
import { useEffect, useState } from "react";

const SubjectsDropDown = ({ value, getValue, noLabel = false }) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchUniqueSubjects = async () => {
    try {
      setIsLoading(true);
      const response = await BasicGetApi("getUniqueSubjects");

      if (response) {
        // console.log("Response: ", response);
        const apiData =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];
        setData(apiData);
      }
    } catch (error) {
      console.error("There was an error fetching the questions!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUniqueSubjects();
  }, []);

  return (
    <div className="my-2">
      <Select
        showSearch
        placeholder="Select Subject"
        onChange={(selectedValue) => {
          getValue("subject", selectedValue);
        }}
        // g
        size={"large"}
        disabled={isLoading}
        className="w-full"
        value={value}
      >
        {data?.map((item) => (
          <Select.Option key={item} value={item}>
            {item}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

export default SubjectsDropDown;
