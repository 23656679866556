export const initialErrors = {
    deckNameError: {
        error: false,
        message: "",
    },
    deckLogoError: {
        error: false,
        message: "",
    },
};



