import { Image } from "antd";

export const columnsWithoutRender = [
  {
    title: "logo",
    dataIndex: "logoUrl",
    flex: 1,
    key: "logoUrl",
    render: (pictureUrl) => {
      if (pictureUrl) {
        return (
          <Image
            width={50}
            height={50}
            className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
            src={pictureUrl}
          />
        );
      }
      return null;
    },
  },
  {
    title: "Institute Name",
    dataIndex: "instituteName",
    key: "instituteName",
    flex: 1,
  },
  {
    title: "Short Name",
    dataIndex: "shortName",
    key: "shortName",
    flex: 1,
  },

  {
    title: "City",
    dataIndex: "city",
    key: "city",
    flex: 1,
  },
];
