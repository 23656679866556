import { apiRequest } from '(apis)/api-interface/api-handler';
import { PlusOutlined } from '@ant-design/icons';
import { message, notification } from 'antd';
import { modulesReactQuill } from 'constants/constantvalues';
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { useParams } from 'react-router-dom';

interface AnnouncementModelProps {
    setAnnouncementsModel: React.Dispatch<React.SetStateAction<boolean>>;
    getAnnouncements: () => void;
}

const AnnouncementModel: React.FC<AnnouncementModelProps> = ({ setAnnouncementsModel ,getAnnouncements}) => {
    const { id } = useParams<{ id: string }>();
    const [announcementTitle, setAnnouncementTitle] = useState<string>('');
    const [announcementDescription, setAnnouncementDescription] = useState<string>('');
    const [links, setLinks] = useState<string[]>([]);
    const [link, setLink] = useState<string>('');



    const addAnnouncements = async () => {
        try {
            const response = await apiRequest("addAnnouncement",{
                CourseID: id,
                AnnouncementTitle: announcementTitle,
                AnnouncementDescription: announcementDescription,
                AnnouncementLinks: links,
            });
            if (response.data.success) {
                getAnnouncements();
                message.success('Announcement Added Successfully'); 
                setAnnouncementTitle('');
                setAnnouncementDescription('');
                setLinks([]);
                setAnnouncementsModel(false);
            }
        } catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occurred while adding the announcement',
            });
            console.error('Error adding announcement:', error);
        }
    };

    return (
        <div className='w-full fixed top-0 left-0 h-full bg-gray-900 bg-opacity-50 flex items-center justify-center'>
            <div className='w-1/2 bg-white p-8 rounded'>
                <h1 className='text-xl font-bold'>Add Announcement</h1>
                <div className='mt-4'>
                    <input
                        type='text'
                        placeholder='Announcement Title'
                        className='w-full p-2 rounded border border-gray-300'
                        value={announcementTitle}
                        onChange={(e) => setAnnouncementTitle(e.target.value)}
                    />
                </div>
                <div className='mt-4'>
                    <ReactQuill
                        theme='snow'
                        value={announcementDescription}
                        onChange={(value) => setAnnouncementDescription(value)}
                        modules={modulesReactQuill}
                    />
                </div>
                <div className='flex flex-col md:flex-row mt-4'>
                    <div className='relative'>
                        <label htmlFor='Links' className='text-md font-bold text-gray-900 mb-4'>
                            Links<span className='text-red-700'>*</span>
                        </label>
                        <div className='flex justify-between items-center'>
                            <input
                                type='text'
                                value={link}
                                onChange={(e) => setLink(e.target.value)}
                                placeholder='Add Link'
                                className='w-full p-1 rounded border border-gray-300'
                            />
                            <button
                                className='text-rose-500 px-4 py-1 rounded ml-2'
                                onClick={() => {
                                    setLinks([...links, link]);
                                    setLink('');
                                }}
                            >
                                <PlusOutlined />
                            </button>
                        </div>
                        {links.map((link, index) => (
                            <div key={index} className='mt-2 flex items-center'>
                                <a href={link} target='_blank' rel='noopener noreferrer'>
                                    {link}
                                </a>
                                <button
                                    className='text-red-500 ml-2'
                                    onClick={() => setLinks(links.filter((_, i) => i !== index))}
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='mt-4 flex justify-end'>
                    <button
                        className='bg-white text-rose-500 border border-rose-500 px-4 py-2 rounded mr-4 hover:bg-rose-200'
                        onClick={() => setAnnouncementsModel(false)}
                    >
                        Cancel
                    </button>
                    <button
                        className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-rose-200'
                        onClick={addAnnouncements}
                    >
                        Add
                    </button>
                </div>
            </div>
        </div>
    );
};

export default AnnouncementModel;
