import React, { useEffect, useState } from 'react'
import { ButtonFill } from 'shared-components/ui/CustomButtons'
import CreatePlanModal from './components/create-plan-modal'
import { Toaster } from 'react-hot-toast';
import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import toast from 'react-hot-toast';
import { Switcher } from 'shared-components/ui/CustomForm';
import { ManageIcon } from 'shared-components/ui/Icons';
import { useNavigate } from 'react-router-dom';
export default function Page() {
    const [PlansModal, setPlansModal] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [packages, setPackages] = useState([]);

    const fetchPackages = () => {
        setIsLoading(true);
        PackagesAPI("getPackages").then((response) => {
            setPackages(response.data.packages);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.error("Failed to fetch packages");
        });
    }

    useEffect(() => {
        fetchPackages();
    }, []);

    const navigate = useNavigate();

    const UpdateStatus = async (id: string, isPublished: boolean) => {
        const body = {
            isPublished: !isPublished,
        };

        try {
            let response: any = await PackagesAPI("updatePackagePublishedStatusById", [id], body);
            if (response) {
                toast.success(`Updated Successfully`);
                fetchPackages();
            }
        } catch (error) {
            console.log("Update package Error: ", error);
            toast.error(error.AxiosError.response.data.message);
        }
    }

    return (
        <div className="w-full pb-20 px-5">
            <div className="flex items-center justify-between pb-1 mt-5">
                <h2 className="text-2xl font-bold text-gray-700">Packages Management</h2>
                <ButtonFill
                    handleClick={() => {
                        setPlansModal(true);
                    }}
                >
                    Add Plan
                </ButtonFill>

            </div>
            <section className="my-5 antialiased">
                <div className="mx-auto">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div className=" bg-white shadow-md sm:rounded-lg">
                            <div className="overflow-x-auto">
                                <table className="w-full mb-20 text-sm text-left text-gray-500">
                                    <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                        <tr>
                                            <th scope="col" className="px-4 py-4">
                                                SR No.
                                            </th>
                                            <th scope="col" className="px-4 py-4">
                                                Board
                                            </th>
                                            <th scope="col" className="px-4 py-4 text-center">
                                                Position
                                            </th>
                                            <th scope="col" className="px-4 py-4 text-center">
                                                isPublished
                                            </th>
                                            <th scope="col" className="px-4 py-3 text-center">
                                                Actions
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {packages?.map((pack, index) => (
                                            <tr className="border-b" key={pack?._id}>
                                                <td className="px-4 py-4">{index + 1}</td>
                                                <td className="px-4 py-3">{pack?.boardName}</td>
                                                <td className="px-4 py-3 text-center">{pack?.position}</td>
                                                <td className="px-4 py-3">
                                                    <div className="mx-auto w-fit">
                                                        <Switcher
                                                            isActive={undefined}
                                                            for={pack?._id}
                                                            onChange={() => {
                                                                UpdateStatus(
                                                                    pack?._id,
                                                                    pack?.isPublished
                                                                );
                                                            }}
                                                            togglevalue={pack?.isPublished}
                                                            size="small"
                                                        />
                                                    </div>
                                                </td>
                                                <td className="px-4 py-3 text-center">
                                                    <div className="flex items-center justify-center">
                                                    <ManageIcon 
                                                        size='w-6 h-6'
                                                        onClick={() => {
                                                            navigate(`/manage-package/${pack?._id}`);
                                                        }}
                                                    />
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
            </section>

            <CreatePlanModal
                active={PlansModal}
                setModal={setPlansModal}
                onCancel={() => {
                    setPlansModal(false);
                }}
                onConfirm={() => {
                    setPlansModal(false);
                }}
                fetchPackages={fetchPackages}
            />

            <Toaster />


        </div>
    )
}

