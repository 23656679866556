import { apiRequest } from "(apis)/api-interface/api-handler";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { Table, message } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Loader from "shared-components/Loader";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import * as Yup from "yup";
import AssignmentModal from "./assignment-modal";
import { PlusOutlined } from "@ant-design/icons";

interface AssignmentProps {
  getAssignmentData: () => void;
  courseAssignmentData: any;
  setLoading: (loading: boolean) => void;
  loading: boolean;
  id: string;
  access?: any;
}

const Assignments: React.FC<AssignmentProps> = ({
  getAssignmentData,
  courseAssignmentData,
  setLoading,
  loading,
  id,
  access,
}) => {
  const [isAssignmentModel, setIsAssignmentModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [ispdfTable, setIsPdfTable] = useState(false);
  const [pdfUrl, setpdfUrl] = useState([]);
  const navigate = useNavigate();
  const handleEditAssignment = (data) => {
    const pdfData = data?.pdfUrls?.map((url, i) => {
      return {
        uid: i,
        name: url,
        status: "done",
        isUrl: true,
        url: url,
        type: "application/pdf",
      };
    });
    const formatedDate = dayjs(data?.dueDate);

    setValues({
      name: data?.name,
      desc: data?.expandData,
      dueDate: formatedDate,
      pdfFile: pdfData,
      AssignmentId: data?.id,
    });
    setIsAssignmentModel(true);
    setIsEditMode(true);
  };

  const handlePdfUrl = (data) => {
    if (data?.pdfUrls?.length === 0) {
      message.error("No pdf files available");
      return;
    }

    setpdfUrl(data?.pdfUrls);
    setIsPdfTable(true);
  };
  const handleAssignmentIsPublish = async (data) => {
    try {
      const res = await apiRequest("updateCourseAssignmentActive", null, [
        data?.id,
      ]);
      if (res?.status === 200) {
        getAssignmentData();
        message.success(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Course Name is required")
      .min(4, "minimum 4 characters"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      pdfFile: null,
      dueDate: Dayjs,
      desc: "",
      AssignmentId: "",
    },
    validationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      try {
        setLoading(true);
        const { name, pdfFile, desc, dueDate } = values;
        const formatedDate: any = dayjs(dueDate)?.toDate();
        const formatedPdfFiles = pdfFile?.map((file) => file?.originFileObj);
        const formattedPdfFilesUrls = pdfFile
          ?.filter((file) => file?.isUrl === true)
          .map((file) => file?.url);
        const formData = new FormData();
        formData.append("name", name || "");
        formData.append(
          "pdfgeneratedUrl",
          JSON.stringify(formattedPdfFilesUrls) || ""
        );
        formatedPdfFiles?.forEach((file, index) => {
          formData.append(`pdfFile`, file);
        });
        if (dueDate) {
          formData.append("dueDate", formatedDate  );
        }
        formData.append("desc", desc || "");
        setLoading(true);
        let res;
        if (isEditMode) {
          res = await apiRequest("updateCourseAssignment", formData, [
            values?.AssignmentId,
          ]);
        } else {
          res = await apiRequest("addCourseAssignment", formData, [id]);
        }
        if (res?.status === 200) {
          setLoading(false);
          setIsAssignmentModel(false);
          message.success(res?.data?.message);
          getAssignmentData();
          setFieldValue("pdfFile", null);
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message || error?.message);
        setLoading(false);
      }
    },
  });

  const { setFieldValue, setValues } = formik;
  const handleManageAssignment = (data) => {
    console.log("data", data);
    navigate(`/manageAssignment/${data?.id}`);
  };
  const columns = [
    {
      title: "Assignmnet Name",
      dataIndex: "name",
      key: "name",
      flex: 1,
    },
    Table.EXPAND_COLUMN,
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      flex: 1,
    },
    Table.SELECTION_COLUMN,
    {
      title: "Is Published",
      dataIndex: "isPublished",
      key: "isPublished",
      align: "center",
      flex: 1,
      render: (data) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handleAssignmentIsPublish(data)}
            disabled={access === "teacher"}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      align: "center",
      flex: 1,
      render: (data) => {
        console.log("data", data);
        return (
          <div className="flex gap-4 justify-center">
            {access !== "teacher" && (
              <div>
                <EditIcon
                  onClick={() => handleEditAssignment(data)}
                  className="cursor-pointer"
                  color="primary"
                />
              </div>
            )}
            <VisibilityIcon
              className="text-blue-500 cursor-pointer"
              onClick={() => handlePdfUrl(data)}
            />
            <div
              className="cursor-pointer text-blue-500"
              onClick={() => handleManageAssignment(data)}
            >
              View Submissions
            </div>
          </div>
        );
      },
    },
  ];

  const formattedAssignmentData = courseAssignmentData?.map(
    ({ description, isPublished, dueDate, name, pdfUrls, _id }, i) => {
      // Updated data mapping
      return {
        key: i + 1,
        dueDate,
        name,
        pdfUrls,
        description:
          description?.length > 50
            ? `${description?.slice(0, 50)}...`
            : description,
        expandData: description,
        isPublished: {
          isPublished,
          id: _id,
        },
        id: _id,
      };
    }
  );
  return (
    <div className="mx-4 mt-4">
      <Loader loading={loading} />
      {access !== "teacher" && (
        <div className="mx-5 flex justify-end">
          <CustomButton
            text="Add New Assignment"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsAssignmentModel(true);
              setIsEditMode(false);
              formik.resetForm();
            }}
          />
        </div>
      )}
      <GlobalTable
        columns={columns}
        data={formattedAssignmentData}
        isExpandable={true}
        loading={loading}
      />
      <CustomAntdModal
        title="PDF Files"
        isOpenModal={ispdfTable}
        setIsOpenModal={setIsPdfTable}
      >
        <div className="flex justify-center">
          <div className="w-full">
            <div>Available Pdf</div>
            <div>
              {pdfUrl?.map((url, i) => (
                <div key={i}>
                  <a rel="noreferrer" title="pdf" href={url} target="_blank">
                    {url}
                  </a>
                </div>
              ))}
            </div>
            <div></div>
          </div>
        </div>
      </CustomAntdModal>
      <AssignmentModal
        isAssignmentModel={isAssignmentModel}
        isEditMode={isEditMode}
        formik={formik}
        setIsAssignmentModel={setIsAssignmentModel}
      />
    </div>
  );
};

export default Assignments;
