import { useEffect, useState } from "react";

import { ButtonOutlined } from "../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../shared-components/ui/CustomDropDown";
import TableView from "./components/view-modules";
import { ModuleAPI } from "../../(apis)/(shared-apis)/modules-apis";
import { TableTop } from "./components/table-top";

const ModuleManager = () => {
  const [modules, setModules] = useState([]);
  const [allModules, setAllModules] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await ModuleAPI("getModules");
        if (response.status === 200) {
          const data = response?.data?.modules || [];
          const filteredModules = data.filter((module: any) => {
            return module.isPublished;
          });
          setModules(filteredModules);
          setAllModules(data);
        }
      } catch (error) {
        console.log("Error fetching modules: ", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    if (!allModules) return;
    const NewData = allModules?.filter((module) => {
      if (!module.moduleName || !module.moduleDescription) return false;

      const matchesSearch =
        module?.moduleName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        module?.moduleDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      return matchesSearch;
    });
    setModules(NewData);
  }, [searchQuery]);

  const handleSearchQueryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setModules(allModules);
      return;
    }
  };

  useEffect(() => {
    if (selectStatus === "Active" && allModules) {
      const filteredModules = allModules.filter((module) => {
        return module.isPublished;
      });
      setModules(filteredModules);
    } else if (selectStatus === "InActive" && allModules) {
      const filteredModules = allModules?.filter((module) => {
        return !module.isPublished;
      });
      setModules(filteredModules);
    } else if (allModules) {
      setModules(allModules);
    }
  }, [selectStatus]);

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Modules</h2>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setModules={setModules}
                />
                <div className="flex items-center p-4 space-x-3">
                  <CustomDropDown
                    label="Published Status"
                    options={["All", "Active", "InActive"]}
                    onSelect={(selectedStatus) =>
                      handleSelectStatusChange(selectedStatus.toString())
                    }
                    width="min-w-[12rem]"
                    value={selectStatus}
                  />
                </div>

                <TableView currentModule={modules} setModule={setModules} />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default ModuleManager;
