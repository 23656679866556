interface FileInfo {
    uid: number | string;
    name: string;
    status: string;
    url: string;
    type: string;
    isOldFile: boolean;
  }
  
 export  const generateAntdPdfObjectFromUrl = (
    fileUrl: string,
  ): FileInfo | null => {
    // if (!fileUrl) return null;
  
    return {
      uid:1,
      name: "file 1",
      status: "done",
      url: fileUrl,
      type: `application/pdf`,
      isOldFile: true,
    };
  };
  
  // Usage example for PDF:
  