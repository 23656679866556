import React, { useState } from "react";
import { notification, Spin } from "antd";
import { apiRequest } from "(apis)/api-interface/api-handler";

const EditCAModal = ({
  associate,
  setcampus_associates,
  showModel,
  setShowModel,
}) => {
  const [formData, setFormData] = useState({
    CAname: associate.CAname,
    CAimage: associate.CAimage,
    CAuniversity: associate.CAuniversity,
    CAlinkedin: associate.CAlinkedin,
    CAinstagram: associate.CAinstagram,
    CAtwitter: associate.CAtwitter,
    CAfacebook: associate.CAfacebook,
  });
  const [imagePreviewUrl, setImagePreviewUrl] = useState(associate.CAimage);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image:any = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800;
          const maxHeight = 600;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);
          const compressedImageData = canvas.toDataURL("image/jpeg", 0.7);
          setImagePreviewUrl(compressedImageData);
          setFormData({
            ...formData,
            CAimage: compressedImageData,
          });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      const response = await apiRequest("addCA",formData, [associate._id]);

      if (response.data.success) {
        notification.success({
          message: "Success",
          description: response.data.message,
        });
        setShowModel(false);
      } else {
        notification.error({
          message: "Error",
          description: response.data.message,
        });
      }
      const Response2 = await apiRequest("getALLCa");

      if (Response2.data.success) {
        setcampus_associates(Response2.data.data);
      }
    } catch (error) {
      console.error("Error editing campus associate", error);
      notification.error({
        message: "Error",
        description: "Failed to edit campus associate",
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        showModel ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
            <div />
            <h3 className="text-lg font-semibold text-gray-900">
              Edit Campus Associate
            </h3>
            <button
              type="button"
              className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                setShowModel(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>

          <div className="space-y-3 mt-3">
            <form onSubmit={handleSubmit}>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Name *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAname"
                  value={formData.CAname}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Image *</span>
                </label>
                <input
                  id="CAimage"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ margin: "10px 0" }}
                />
                <img
                  src={imagePreviewUrl}
                  alt="Preview"
                  style={{ maxWidth: "100%", marginBottom: 10 }}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate University *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAuniversity"
                  value={formData.CAuniversity}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Linkedin *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAlinkedin"
                  value={formData.CAlinkedin}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Instagram *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAinstagram"
                  value={formData.CAinstagram}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Twitter *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAtwitter"
                  value={formData.CAtwitter}
                  onChange={handleInputChange}
                />
              </div>
              <div>
                <label className="block text-gray-900">
                  <span>Campus Associate Facebook *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAfacebook"
                  value={formData.CAfacebook}
                  onChange={handleInputChange}
                />
              </div>
              <button
                type="submit"
                className="px-4 py-2 text-white bg-rose-500 rounded-md"
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spin /> : "Update Campus Associate"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditCAModal;
