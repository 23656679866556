import * as Yup from "yup";
import { commonValidationSchema, thumbnailImageSchema } from "../../components/validation-schema";

const NoteSchema = Yup.object().shape({
  ...commonValidationSchema.fields,
  thumbnailImage: thumbnailImageSchema,
  pdfFile: Yup.mixed()
  .required()
  .test("filepdf", function (value:any) {
    if (value) {
      const fileType = value && value?.[0]?.type;
      if (!["application/pdf"].includes(fileType)) {
        return this.createError({
          message: "Please upload a PDF file.",
        });
      }
    }
    return true;
  }),
});


export {NoteSchema}
