import { useEffect, useState } from "react";
import { Input, Label } from "../../../../shared-components/ui/CustomForm";
import {
  // ButtonFill,
  ButtonOutlined,
} from "../../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../../shared-components/ui/CustomDropDown";
// import toast from "react-hot-toast";
// import {
//   addCommissionUserApi,
//   updateCommissionUserById,
// } from "../../../APIs/LatestCommisionAPI";
import { Select } from "antd";
import { ConfirmationModal } from "../../../../shared-components/ui/CustomModal";
import { getUsersOfSpecificRole, ROlES } from "../../utils";
import { CommissionAPI } from "../../../../(apis)/(shared-apis)/latest-commission-api";
import toast from "react-hot-toast";

export const PromoteHeadToManagerForm = (props: any) => {
  console.log("Report to User: ", props?.reportToUsers);
  const [requestType, setRequestType] = useState("Update For All");

  const InitialErrors = {
    entityError: {
      error: false,
      message: "",
    },
    categoryError: {
      error: false,
      message: "",
    },
    roleError: {
      error: false,
      message: "",
    },
    userNameError: {
      error: false,
      message: "",
    },
  };

  const [errors, setErrors] = useState(InitialErrors);

  const roleHierarchy = {
    ASSOCIATE: "HEAD",
    HEAD: "MANAGER",
    MANAGER: "PROVINCIAL_DIRECTOR",
    PROVINCIAL_DIRECTOR: "NATIONAL_DIRECTOR",
    NATIONAL_DIRECTOR: null,
  };
  const defaultCommisionRate = {
    ASSOCIATE: 10,
    HEAD: 2.5,
    MANAGER: 1,
    PROVINCIAL_DIRECTOR: 0.25,
    NATIONAL_DIRECTOR: "",
  };

  // const initialState = {
  //   name: "Humaira Shaheen",
  //   userName: "",
  //   email: "da219216@gmail.com",
  //   reportToId: "",
  //   reportToEmail: "abrarahmed2185@gmail.com",
  //   role: "ASSOCIATE",
  //   reportTo: "HEAD",
  //   commissionPercentage: 10,
  //   reportToName: "",
  //   newHeadEmailForPreviousAssociates: "",
  //   associateEmails: [],
  //   newHeadEmailForAssociates_1: "zuha514@gmail.com",
  //   newHeadEmailForAssociates_2: "mani.islam04@gmail.com",
  //   associatesForHead_1: [],
  //   associatesForHead_2: [],
  // };
  const initialState = {
    name: "",
    userName: "",
    email: "",
    reportToId: "",
    reportToEmail: "",
    role: "ASSOCIATE",
    reportTo: roleHierarchy["ASSOCIATE"],
    commissionPercentage: defaultCommisionRate["ASSOCIATE"],
    reportToName: "",
    newHeadEmailForPreviousAssociates: "",
    newHeadEmailForAssociates_1: "",
    newHeadEmailForAssociates_2: "",
    associateEmails: [],
    associateIds: [],
    associatesForHead_1: [],
    associatesForHead_2: [],
  };
  const [formData, setFormData] = useState(initialState);
  // const [isLoading, setIsLoading] = useState(false);
  console.log("🔰-formData: ", formData);
  useEffect(() => {
    if (props?.commissionUser) {
      setFormData(props?.commissionUser);
    }
  }, [props?.commissionUser]);

  const handleInputChange = (name, value) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const getUserInfoByEmail = (email) => {
    const user = props?.reportToUsers?.find((user) => user?.username == email);
    return user;
  };

  const [associatesData, setAssociatesData] = useState([]);

  useEffect(() => {
    if (formData?.email && props?.allCommissionUsers) {
      const userCommissionDetails = props?.allCommissionUsers?.find(
        (user) => user?.email === formData?.email
      );

      const associateIds = userCommissionDetails?.associateIds;
      const associatesInfo = associateIds?.map((id) => {
        const getUser = props?.allCommissionUsers?.find(
          (user) => user?._id === id
        );
        if (getUser) {
          return {
            _id: getUser?._id,
            userId: getUser?.userId,
            email: getUser?.email,
            name: getUser?.name,
          };
        }
      });
      const associatesEmail = associateIds?.map((id) => {
        const getUser = props?.allCommissionUsers?.find(
          (user) => user?._id === id
        );
        if (getUser) return getUser?.email;
      });

      const updatedFormData = { ...formData, associateEmails: associatesEmail };
      setFormData(updatedFormData);

      setAssociatesData(associatesInfo);
      console.log(
        "userCommissionDetails: ",
        userCommissionDetails,
        associatesEmail
      );
    }
  }, [formData?.email]);

  console.log("🔰-formData: ", formData);
  const [confirmationModal, setConfirmationModal] = useState(false);

  // ==============
  // Filter Heads
  // ==============
  const heads = getUsersOfSpecificRole(props?.allCommissionUsers, ROlES.HEAD);
  const formattedUserEmail = heads?.map((user) => user?.email) || [];

  // ==============
  // Filter Provincial User
  // ==============
  const provincialUsers = getUsersOfSpecificRole(
    props?.allCommissionUsers,
    ROlES.PROVINCIAL_DIRECTOR
  );
  const formattedSupervisorsEmail =
    provincialUsers?.map((user) => user?.email) || [];

  const handleUserEmailChange = (mail: string, name = "") => {
    const user = getUserInfoByEmail(mail);
    console.log("user", user);
    if (user) {
      const updatedFormData = { ...formData, email: mail, name: user.fullname };
      setFormData(updatedFormData);
    }
  };

  const handleNewHeadEmailChangeForPreviousAssociates = (mail) => {
    const user = getUserInfoByEmail(mail);
    console.log("EmailChangeForPreviousAssociates", user);
    if (user) {
      if (requestType === "Split Associates Among Heads") {
      }
      const updatedFormData = {
        ...formData,
        newHeadEmailForPreviousAssociates: mail,
        name: user.fullname,
      };
      setFormData(updatedFormData);
    }
  };
  const handleNewHeadEmailChangeForSplit = (mail) => {
    const user = getUserInfoByEmail(mail);
    console.log("EmailChangeForPreviousAssociates", user);
    if (user) {
      if (requestType === "Split Associates Among Heads") {
      }
      const updatedFormData = {
        ...formData,
        newHeadEmailForPreviousAssociates: mail,
        name: user.fullname,
      };
      setFormData(updatedFormData);
    }
  };

  // const [screenType, setScreenType] = useState("Assoicates_Management");
  const [screenType, setScreenType] = useState("Select_Head");

  const handleButtonClick = () => {
    if (screenType === "Select_Head") {
      setScreenType("Assoicates_Management");
    } else if (screenType === "Assoicates_Management") {
      setScreenType("Show_Final_Detail");
      // if (
      //   requestType === "Assign Associates to New Head" ||
      //   requestType === "Reassign All Associates"
      // ) {
      //   setScreenType("Show_Final_Detail");
      // }
    } else if (screenType === "Show_Final_Detail") {
      if (requestType === "Reassign All Associates") {
        promoteManagerAlongWithAssociates();
      } else if (requestType === "Assign Associates to New Head") {
        promoteManagerAndAssignNewHead();
      } else if (requestType === "Split Associates Among Heads") {
        promoteHeadAndSplitAssociates();
      }
    }

    //
  };

  const promoteManagerAndAssignNewHead = async () => {
    try {
      const data = {
        newManagerEmail: formData?.email,
        provincialDirectorEmail: formData?.reportToEmail,
        newHeadEmail: formData?.newHeadEmailForPreviousAssociates,
      };
      const response = await CommissionAPI(
        "promoteHeadandAssignNewHead",
        [],
        data
      );

      if (response) {
        console.log("promoteHeadandAssignNewHead: ", response);
        toast.error("Head successfully promoted to Manager");
        reset();
      }
    } catch (error) {
      console.log("Error promoteManagerAndAssignNewHead ", error);
    }
  };
  const promoteManagerAlongWithAssociates = async () => {
    try {
      const data = {
        newManagerEmail: formData?.email,
        provincialDirectorEmail: formData?.reportToEmail,
      };

      console.log("Promote Manager Along With Associates ....");
      const response = await CommissionAPI(
        "promoteManagerAlongWithAssociates",
        [],
        data
      );

      if (response) {
        toast.error(
          "Head successfully promoted to Manager along with Associates without assigning a new Head to previous associates"
        );
        reset();
        console.log("promoteHeadandAssignNewHead: ", response);
      }
    } catch (error) {
      console.log("Error promoteManagerAndAssignNewHead ", error);
    }
  };
  const promoteHeadAndSplitAssociates = async () => {
    try {
      const data = {
        newManagerEmail: formData?.email,
        provincialDirectorEmail: formData?.reportToEmail,
        newHeadEmailForAssociates_1: formData?.newHeadEmailForAssociates_1,
        associatesForHead_1: formData?.associatesForHead_1,
        newHeadEmailForAssociates_2: formData?.newHeadEmailForAssociates_2,
        associatesForHead_2: formData?.associatesForHead_2,
      };

      console.log("Promote Head And Split Associates ....");
      const response = await CommissionAPI(
        "promoteHeadAndSplitAssociates",
        [],
        data
      );

      if (response) {
        const message =
          response?.data?.message ||
          "Head promoted to Manager and Associates successfully split between two Heads";
        toast.success(message);
        reset();
        console.log("promoteHeadAndSplitAssociates: ", response);
      }
    } catch (error) {
      console.log("Error promoteHeadAndSplitAssociates ", error);
    }
  };
  const reset = () => {
    setScreenType("Select_Head");
    setRequestType("");
    setConfirmationModal(false);
    setFormData(initialState);
    props?.setModal(false);
  };

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props?.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              Promote Head to Manager
            </h3>

            <button
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                reset();
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-4">
            {screenType === "Select_Head" && (
              <>
                <div className="">
                  <Label>Select Head email you wanna promote</Label>
                  <Select
                    showSearch
                    onChange={(selectedEmail) => {
                      handleUserEmailChange(selectedEmail);
                    }}
                    // g
                    size={"large"}
                    // disabled={isLoading}
                    // dis
                    className="w-full"
                    value={formData.email}
                  >
                    {formattedUserEmail?.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                <div className="space-y-2">
                  <Label>User Name</Label>
                  <Input
                    type="text"
                    value={formData.name}
                    name="name"
                    disabled={true}
                    // onChange={handleInputChange}
                    isError={errors?.userNameError?.error}
                    errorMessage={errors?.userNameError?.message}
                  />
                </div>

                <div className="">
                  <Label>Select Provincial Director</Label>
                  <Select
                    showSearch
                    onChange={(selectedEmail) => {
                      handleInputChange("reportToEmail", selectedEmail);
                    }}
                    // g
                    size={"large"}
                    // disabled={isLoading}
                    className="w-full"
                    value={formData.reportToEmail}
                  >
                    {formattedSupervisorsEmail?.map((item) => (
                      <Select.Option key={item} value={item}>
                        {item}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
              </>
            )}
            {screenType === "Assoicates_Management" && (
              <>
                <CustomDropDown
                  label="Select Promote Type"
                  options={[
                    "Reassign All Associates",
                    "Assign Associates to New Head",
                    "Split Associates Among Heads",
                  ]}
                  onSelect={(type) => setRequestType(type)}
                  width="w-full"
                  value={requestType}
                />
                {requestType === "Assign Associates to New Head" && (
                  <div className="">
                    <Label>Select new head for previous associates</Label>
                    <Select
                      showSearch
                      onChange={(selectedEmail) => {
                        handleNewHeadEmailChangeForPreviousAssociates(
                          selectedEmail
                        );
                      }}
                      // g
                      size={"large"}
                      className="w-full"
                      value={formData.newHeadEmailForPreviousAssociates}
                    >
                      {formattedUserEmail
                        ?.filter((email) => email !== formData.email)
                        .map((item) => (
                          <Select.Option key={item} value={item}>
                            {item}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                )}
                {requestType === "Split Associates Among Heads" && (
                  <>
                    {/* =========== */}
                    {/* For Head 1 */}
                    {/* =========== */}
                    <div className="">
                      <Label>Select First head for previous associates</Label>
                      <Select
                        showSearch
                        onChange={(selectedEmail) => {
                          const updatedFormData = {
                            ...formData,
                            newHeadEmailForAssociates_1: selectedEmail,
                          };
                          setFormData(updatedFormData);
                        }}
                        // g
                        size={"large"}
                        className="w-full"
                        value={formData.newHeadEmailForAssociates_1}
                      >
                        {formattedUserEmail
                          ?.filter(
                            (email) =>
                              email !== formData?.email &&
                              email !== formData?.newHeadEmailForAssociates_2
                          )
                          ?.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>

                    <div className="">
                      <h4 className="">
                        Select Associates you wanna assign this Head
                      </h4>
                      <div className="flex gap-2 flex-wrap p-3 my-2 bg-gray-100 rounded ">
                        <div className="flex gap-2 flex-wrap p-3 my-2 bg-gray-100 rounded">
                          {formData?.associateEmails &&
                            formData?.associateEmails?.length > 0 &&
                            formData?.associateEmails?.map(
                              (userEmail, index) => {
                                // Check if the user is already assigned to Head 2, so we can disable them for Head 1
                                const isDisabled =
                                  formData.associatesForHead_2?.includes(
                                    userEmail
                                  );
                                // Check if the user is already assigned to Head 1
                                const isChecked =
                                  formData.associatesForHead_1?.includes(
                                    userEmail
                                  );

                                return (
                                  <div
                                    className={`flex items-center border-gray-200 rounded dark:border-gray-700 ${
                                      isDisabled ? "hidden" : "block"
                                    }`}
                                    key={index}
                                  >
                                    <input
                                      id={`checkbox-head1-${index}`}
                                      type="checkbox"
                                      name="bordered-checkbox"
                                      className="w-4 h-4 text-blue-600 bg-gray-100 rounded disabled:opacity-40"
                                      disabled={isDisabled}
                                      checked={isChecked}
                                      onChange={() => {
                                        // Update the associates list for Head 1
                                        const updatedAssociates = isChecked
                                          ? formData.associatesForHead_1.filter(
                                              (email) => email !== userEmail
                                            )
                                          : [
                                              ...(formData.associatesForHead_1 ||
                                                []),
                                              userEmail,
                                            ];

                                        setFormData({
                                          ...formData,
                                          associatesForHead_1:
                                            updatedAssociates,
                                        });
                                      }}
                                    />
                                    <label
                                      htmlFor={`checkbox-head1-${index}`}
                                      className="w-full ms-2 text-sm font-medium text-gray-900"
                                    >
                                      {userEmail}
                                    </label>
                                  </div>
                                );
                              }
                            )}
                        </div>
                      </div>
                    </div>

                    {/* =========== */}
                    {/* For Head 2 */}
                    {/* =========== */}
                    <div className="">
                      <Label>Select Second head for previous associates</Label>
                      <Select
                        showSearch
                        onChange={(selectedEmail) => {
                          const updatedFormData = {
                            ...formData,
                            newHeadEmailForAssociates_2: selectedEmail,
                          };
                          setFormData(updatedFormData);
                        }}
                        // g
                        size={"large"}
                        className="w-full"
                        value={formData.newHeadEmailForAssociates_2}
                      >
                        {formattedUserEmail
                          ?.filter(
                            (email) =>
                              email !== formData.email &&
                              email !== formData.newHeadEmailForAssociates_1
                          )
                          ?.map((item) => (
                            <Select.Option key={item} value={item}>
                              {item}
                            </Select.Option>
                          ))}
                      </Select>
                    </div>

                    <div className="">
                      <h4 className="">
                        Select Associates you wanna assign this Head
                      </h4>
                      <div className="flex gap-2 my-2 flex-wrap p-3 bg-gray-100 rounded ">
                        {formData?.associateEmails &&
                          formData?.associateEmails?.length > 0 &&
                          formData?.associateEmails?.map((userEmail, index) => {
                            // Check if the user is already assigned to Head 2, so we can disable them for Head 1
                            const isDisabled =
                              formData?.associatesForHead_1?.includes(
                                userEmail
                              );
                            // Check if the user is already assigned to Head 1
                            const isChecked =
                              formData?.associatesForHead_2?.includes(
                                userEmail
                              );

                            return (
                              <div
                                className={`flex items-center border-gray-200 rounded dark:border-gray-700 ${
                                  isDisabled ? "hidden" : "block"
                                }`}
                                key={index}
                              >
                                <input
                                  id={`checkbox-head1-${index}`}
                                  type="checkbox"
                                  name="bordered-checkbox"
                                  className="w-4 h-4 text-blue-600 bg-gray-100 rounded disabled:opacity-40"
                                  disabled={isDisabled}
                                  checked={isChecked}
                                  onChange={() => {
                                    // Update the associates list for Head 1
                                    const updatedAssociates = isChecked
                                      ? formData?.associatesForHead_2?.filter(
                                          (email) => email !== userEmail
                                        )
                                      : [
                                          ...(formData.associatesForHead_2 ||
                                            []),
                                          userEmail,
                                        ];

                                    setFormData({
                                      ...formData,
                                      associatesForHead_2: updatedAssociates,
                                    });
                                  }}
                                />
                                <label
                                  htmlFor={`checkbox-head1-${index}`}
                                  className="w-full ms-2 text-sm font-medium text-gray-900"
                                >
                                  {userEmail}
                                </label>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
            {screenType === "Show_Final_Detail" && (
              <>
                {requestType === "Assign Associates to New Head" && (
                  <div className="">
                    <Label>Note:</Label>

                    <div className="">
                      You are promoting{" "}
                      <span className="text-emerald-600 font-semibold">
                        {formData.email}
                      </span>{" "}
                      to Manager under the National Director{" "}
                      <span className="text-red-800 font-semibold">
                        {formData.reportToEmail}
                      </span>{" "}
                      , and assigning all associates to a new Head:{" "}
                      <span className="text-amber-800 font-semibold">
                        {formData.newHeadEmailForPreviousAssociates}
                      </span>
                      .
                    </div>
                  </div>
                )}
                {requestType === "Reassign All Associates" && (
                  <div className="">
                    <Label>Note:</Label>

                    <div className="">
                      You are promoting{" "}
                      <span className="text-emerald-600 font-semibold">
                        {formData.email}
                      </span>{" "}
                      to Manager under the National Director{" "}
                      <span className="text-red-800 font-semibold">
                        {formData.reportToEmail}
                      </span>{" "}
                      , along with all associates without Head
                    </div>
                  </div>
                )}
                {requestType === "Split Associates Among Heads" && (
                  <div className="">
                    <Label>Notice:</Label>

                    <div className="">
                      You are promoting{" "}
                      <span className="text-emerald-600 font-semibold">
                        {formData?.name} ({formData?.email})
                      </span>{" "}
                      to Manager under the Provincial Director{" "}
                      <span className="text-red-800 font-semibold">
                        {formData?.reportToEmail}
                      </span>
                      . You have chosen to split the associates between the
                      following heads:
                      <div className="mt-2">
                        <strong>Head 1:</strong>
                        <span className="text-blue-600 font-semibold">
                          {formData?.newHeadEmailForAssociates_1}
                        </span>
                        <ul className="list-disc list-inside">
                          {formData?.associatesForHead_1?.map(
                            (email, index) => (
                              <li key={index}>{email}</li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="mt-2">
                        <strong>Head 2:</strong>
                        <span className="text-blue-600 font-semibold">
                          {formData?.newHeadEmailForAssociates_2}
                        </span>
                        <ul className="list-disc list-inside">
                          {formData?.associatesForHead_2.map((email, index) => (
                            <li key={index}>{email}</li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <div className="flex items-center justify-end gap-2">
              <ButtonOutlined
                handleClick={() => {
                  if (
                    props?.commissionUser &&
                    JSON.stringify(formData) !==
                      JSON.stringify(props?.commissionUser)
                  ) {
                    setConfirmationModal(true);
                  } else if (
                    formData.userName !== "" ||
                    formData.role !== "" ||
                    formData.commissionPercentage !== 0
                  ) {
                    setConfirmationModal(true);
                  } else {
                    reset();
                  }
                }}
              >
                Cancel
              </ButtonOutlined>
              <button
                className="text-white bg-gradient-to-r from-primary-400 via-primary-500 to-primary-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-primary-300 shadow-lg shadow-primary-500/50 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 transition-all ease-in-out"
                onClick={() => {
                  handleButtonClick();
                }}
              >
                {screenType === "Show_Final_Detail" ? "Submit" : "Next"}
              </button>
              {/* <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={disableCreateButton}
              >
                {props?.mode === "add" ? "Create" : "Update"}
              </ButtonFill> */}
            </div>
          </div>
          <ConfirmationModal
            active={confirmationModal}
            onCancel={() => {
              setConfirmationModal(false);
            }}
            onConfirm={() => {
              setFormData(initialState);
              props?.setModal(false);
              setConfirmationModal(false);
            }}
            message={"Are you sure you want to leave this page?"}
          />
        </div>
      </div>
    </div>
  );
};

export default PromoteHeadToManagerForm;
