import { apiRequest } from '(apis)/api-interface/api-handler';
import { Button, Image, Modal, notification, Tag } from 'antd';
import React, { useEffect, useState } from 'react';
import GlobalTable from 'shared-components/table/GlobalTable';

// Interface for the renewal request item
interface RenewalRequest {
  _id: string;
  PaymentProof?: string;
  SubscriptionId: string;
  CourseID: string;
  courseName: string;
  username: string;
  AmountPaid: number;
  PaymentStatus: string;
  PlanName?: string;
  ExtensionRequested?: number;
  [key: string]: any; // Allow additional properties if they exist
}

// Interface for the modal data
interface ModalData extends RenewalRequest {
  // You can extend the RenewalRequest or add more specific properties if necessary
}

const Renewals: React.FC = () => {
  const [renewalRequests, setRenewalRequests] = useState<RenewalRequest[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [modalData, setModalData] = useState<ModalData | null>(null);

  const getRenewalRequests = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getAllRenewalRequests");
      if (res?.status === 200) {
        console.log("res?.data", res?.data);
        const formattedData = res?.data.map((item: RenewalRequest) => ({
          ...item,
          key: item._id,
          statusValue: item?.PaymentStatus,
        }));
        setRenewalRequests(formattedData);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getRenewalRequests();
  }, []);

  const handleApprove = async (status: string) => {
    if (modalData) {

        const data = {
                requestId:modalData._id,
                status,
                subscriptionId: modalData.SubscriptionId,
                extension: modalData?.ExtensionRequested,
        }
      const res = await apiRequest("modifyRenewal",data);
      if (res?.data.success) {
        notification.success({
          message: 'Success',
          description: 'Request has been updated successfully',
        });
        getRenewalRequests();
        setModalVisible(false);
      } else {
        notification.error({
          message: 'Error',
          description: res?.data.message,
        });
      }
    }
  };

  const columns = [
    {
      title: "Payment Proof",
      dataIndex: "PaymentProof",
      key: "PaymentProof",
      render: (pictureUrl: string) => {
        if (pictureUrl) {
          return (
            <Image
              width={50}
              height={50}
              className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
              src={pictureUrl}
            />
          );
        }
        return null;
      }
    },
    {
      title: '#',
      dataIndex: '_id',
      key: '_id',
    },
    {
      title: 'Subscription ID',
      dataIndex: 'SubscriptionId',
      key: 'SubscriptionId',
    },
    {
      title: 'Course ID',
      dataIndex: 'CourseID',
      key: 'CourseID',
    },
    {
      title: 'Course Name',
      dataIndex: 'courseName',
      key: 'courseName',
    },
    {
      title: 'Email',
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Amount Paid (PKR)',
      dataIndex: 'AmountPaid',
      key: 'AmountPaid',
    },
    {
      title: 'Status',
      dataIndex: 'PaymentStatus',
      key: 'PaymentStatus',
      render: (text: string) => {
        let color;
        switch (text) {
          case 'Pending':
            color = 'gray';
            break;
          case 'Rejected':
            color = 'red';
            break;
          case 'Approved':
            color = 'green';
            break;
          default:
            color = 'default';
        }
        return <Tag color={color}>{text}</Tag>;
      },
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: any, record: RenewalRequest) => (
        <Button onClick={() => toggleModal(record)}>View</Button>
      ),
    },
  ];

  const toggleModal = (data: ModalData) => {
    setModalData(data);
    setModalVisible(!modalVisible);
  };

  const statusList = [
    {
      key: -1,
      label: "All",
    },
    {
      key: "Pending",
      label: "Pending",
    },
    {
      key: "Approved",
      label: "Approved",
    },
    {
      key: "Rejected",
      label: "Rejected",
    },
  ];

  return (
    <div className="relative mx-4">

      <div className="w-full mx-4 my-10">
        <h1 className="text-2xl font-bold">Renewal Requests</h1>
      </div>

      <GlobalTable
        columns={columns}
        data={renewalRequests}
        loading={loading}
        // rowKey={(record) => record._id}
        statusList={statusList}
      />

      <Modal
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={[
          <Button key="cancel" onClick={() => setModalVisible(false)}>
            Cancel
          </Button>,
          <Button key="reject" className='text-red-500' onClick={() => handleApprove('Rejected')}>
            Reject
          </Button>,
          <Button key="approve" className='text-blue-500' type="primary" onClick={() => handleApprove('Approved')}>
            Approve
          </Button>,
        ]}
      >
        <div>
          <p><b>Subscription ID: </b>{modalData?.SubscriptionId}</p>
          <p><b>Course ID: </b>{modalData?.CourseID}</p>
          <p><b>Course Name: </b>{modalData?.courseName}</p>
          <p><b>Email: </b>{modalData?.username}</p>
          <p><b>Amount Paid: </b>{modalData?.AmountPaid}</p>
          <p><b>Status: </b>{modalData?.PaymentStatus}</p>
          <p className="text-rose-500"><b>Plan: </b>{modalData?.PlanName}</p>
          <p className="text-rose-500"><b>Extension Asked: </b>{modalData?.ExtensionRequested === 0 ? "1 Year" : `${modalData?.ExtensionRequested} Months`}</p>
          {modalData?.PaymentProof && (
            <Image height={500} src={modalData.PaymentProof} alt="Payment Proof" />
          )}
        </div>
      </Modal>
    </div>
  );
};

export default Renewals;

