import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import CustomButton from 'shared-components/form-fields/custom-button';
import * as Yup from 'yup';
import PricingModal from './modal';
import PricingbundleCard from './pricing-bundle-card';
import { PlusOutlined } from '@ant-design/icons';

const Pricing = ({ id, getCourseData, courseData }) => {
  const [isPricingAddModel, setIsPricingAddModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessTag, setAccessTag] = useState([]);
  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await apiRequest("getDistintTags");
        setAccessTag(response?.data?.tags);
        // console.log("ACCESS TAGS", accessTag)
      } catch (err) {
        console.error("Error fetching tags:", err);
      }
    };

    fetchTags();
  }, []);

  const initialValues = {
    planName: '',
    planTime: '',
    desc: '',
    price: '',
    discount: '',
    bundlePoints: [],
    selectedAccess: [],
    isLifeTime: true,
    cardId: null,
    tag:''
  };

  const validationSchema = Yup.object().shape({
    planName: isEditMode ? Yup.string().required('Name is required').test('unique', 'Plan name already exist.', function (value) {
      const existingPlanNameExceptCurrent = courseData?.pricingModel?.filter(pricingModel => pricingModel._id !== values?.cardId);
      const existingPlanNames = existingPlanNameExceptCurrent?.map(planNames => planNames?.SKUName)
      return !existingPlanNames?.includes(value);
    }) : Yup.string().required('Name is required').test('unique', 'Plan  name already exist.', function (value) {
      const existingPlanNames = courseData?.pricingModel?.map(planNames => planNames?.SKUName);
      return !existingPlanNames?.includes(value);
    }),

    planTime: Yup.number().nullable().test('is-month', 'months must be between 1 and 12', function (value) {
      const { isLifeTime } = this.parent;
      if (isLifeTime) return true;
      if (value >= 1 && value <= 12) return true;
      if (!value) {
        return this.createError({ message: 'Pick life time plan or  monthly' });
      }
      return this.createError({ message: 'Months must be between 1 and 12' });
    }),
    desc: Yup.string().required('description  is required').max(200, "maximum 200  characters"),
    bundlePoints: Yup.array().required('desc  is required').min(2, "Please enter minimum 2 points"),
    discount: Yup.number().required('Discount is required').min(0, 'Discount must be at least 0').max(0.9, 'Discount cannot exceed 0.9')

  });

  const  formik= useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        // setIsloadingWhileSubmitting(true)
        const { planName, planTime, desc,tag, price, discount, bundlePoints, selectedAccess, isLifeTime } = values


        const pricingModelData = {
          planName,
          access: selectedAccess,
          planTime: isLifeTime ? 0 : planTime,
          bundlePoints,
          originalPrice: price,
          discount,
          desc,
          tag
        }
        let res
        if (isEditMode) {
          res = await apiRequest("updateCoursePricing",pricingModelData,[ id, values?.cardId]);
        } else {
          res = await apiRequest("addCoursePricing",pricingModelData, [id]);
        }

        if (res?.status === 200) {
          setIsPricingAddModel(false)
          setIsEditMode(false)
          message.success(res?.data?.message)
          getCourseData()
          resetForm()
        }

      } catch (error) {
        message.error(error?.response?.data?.message)
      }
    },
  });
  const { values,  setTouched, setErrors, setValues } = formik;
  const handlePricingModel = () => {
    setIsPricingAddModel(true)

  }
  const handleIsPublishToggle = async (cardId) => {
    try {
      const res = await apiRequest("updateCoursePricingPublish",null,[id, cardId])
      if (res?.status === 200) {
        message.success(res?.data?.message)
        getCourseData()
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message)
    }

  }
  const handleEditCard = async (data) => {
    setTouched({});
    setErrors({});

    const { SKUName , PreMedAccess, BundlePoints, Tag, PlanDuration, Price, Discount, Description } = data
    setValues({
      planName: SKUName,
      planTime: PlanDuration,
      desc: Description,
      price: Price || '',
      discount: Discount,
      bundlePoints: BundlePoints,
      selectedAccess: PreMedAccess,
      cardId: data?._id,
      tag: Tag,
      isLifeTime: PlanDuration == 0 ? true : false
    })
    setIsEditMode(true)
    setIsPricingAddModel(true)
  }


  return (
    <>
      <div className='mt-4'>
        <div className='  mx-5 flex justify-end'>
          <CustomButton
            text="Add new  Pricing bundle"
            icon={<PlusOutlined/>}
            onClick={handlePricingModel}
          />
        </div>
        {/* cards  */}
        <div>
          <section className="text-gray-600 body-font overflow-hidden">
            <div className="container px-5 pb-24 mx-auto">
              <div className="flex flex-wrap -m-4">
                {
                  courseData?.pricingModel?.map((data, i) => {
                    return <div key={i} className="p-4  md:w-1/2 lg:w-1/3 w-full">
                      <PricingbundleCard data={data} handleIsPublishToggle={handleIsPublishToggle} handleEditCard={handleEditCard} />
                    </div>
                  })
                }
              </div>
            </div>
          </section>
        </div>
      </div>

      <PricingModal
        formik={formik}
        isPricingAddModel={isPricingAddModel}
        filteredbundlePoints={[]}
        filteredAccess={accessTag}
        setIsPricingAddModel={setIsPricingAddModel}
        isEditMode={isEditMode}
      />
    </>
  );
}

export default Pricing;
