import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import { useFormik } from "formik";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import Loader from "shared-components/Loader";
import * as Yup from "yup";
import ScheduleTable from "./schedule-table";

const Scheduled = ({
  id,
  subjectData,
  scheduledCourseTopicData,
  getScheduleData,
  loading,
}) => {
  const initialValues = {
    topic: "",
    date: "",
    markedValue: "",
    subject: "",
  };

  const validationSchema = Yup.object().shape({
    topic: Yup.string()
      .required("Topic is required")
      .test("unique", "Topic name already exist.", function (value) {
        const existingDeckNames = scheduledCourseTopicData?.map(
          (data) => data.topic
        );
        return !existingDeckNames?.includes(value);
      }),
    // topic: Yup.string().required('Topic is required'),
    date: Yup.string().required("date  is required"),
    markedValue: Yup.string().required("markedValue  is required"),
    subject: Yup.string().required("subject  is required"),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { topic, date, markedValue, subject } = values;
        const payLoad = {
          topic,
          date,
          markedValue,
          subject,
          courseId: id,
        };
        const res = await apiRequest("addSchedule", payLoad);
        if (res?.status === 200) {
          message.success(res?.data?.message);
          getScheduleData().then(() => console.log());
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { values, handleSubmit, setFieldValue, touched, errors, handleChange } =
    formik;
  return (
    <div className="">
      <Loader loading={loading} />
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="rounded-lg shadow-lg p-4 bg-white m-4"
      >
        <div className="flex flex-row justify-between">
          <div className="font-bold text-rose-500">Add Schedule</div>
          <div>
            <button
              disabled={formik?.isSubmitting}
              type="submit"
              className="flex items-center bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
            >
              {formik?.isSubmitting ? "Adding..." : "Add To Schedule"}
            </button>
          </div>
        </div>
        <div className="flex flex-row flex-wrap items-center justify-between mx-4"></div>
        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full md:w-1/3">
            <div className="relative">
              <CustomFormField
                type="text"
                formik={formik}
                name="topic"
                label="Topic"
              />
            </div>
          </div>
          <div className="md:p-2 w-full md:w-1/3">
            <div className="relative">
              <CustomFormField
                type="select"
                formik={formik}
                name="subject"
                label="Subject"
                options={subjectData}
              />
            </div>
          </div>
          <div className="md:p-2 w-full md:w-1/3">
            <div className="relative">
              <label
                htmlFor="name"
                className=" text-md font-bold text-gray-900 mt-2.5"
              >
                Date
              </label>
              <input
                type="date"
                id="date"
                name="date"
                value={values?.date}
                onChange={handleChange}
                placeholder="Date"
                className="w-full custom-input-class"
              />
              {touched?.date && errors?.date && (
                <div className="text-red-500 text-sm mt-1">{errors.date}</div>
              )}
            </div>
          </div>
        </div>
        <div className=" mt-2 md:mt-0 md:w-1/2">
          <div className="flex flex-row gap-4 items-center flex-start m-2">
            <button
              className={`${
                values?.markedValue === "DEMO"
                  ? "bg-rose-500 text-white "
                  : "bg-white text-rose-500"
              } font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
              onClick={(e) => {
                e.preventDefault();
                setFieldValue("markedValue", "DEMO");
              }}
            >
              Mark{values?.markedValue === "DEMO" && "ed"} as Demo
            </button>
            <button
              className={`${
                values?.markedValue === "UPCOMING"
                  ? "bg-rose-500 text-white "
                  : "bg-white text-rose-500"
              } font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
              onClick={(e) => {
                e.preventDefault();
                setFieldValue("markedValue", "UPCOMING");
              }}
            >
              Mark{values?.markedValue === "UPCOMING" && "ed"} as Upcoming
            </button>
            <button
              className={`${
                values?.markedValue === "RECORDED"
                  ? "bg-rose-500 text-white "
                  : "bg-white text-rose-500"
              } font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline shadow-lg`}
              onClick={(e) => {
                e.preventDefault();
                setFieldValue("markedValue", "RECORDED");
              }}
            >
              Mark{values?.markedValue === "RECORDED" && "ed"} as Recorded
            </button>
          </div>
          {touched?.markedValue && errors?.markedValue && (
            <div className="text-red-500 text-sm mt-1">
              {errors?.markedValue}
            </div>
          )}
        </div>
      </form>

      <div className=" w-full ">
        {scheduledCourseTopicData?.length > 0 && (
          <ScheduleTable
            getScheduleData={getScheduleData}
            courseId={id}
            scheduledCourseTopicData={scheduledCourseTopicData}
          />
        )}
      </div>
    </div>
  );
};

export default Scheduled;
