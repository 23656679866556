import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const CustomBundleAPIs: ApiInterface[] = [
    {
        url: "/api/custom-bundles/add",
        method: "POST",
        name: "addCustomBundle",
    },
    {
        url: "/api/custom-bundles/get",
        method: "GET",
        name: "getCustomBundles",
    },
    {
        url: "/api/custom-bundles/update-bundle-published-status-by-id",
        method: "PUT",
        name: "updateBundlePublishedStatusById",
    },
    {
        url: "/api/custom-bundles/get-bundles-by-id",
        method: "GET",
        name: "getBundleById",
    },
    {
        url: "/api/custom-bundles/update-bundles",
        method: "PATCH",
        name: "updateBundle",
    },
    {
        url: "/api/custom-bundles/add-toggle-to-bundle",
        method: "POST",
        name: "addToggleToBundle",
    },
    {
        url: "/api/custom-bundles/update-toggle",
        method: "PUT",
        name: "updateToggle",
    },
    {
        url: "/api/custom-bundles/add-feature-to-bundle",
        method: "POST",
        name: "addFeatureToBundle",
    },
    {
        url: "/api/custom-bundles/update-sku",
        method: "PUT",
        name: "updateSKU",
    },
]

export function CustomBundleAPI(name: string, params: string[] = [""], data: any = null) {
    const api = CustomBundleAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

