import { ApiInterface } from "../api-interface/types";

const MyOwnBundlesEndpoint = "/api/myOwnBundles";
const url = MyOwnBundlesEndpoint

export const myOwnBundlesApis: ApiInterface[] = [
    {
        url: `${url}/getBundles`,
        method: "GET",
        name: "getMyBundles",
    },
    {
        url: `${url}/updateActive/:id`,  // Use `:id` as a placeholder for dynamic ID
        method: "PUT",
        name: "updateBundleActive",
    },
    {
        url: `${url}/getBoards`,
        method: "GET",
        name: "getBoardsDataApi",
    },
    {
        url: `${url}/addBundles`,
        method: "POST",
        name: "addMyBundle",
    },
    {
        url: `${url}/updateBundles/:myBundleId`,  // Use `:myBundleId` as a placeholder
        method: "PUT",
        name: "updateMyBundle",
    },
    {
        url: `${url}/createFeature/:bundleId`,  // Use `:bundleId` as a placeholder
        method: "POST",
        name: "addMyBundleFeature",
    },
    {
        url: `${url}/updateFeature/:bundleId/:featureId`,  // Use `:bundleId` and `:featureId` as placeholders
        method: "PUT",
        name: "updateMyBundleFeature",
    },
    {
        url: `${url}/getById/:id`,  // Use `:id` as a placeholder
        method: "GET",
        name: "getMyBundleById",
    },
    {
        url: `${url}/update/:id`,  // Use `:id` as a placeholder
        method: "PATCH",
        name: "updateBundleApi",
    },
];
