import React, { useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useFormik } from "formik";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, message } from "antd";
import Loader from "shared-components/Loader";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import { topicalGuidesInitialValues } from "shared-pages/vault/components/initialvalues";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import { generateAntdPdfObjectFromUrl } from "utility/form/generate-antd-pdf-object-from-url";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { columnsWithoutRender } from "./columns";
import { validationSchema } from "./schema";
import AddAndUpdateModal from "./modal";
import { TopicalGuide } from "./types";
import { convertToFormData } from "utility/form/converttoformdata";
const TopicalGuides: React.FC = () => {
  const [ShowModel, setShowModel] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  type SelectOption = { value: string; label: string }[];
  const [boardsData, setBoardData] = useState<SelectOption>([]);
  const [subjectData, setSubjectData] = useState<SelectOption>([]);
  const [allSubTopicsData, setAllSubTopicsData] = useState<SelectOption>([]);
  const [topicalGuideData, setTopicalGuideData] = useState<TopicalGuide[]>([]);
  const [institutesData, setInstitutesData] = useState<SelectOption>([]);
  const [topicsData, setTopicsData] = useState<SelectOption>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const formik = useFormik({
    initialValues: topicalGuidesInitialValues,
    validationSchema,
    onSubmit: async (values) => {
      const filteredPaginations = values.paginations?.filter(
        (pagination) => pagination.startPageNo && pagination.endPageNo
      );
      const base64ThumbnailImage = await identifyAndConvertNewImagesToBase64(values?.thumbnailImage);
      const thumbnailImageUrl = filteroutGeneratedImagesUrls(values?.thumbnailImage);
      const updatedValues = {
        ...values,
        pdf: values?.pdf?.[0]?.originFileObj,
        thumbnailImageUrl: thumbnailImageUrl?.[0],
        thumbnailImage: base64ThumbnailImage?.[0],
        category:process.env.REACT_APP_CATEGORY,
        entity:process.env.REACT_APP_ENTITY,
        paginations:
        filteredPaginations?.length > 0 ? filteredPaginations : undefined,
      };
      const formData = convertToFormData(updatedValues);
      try {
        setLoading(true);
        let res;
        if (isEditMode) {
          res = await apiRequest("Updateguide",formData,[values?.topicalGuideId]);
        } else {
          res = await apiRequest("addquide",formData);
        }
        if (res?.status === 200) {
          setLoading(false);
          setIsEditMode(false);
          setShowModel(false);
          getTopicalGuidesData();
          message.success(res?.data?.message);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
        setLoading(false);
      }
    },
  });

  const { setValues, resetForm } = formik;
  const getTopicalGuidesData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getguides");
      if (res?.data?.success) {
        const resData = res?.data?.data;
        setTopicalGuideData(resData);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        topicalGuideRes,
        topicsRes,
        boardsRes,
        subjectsRes,
        institutesRes,
      ] = await Promise.all([
        
        apiRequest("getguides"),
 
        apiRequest("getalltopics"),
        apiRequest("gethierarchyboard"),
        apiRequest("getallsubjects"),
        apiRequest("getinstitutes"),
      ]);

      if (topicalGuideRes?.data?.success) {
        setTopicalGuideData(topicalGuideRes.data?.data);
      }

      if (institutesRes?.data?.institutes?.length > 0) {
        const instituteData = institutesRes?.data?.institutes?.map((item: any) => ({
          value: item?._id,
            label: item?.instituteName,
        }));
        setInstitutesData(instituteData);
      }

      if (topicsRes?.data?.data?.length > 0) {
        const topicsData = topicsRes.data?.data;
        const formatedTopicsData = topicsData?.map((topic: any) => ({
          ...topic,
          value: topic._id,
          label: topic.topicName,
        }));
        let allSubTopics: any[] = [];

        // Iterate over each topic
        topicsData.forEach((topic: any) => {
          // Iterate over each subtopic of the current topic
          topic.subTopics.forEach((subTopic: any) => {
            allSubTopics.push({
              ...subTopic,
              value: subTopic._id,
              label: subTopic.subTopic,
            });
          });
        });
        setTopicsData(formatedTopicsData);
        setAllSubTopicsData(allSubTopics);
      }

      if (boardsRes?.data?.length> 0) {
        const boardsData = boardsRes.data?.map((item: any) => ({
          value: item?.board,
          label: item?.board,
        }));
        setBoardData(boardsData);
      }

      if (subjectsRes?.data?.length > 0) {
        
        const subjectData = subjectsRes.data?.map((subject: any) => ({
          value: subject?._id,
          label: subject?.name,
        }));
        setSubjectData(subjectData);
      }

      setLoading(false);
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };

  const handleIsPublish = async (id: string) => {
    try {
      setLoading(true);
      const res = await apiRequest("Updateguidepublish",null,[id]);

      if (res?.data?.success) {
        message.success(res?.data?.message);
        getTopicalGuidesData();
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleEdit = (data:any) => {
    resetForm();
    const { expandData, id, thumbnailImageUrl, pdfUrl, } = data;
    const thb = generateAntdImageObjectFromUrl(thumbnailImageUrl);
    const pdfFormated = generateAntdPdfObjectFromUrl(pdfUrl);
    setValues({
      ...data,
      description: expandData,
      topicalGuideId: id,
      thumbnailImage: [thb],
      pdf: [pdfFormated],
    });
    setIsEditMode(true);
    setShowModel(true);
  };
  const handleViewRedirect = (data:any) => {
    const { pdfUrl } = data;
    if (!pdfUrl) {
      message.error("No file uploaded");
    } else if (pdfUrl) {
      window.open(pdfUrl);
    }
  };
  const Columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnailImageUrl",
      key: "thumbnailImageUrl",
      render: (thumbnailImageUrl: string) => (
        <Image width={50} height={50} src={thumbnailImageUrl} />
      ),
    },
   ...columnsWithoutRender,
    {
      title: "Publish Status",
      dataIndex: "isPublished",
      key: "isPublished",
      render: (data:any) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handleIsPublish(data?.id)}
          />
        </div>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (record: TopicalGuide) => (
        <div className="flex  gap-2">
          <div onClick={()=>handleViewRedirect(record)}>
            <EyeIcon size="w-6 h-6"/>
          </div>
          <div onClick={()=>handleEdit(record)}>
          <EditIcon size="w-6 h-6"/>

          </div>
        </div>
      ),
    },
  ];
  const formatedTopicalGuideData = topicalGuideData?.map((item, i) => {
    return {
      key: i + 1,
      ...item,
      expandData: item?.description,
      description:
        item?.description?.length > 10
          ? `${item?.description.slice(0, 10)}...`
          : item?.description,

      id: item?._id,
      isPublished: {
        isPublished: item?.isPublished,
        id: item?._id,
      },
    };
  });
  return (
    <>
      <div className="container-fluid">
        { <Loader  loading = {loading}/>}
        <div className="mb-4 flex justify-end">
          <CustomButton
            onClick={() => {
              formik.resetForm();
              setShowModel(true)}
            }
            text="Add New Topical Guide"
            icon={<PlusOutlined />}
          
          />
        </div>
        <GlobalTable
          data={formatedTopicalGuideData}
          columns={Columns}
          tableName="Topical Guides"
          loading={loading}
        />
        {ShowModel && (
          <AddAndUpdateModal
          formik={formik}
          institutesData={institutesData}
          isEditMode={isEditMode}
          loading={loading}
          ShowModel={ShowModel}
          boardsData={boardsData}
          allSubTopicsData={allSubTopicsData}
          subjectData={subjectData}
          topicsData={topicsData}
          setIsEditMode={setIsEditMode}
          setShowModel={setShowModel}
        />
        )}
      </div>
    </>
  );
};

export default TopicalGuides;
