import { CustomBundleAPI } from '(apis)/(shared-apis)/custom-bundle-apis';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { Select } from 'antd';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { ButtonFill, ButtonOutlined } from 'shared-components/ui/CustomButtons';
import CustomDropDown from 'shared-components/ui/CustomDropDown';
import { Input, Label } from 'shared-components/ui/CustomForm';

export default function AddBundleModal(props) {

    const [mode, setMode] = useState('add')
    const [formData, setFormData] = useState({
        featureName: "",
        category: "",
        access: [],
        position: 1000,
        toggleValuesAndPricings: [],
        isPublished: true
    });
    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };
    const [accessTags, setAccessTags] = useState([])
    const GetDiscountTags = async () => {
        try {
            const response = await apiRequest("getDistintTags");
            if (response?.data?.tags) {
                setAccessTags(response.data.tags);
            }
        } catch (error) {
            console.log("Error in fetching discount tags: ", error);
        }
    }
    useEffect(() => {
        GetDiscountTags();
    }, [])

    useEffect(() => {
        if (props?.bundleData && props?.mode === 'add') {
            const newToggleValuesAndPricings = props.bundleData.togglesAndPricings.map((toggle) => ({
                _id: toggle?._id,
                price: 0,
                discount: 0,
                active: true
            }));
            setFormData({
                ...formData,
                toggleValuesAndPricings: newToggleValuesAndPricings,
            });
        }
        else if (props?.bundleData && props?.mode === 'edit') {
            const newToggleValuesAndPricings = props.bundleData.togglesAndPricings.map((toggle) => {
                const FindSku = toggle?.featurePricings.find((sku: any) => sku.featureId === props.selectedFeature._id);
                return {
                    _id: toggle?._id,
                    price: FindSku?.price || 0,
                    discount: FindSku?.discount || 0,
                    active: FindSku?.active || false
                };
            });
            setFormData({
                ...formData,
                featureName: props.selectedFeature?.featureName,
                category: props.selectedFeature?.category,
                access: props.selectedFeature?.access,
                position: props.selectedFeature?.position,
                isPublished: props.selectedFeature?.isPublished,                
                toggleValuesAndPricings: newToggleValuesAndPricings,
            });
        }
    }, [props?.bundleData, props?.mode , props?.selectedFeature])

    const HandleToggleValuesAndPricings = (index: number, value: string, key: string) => {
        const updatedTogglesAndPricings = formData.toggleValuesAndPricings.map((toggle, i) => {
            if (i === index) {
                return {
                    ...toggle,
                    [key]: value
                }
            }
            return toggle
        })
        setFormData({
            ...formData,
            toggleValuesAndPricings: updatedTogglesAndPricings
        })
    }

    const [loading, setLoading] = useState(false);

    const Submit = async () => {

        if (!formData.featureName) {
            toast.error("Feature Name is required");
            return;
        }
        if (!formData.category) {
            toast.error("Category is required");
            return;
        }
        let invalidDiscount = false;
        let invalidPrice = false;
        let invalidNumberOfUsers = false;
        formData.toggleValuesAndPricings.map((toggle) => {
            if (toggle.price < 0) {
                invalidPrice = true;
            }
            if (toggle.discount < 0 || toggle.discount > 10) {
                invalidDiscount = true;
            }
        })
        if (invalidPrice) {
            toast.error("Prices should be positive");
            return;
        }
        if (invalidDiscount) {
            toast.error("Discounts should be between 0 and 10 only");
            return;
        }
        if (invalidNumberOfUsers) {
            toast.error("Number of Users should be positive");
            return;
        }

        setLoading(true);
        try {
            if (props?.mode === 'edit') {
                const response = await CustomBundleAPI("updateSKU", [props?.bundleData?._id], {
                    ...formData,
                    _id: props.selectedFeature._id
                });
                if (response.data.success) {
                    toast.success("Feature Updated Successfully");
                    props?.setModal(false);
                    props?.fetchPackage();
                }
                else {
                    toast.error("Failed to update pricing plan");
                }
                setLoading(false);
                return;
            }
            else {
                const response = await CustomBundleAPI("addFeatureToBundle", [props?.bundleData?._id], formData);
                if (response.data.success) {
                    toast.success("Feature Added Successfully");
                    props?.setModal(false);
                    props?.fetchPackage();
                }
                else {
                    toast.error("Failed to add pricing plan");
                }
                setLoading(false);
                return;
            }
        }
        catch (error) {
            console.log("Error in adding pricing plan: ", error);
            toast.error("Failed to add pricing plan");
        }
        finally {
            setLoading(false);
        }
    }


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Add Bundle
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>


                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4 items-start'>
                        <div>
                            <div className='grid grid-cols-1 gap-1'>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>Feature Name</Label>
                                        <Input
                                            type="text"
                                            placeholder="Enter Feature Name"
                                            disabled={props?.mode === 'edit'}
                                            value={formData.featureName}
                                            name="featureName"
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <CustomDropDown
                                            width='w-full'
                                            label='Category'
                                            options={props?.categories}
                                            onSelect={(value) => {
                                                setFormData({
                                                    ...formData,
                                                    category: value,
                                                })
                                            }}
                                            value={formData.category}
                                        />                                    
                                     </div>
                                </div>

                                <div className="space-y-2">
                                    <div className="space-y-2">
                                        <Label>Position</Label>
                                        <Input
                                            type="number"
                                            placeholder="Enter Position"
                                            value={formData.position}
                                            name="position"
                                            onChange={handleInputChange}
                                        />  
                                    </div>
                                </div>

                                <div className="space-y-1">
                                    <Label>Access</Label>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        placeholder="Select Access"
                                        value={formData.access}
                                        onChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                access: value
                                            })
                                        }}
                                    >
                                        {accessTags.map((tag) => (
                                            <Select.Option key={tag} value={tag}>
                                                {tag}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </div>

                                <div className="space-y-1">
                                    <Label>Published</Label>
                                    <Select
                                        style={{ width: "100%", height: '45px' }}
                                        placeholder="Select Published"
                                        value={formData.isPublished}
                                        onChange={(value) => {
                                            setFormData({
                                                ...formData,
                                                isPublished: value
                                            })
                                        }}
                                    >
                                        <Select.Option key='true' value={true}>
                                            True
                                        </Select.Option>
                                        <Select.Option key='false' value={false}>
                                            False
                                        </Select.Option>
                                    </Select>
                                </div>

                            </div>
                        </div>
                        <div>

                        {props?.bundleData?.togglesAndPricings?.map((toggle:any, index:number) => (
                                <div className='flex flex-col gap-2 mt-2'>
                                    <div className='text-md font-semibold text-gray-900'>
                                        {toggle?.toggleValue}
                                    </div>
                                    <div className='grid grid-cols-1 md:grid-cols-3 gap-2 items-start'>
                                        <div className="space-y-1">
                                            <Label>Price</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Price"
                                                value={formData.toggleValuesAndPricings[index]?.price}
                                                name='price'
                                                onChange={(e) => {
                                                    HandleToggleValuesAndPricings(index, e.target.value, 'price')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Discount</Label>
                                            <input
                                                className="w-full rounded border-[1.5px] border-stroke bg-transparent py-3 px-5 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-white disabled:text-gray-400 disabled:font-light"
                                                type="number"
                                                placeholder="Enter Discount"
                                                value={formData.toggleValuesAndPricings[index]?.discount}
                                                name='discount'
                                                onChange={(e) => {
                                                    HandleToggleValuesAndPricings(index, e.target.value, 'discount')
                                                }}
                                            />
                                        </div>
                                        <div className="space-y-1">
                                            <Label>Active</Label>
                                            <Select
                                                style={{ width: "100%", height: '45px' }}
                                                placeholder="Select Active"
                                                value={formData.toggleValuesAndPricings[index]?.active}
                                                onChange={(value) => {
                                                    const updatedTogglesAndPricings = formData.toggleValuesAndPricings.map((toggle, i) => {
                                                        if (i === index) {
                                                            return {
                                                                ...toggle,
                                                                active: value
                                                            }
                                                        }
                                                        return toggle
                                                    })
                                                    setFormData({
                                                        ...formData,
                                                        toggleValuesAndPricings: updatedTogglesAndPricings
                                                    })
                                                }}
                                            >
                                                <Select.Option key='true' value={true}>
                                                    True
                                                </Select.Option>
                                                <Select.Option key='false' value={false}>
                                                    False
                                                </Select.Option>
                                            </Select>
                                        </div>



                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>



                    <div className="flex items-center justify-end gap-2">
                        <ButtonOutlined
                            handleClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            Cancel
                        </ButtonOutlined>

                        <ButtonFill handleClick={Submit} isLoading={loading}>
                            {props?.mode === 'add' ? 'Add' : 'Edit'}{" "} Feature
                        </ButtonFill>
                    </div>

                </div>

            </div>

        </div>
    )
}
