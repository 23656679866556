import React from "react";
import LabelComponent from "./LabelComponent";
import CustomFormikError from "./CustomFormikError";
import { InputNumber } from "antd"; // Use InputNumber from Ant Design
import { FormikProps } from "formik";
import { getErrorString } from "../../utility/form/functions";

interface CustomInputNumberFieldProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  onChange?: (value: number | null) => void; // Adjust to handle number input
  require?: boolean;
  prefix?: React.ReactNode | string; // Add prefix to input field, e.g., '$' or '��'
}

const CustomInputNumberField: React.FC<CustomInputNumberFieldProps> = ({
  label,
  name,
  formik,
  onChange,
  require = true,
  prefix,
}) => {
  const touched = !!formik?.touched[name];
  const error = getErrorString(formik?.errors[name]);
  
  return (
    <div className="flex flex-col ">
      <LabelComponent value={label} require={require} />
      <InputNumber
        id={name}
        prefix={prefix}
        name={name}
        onChange={(value) => {
          if (onChange) {
            onChange(value);
          } else {
            formik?.setFieldValue(name, value);
          }
        }}
        className="border !w-full"
        placeholder={`Enter ${label}`}
        value={formik?.values?.[name]}
      />
      <CustomFormikError name={name} touched={touched} error={error} />
    </div>
  );
};

export default CustomInputNumberField;
