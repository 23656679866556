export const getErrorString = (error: any): string => {
  console.log("error", error)
    if (typeof error === 'string') {
      return error;
    }
    if (Array.isArray(error)) {
      return error.map(getErrorString).join(', ');
    }
    if (error && typeof error === 'object') {
    
      return Object.values(error).map(getErrorString).join(', ');
    }
    return '';
  };

  export const getBase64ImageData = (file:any) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  