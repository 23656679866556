
import { apiRequest } from "(apis)/api-interface/api-handler";
import { DeckAPI } from "../../../../../(apis)/(shared-apis)/academics/decks-api";
import { Select } from "antd";
import React, { useEffect, useState } from "react";

export default function SelectionArea({ setForm, form , mode = "add"}) {

    const [deckNames, setDeckNames] = useState([]);
    const [boardValues, setBoardValues] = useState([]);


    const getDeckNames = async () => {
        const response = await DeckAPI("getDeckNames");
        setDeckNames(response.data.data);
    };

    const GetBoards = async () => {
        const response = await apiRequest("gethierarchyboard");
        let uniqueBoards = [];
        response.data.map((item) => {
            uniqueBoards.push(item.board);
        });
        setBoardValues(uniqueBoards);
    };


    useEffect(() => {
        getDeckNames();
        GetBoards();
    }, []);

    console.log("form", process.env.REACT_APP_ENTITY);


    return (
        <div className="flex flex-row flex-wrap items-center justify-between">
                      {mode !== "edit" && (
                <div className='flex flex-row flex-wrap items-center gap-4'>
                    <h5 className="text-lg text-gray-700 font-bold">Deck Name</h5>
                    {deckNames.length > 0 && (
                        <Select
                            style={{ width: 500, backgroundColor: "transparent" }}
                            value={form.deckName || "Select Deck"}
                            placeholder="Select Deck"
                            showSearch
                            className="bg-transparent"
                            onChange={(value) => {
                                setForm({
                                    ...form,
                                    deckName: value
                                });
                            }}
                        >
                            {deckNames.map((deck) => (
                                <Select.Option key={deck.deckName} value={deck.deckName}>
                                    {deck.deckName}
                                </Select.Option>
                            ))}
                        </Select>
                    )}
                </div>)}
                <div className="flex gap-2 items-center justify-between">
                    <div className="text-lg text-gray-700 font-bold">Select Boards </div>
                    <Select
                        style={{ width: 500 }}
                        mode="multiple"
                        placeholder="Select Board"
                        onChange={(value) => {
                            setForm({ ...form, board: value });
                        }}
                        disabled={!process.env.REACT_APP_ENTITY || process.env.REACT_APP_ENTITY === ""}
                        className="w-1/2"
                        value={form.board || []}
                    >
                        {boardValues.map((board) => (
                            <Select.Option key={board} value={board}>
                                {board}
                            </Select.Option>
                        ))}
                    </Select>
            </div>
        </div>
    );
}
