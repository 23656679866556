import { ButtonProps } from "antd";
import React from "react";
import { LoadingIconFilled } from "shared-components/ui/Icons";

interface GlobalBtnProps {
  text?: string;
  disabled?: boolean;
  icon?: React.ReactNode;
  onClick: React.MouseEventHandler<HTMLElement>;
  type?: ButtonProps['type'];
  size?: ButtonProps['size'];
  className?: string;
  isLoading?: boolean;
}

const CustomButton: React.FC<GlobalBtnProps> = ({
  text = "Submit",
  disabled,
  icon,
  onClick,
  type = "button",
  size,
  className,
  isLoading = false,
}) => {
  return (
    <button
      type={type === "Submit" ? "submit" : "button"}
      className={`"text-white bg-primary focus:ring-4 focus:outline-none focus:ring-primary-300 shadow-none font-medium rounded-lg text-sm px-5 py-2.5 mt-2"
      hover:shadow-none hover:bg-opacity-90 hover:scale-1 text-center mb-2 transition-all ease-in-out disabled:bg-rose-200 disabled:cursor-not-allowed"`}
      disabled={disabled || isLoading}
      onClick={onClick}
    >
      {isLoading ? (
        <div role="status" className="flex items-center space-x-2">
          <span className="relative text-base font-semibold text-white flex items-center space-x-3">
            Loading
          </span>
          <LoadingIconFilled />
        </div>
      ) : (
        <div
          className={`relative flex items-center ${text && icon && " space-x-3 "} text-white`}
        >
          {icon}
          {text && <span className="text-base font-semibold">{text}</span>}
        </div>
      )}
    </button>
  );
};

export default CustomButton;
