export const columnsWithoutRender =[
    {
        title: "Board",
        dataIndex: "board",
        key: "board",
        flex: 1,
      },
      {
        title: "Access",
        dataIndex: "access",
        key: "access",
        flex: 1,
      },
      {
        title: "Topic",
        dataIndex: "topicName",
        key: "topicName",
        flex: 1,
      },
      {
        title: "Sub Topic ",
        dataIndex: "subTopicName",
        key: "subTopicName",
        flex: 1,
      },
      {
        title: "Province",
        dataIndex: "province",
        key: "province",
        flex: 1,
      },
      {
        title: "Subject",
        dataIndex: "subject",
        key: "subject",
        flex: 1,
      },
]