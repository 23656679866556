
import * as Yup from "yup";
import { commonValidationSchema, pdfFileSchema, thumbnailImageSchema } from "../../components/validation-schema";
export const shortlistingSchema=  Yup.object().shape({
    ...commonValidationSchema.fields,
    thumbnailImage: thumbnailImageSchema,
    pdfFile: pdfFileSchema
  });














