import { apiRequest } from '(apis)/api-interface/api-handler';
import { message, notification } from 'antd';
import dayjs from 'dayjs';
import React, { useState } from 'react';

interface ActivatedSubscription {
    UserID: {
        username: string;
    };
}

interface EmailProps {
    activatedSubscriptions: ActivatedSubscription[];
}

interface FormState {
    Name: string;
    Course: string;
    Date: string;
    Time: string;
    Description: string;
}

const Email: React.FC<EmailProps> = ({ activatedSubscriptions }) => {
    const [disabled, setDisabled] = useState<boolean>(false);
    const [form, setForm] = useState<FormState>({
        Name: "",
        Course: "",
        Date: "",
        Time: "",
        Description: ""
    });

    const SendEmail = async () => {
        setDisabled(true);

        if (form.Name === "" || form.Course === "" || form.Date === "" || form.Time === "" || form.Description === "") {
            notification.error({
                message: 'Error',
                description: 'Please fill all fields'
            });
            setDisabled(false);
            return;
        }

        const Emails = activatedSubscriptions.map(sub => sub.UserID.username);

        const convertTo12HourFormat = (time: string) => {
            return dayjs(time, 'HH:mm').format('h:mm A');
        };

        const date = new Date(form.Date);
        const day = date.getDate();
        const month = date.toLocaleString('default', { month: 'long' });
        const year = date.getFullYear();
        const time = convertTo12HourFormat(form.Time);

        const EmailData = {
            Emails,
            Name: form.Name,
            Course: form.Course,
            Date: `${day} ${month} ${year}`,
            Time: time,
            Description: form.Description
        };

        try {
            const Response = await apiRequest("SendUpcomingAnnouncement",EmailData);
            if (Response.data.succes) {
            message.success(Response.data.message);
            }
        } catch (error) {
            message.error(error.response.data.message || error.message);
        }

        setForm({
            Name: "",
            Course: "",
            Date: "",
            Time: "",
            Description: ""
        });
        setDisabled(false);
    };

    return (
        <div>
            <div className='mt-8'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl font-bold'>Send Upcoming Class Emails</h1>
                    <div className='mt-4'>
                        <input
                            type="text"
                            placeholder='Name'
                            className='w-full p-2 rounded border border-gray-300'
                            value={form.Name}
                            onChange={(e) => setForm({ ...form, Name: e.target.value })}
                        />
                        <input
                            type="text"
                            placeholder='Course'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Course}
                            onChange={(e) => setForm({ ...form, Course: e.target.value })}
                        />
                        <input
                            type="date"
                            placeholder='Date'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Date}
                            onChange={(e) => setForm({ ...form, Date: e.target.value })}
                        />
                        <input
                            type="time"
                            placeholder='Time'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Time}
                            onChange={(e) => setForm({ ...form, Time: e.target.value })}
                        />
                        <textarea
                            placeholder='Description'
                            className='w-full p-2 rounded border border-gray-300 mt-4'
                            value={form.Description}
                            onChange={(e) => setForm({ ...form, Description: e.target.value })}
                        />
                    </div>
                    <div className='mt-4'>
                        <div className="flex justify-end items-end">
                            <button
                                className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-rose-400'
                                disabled={disabled}
                                onClick={SendEmail}
                            >
                                {disabled ? "Sending..." : "Send Email"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Email;
