import { TextField } from "@material-ui/core";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import { useFormik, FormikHelpers } from "formik";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { message, Select } from "antd";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { CloseOutlined } from "@material-ui/icons";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

// Define the types for the props
interface Department {
  value: string;
  label: string;
}

interface MemberData {
  id: string;
  name?: string;
  designation?: string;
  department?: {
    _id: string;
    name: string;
  };
  expandData?: string;
  order?: number;
  socials?: {
    linkedInURL?: string;
    instagramURL?: string;
    facebookURL?: string;
    twitterURL?: string;
  };
  imageURL?: string;
}

interface UpdateMemberProps {
  opened: boolean;
  setOpened: (opened: boolean) => void;
  data: MemberData | null;
  setData: (data: MemberData) => void;
  departmentData: Department[];
  fetchTeamMembers: () => void;
}

// Define the types for form values
interface FormValues {
  Name: string;
  department: Department | null;
  position: string;
  introduction: string;
  order: number;
  linkedin?: string;
  facebook?: string;
  instagram?: string;
  twitter?: string;
  image: File | null;
  imageURL: string;
}

export function UpdateMember({
  opened,
  setOpened,
  data,
  setData,
  departmentData,
  fetchTeamMembers,
}: UpdateMemberProps) {
  const imageWidthAndHeight = (
    provideFile: File
  ): Promise<{ width: number | null; height: number | null }> => {
    const imgDimensions = { width: null, height: null };

    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.readAsDataURL(provideFile);
      reader.onload = function () {
        const img = new Image();
        img.src = reader.result as string;

        img.onload = function () {
          imgDimensions.width = img.width;
          imgDimensions.height = img.height;

          resolve(imgDimensions);
        };
      };
    });
  };

  const imageDimensionCheck = yup.addMethod(
    yup.mixed,
    "imageDimensionCheck",
    function (message: string, requiredWidth: number, requiredHeight: number) {
      return this.test(
        "image-width-height-check",
        message,
        async function (value: File) {
          const { path, createError } = this;

          if (!value) {
            return true;
          }

          const imgDimensions = await imageWidthAndHeight(value);

          const minHeight = requiredHeight - 10;
          const maxHeight = requiredHeight + 5;

          if (
            imgDimensions.height! < minHeight ||
            imgDimensions.height! > maxHeight
          ) {
            return createError({
              path,
              message: `The image height should be between ${minHeight} and ${maxHeight} pixels`,
            });
          }

          if (imgDimensions.width !== requiredWidth) {
            return createError({
              path,
              message: `The image width should be ${requiredWidth} pixels`,
            });
          }

          return true;
        }
      );
    }
  );

  const validationSchema = yup.object({
    Name: yup.string().required("Name is required"),
    position: yup.string().required("Position is required"),
    department: yup.mixed<Department>().required("Department is required"),
    introduction: yup.string().required("Introduction is required"),
    order: yup.number().required("Order number is required"),
    linkedin: yup.string().url("Enter a valid URL"),
    facebook: yup.string().url("Enter a valid URL"),
    instagram: yup.string().url("Enter a valid URL"),
    twitter: yup.string().url("Enter a valid URL"),
    image: yup
      .mixed<File>()
      .test("fileFormat", "Invalid file format", function (value) {
        if (value) {
          const validTypes = ["image/jpeg", "image/jpg", "image/png"];
          if (!validTypes.includes(value.type)) {
            return false;
          }
        }
        return true;
      })
      .nullable(),
    // .imageDimensionCheck("test", 400, 600),
  });

  const handleClose = () => {
    setOpened(false);
  };

  const handleSocial = (
    facebook?: string,
    linkedin?: string,
    instagram?: string,
    twitter?: string
  ) => {
    let socials: { [key: string]: string } = {};

    if (facebook) {
      socials.facebookURL = facebook;
    }
    if (linkedin) {
      socials.linkedInURL = linkedin;
    }
    if (instagram) {
      socials.instagramURL = instagram;
    }
    if (twitter) {
      socials.twitterURL = twitter;
    }
    return JSON.stringify(socials);
  };

  const fetchDepartments = async () => {
    try {
      const res = await apiRequest("getAllDepartments");
      if (res.status === 200) {
        if (res.data.length > 0) {
          const updatedRow = res.data.map((item: any) => ({
            value: item._id,
            label: item.name,
          }));
          // setDepartmentData(updatedRow);
        }
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    fetchDepartments();
  }, []);

  const initialValues = (): FormValues => ({
    Name: data?.name || "",
    department:
      {
        value: data?.department?._id || "",
        label: data?.department?.name || "",
      } || null,
    position: data?.designation || "",
    introduction: data?.expandData || "",
    order: data?.order || 0,
    linkedin: data?.socials?.linkedInURL || "",
    instagram: data?.socials?.instagramURL || "",
    facebook: data?.socials?.facebookURL || "",
    twitter: data?.socials?.twitterURL || "",
    image: null,
    imageURL: data?.imageURL || "",
  });

  useEffect(() => {
    if (data) {
      formik.setValues(initialValues());
    }
  }, [data]);

  const formik = useFormik<FormValues>({
    initialValues: initialValues(),
    validationSchema: validationSchema,
    onSubmit: async (
      values: FormValues,
      { resetForm }: FormikHelpers<FormValues>
    ) => {
      let socials = handleSocial(
        values.facebook,
        values.linkedin,
        values.instagram,
        values.twitter
      );

      if (values.image) {
        const reader = new FileReader();
        reader.readAsDataURL(values.image);
        reader.onload = async () => {
          const base64Image = reader.result as string;
          const json = {
            name: values.Name,
            designation: values.position,
            department: values.department?.value,
            aboutMe: values.introduction,
            order: values.order,
            image: base64Image,
            socials: socials,
          };

          try {
            const res = await apiRequest("updateTeamMember", json, [data!.id]);
            if (res?.status === 200) {
              message.success("Team Member Updated Successfully");
              fetchTeamMembers();
            }
            setData(res.data.teamMember);
          } catch (error: any) {
            message.error(error?.response?.data?.message || error?.message);
          }
        };
      } else {
        const json = {
          name: values.Name,
          designation: values.position,
          department: values.department?.value,
          aboutMe: values.introduction,
          order: values.order,
          socials: socials,
        };

        try {
          const res = await apiRequest("updateTeamMember", json, [data?.id]);
          if (res?.status === 200) {
            message.success("Team Member Updated Successfully");
            fetchTeamMembers();
          }
        } catch (error: any) {
          message.error(error?.response?.data?.message || error?.message);
        }
      }

      resetForm();
      handleClose();
    },
  });

  return (
    <div>
      <CustomAntdModal
        isOpenModal={opened}
        title={"Update Team Member Details"}
        setIsOpenModal={setOpened}
        formik={formik}
      >
        <form
          id="login-form"
          className="flex flex-col"
          onSubmit={formik.handleSubmit}
        >
          <CustomFormField
            type="text"
            label="Name"
            name="Name"
            formik={formik}
          />
          <CustomFormField
            type="select"
            label="Department"
            name="department"
            formik={formik}
            options={departmentData}
          />
          <CustomFormField
            type="number"
            label="Position"
            name="position"
            formik={formik}
          />
          <CustomFormField
            type="textarea"
            label="Introduction"
            name="introduction"
            formik={formik}
          />
          <CustomFormField
            type="number"
            label="Order"
            name="order"
            formik={formik}
          />
          <CustomFormField
            type="text"
            label="Facebook URL"
            name="facebook"
            formik={formik}
          />
          <CustomFormField
            type="text"
            label="LinkedIn URL"
            name="linkedin"
            formik={formik}
          />
          <CustomFormField
            type="text"
            label="Instagram URL"
            name="instagram"
            formik={formik}
          />
          <CustomFormField
            type="text"
            label="Twitter URL"
            name="twitter"
            formik={formik}
          />
          <div>
            <label htmlFor="image">Image:</label>
            <input
              id="image"
              name="image"
              type="file"
              accept="image/*"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                if (event.target.files) {
                  formik.setFieldValue("image", event.target.files[0]);
                }
              }}
            />
            {formik.touched.image && formik.errors.image && (
              <div className="ant-form-item-explain ant-form-item-explain-error">
                {formik.errors.image as string}
              </div>
            )}
            {formik.values.imageURL && (
              <div>
                <img src={formik.values.imageURL} alt="Current" />
              </div>
            )}
          </div>
        </form>
      </CustomAntdModal>
    </div>
  );
}
