import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import { useEffect, useState } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import Overview from './components/tabs/overview';
import SelectFeatures from 'shared-components/select-features';
import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import SelectTestimonials from 'shared-components/select-testimonials';
import FAQs from './components/tabs/faqs';
import TagScreen from 'shared-pages/plans/components/tags';

export default function PackageEdit() {

    const { id } = useParams();
    const [packageData, setPackageData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [FeatureData, setFeatureData] = useState([]);
    const [testimonialsData, setTestimonialsData] = useState([]);

    const fetchPackage = () => {
        setIsLoading(true);
        PackagesAPI("getPackageById", [id]).then((response) => {
            setPackageData(response.data.package);
            setIsLoading(false);
        }).catch((error) => {
            setIsLoading(false);
            toast.error("Failed to fetch package");
        });
    }
    const getAllFeatures = async () => {
        const res = await apiRequest("getFeatures");
        if (res?.status === 200) {
          const featureData = res?.data?.courseFeatures;
          const formatedData = featureData.map((item: any) => {
            return {
              ...item,
              key: item._id,
            };
          });
          setFeatureData(formatedData);
        }
      };
    
      const fetchAllTestimonials = async () => {
        try {
          const res = await apiRequest("getTestimonials");
          if (res?.status === 200) {
            const data = res?.data?.Testimonials;
            const formattedData = data.map((item) => {
              return {
                ...item,
                videothumbnailUrl: item.video?.thumbnailUrl,
                videoUrl: item.video?.videoUrl,
                pictureUrl: item.text?.pictureUrl,
                description: item.text?.description,
                tags: item.text?.tags,
                key: item._id,
              };
            });
            setTestimonialsData(formattedData);
          }
        } catch (error) {
        //   message.error(error?.response?.data?.message || error?.message);
    
          console.error(error);
        }
      };
    useEffect(() => {
        fetchPackage();
        getAllFeatures();
        fetchAllTestimonials();
    }, []);


    const [toggles, setToggles] = useState("Overview");
    const screens = [
        "Overview",
        "Features",
        "Testimonials",
        "FAQs",
        "Tags",
    ]



    return (
        <div className="w-full pb-20 px-5">
            {isLoading ? <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
                <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ top: "50%" }}>
                    <i className="fas fa-circle-notch fa-spin fa-5x"></i>
                </span>
            </div> :
                <>
                    <div className="flex items-center justify-start mt-5 border gap-2 mb-5">
                        {screens.map((screen, index) => (
                            <div key={index} className={`cursor-pointer ${toggles === screen ? "bg-primary text-white" : "bg-white text-gray-700"} p-2 rounded text-md font-bold`} onClick={() => { setToggles(screen) }}>{screen}</div>
                        ))}
                    </div>

                    {toggles === "Overview" && 
                        <Overview 
                            packageData={packageData} 
                            setPackageData={setPackageData} 
                            id={id} 
                            fetchPackage={fetchPackage} 
                        />
                    }
                    {toggles === "Features" && 
                        <SelectFeatures
                        featuresData={FeatureData}
                        updateApi="updatePackageById"
                        data={packageData}
                        fetchData={fetchPackage}
                       
                        // loading={loading}
                      />
                    }
                    {toggles === "Testimonials" && 
                        <SelectTestimonials
                        data={testimonialsData}
                        updateSelectedTestimonialsApi={"updatePackageById"}
                        id={id}
                        getData={fetchPackage}
                        // loading={loading}
                        // setLoading={setLoading}
                        storedSelectedTestimonials={packageData?.selectedTestimonials}
                      />
                    }
                    {toggles === "FAQs" && 
                        <FAQs
                        packageData={packageData}
                        fetchPackage={fetchPackage}
                        id={id}
                      />
                    }
                    {toggles === "Tags" && 
                        <TagScreen
                        planData={packageData} getPlanData={fetchPackage}
                        updateTagApi="updatePackageTag"
                        updateTagPublishApi="updatePackageTagPublish"    
                        addTagApi="addPackageTag"                   
                      />
                    }



                </>
            }


            <Toaster />
        </div>
    )
}


