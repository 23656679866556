import { PlusOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import React from 'react'
import CustomFormField from 'shared-components/form-fields/custom-form-field';
import CustomAntdModal from 'shared-components/modal/CustomAntdModal';

import { FormikProps } from 'formik';
import { getBase64ImageData } from 'utility/form/functions';

interface TagModalProps {
  formik: FormikProps<any>;
  accessTags: string[];
  isTagAddModel: boolean;
  isEditMode: boolean;
  setIsTagAddModel: (value: boolean) => void;
  file: any ;
  setVideoPreviewUrl: (url: string) => void;
  videoPreviewUrl: string | null;
    setFile: (file: any) => void;
}

const TagModal: React.FC<TagModalProps> = ({
  formik,
  accessTags,
  isTagAddModel,
  isEditMode,
  setIsTagAddModel,
  file,
  setVideoPreviewUrl,
  videoPreviewUrl,
  setFile
}) => {
    const {values, errors, touched, setFieldValue} = formik;
    const handleFileChangeVideo = (event) => {
        const file = event.currentTarget.files[0];
        if (file) {
          setFieldValue("videoUrl", file);
          const videoUrl = URL.createObjectURL(file);
          setVideoPreviewUrl(videoUrl);
        }
      };
      const clearSelectedVideo = () => {
        // setFieldValue("", null);
        setFieldValue("videoUrl", null);
        setVideoPreviewUrl(null);
        const fileInput = document.getElementById("videoFile") as HTMLInputElement;
        if (fileInput) {
          fileInput.value = "";
        }
      };
    const uploadButtonStudentPicture = (
        <div>
          <PlusOutlined />
          <div style={{ marginTop: 8 }}>Upload Icon</div>
        </div>
      );
      const handleChangeImage = async (info) => {
        if (info?.file?.status === "removed") {
          setFieldValue("iconLink", "");
          setFile("");
        } else {
          const base64 = await getBase64ImageData(info?.file);
          setFieldValue("iconLink", base64);
          setFile(base64);
        }
      };
  return (
    <div>
         <CustomAntdModal  isOpenModal={isTagAddModel}
    title={isEditMode ? `Edit Pricing` : `Add Pricing `}
    setIsOpenModal={setIsTagAddModel} formik={formik}>
          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full ">
              <div className="relative">
                <CustomFormField type='text' label='Feature Name' formik={formik} name="featureName" />
              </div>
            </div>
          </div>

          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full ">
              <div className="relative">
                <CustomFormField type='select' mode='multiple' label='Tags' formik={formik} name="tags" options={accessTags?.map((item) => ({
                    label: item,
                    value: item,
                  }))} />
              </div>
            </div>
          </div>

          <div className="md:p-2 w-full">
            <div className="relative">
           
              <CustomFormField type='textarea' label='Description' formik={formik} name="description" />
            </div>
          </div>

          <div className="md:p-2 w-full mdm:w-1/2">
            <Upload
              listType="picture-card"
              fileList={file ? [{ uid: "-1",name:"file", url: file }] : []}
              onChange={handleChangeImage}
              accept="image/*"
              maxCount={1}
              beforeUpload={() => false}
            >
              { uploadButtonStudentPicture}
            </Upload>
            {touched?.iconLink && errors?.iconLink && (
              <div className="text-red-700">
               {errors?.iconLink as string}
              </div>
            )}
          </div>

          <div className="md:p-2 w-full md:w-1/2">
            <div className="flex flex-col">
              <div className="flex flex-row  items-center  bg-gray-100 p-2 rounded-md justify-center">
                <div className="flex w-1/2 flex-col items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  <button
                    className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                    onClick={(event) => {
                      event.preventDefault();
                      document.getElementById("videoFile").click();
                    }}
                  >
                    Upload Video
                  </button>
                  <p>File accepted: Video mp4</p>

                  <input
                    type="file"
                    id="videoFile"
                    name="videoFile"
                    accept="video/*"
                    className="hidden"
                    onChange={(event) => {
                      handleFileChangeVideo(event);
                    }}
                  />
                </div>
                {isEditMode && values?.videoUrl && !videoPreviewUrl ? (
                  <>
                    <div className="w-1/2 flex flex-col justify-center items-center gap-2">
                      <iframe title='video' className="w-52" src={values?.videoUrl}></iframe>

                      {isEditMode && values?.videoUrl && (
                        <button
                          className="border  hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 my-0  px-4 rounded border-rose-500"
                          onClick={clearSelectedVideo}
                        >
                          Remove Video
                        </button>
                      )}
                    </div>
                  </>
                ) : (
                  <>
                    {videoPreviewUrl && (
                      <div className="flex flex-col w-1/2 h-40 items-center gap-2 my-4  bg-gray-50 ">
                        <video
                          controls
                          className="h-40 w-40"
                          style={
                            {
                              //   maxHeight: "240px",
                            }
                          }
                        >
                          <source src={videoPreviewUrl} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                        <div className="">
                          <button
                            className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                            onClick={clearSelectedVideo}
                          >
                            Remove Video
                          </button>
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
            <div className="flex justify-center">
              {touched?.videoUrl && errors?.videoUrl && (
                <div className="text-red-700">
                 {errors?.videoUrl as string}
                </div>
              )}
            </div>
          </div>

        
      </CustomAntdModal>
    </div>
  )
}

export default TagModal
