
import * as Yup from "yup";
export const validationSchema = Yup.object({
    topicName: Yup.string().required("Name is required"),
    //   ? Yup.string().required("Name is required")
    //   : //   .test("unique", "topicName name already exist.", function (value) {
        //     const existingTopicsExceptCurrent = topicsData?.filter(
        //       (topicId) => topicId._id !== values?.topicEditId
        //     );
        //     const existingTopicNames = existingTopicsExceptCurrent?.map(
        //       (topic) => topic.topicName && topic.metadata?.entity === value && topic.metadata?.category === value
        //     );
        //     return !existingTopicNames?.includes(value);
        //   }
        // )
        // Yup.string().required("Name is required"),
    // .test("unique", "topic name already exist.", function (value) {
    //   const existingTopicNames = topicsData?.map((topic) =>
    //     topic.topicName?.toLowerCase()
    //   );
    //   return !existingTopicNames?.includes(value?.toLowerCase());
    // })
    // subTopics: Yup.array().of(
    //   Yup.object().shape({
    //     subTopicName: Yup.string()
    //       // .required("sub topic is required")
    //       .min(4, "sub topic must be at least 4 characters"),
    //   })
    // ),
    logo: Yup.mixed()
      .required("Logo is required")
      .test("is-valid-type", "Invalid image type", function (value) {
        const newVal = value?.[0]?.type;
        if (!value?.[0]) {
          return this.createError({
            message: "Please upload a logo image",
          });
        }
        if (newVal) {
          return ["image/jpeg", "image/jpg", "image/png"].includes(
            newVal?.toLowerCase()
          );
        }
        return true;
      }),
    modules: Yup.array()
      .nullable()
      .test("required", "Modules is required", function (value) {
        const category = process.env.REACT_APP_CATEGORY;
        if ( category === "MEDSCHOOL") {
          return value?.length > 0;
        }
        return true;
      }),
  });