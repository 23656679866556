import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    BarChart,
    Bar,
    ResponsiveContainer,
} from 'recharts';
import { GetBackendUrlForCategory } from 'constants/config';

// API Endpoint for fetching data
const API_URL = 'YOUR_API_ENDPOINT_HERE';

const OrderStatistics = () => {
    const [data, setData] = useState(null);

    const [orders, setOrderData] = useState({
        PreMedTotalDiscountAmount: 0,
        PreMedTotalPaidAmount: 0,
        PreMedTotalOrders: 0,
        PreMedTotalCommisions: 0,
    })



    useEffect(() => {
        // Fetch data from API
        const fetchData = async () => {
            try {
                const response = await axios.get(GetBackendUrlForCategory('PRE-MEDICAL') + '/api/finance/get-order-statistics')
                console.log(response.data);
                setData(response.data);                 

                // Set order data
                setOrderData({
                    PreMedTotalDiscountAmount: response.data.TotalDiscountAmount,
                    PreMedTotalPaidAmount: response.data.TotalPaidAmount,
                    PreMedTotalOrders: response.data.TotalOrders,
                    PreMedTotalCommisions: response.data.TotalCommisions,
                })
            } catch (error) {
                console.error('Error fetching data', error);
            }
        };
        fetchData();
    }, []);

    if (!data) {
        return <div>Loading...</div>;
    }

    // Process data for year-based statistics
    const yearData = data.yearDivisions.map((item) => ({
        year: item.year,
        TotalDiscountAmount: item.TotalDiscountAmount.toFixed(),
        TotalPaidAmount: item.TotalPaidAmount.toFixed(),
        couponsUsed: item.couponsUsed,
        commisions: item.commisions,
    }));

    // Process data for month-based statistics
    const monthData = data.monthDivisions.map((item) => ({
        month: `${item.month + 1}/${item.year}`, // Display as Month/Year
        TotalDiscountAmount: item.TotalDiscountAmount.toFixed(),
        TotalPaidAmount: item.TotalPaidAmount.toFixed(),
        couponsUsed: item.couponsUsed,
        commisions: item.commisions,
    }));


    return (
        <div>
            <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                <h2 className='font-bold text-xl text-gray-800'>Order Statistics</h2>
                <div className='grid grid-cols-2 gap-4'>
                    <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                        <h2 className='font-bold text-lg text-gray-600'>Total Orders</h2>
                        <p className='text-2xl font-bold text-gray-800'>{orders.PreMedTotalOrders.toFixed()}</p>
                    </div>
                    <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                        <h2 className='font-bold text-lg text-gray-600'>Total Paid Amount</h2>
                        <p className='text-2xl font-bold text-gray-800'>{orders.PreMedTotalPaidAmount.toFixed()}</p>
                    </div>
                    <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                        <h2 className='font-bold text-lg text-gray-600'>Total Discount Amount</h2>
                        <p className='text-2xl font-bold text-gray-800'>{orders.PreMedTotalDiscountAmount.toFixed()}</p>
                    </div>
                    <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                        <h2 className='font-bold text-lg text-gray-600'>Total Commisions</h2>
                        <p className='text-2xl font-bold text-gray-800'>{orders.PreMedTotalCommisions.toFixed()}</p>
                    </div>
                </div>
            </div>

            <div className='grid grid-cols-2 gap-4'>
                <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                    <h2 className='font-bold text-xl text-gray-800'>Yearly Order Statistics</h2>
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart data={yearData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="year" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Line type="monotone" dataKey="TotalDiscountAmount" stroke="#00D9FFFF" label="Total Discount Amount" />
                            <Line type="monotone" dataKey="TotalPaidAmount" stroke="#FF0077FF" label="Total Paid Amount" />
                            <Line type="monotone" dataKey="commisions" stroke="#6FA823FF" label="Commisions" />
                        </LineChart>
                    </ResponsiveContainer>
                </div>

                <div className='bg-white p-4 rounded-lg shadow-md flex flex-col gap-2'>
                    <h2 className='font-bold text-xl text-gray-800'>Monthly Order Statistics</h2>
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={monthData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="month" />
                            <YAxis />
                            <Tooltip />
                            <Legend />
                            <Bar dataKey="TotalDiscountAmount" fill="#00D9FFFF" />
                            <Bar dataKey="TotalPaidAmount" fill="#FF0077FF" />
                            <Bar dataKey="commisions" fill="#6FA823FF" />
                        </BarChart>
                    </ResponsiveContainer>
                </div>





            </div>
        </div>
    );
};

export default OrderStatistics;