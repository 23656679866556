import React, { ReactNode } from "react";
import { ButtonProps, ConfigProvider, message, Modal } from "antd";
import { FormikProps } from "formik";
import { flattenErrors } from "./functions";

interface CustomAntdModalProps {
  isOpenModal: boolean;
  fromEmail?: boolean;
  setIsOpenModal: (isOpen: boolean) => void;
  children?: ReactNode;
  title?: string;
  footer?: ReactNode | null;
  isFooter?: boolean;
  okText?: string;
  width?: number;
  headerBg?: string;
  titleColor?: string;
  handleOnOk?: () => void;
  okButtonProps?: ButtonProps;
  formik?: FormikProps<any>;
}
const CustomAntdModal: React.FC<CustomAntdModalProps> = ({
  isOpenModal,
  setIsOpenModal,
  children,
  title,
  isFooter = true,
  okButtonProps,
  handleOnOk,
  okText = "Save",
  width = 1000,
  formik,
}) => {
  const handleCloseModal = () => {
    setIsOpenModal(false);
  };
  const finalOkButtonProps = formik
    ? {
        ...okButtonProps,
        loading: formik.isSubmitting,
        disabled: formik.isSubmitting,
      }
    : okButtonProps;
    const handleOkClicked = () => {
      if (formik) {
        console.log("formik", formik.isValid);
        if (formik.isValid) {
          formik.submitForm();
        } else {
          const errors = flattenErrors(formik.errors);
          if (errors.length > 0) {
            console.log("errors", errors);
            message.error(errors?.[0] as string); // Display the first error
          }
        }
      } else {
        handleOnOk();
      }
    };
    
  return (
    <div>
      <ConfigProvider
        theme={{
          token: {
          },
          components: {
            Modal: {
            },
          },
        }}
      >
        <Modal
          className="global-ant-modal"
          title={title}
          open={isOpenModal}
          onOk={handleOkClicked}
          okButtonProps={{ ...finalOkButtonProps, danger: true }}
          footer={isFooter ? undefined : null}
          okText={okText}
          //   okType="submit"
          onCancel={handleCloseModal}
          width={width}
        >
          {children}
        </Modal>
      </ConfigProvider>
    </div>
  );
};

export default CustomAntdModal;
