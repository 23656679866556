import { apiRequest } from "(apis)/api-interface/api-handler";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import Loader from "shared-components/Loader";
import AssignmentCard from "./AssignmentCard";
import { useParams } from "react-router-dom";
import { message } from "antd";

interface AssignmentDetails {
  name: string;
  submissionDate: string;
  description: string;
  pdfUrls: string[];
}

interface Assignment {
  studentName: string;
  assignmentDetails: AssignmentDetails;
}

const ManageAssignment: React.FC = () => {
  const [assignmentData, setAssignmentData] = useState<Assignment[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { assignmentId } = useParams<{ assignmentId: string }>();

  const fetchSubittedAssignmentByAssignmentId = async () => {
    try {
      const response = await apiRequest(
        "fetchSubmittedAssignmentsByAssignmentId",
        null,
        [assignmentId]
      );
      if (response.data?.assignments.length > 0) {
        setAssignmentData(response.data?.assignments);
        setLoading(false);
      }
    } catch (err) {
      message.error(err?.response?.data?.message || err?.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSubittedAssignmentByAssignmentId();
  }, []);

  const filteredAssignments = assignmentData.filter((assignment) =>
    assignment.studentName.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div>
      <Loader loading={loading} />
      {assignmentData?.length > 0 ? (
        <div>
          <div className="flex justify-start flex-col mx-8 shadow-md bg-white px-4">
            <div className="flex flex-row">
              <div className="w-full md:w-1/3">
                <span className="text-lg font-bold">Assignment Name: </span>
                {assignmentData?.[0]?.assignmentDetails?.name
                  ?.charAt(0)
                  .toUpperCase() +
                  assignmentData?.[0]?.assignmentDetails?.name?.slice(1)}
              </div>
              <div className="w-full md:w-1/3">
                <span className="text-lg font-bold">Submission Date: </span>
                {dayjs(
                  assignmentData?.[0]?.assignmentDetails?.submissionDate
                )?.format("dddd Do MMMM, [at] h:mm A")}
              </div>
              <div className="w-full md:w-1/3">
                <div className="flex flex-row gap-3">
                  <span className="text-lg font-bold">Assignments Pdf: </span>
                  <div className="flex flex-row gap-2">
                    {assignmentData?.[0]?.assignmentDetails?.pdfUrls?.map(
                      (link, index) => (
                        <a
                          href={link}
                          target="_blank"
                          className="text-indigo-500 inline-flex items-center"
                          key={index}
                          rel="noopener noreferrer"
                        >
                          {`File${index + 1}`}
                        </a>
                      )
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span className="text-lg font-bold">Descriptions: </span>
              {assignmentData?.[0]?.assignmentDetails?.description}
            </div>
            <div className="flex justify-center items-center mb-4">
              <input
                type="text"
                placeholder="Search by student name"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="w-2/3 bg-opacity-50 rounded border focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base font-normal outline-none my-1 py-0 px-3 leading-7 transition-colors duration-200 ease-in-out"
              />
            </div>
          </div>

          <section className="text-gray-600 body-font">
            <div className="container px-5 py-5 mx-auto">
              <div className="flex flex-wrap -m-4">
                {filteredAssignments?.map((item, index) => (
                  <AssignmentCard
                    key={index}
                    assignment={item}
                    fetchSubittedAssignmentByAssignmentId={
                      fetchSubittedAssignmentByAssignmentId
                    }
                  />
                ))}
              </div>
            </div>
          </section>
        </div>
      ) : (
        <h1>Assignments are not submitted yet.</h1>
      )}
    </div>
  );
};

export default ManageAssignment;
