import { useEffect, useState } from "react";
import { Select } from "antd";
import toast, { Toaster } from "react-hot-toast";
import { DeckAPI } from "../../../(apis)/(shared-apis)/academics/decks-api";
import { ButtonFill } from "../../../shared-components/ui/CustomButtons";
import TableView from "./components/table-view";

export default function Page() {
  const [decks, setDecks] = useState([]);
  const [selectedDeck, setSelectedDeck] = useState("");

  useEffect(() => {
    const fetchDeckNames = async () => {
      try {
        const res = await DeckAPI("getDeckNames");
        if (res?.status === 200) {
          setDecks(res?.data.data);
        }
      } catch (error) {
        console.error("Error fetching deck names:", error);
      }
    };

    fetchDeckNames();
  }, []);

  const [loading, setLoading] = useState(false);
  const [attempts, setAttempts] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const Search = async () => {
    setLoading(true);
    try {
      const result = await DeckAPI("getDeckStatistics", selectedDeck);
      if (result.data.success) {
        toast.success("Deck Statistics fetched successfully");
        setAttempts(result.data.data);
        setFilteredUsers(result.data.data);
      } else {
        toast.error("Error fetching deck statistics");
      }
    } catch (err) {
      console.error("Error fetching deck statistics:", err);
      toast.error("Error fetching deck statistics");
    }
    setLoading(false);
  };

  const DownloadResultsInCSV = () => {
    if (attempts.length === 0) {
      toast.error("No data to download");
      return;
    }


    const csv = attempts.map((attempt) => {
      return {
        "Full Name": attempt.fullname,
        "Email": attempt.username,
        "Phone": attempt.phone,
        "City": attempt.city,
        "Province": attempt.province,
        "Correct Attempts": attempt.correctAttempts,
        "Incorrect Attempts": attempt.incorrectAttempts,
        "Skipped Attempts": attempt.skippedAttempts,
        "Time Taken": (attempt.totalTimeTaken / 60).toFixed(2),
        "Mode": attempt.attemptMode,
        "Education Level": attempt.educationLevel,
        "Account Created": attempt.accountCreated,
        "Features Purchased": attempt.featuresPurchased
          ? attempt.featuresPurchased.map((feature) => feature.planName).join(", ")
          : "",        
      };
    });

    const csvData = csv.map((row) =>
      Object.values(row).map((value) => `"${value}"`).join(",")
    );

    const csvRows = ["Full Name,Email,Phone,City,Province,Correct Attempts,Incorrect Attempts,Skipped Attempts,Time Taken,Mode"].concat(
      csvData
    );

    const csvString = csvRows.join("\n");

    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([csvString], { type: "text/csv" }));
    a.setAttribute("download", "deck-statistics.csv");
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };


  const [showFreeUsers, setShowFreeUsers] = useState(false);
  const FilterFreeUsers = (showFreeUsers) => {
    if (showFreeUsers) {
      setFilteredUsers(attempts);
    } else {
      setFilteredUsers(attempts.filter((user) => user.featuresPurchased && user.featuresPurchased.length === 0));
    }
    setShowFreeUsers(!showFreeUsers);
  }



  return (
    <div>
      <Toaster />

      <div className="w-full pb-20 px-5">
        <div className="flex flex-col pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Deck Statistics</h2>
          <div className="flex justify-between mt-5">
            <div className="w-full flex flex-col md:flex-row items-center gap-4">
              <label
                htmlFor="deck"
                className="text-lg font-semibold text-gray-700"
              >
                Select Deck
              </label>
              <div className="flex items-center">
                <Select
                  showSearch
                  style={{ width: 300, height: 40 }}
                  placeholder="Select a Deck"
                  optionFilterProp="children"
                  onChange={(value) => setSelectedDeck(value)}
                >
                  {decks.length > 0 &&
                    decks.map((deck) => (
                      <Select.Option key={deck._id} value={deck._id}>
                        {deck.deckName}
                      </Select.Option>
                    ))}
                </Select>
                <div className="flex items-center ml-4">
                  <ButtonFill
                    handleClick={Search}
                    disabled={!selectedDeck}
                  >
                    Search
                  </ButtonFill>
                </div>
              </div>
              <div className="flex items-center">
                <ButtonFill
                  handleClick={DownloadResultsInCSV}
                  disabled={attempts.length === 0}
                >
                  Download Results
                </ButtonFill>
              </div>
              <div className="flex items-center">
                <ButtonFill
                  handleClick={() => FilterFreeUsers(showFreeUsers)}
                >
                  {showFreeUsers ? "Show All Users" : "Show Free Users"}
                </ButtonFill>
              </div>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-3   my-4">
          <div className="rounded-xl border border-gray-50 shadow-md h-24 flex flex-col text-gray-700 justify-center items-center text-lg md:text-xl font-semibold">
            Total Attempts : {filteredUsers.length}
          </div>
          <div className="rounded-xl border border-gray-50 shadow-md h-24 flex flex-col text-gray-700 justify-center items-center text-lg md:text-xl font-semibold">
            Total Test Attempts :{" "}
            {
              filteredUsers.filter((attempt) => attempt.attemptMode === "TESTMODE")
                .length
            }
          </div>
          <div className="rounded-xl border border-gray-50 shadow-md h-24 flex flex-col text-gray-700 justify-center items-center text-lg md:text-xl font-semibold">
            Total Practice Attempts :{" "}
            {
              filteredUsers.filter((attempt) => attempt.attemptMode === "TUTORMODE")
                .length
            }
          </div>
        </div>
        <TableView attempts={filteredUsers} loading={loading} />
      </div>
    </div>
  );

}
