import dayjs from 'dayjs';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

// Define the expected type for the data
interface Last7DaysBarChartData {
  _id: string; // Assuming _id is part of your original data
  total: number;
  time?: string; // Optional, as it’s derived from _id
}

// Define the props for the component
interface Last7DaysBarChartProps {
  data: Last7DaysBarChartData[];
}

const Last7DaysBarChart: React.FC<Last7DaysBarChartProps> = ({ data }) => {
  // Map the data to match the format required by the BarChart
  const formattedData = data?.map((item) => ({
    ...item,
    time: dayjs(item._id).format('DD/MM'), // Assuming _id holds the time or date
  }));

  return (
    <div>
      <BarChart width={800} height={400} data={formattedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default Last7DaysBarChart;
