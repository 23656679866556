import { Image, message, Space } from "antd";
import { useEffect, useState } from "react";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { useFormik } from "formik";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { getBase64ImageData } from "utility/form/functions";
import * as Yup from "yup";
import { initialValues } from "./initialValues";
import TestimonialModal from "./modal";
import { columnsOfTestimonials } from "./columns";

const ManageTestimonials = () => {
  const [isAddModalVisible, setIsAddModalVisible] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [isFetchingTestimonials, setIsFetchingTestimonials] = useState(false);
  // antd image
  const [file, setFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);

  const [boardValues, setBoardValues] = useState([]);

  const GetBoards = async () => {
    const response = await apiRequest("getHierarchies");
    let uniqueBoards = [];
    response.data.map((item) => {
      uniqueBoards.push(item.board);
    });
    setBoardValues(uniqueBoards);
  };

  const columns = [
   ...columnsOfTestimonials,
    {
      title: "is Active",
      dataIndex: "isPublish",
      key: "isPublish",
      render: (data) => {
        return (
          <div>
            <GlobalPublishButton
              onChange={() => handlePublish(data)}
              isActive={data?.isPublish}
            />
          </div>
        );
      },
    },
    {
      title: "Actions",
      key: "actions",
      render: (data) => (
        <Space size="middle">
          <div className="flex flex-row gap-2 items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-7 h-7 cursor-pointer text-black hover:text-rose-200"
              onClick={() => handleEditTestimonial(data)}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
              />
            </svg>
          </div>
        </Space>
      ),
    },
  ];

  const fetchTestimonials = async () => {
    try {
      setIsFetchingTestimonials(true);
      const res = await apiRequest("getTestimonials");
      if (res?.status === 200) {
        const data = res?.data?.Testimonials;
        const formattedData = data.map((item) => {
          return {
            ...item,
            isPublish: {
              id: item._id,
              isPublish: item.isPublish,
            },
            videothumbnailUrl: item.video?.thumbnailUrl,
            videoUrl: item.video?.videoUrl,
            pictureUrl: item.text?.pictureUrl,
            description: item.text?.description,
            tags: item.text?.tags,
            key: item._id,
          };
        });
        setTestimonialsData(formattedData);
        setIsFetchingTestimonials(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message);
      setIsFetchingTestimonials(false);
      console.error(error);
    }
  };
  useEffect(() => {
    fetchTestimonials();
    GetBoards();
  }, []);
  const handlePublish = async (data) => {
    try {
      const res = await apiRequest("updateTestimonialActive", null, [data?.id]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        fetchTestimonials();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
    }
  };
  // Edit Testimonial
  const handleEditTestimonial = (record) => {
    setIsAddModalVisible(true);
    setIsEditMode(true);
    setValues({
      ...record,

      clickedTestimonialId: record._id,
      text: {
        ...record.text,
        // tags: record.text?.tags?.map((tag) =>{
        //     return {
        //         label: tag,
        //         value: tag,

        //     };

        // }),
        generatedPictureUrl: record.text?.pictureUrl,
      },
      video: {
        generatedThumbnailUrl: record.video?.thumbnailUrl,
        generatedVideoUrl: record.video?.videoUrl,
      },
    });
    if (record.type === "text") {
      setFile(record.text?.pictureUrl);
    } else {
      setFile(record.video?.thumbnailUrl);
    }
  };

  // set up useFormik
  function isValidImageType(str) {
    const [, imageType] = str.match(/^data:image\/(jpeg|jpg|png);base64,/);
    return ["jpeg", "jpg", "png"].includes(imageType.toLowerCase());
  }
  const validationSchema = Yup.object().shape({
    type: Yup.string().required("Type is required"),
    name: Yup.string().required("Name is required"),
    score: Yup.string().required().max(33, "Score cannot exceed 200"),
    examName: Yup.string().required("Exam Name is required"),
    university: Yup.string().required("University is required"),
    text: Yup.object().test({
      name: "required-if-text",
      exclusive: true,
      test: function (value: any) {
        if (this.parent.type === "text") {
          const { picture, generatedPictureUrl, description } = value;
          if (!description) {
            return this.createError({
              path: "text.description",
              message: "Description is required when type is text",
            });
          } else if (description.length < 50) {
            return this.createError({
              path: "text.description",
              message: "Description must be at least 50 characters",
            });
          }

          // else if (description.length > 200) {
          //   return this.createError({
          //     path: "text.description",
          //     message: "Description must be at most 200 characters",
          //   });
          // }
          if (!generatedPictureUrl && !picture) {
            return this.createError({
              path: "text.picture",
              message: "Picture is required when type is text",
            });
          } else if (!generatedPictureUrl && !isValidImageType(picture)) {
            return this.createError({
              path: "text.picture",
              message: "Picture must be in jpg, jpeg, or png format",
            });
          }
        }
        return true;
      },
    }),
    video: Yup.object().test({
      name: "required-if-video",
      exclusive: true,
      test: function (value: any) {
        if (this.parent.type === "video") {
          const { videoFile, generatedThumbnailUrl, generatedVideoUrl, image } =
            value;

          if (!generatedVideoUrl && !videoFile) {
            return this.createError({
              path: "video.videoFile",
              message: "Video file is required when type is video",
            });
          } else if (!generatedVideoUrl && !/\.mp4$/.test(videoFile.name)) {
            return this.createError({
              path: "video.videoFile",
              message: "Video file must be in mp4 format",
            });
          } else if (!generatedVideoUrl && videoFile.size > 100 * 1024 * 1024) {
            return this.createError({
              path: "video.videoFile",
              message: "Video file size must be less than 100MB",
            });
          }

          if (!generatedThumbnailUrl && !image) {
            return this.createError({
              path: "video.image",
              message: "Image is required when type is video",
            });
          } else if (!generatedThumbnailUrl && !isValidImageType(image)) {
            return this.createError({
              path: "video.image",
              message: "Image must be in jpg, jpeg, or png format",
            });
          }
        }
        return true;
      },
    }),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        let formattedValues = {};

        if (values.type === "text") {
          // For text-based testimonials
          formattedValues = {
            ...values,
            metadata: {
              category: process.env.REACT_APP_CATEGORY,
              entity: process.env.REACT_APP_ENTITY,
            },
            text: {
              ...values.text,
              picture: values.text.picture || values.text.generatedPictureUrl,
            },
          };
        } else {
          // For video-based testimonials
          formattedValues = {
            ...values,
            metadata: {
              category: process.env.REACT_APP_CATEGORY,
              entity: process.env.REACT_APP_ENTITY,
            },
            text:{
              description:"",
            },
            video: {
              ...values.video,
              image: values.video.image || values.video.generatedThumbnailUrl,
              videoFile:
                values.video.videoFile || values.video.generatedVideoUrl,
            },
          };
        }
        const formData = new FormData();
        // Loop through each key-value pair in values
        for (const key in formattedValues) {
          if (key === "text" || key === "video") {
            // convert object in stringify
            if (key === "video" && formattedValues[key].videoFile) {
              formData.append("videoFile", formattedValues[key].videoFile);
              delete formattedValues[key].videoFile; // Remove videoFile from the object
            }
            formData.append(key, JSON.stringify(formattedValues[key]) || "");
          } else if (key === "metadata") {
            const metadataString = JSON.stringify(formattedValues[key]) || "";
            formData.append(key, metadataString);
          } else {
            formData.append(key, formattedValues[key] || "");
          }
        }
        let res;

        // return;
        if (isEditMode) {
          res = await apiRequest("updateTestimonial", formData, [
            values.clickedTestimonialId,
          ]);
        } else {
          res = await apiRequest("addTestimonial", formData);
        }
        if (res?.status === 200) {
          setIsAddModalVisible(false);
          setIsEditMode(false);
          resetForm();
          message.success(res?.data?.message);
          fetchTestimonials();
          setFile(null);
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
        console.error(error);
      }
    },
  });

  const { setFieldValue, setValues } = formik;
  // image ant design
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64ImageData(file.originFileObj);
    }
  };

  const handleChangeImage = async (info) => {
    if (info?.file?.status === "removed") {
      setFieldValue("text.picture", "");
      setFile("");
      setFieldValue("text.generatedPictureUrl", "");
    } else {
      const base64 = await getBase64ImageData(info?.file?.originFileObj);
      setFieldValue("text.picture", base64);
      setFile(base64);
    }
  };
  const handleChangeThumbnail = async (info) => {
    if (info?.file?.status === "removed") {
      setFieldValue("video.image", "");
      setFieldValue("video.generatedThumbnailUrl", "");
      setFile("");
    } else {
      const base64 = await getBase64ImageData(info?.file?.originFileObj);
      setFieldValue("video.image", base64);
      setFile(base64);
    }
  };

  const handleFileChangeVideo = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("video.videoFile", file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const clearSelectedVideo = () => {
    setFieldValue("video.videoFile", null);
    setFieldValue("video.generatedVideoUrl", null);
    setVideoPreviewUrl(null);
    const fileInput = document.getElementById("videoFile") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleSelectChange = (field, value) => {
    setFieldValue(field, value);
  };

  return (
    <div>
      <div className="mx-4">
        <div className="flex justify-end">
          <CustomButton
            text="Add  new Testimonial"
            onClick={() => {
              setIsAddModalVisible(true);
              formik.resetForm();
            }}
          />
        </div>

        <GlobalTable
          tableName={"Manage Testimonials"}
          bordered={true}
          columns={columns}
          loading={isFetchingTestimonials}
          data={testimonialsData}
          desktopScrollWidth={1500}
          
        />
        <TestimonialModal
          handleSelectChange={handleSelectChange}
          formik={formik}
          isAddModalVisible={isAddModalVisible}
          isEditMode={isEditMode}
          clearSelectedVideo={clearSelectedVideo}
          handleChangeImage={handleChangeImage}
          handleChangeThumbnail={handleChangeThumbnail}
          handleFileChangeVideo={handleFileChangeVideo}
          handlePreview={handlePreview}
          videoPreviewUrl={videoPreviewUrl}
          file={file}
          boardValues={boardValues}
          setIsAddModalVisible={setIsAddModalVisible}
        />
      </div>
    </div>
  );
};

export default ManageTestimonials;
