import React from "react";
import { useState } from "react";
import { Switcher } from "../../../shared-components/ui/CustomForm";
import { EditIcon } from "../../../shared-components/ui/Icons";

import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import CreateModule from "./create-module-modal";
import CustomBadges from "../../../shared-components/ui/CustomBadges";
import toast, { Toaster } from "react-hot-toast";
import { ModuleAPI } from "../../../(apis)/(shared-apis)/modules-apis";

interface TableViewProps {
  currentModule: any;
  setModule: any;
}

export const TableView = ({ currentModule, setModule }: TableViewProps) => {
  const [openCreateModuleModal, setModuleModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [confirmationModalType, setConfirmationModalType] = useState("");
  const [toBeViewModules, setToBeViewModule] = useState({
    _id: "",
    moduleName: "",
    moduleImage: "",
    moduleDescription: "",
    isPublished: false,
    imageAltText: "",
  });

  const UpdateStatus = async (id: string, isPublished: boolean) => {
    const body = {
      isPublished: !isPublished,
    };

    try {
      let response = null;
      response = await ModuleAPI("updateModulePublishedStatusById", [id], body);
      if (response) {
        toast.success(`Updated Successfully`);
      }
    } catch (error) {
      console.log("Add Module Error: ", error);
    }
  };

  const ModulesPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div className="overflow-x-auto">
        <Toaster />
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">
                Module Name
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Entity
              </th>
              <th scope="col" className="px-4 py-4 text-left">
                Category
              </th>
              <th scope="col" className="px-4 py-4 text-center">
                isPublished
              </th>
              <th scope="col" className="px-4 py-3 text-center">
                Actions
              </th>
            </tr>
          </thead>
          <tbody>
            {currentModule
              ?.slice(
                (currentPage - 1) * ModulesPerPage,
                currentPage * ModulesPerPage
              )
              ?.map((module) => (
                <tr className="border-b" key={module?._id}>
                  <td className="px-4 py-3">{module?.moduleName}</td>
                  <td className="px-4 py-3 text-left">
                    <CustomBadges label={module?.metadata?.entity} />
                  </td>
                  <td className="px-4 py-3 text-left">
                    <CustomBadges
                      label={module?.metadata?.category}
                      type="warning"
                    />
                  </td>
                  <td className="px-4 py-3">
                    <div className="mx-auto w-fit">
                      <Switcher
                        isActive={undefined}
                        for={module?._id}
                        onChange={() => {
                          UpdateStatus(module?._id, module?.isPublished);
                        }}
                        togglevalue={module?.isPublished}
                        size="small"
                      />
                    </div>
                  </td>
                  <td className="relative flex items-center justify-center px-4 py-3">
                    <button
                      type="button"
                      className="flex items-center justify-center w-full px-4 py-2 space-x-2 text-gray-700 hover:bg-gray-100"
                      onClick={() => {
                        setModuleModal(true);
                        setToBeViewModule((prevState) => module);
                      }}
                    >
                      <EditIcon />
                      <span>Edit</span>
                    </button>
                  </td>
                </tr>
              ))}
          </tbody>
          <ConfirmationModal
            active={confirmationModal}
            message={
              confirmationModalType === "delete"
                ? "Are you sure you want to delete this modules?"
                : "Are you sure you want to delete all modules?"
            }
            onConfirm={() => {
              setConfirmationModal(false);
              setConfirmationModalType("");
            }}
            onCancel={() => {
              setConfirmationModal(false);
              setConfirmationModalType("");
            }}
          />
          <CreateModule
            mode="edit"
            active={openCreateModuleModal}
            setModal={setModuleModal}
            onCancel={() => {
              setModuleModal(false);
            }}
            onConfirm={() => {
              setModuleModal(false);
            }}
            module={toBeViewModules}
            setModule={setModule}
          />
        </table>
      </div>
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * ModulesPerPage - ModulesPerPage + 1}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">
            {currentModule.length}
          </span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <a
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage - 1)}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
          {Array.from({
            length: Math.ceil(currentModule.length / ModulesPerPage),
          }).map((_, index) => (
            <li key={index}>
              <a
                className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 ${
                  currentPage === index + 1
                    ? "font-semibold text-primary-600 bg-primary-50"
                    : ""
                }`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </a>
            </li>
          ))}
          <li>
            <a
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => handlePageChange(currentPage + 1)}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </>
  );
};

export default TableView;
