import { FilterQuestionApi } from "(apis)/(shared-apis)/filter-questions-apis";
import { Select } from "antd";
import { useEffect, useState } from "react";

const SubTopicsDropDown = ({
  value,
  getValue,
  noLabel = false,
  category,
  topicName,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [topicValues, setTopicValues] = useState([]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await FilterQuestionApi("getAllTopicsWithSubtopics");

      console.log("Topic Response: ", response);
      if (response) {
        const apiData =
          response?.data?.data && response?.data?.data?.length > 0
            ? response?.data?.data
            : [];
        setTopicValues(apiData);
      }
    } catch (error) {
      console.error("There was an error fetching the topics!", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  function filterTopicsByCategory(topics, selectedCategory) {
    if (!selectedCategory || selectedCategory?.trim() === "") {
      return topics;
    } else if (selectedCategory === "PRE-ENGINEERING") {
      return topics.filter(
        (topic) => topic.metadata.category === selectedCategory
      );
    } else if (selectedCategory === "MEDSCHOOL") {
      // return medschoolSubjects;
      return topics.filter(
        (topic) => topic?.metadata?.category === "MEDSCHOOL"
      );
    } else {
      return topics.filter(
        (topic) => topic.metadata.category === selectedCategory
      );
    }
  }

  const filteredTopics = filterTopicsByCategory(topicValues, category);

  return (
    <div className="my-2">
      <Select
        placeholder="Select Sub-Topic"
        showSearch
        // mode="multiple"
        disabled={isLoading}
        onChange={(value) => {
          getValue("subtopic", value);
        }}
        value={value}
        size={"large"}
        className="w-full"
      >
        {filteredTopics
          .filter((topic) => topic?.topicName === topicName)
          .map((topic) =>
            topic?.subTopics?.map((subTopic, index) => (
              <Select.Option
                key={subTopic?.subTopicName + index}
                value={subTopic?.subTopicName}
              >
                {subTopic?.subTopicName}
              </Select.Option>
            ))
          )}
      </Select>
    </div>
  );
};
export default SubTopicsDropDown;
