import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import * as Yup from "yup";

interface AssignmentCardProps {
  assignment: any;
  fetchSubittedAssignmentByAssignmentId: () => Promise<void>;
}

const AssignmentCard: React.FC<AssignmentCardProps> = ({
  assignment,
  fetchSubittedAssignmentByAssignmentId,
}) => {
  const {
    userId,
    assignmentId,
    studentName,
    submissionTime,
    uploadLinks,
    grade: initialGrade,
  } = assignment;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      grade: initialGrade,
    },
    validationSchema: Yup.object({
      grade: Yup.number()
        .required("Grade is required")
        .min(0, "Grade must be at least 0")
        .max(10, "Grade must be at most 10"),
    }),
    onSubmit: async (values) => {
      console.log("values", values);
      try {
        setIsSubmitting(true);
        const payload = {
          grade: values.grade,
        };
        const response = await apiRequest("updateAssignmentsGradeByAssignmentIdUserId",payload,
          [userId,
          assignmentId]
          
        );
        if (response.status === 200) {
          await fetchSubittedAssignmentByAssignmentId();
          message.success("Grade updated successfully");
          setIsSubmitting(false);
        }
        console.log("response", response);
      } catch (error) {
        console.log("error", error);
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className="p-4 lg:w-1/3">
      <div className="h-full flex flex-col bg-white shadow-md px-8 py-10 rounded-lg overflow-hidden text-start relative">
        <h2 className="tracking-widest text-sm title-font font-medium mb-1">
          {dayjs(submissionTime)?.format("dddd Do MMMM, [at] h:mm A")}
        </h2>
        <h1 className="title-font sm:text-2xl text-xl font-medium text-gray-900 mb-3">
          {studentName}
        </h1>
        <div className="flex flex-col gap-3">
          <div>Submitted Assignments</div>
          {uploadLinks?.map((link, index) => (
            <a
              href={link}
              target="_blank"
              className="text-indigo-500 inline-flex items-center"
              key={index}
              rel="noopener noreferrer"
            >
              {`File ${index + 1}`}
            </a>
          ))}
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="md:p-2 w-full">
            <CustomFormField name="grade" formik={formik}  type="number" label="Grade"/>
          </div>
          <div className="flex justify-end text-end items-end">
            <CustomButton
              disabled={isSubmitting}
              onClick={formik.submitForm}
              text="Apply"
              isLoading={formik.isSubmitting}
            />
          </div>
        </form>
      </div>
    </div>
  );
};

export default AssignmentCard;
