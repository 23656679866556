import { apiRequest } from "(apis)/api-interface/api-handler";
import { Typography } from "@mui/material";
import { message, notification, Spin } from "antd";
import { useState } from "react";
const AddCAModal = ({ setcampus_associates, ShowModel, setShowModel }) => {
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formData, setFormData] = useState({
    CAname: "",
    CAimage: null,
    CAuniversity: "",
    CAlinkedin: "",
    CAinstagram: "",
    CAtwitter: "",
    CAfacebook: "",
  });

  const initialErrors = {
    caNameError: {
      error: false,
      message: "",
    },
    caImageError: {
      error: false,
      message: "",
    },
    caUniversityError: {
      error: false,
      message: "",
    },
    calinkedinError: {
      error: false,
      message: "",
    },
    catwitterError: {
      error: false,
      message: "",
    },
    cafacebookError: {
      error: false,
      message: "",
    },
    cainstagramError: {
      error: false,
      message: "",
    },
  };

  const [errors, setErrors] = useState(initialErrors);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const image: any = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const maxWidth = 800;
          const maxHeight = 600;
          let width = image.width;
          let height = image.height;

          if (width > height) {
            if (width > maxWidth) {
              height *= maxWidth / width;
              width = maxWidth;
            }
          } else {
            if (height > maxHeight) {
              width *= maxHeight / height;
              height = maxHeight;
            }
          }

          canvas.width = width;
          canvas.height = height;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(image, 0, 0, width, height);
          const compressedImageData = canvas.toDataURL("image/jpeg", 0.7); // Adjust quality as needed
          setImagePreviewUrl(compressedImageData);
          setFormData({
            ...formData,
            CAimage: compressedImageData,
          });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  // const handleFileChange = (event) => {
  //     const file = event.target.files[0];
  //     if (file) {
  //         const reader = new FileReader();
  //         reader.onloadend = () => {
  //             const base64Data = reader.result;
  //             setImagePreviewUrl(base64Data);
  //             setFormData({
  //                 ...formData,
  //                 CAimage: base64Data
  //             });
  //         };
  //         reader.readAsDataURL(file);
  //     }
  // };

  const CheckValidity = () => {
    const newErrors = { ...errors };
    let isError = false;

    if (formData.CAname === "") {
      newErrors.caNameError.error = true;
      newErrors.caNameError.message = "Campus Associate Name is required";
      isError = true;
    } else if (/\d/.test(formData.CAname)) {
      newErrors.caNameError.error = true;
      newErrors.caNameError.message =
        "Campus Associate Name cannot contain numbers";
      isError = true;
    }
    if (formData.CAimage === null) {
      newErrors.caImageError.error = true;
      newErrors.caImageError.message = "Campus Associate Image is required";
      isError = true;
    }

    if (formData.CAuniversity === "") {
      newErrors.caUniversityError.error = true;
      newErrors.caUniversityError.message =
        "Campus Associate Position is required";
      isError = true;
    } else if (/\d/.test(formData.CAuniversity)) {
      newErrors.caUniversityError.error = true;
      newErrors.caUniversityError.message =
        "Campus Associate Position cannot contain numbers";
      isError = true;
    } else {
      newErrors.caNameError.error = false;
      newErrors.caNameError.message = "";
    }

    if (
      formData.CAlinkedin.trim() !== "" &&
      !isValidURL(formData.CAlinkedin.trim())
    ) {
      newErrors.calinkedinError.error = true;
      newErrors.calinkedinError.message = "Invalid LinkedIn URL";
      isError = true;
    } else {
      newErrors.calinkedinError.error = false;
      newErrors.calinkedinError.message = "";
    }

    if (
      formData.CAtwitter.trim() !== "" &&
      !isValidURL(formData.CAtwitter.trim())
    ) {
      newErrors.catwitterError.error = true;
      newErrors.catwitterError.message = "Invalid Twitter URL";
      isError = true;
    } else {
      newErrors.catwitterError.error = false;
      newErrors.catwitterError.message = "";
    }

    if (
      formData.CAinstagram.trim() !== "" &&
      !isValidURL(formData.CAinstagram.trim())
    ) {
      newErrors.cainstagramError.error = true;
      newErrors.cainstagramError.message = "Invalid Instagram URL";
      isError = true;
    } else {
      newErrors.cainstagramError.error = false;
      newErrors.cainstagramError.message = "";
    }

    if (
      formData.CAfacebook.trim() !== "" &&
      !isValidURL(formData.CAfacebook.trim())
    ) {
      newErrors.cafacebookError.error = true;
      newErrors.cafacebookError.message = "Invalid Facebook URL";
      isError = true;
    } else {
      newErrors.cafacebookError.error = false;
      newErrors.cafacebookError.message = "";
    }

    setErrors(newErrors);
    return isError;
  };

  const isValidURL = (url) => {
    // Regular expression for URL validation
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlRegex.test(url);
  };

  const Submit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      CheckValidity();

      if (CheckValidity()) {
        return;
      }

      const data = {
        CAname: formData.CAname,
        CAimage: formData.CAimage,
        CAuniversity: formData.CAuniversity,
        CAlinkedin: formData.CAlinkedin,
        CAinstagram: formData.CAinstagram,
        CAtwitter: formData.CAtwitter,
        CAfacebook: formData.CAfacebook,
      };

      const Response = await apiRequest("addCA", data);

      if (Response.data.success) {
        message.success(Response.data.message);
        setShowModel(false);
        setFormData({
          CAname: "",
          CAimage: null,
          CAuniversity: "",
          CAlinkedin: "",
          CAinstagram: "",
          CAtwitter: "",
          CAfacebook: "",
        });
        clearSelectedImage();
      } else {
        message.error(Response.data.message);
      }
      const Response2 = await apiRequest("getALLCa");

      if (Response2.data.success) {
        setcampus_associates(Response2.data.data);
      }
    } catch (error) {
      console.error("Error adding campus associate", error);
      notification.error({
        message: "Error",
        description: "Failed to add campus associate",
      });
    } finally {
      setIsSubmitting(false);
    }
  };
  const clearSelectedImage = () => {
    setImagePreviewUrl(null);
    const fileInput = document.getElementById("CAimage") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };
  return (
    <>
      <div
        id="createProductModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          ShowModel ? "" : "hidden"
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative w-full max-w-2xl max-h-full p-4">
          <div className="relative p-4 bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
              <h3 className="text-lg font-semibold text-gray-900 ">
                Add New Campus Associate
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                data-modal-target="createProductModal"
                data-modal-toggle="createProductModal"
                onClick={() => {
                  setShowModel(false);
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="space-y-3 mt-3">
              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Name *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                  type="text"
                  name="CAname"
                  value={formData.CAname}
                  onChange={handleInputChange}
                />
                {errors.caNameError.error && (
                  <span className="text-xs text-red-500">
                    {errors.caNameError.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Image *</span>
                </label>
                <input
                  id="CAimage"
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ margin: "10px 0" }}
                />
                <Typography variant="body2">Preview:</Typography>
                {imagePreviewUrl && (
                  <img
                    src={imagePreviewUrl}
                    alt="Preview"
                    style={{ maxWidth: "100%", marginBottom: 10 }}
                  />
                )}
                {errors.caImageError.error && (
                  <span className="text-xs text-red-500">
                    {errors.caImageError.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Position *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light "
                  type="text"
                  name="CAuniversity"
                  value={formData.CAuniversity}
                  onChange={handleInputChange}
                />
                {errors.caUniversityError.error && (
                  <span className="text-xs text-red-500">
                    {errors.caUniversityError.message}
                  </span>
                )}
              </div>
              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Linkedin *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light "
                  type="text"
                  name="CAlinkedin"
                  value={formData.CAlinkedin}
                  onChange={handleInputChange}
                />
                {errors.calinkedinError.error && (
                  <span className="text-xs text-red-500">
                    {errors.calinkedinError.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Instagram *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light "
                  type="text"
                  name="CAinstagram"
                  value={formData.CAinstagram}
                  onChange={handleInputChange}
                />
                {errors.cainstagramError.error && (
                  <span className="text-xs text-red-500">
                    {errors.cainstagramError.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Twitter *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light "
                  type="text"
                  name="CAtwitter"
                  value={formData.CAtwitter}
                  onChange={handleInputChange}
                />
                {errors.catwitterError.error && (
                  <span className="text-xs text-red-500">
                    {errors.catwitterError.message}
                  </span>
                )}
              </div>

              <div>
                <label className="block text-gray-900 ">
                  <span>Campus Associate Facebook *</span>
                </label>
                <input
                  className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light "
                  type="text"
                  name="CAfacebook"
                  value={formData.CAfacebook}
                  onChange={handleInputChange}
                />
                {errors.cafacebookError.error && (
                  <span className="text-xs text-red-500">
                    {errors.cafacebookError.message}
                  </span>
                )}
              </div>
            </div>
            <div className="flex items-center justify-end gap-2">
              <button
                className="px-5 py-3 mt-2 text-gray-500 border bg-white rounded-md hover:bg-gray-600"
                onClick={() => {
                  setShowModel(false);
                }}
              >
                Cancel
              </button>
              <button
                className="px-5 py-3 mt-2 text-white bg-rose-500 rounded-md"
                onClick={Submit}
                disabled={isSubmitting}
              >
                {isSubmitting ? <Spin /> : "Add Campus Associate"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCAModal;
