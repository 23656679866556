
import { DeckAPI } from "../../../../(apis)/(shared-apis)/academics/decks-api";
import CustomBadges from "../../../../shared-components/ui/CustomBadges";
import { Switcher } from "../../../../shared-components/ui/CustomForm";
import { toast } from "react-hot-toast";

const UnpublishedTable = ({ unpublishedQuestions, setUnpublishedQuestions , deckInformation, getQuestions }) => {

    const handlePublishedChange = async (questionId) => {
      const updatedQuestions = unpublishedQuestions.map((question) => {
        if (question._id === questionId) {
          question.published = !question.published;
        }
        return question;
      });
      setUnpublishedQuestions(updatedQuestions);
      const response = await DeckAPI("unPublishDeckQuestion", "" , { deckId: deckInformation._id, questionId });
      if (response.data.success) {
        toast.success("Question unpublished Successfully");
        getQuestions();
      } else {
        toast.error("Error while unpublishing question");
      }
    };
  
    return (
      <div className="mx-auto p-12 pt-0">
        <div className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold">
          <div className="overflow-x-auto">
            <table className="w-full mb-20 text-sm text-left text-gray-500">
              <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                <tr>
                  <th scope="col" className="px-4 py-4 text-left">
                    SR#
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Question Id
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Question Text
                  </th>
                  <th scope="col" className="px-4 py-4">
                    Tags
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Published
                  </th>
                  <th scope="col" className="px-4 py-4 text-center">
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200 text-gray-800">
                {unpublishedQuestions &&
                  unpublishedQuestions.map((question, index) => (
                    <tr
                      key={question._id}
                      className="bg-white divide-y divide-gray-200 text-gray-800"
                    >
                      <td className="px-4 py-4">{index + 1}</td>
                      <td className="px-4 py-4">{question._id}</td>
                      <td
                        className="px-4 pt-5"
                        dangerouslySetInnerHTML={{
                          __html:
                            question.QuestionText.length > 60
                              ? question.QuestionText.substring(0, 60) + "..."
                              : question.QuestionText,
                        }}
                      ></td>
                      <td className="px-4 py-4">
                        <div className="flex flex-wrap gap-1">
                          <CustomBadges label={question.meta.subject} showIcon={false} />
                          <CustomBadges label={question.meta.topic} showIcon={false} type="light" />
                          {question.meta.subTopics && question.meta.subTopics.length > 0 && question.meta.subTopics.map((subTopic: string, index: number) => (
                            <CustomBadges key={index} label={subTopic} showIcon={false} type="info" />
                          ))}
                        </div>
                      </td>
                      {/*radio button*/}
                      <td className="px-4 py-3 text-center">
                        <label className="flex items-center justify-center cursor-pointer">
                          <Switcher
                            isActive={undefined}
                            for={question._id}
                            onChange={() => {
                              handlePublishedChange(question._id);
                            }}
                            togglevalue={question.published}
                            size="small"
                          />
                        </label>
                      </td>
                      <td className="px-4 py-4 text-center flex items-center justify-around">
                        <svg
                          onClick={() => {
                            // history.push(`/editQuestion/${question._id}`);
                          }}
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6 text-gray-500 hover:text-rose-500 cursor-pointer mt-2"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
  
    )
  }

  
    export default UnpublishedTable;