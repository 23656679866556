import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const FilterQuestionAPIs: ApiInterface[] = [
  {
    url: "/api/questions/add-question-to-quiz-builder",
    method: "POST",
    name: "addQuestionToQuizBuilder",
  },
  {
    url: "/api/questions/remove-question-from-quiz-builder",
    method: "POST",
    name: "removeQuestionToQuizBuilder",
  },
  {
    url: "/api/questions/getQuestionsByFilter",
    method: "GET",
    name: "getFilteredQuestions",
  },
  {
    url: "/api/questions/getAllTopics",
    method: "GET",
    name: "getAllTopicsWithSubtopics",
  },
];

export function FilterQuestionApi(
  name: string,
  params: string[] = [""],
  data: any = null,
  queryParams: Record<string, any> = {}
) {
  const api = FilterQuestionAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url =
    backendUrl + api.url + (params.length ? "/" + params.join("/") : "");

  // Convert queryParams object to a query string
  const queryString = new URLSearchParams(queryParams).toString();

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url: queryString ? `${url}?${queryString}` : url,
  });
}
