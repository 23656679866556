import { PlusOutlined, UploadOutlined } from "@ant-design/icons";
import { Box, Grid } from "@mui/material";
import { Button, Image, Input, Select, Upload } from "antd";
import React, { useState } from "react";
import ReactQuill from "react-quill";
import GlobalPublishButton from "../ui/CustomButtons";
import { getBase64ImageData } from "../../utility/form/functions";
import CustomFormikError from "./CustomFormikError";
import CustomButton from "./custom-button";
const { TextArea } = Input;
export const LabelComponent = ({ value, require }) => {
  return (
    <label style={{ marginBottom: "5px" }}>
      {value} {require && <span className="text-red-500">*</span>}
    </label>
  );
};
const GlobalForm = ({ formik, formData, spacing = 2 }) => {
  const [uploadImagePreview, setUploadImagePreview] = useState(false);
  const [uploadImagePreviewUrl, setUploadImagePreviewUrl] = useState("");
  const handleThumbnailPreview = async (file) => {
    if (!file?.url && !file.preview) {
      file.preview = await getBase64ImageData(file.originFileObj);
    }
    setUploadImagePreviewUrl(file?.url || file?.preview);
    setUploadImagePreview(true);
  };
  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={spacing} marginTop={"5px"}>
        {formData?.fields?.map((field, index) => {
          const isMultiple = field.isMultiple || false;
          const options = field.options || [];
          const require = field.require || false;
          const loader = field.loader || false;
          const disabled = field.disabled || false;

          const startIcon = field.startIcon || <></>;

          const props = field.props || {};
          const multiDropdownOptions = [...options];
          const filterOption = (input, option) =>
            (option?.label ?? "")
              ?.toLowerCase()
              ?.includes(input?.toLowerCase());
          return (
            <>
              <Grid item md={field.size} sm={12} xs={12} key={index}>
                {/* For Text Field */}
                {field?.formContent === "input" && (
                  <>
                    <LabelComponent value={field?.label} require={require} />
                    <Input
                      id={field.name}
                      name={field.name}
                      variant="outlined"
                      type={field.type}
                      disabled={false}
                      onChange={(e) => {
                       
                        if (field.onChange) {
                          field.onChange(e);
                        } else {
                          formik.setFieldValue(field.name, e?.target?.value);
                        }
                      }}
                     
                      placeholder={`Enter ${field.label}`}
                      value={formik.values[field.name]}
                      // size="small"
                    
                   
                    />
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                    {field?.formContent === "react-quill" && (
                  <>
                    <div className="flex flex-col gap-3">
                      <LabelComponent
                        value={field?.label}
                        require={field.require}
                      />
                      <ReactQuill
                           id={field.name}
                          
                           placeholder={`Enter ${field.label}`}
                           value={formik.values[field.name] || ""}
                        onChange={(content) =>formik.setFieldValue(field.name, content)}
                        className="border border-gray-300 rounded"
                      />
                    </div>
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                {field?.formContent === "textarea" && (
                  <>
                    <LabelComponent value={field?.label} require={require} />
                    <TextArea
                      id={field.name}
                      name={field.name}
                      variant="outlined"
                      
                      disabled={false}
                      onChange={(e) => {
                        if (field.onChange) {
                          field.onChange(e);
                        } else {
                          formik.setFieldValue(field.name, e?.target?.value);
                        }
                      }}
                    
                      placeholder={`Enter ${field.label}`}
                      value={formik.values[field.name]}
                      // size="small"
                     
                    />
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                {field?.formContent === "select" && (
                  <>
                    <LabelComponent
                      value={field?.label}
                      require={field.require}
                    />
                    <div>
                      <Select
                        mode={field?.mode}
                        className="w-full"
                        allowClear
                        filterOption={filterOption}
                       
                        options={multiDropdownOptions}
                        value={formik.values[field.name] || []}
                        showSearch
                        placeholder={`Select ${field.label}`}
                        disabled={disabled}
                        onChange={(selected, option) => {
                          if(field?.isSetValue){
                            formik.setFieldValue(field.name, option?.value);
                          }else if(field?.mode){
                            formik.setFieldValue(field.name, selected);                            
                          }else{
                            formik.setFieldValue(field.name, option?.label);
                          }

                        }}
                      />
                    </div>
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                {field?.formContent === "image" && (
                  <>
                    <LabelComponent
                      value={field?.label}
                      require={field.require}
                    />
                    <Upload
                      listType="picture-card"
                      fileList={formik.values[field.name] || []}
                      onPreview={handleThumbnailPreview}
                      
                      onChange={(info) => {
                        const fileList = info.fileList// Allow only one file
                        formik.setFieldValue(field.name, fileList);
                      }}
                      accept="image/*" // Accept only image files
                      beforeUpload={() => false} // Prevent automatic upload
                      maxCount={1} // Allow only one file
                      className="w-full"
                    >
                      {/* {formik.values[field.name]?.length >= 1 ? null : ( */}
                        <div>
                          <PlusOutlined />
                          <div style={{ marginTop: 8 }}>Upload</div>
                        </div>
                      {/* // )} */}
                    </Upload>
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                    {uploadImagePreview && (
                      <Image
                        preview={{
                          visible: uploadImagePreview,
                          onVisibleChange: (visible) =>
                            setUploadImagePreview(visible),
                          // afterClose: () => setUploadImagePreviewUrl(""),
                        }}
                        src={uploadImagePreviewUrl}
                        style={{ display: "none" }}
                      />
                    )}
                  </>
                )}
                {field?.formContent === "pdf" && (
                  <>
                    <div className="flex flex-col gap-3">
                      <LabelComponent
                        value={field?.label}
                        require={field.require}
                      />
                      <Upload
                        listType="text" // Use "text" list type to display file name
                        fileList={formik.values[field.name] || []}
                        onChange={(info) => {
                          formik.setFieldValue(field.name, info.fileList);
                        }}
                        accept=".pdf" // Accept only PDF files
                        beforeUpload={() => false} // Prevent automatic upload
                        multiple={isMultiple} // Set multiple upload based on isMultiple prop
                        className="w-full"
                        maxCount={1}
                      >
                        {/* {formik.values[field.name]?.length >= 1 ? null : ( */}
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        {/* // )} */}
                      </Upload>
                    </div>
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                {field?.formContent === "video" && (
                  <>
                    <div className="flex flex-col gap-3">
                      <LabelComponent
                        value={field?.label}
                        require={field.require}
                      />
                      <Upload
                        listType="text" // Use "text" list type to display file name
                        fileList={formik.values[field.name] || []}
                        onChange={(info) => {
                          formik.setFieldValue(field.name, info.fileList);
                        }}
                        accept="video/mp4" // Accept only PDF files
                        beforeUpload={() => false} // Prevent automatic upload
                        multiple={isMultiple} // Set multiple upload based on isMultiple prop
                        className="w-full"
                        maxCount={1}
                      >
                        {/* {formik.values[field.name]?.length >= 1 ? null : ( */}
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        {/* // )} */}
                      </Upload>
                    </div>
                    <CustomFormikError
                      name={formik.errors[field.name]}
                      touched={formik.touched[field.name]}
                      error={formik.errors[field.name]}
                    />
                  </>
                )}
                 {field?.formContent === "publishbutton" && (
         <>
         <div className="mb-0">
                <LabelComponent
                value={field?.label}
                require={field.require}
              />

         </div>
             <GlobalPublishButton
               onChange={()=>{
                 if(field.onChange){
                   field.onChange()
                 }else{
                   formik.setFieldValue(field.name, !formik.values[field.name]);
                 }
               }}
               isActive={formik.values[field.name]}
             />
         
         </>
        
         )}
                {field?.formContent === "array" && (
                  <>
                    <div
                      className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-4`}
                    >
                      {formik.values[field.name]?.length > 0 && (
                        <LabelComponent
                          value={field?.label}
                          require={field.require}
                        />
                      )}
                      {formik.values[field.name]?.map((item, index) => (
                        <div
                          key={index}
                          className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-4`}
                        >
                          <div className="flex justify-between my-1 mb-2">
                            <label
                              className="text-md font-medium  mt-2"
                              htmlFor={`paginationsubTopic${index}`}
                            >
                              {field?.label} {index + 1}
                            </label>
                            <button
                              className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                              onClick={() => {
                                const updatedpaginations = [
                                  ...formik.values[field.name],
                                ];
                                updatedpaginations.splice(index, 1);
                                formik.setFieldValue(
                                  field.name,
                                  updatedpaginations
                                );
                              }}
                            >
                              Remove {field?.label}
                            </button>
                          </div>
                          {field?.items?.map((nestedItem, nestedIndex) => (
                            <React.Fragment key={nestedIndex}>
                              {nestedItem?.formContent === "select" && (
                                <>
                                  <LabelComponent
                                    value={nestedItem?.label}
                                    require={nestedItem.require}
                                  />
                                  <div className="office-city">
                                    <Select
                                      className="w-full"
                                      filterOption={filterOption}
                                     allowClear
                                      value={
                                        formik.values[field.name]?.[index]?.[
                                          nestedItem.name
                                        ] || []
                                      }
                                      options={nestedItem.options}
                                      showSearch
                                      placeholder={`Select ${nestedItem.label}`}
                                      disabled={disabled}
                                      onChange={(selected, option) => {
                                        formik.setFieldValue(
                                          `${field.name}.${index}.${nestedItem.name}`,
                                          option.label
                                        );
                                      }}
                                    />
                                  </div>
                                  <CustomFormikError
                                    name={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust error path
                                    touched={
                                      formik.touched[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust touched path
                                    error={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    }
                                  />
                                </>
                              )}
                              {nestedItem?.formContent === "input" && (
                                <>
                                  <LabelComponent
                                    value={nestedItem?.label}
                                    require={require}
                                  />
                                  <Input
                                    id={nestedItem.name}
                                    name={`${field.name}.${index}.${nestedItem.name}`}
                                    value={
                                      formik.values[field.name]?.[index]?.[
                                        nestedItem.name
                                      ] || []
                                    }
                                    variant="outlined"
                                    type={nestedItem.type}
                                    //   disabled={disabled}
                                    onChange={(e) => {
                                      if (nestedItem.onChange) {
                                        nestedItem.onChange(e);
                                      } else {
                                        formik.setFieldValue(
                                          `${field.name}.${index}.${nestedItem.name}`,
                                          e?.target?.value
                                        );
                                      }
                                    }}
                                  
                                    placeholder={`Enter ${nestedItem.label}`}
                                
                                  />
                                  <CustomFormikError
                                    name={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust error path
                                    touched={
                                      formik.touched[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust touched path
                                    error={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    }
                                  />
                                </>
                              )}
                              
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
                    <button
                      className="bg-rose-500 hover:bg-rose-700 text-white font-semibold p-1 rounded"
                      type="button"
                      onClick={() => {
                        const updatedPaginations = [
                          ...formik.values[field.name],
                          { subTopic: "", startPageNo: "", endPageNo: "" },
                        ];
                        formik.setValues({
                          ...formik.values,
                          [field.name]: updatedPaginations,
                        });
                      }}
                    >
                      Add{" "}
                      {formik.values[field.name]?.length > 0 ? (
                        <span>More</span>
                      ) : (
                        <></>
                      )}{" "}
                      {field?.label}
                    </button>
                  </>
                )}
                {field?.formContent === "array-single" && (
                  <>
                    <div
                      className={`overflow-hidden bg-white sm:rounded-lg text-semibold  `}
                    >
                      {formik.values[field.name]?.length > 0 && (
                        <LabelComponent
                          value={field?.label}
                          require={field.require}
                        />
                      )}
                      {formik.values[field.name]?.map((item, index) => (
                        <div
                       
                          className={`overflow-hidden bg-white  sm:rounded-lg text-semibold p-2`}
                        >
                       
                          {field?.items?.map((nestedItem, nestedIndex) => (
                            <React.Fragment key={nestedIndex}>
                            
                              {nestedItem?.formContent === "select" && (
                                <>
                                  <LabelComponent
                                    value={nestedItem?.label}
                                    require={nestedItem.require}
                                  />
                                  <div >
                                    <Select
                                      className="w-full"
                                      filterOption={filterOption}
                                    allowClear
                                      value={
                                        formik.values[field.name]?.[index]?.[
                                          nestedItem.name
                                        ] || []
                                      }
                                      options={nestedItem.options}
                                      showSearch
                                      placeholder={`Select ${nestedItem.label}`}
                                      disabled={disabled}
                                      onChange={(selected, option) => {
                                        formik.setFieldValue(
                                          `${field.name}.${index}.${nestedItem.name}`,
                                          option.label
                                        );
                                      }}
                                    />
                                  </div>
                                  <CustomFormikError
                                    name={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust error path
                                    touched={
                                      formik.touched[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust touched path
                                    error={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    }
                                  />
                                </>
                              )}
                              {nestedItem?.formContent === "input" && (
                                <div>
                                  <LabelComponent
                                    value={nestedItem?.label}
                                    require={nestedItem?.require}
                                  />
                                  <div className="flex flex-col w-full">
                                  <input
                                  className="w-full custom-input-class"
                                    id={nestedItem.name}
                                    name={`${field.name}.${index}.${nestedItem.name}`}
                                    value={
                                      formik.values[field.name]?.[index]?.[
                                        nestedItem.name
                                      ] 
                                    }
                                 
                                    type={nestedItem.type}
                                    //   disabled={disabled}
                                   
                                    onChange={(e) => {
                                      if (nestedItem.onChange) {
                                        nestedItem.onChange(e);
                                      } else {
                                        formik.setFieldValue(
                                          `${field.name}.${index}.${nestedItem.name}`,
                                          e?.target?.value
                                        );
                                      }
                                    }}
                                  
                                    placeholder={`Enter ${nestedItem.label}`}
                                
                                  />
                                  <CustomFormikError
                                    name={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust error path
                                    touched={
                                      formik.touched[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    } // Adjust touched path
                                    error={
                                      formik.errors[field.name]?.[index]?.[
                                        nestedItem.name
                                      ]
                                    }
                                  />

                                  </div>
                                </div>
                              )}
                              
                            </React.Fragment>
                          ))}
                        </div>
                      ))}
                    </div>
               
                  </>
                )}

                {/* Button */}
                {field?.formContent === "button" && (
                  <Box sx={{ marginTop: "24px" }}>
                    <Button
                      size="medium"
                      variant="contained"
                      onClick={field.onChange}
                      startIcon={startIcon}
                      disabled={field?.disable}
                      {...props}
                    >
                      {field?.label}
                    </Button>
                  </Box>
                )}
           
                {field?.formContent === "submitbutton" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "center",
                    }}
                  >
                    <CustomButton
                      text={`${field?.isEditMode ? "Update" : "Add"} `}
                      disabled={formik.isSubmitting}
                      isLoading={formik.isSubmitting}
                      onClick={formik.handleSubmit}
                    />
                  </Box>
                )}
              </Grid>
            </>
          );
        })}
      </Grid>
    </form>
  );
};
export default GlobalForm;
