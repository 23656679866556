import dayjs from 'dayjs';
import React from 'react';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend } from 'recharts';

interface TodaysBarChartData {
  total: number;
  time: string;
}

interface TodaysBarChartProps {
  data: TodaysBarChartData[];
}

const TodaysBarChart: React.FC<TodaysBarChartProps> = ({ data }) => {
  const formattedData = data?.map((item) => ({
    total: item.total,
    time: dayjs(item.time.slice(0, -1)).format('hh:mm a'),
  }));

  return (
    <div>
      <BarChart width={800} height={400} data={formattedData}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="time" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Bar dataKey="total" fill="#8884d8" />
      </BarChart>
    </div>
  );
};

export default TodaysBarChart;
