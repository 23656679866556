import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  type: Yup.string().required('Please select a file type'),
  file: Yup.mixed()
    .required('Please upload a file')
    .test('fileType', function (value:any) {
        const { type } = this.parent;
  console.log("value", value);
        if (!value?.length) {
          return this.createError({ message: 'Please upload a file' });
        }
  
        const validImageTypes = ['image/jpg', 'image/png', 'image/svg+xml'];
        const validVideoTypes = ['video/mp4'];
        const validPdfTypes = ['application/pdf'];
  
        let isValid = false;
        let validTypesList = '';
  
        switch (type) {
          case 'image':
            isValid = validImageTypes.includes(value?.[0]?.type?.toLowerCase());
            validTypesList = validImageTypes.join(', ');
            break;
          case 'video':
            isValid = validVideoTypes.includes(value?.[0]?.type?.toLowerCase());
            validTypesList = validVideoTypes.join(', ');
            break;
          case 'pdf':
            isValid = validPdfTypes.includes(value?.[0]?.type?.toLowerCase());
            validTypesList = validPdfTypes.join(', ');
            break;
          default:
            return this.createError({ message: 'Unsupported file type' });
        }
  
        if (!isValid) {
          return this.createError({
            message: `Invalid file type. Accepted types are: ${validTypesList}. your uploaded file type is ${value?.[0]?.type}`,
          });
        }
  
        return true;
      })
    .test('fileSize', function (value:any) {
      const { type } = this.parent;

      if (!value?.length) {
        return this.createError({ message: 'Please upload a file' });
      }

      const maxSize =
        type === 'video'
          ? 100 * 1024 * 1024 // 100MB
          : type === 'pdf'
          ? 4 * 1024 * 1024 // 4MB
          : 2 * 1024 * 1024; // 2MB

      if (value?.[0]?.size > maxSize) {
        let maxSizeText;
        if (type === 'video') {
          maxSizeText = '100MB';
        } else if (type === 'pdf') {
          maxSizeText = '4MB';
        } else if (type === 'image') {
          maxSizeText = '2MB';
        }

        return this.createError({
          message: `File too large. Maximum allowed size for ${type} is ${maxSizeText}.`,
        });
      }

      return true;
    }),
});

export default validationSchema;
