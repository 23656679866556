
import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "../../interfaces/global-interfaces";

export const bankApis: ApiInterface[] = [
    {
        url: "/api/bank-details/get-bank-details",
        method: "GET",
        name: "getBankDetails",
    },
    {
        url: "/api/bank-details/add-amount-deposited",
        method: "POST",
        name: "addAmountToBank"
    },
    {
        url: "/api/bank-details/update-bank-details",
        method: "PUT",
        name: "updateBankDetails"
    },
    {
        url: "/api/bank-details/add-bank-details",
        method: "POST",
        name: "addBankDetails"
    },
    {
        url: "/api/bank-details/update-bank-details",
        method: "PUT",
        name: "updateBankDetails"
    },
    {
        url: "/api/bank-details/update-publish-status",
        method: "PUT",
        name: "updatePublishStatus"
    },
    {
        url: "/api/bank-details/get-history",
        method: "GET",
        name: "getHistoryById"
    }
];
  
export function BankDetailsAPI(name: string, params: string = "", data: any = null) {
    const api = bankApis.find((api) => api.name === name);
    if (!api) {
        throw new Error("API not found");
    }

    const url = backendUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");

    return axios({
        method: api.method,
        data,
        url
    });
}
