
import { ApiInterface } from "interfaces/global-interfaces";

export const questionapis: ApiInterface[] = [
    {
        url: "/api/questions/add",
        method: "POST",
        name: "addQuestion",        
    },
    {
        url: "/api/questions/get-question-by-id/:id",
        method: "GET",
        name: "getQuestionById",
    },
    {
        url: "/api/questions/findDeckByQuestionId/:id",
        method: "GET",
        name: "findDeckByQuestionId",
    },
    {
        url: "/api/questions/edit/:id",
        method: "POST",
        name: "editQuestion",
    },
    {
        url: "/questions/edit/:id",
        method: "POST",
        name: "editQuestion",
    },
    {
        url: "/api/questions/add-question-to-quiz-builder",
        method: "POST",
        name: "addQuestionToQuizBuilder",
    },
    {
        url: "/api/questions/remove-question-from-quiz-builder",
        method: "POST",
        name: "removeQuestionToQuizBuilder",
    },
    {
        url: "/api/questions/getQuestionsByFilter",
        method: "GET",
        name: "getFilteredQuestions",
    },
]

