
import { CustomBundleAPI } from '(apis)/(shared-apis)/custom-bundle-apis';
import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import { Delete } from '@material-ui/icons';
import { useState } from 'react';
import toast from 'react-hot-toast';
import CustomBadges from 'shared-components/ui/CustomBadges';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import { Input, Label, Switcher } from 'shared-components/ui/CustomForm';
import { EditIcon } from 'shared-components/ui/Icons';
import { EditToggleModal } from './edit-toggle-modal';
import AddBundleModal from './add-bundle-modal';
// import AddPricingModal from '../add-pricing-modal';
// import { EditToggleModal } from '../edit-toggle-modal';

const Overview = ({ bundleData, setbundleData, id, fetchPackage }) => {

    const handleInputChange = (name: string, value: string) => {
        setbundleData({
            ...bundleData,
            [name]: value
        });
    };

    const UpdatePackage = async () => {
        try {
            let response: any = await CustomBundleAPI("updateBundle", [id], bundleData);
            if (response) {
                toast.success(`Updated Successfully`);
                fetchPackage();
            }
        } catch (error) {
            toast.error("Failed to update package");
            console.log("Update package Error: ", error);
        }
    }


    const [toggleToBeAdded, settoggleToBeAdded] = useState({
        toggleValue: "",
        discountAmount: "",
        endDate: new Date(),
        numberOfMonths: 0
    })

    const AddToggles = async (toggle: any) => {
        if (!toggle.toggleValue || !toggle.discountAmount) {
            toast.error("Please fill all fields");
            return;
        }
        toggle.toggleValue = toggle.toggleValue.trim();
        toggle.discountAmount = toggle.discountAmount.trim();

        // if showDate is true then we will add endDate to the toggle object
        if (showDate) {
            toggle.endDate = new Date(toggle.endDate).toISOString();
            delete toggle.numberOfMonths;
        }
        else {
            toggle.numberOfMonths = toggle.numberOfMonths ? parseInt(toggle.numberOfMonths) : 0;
            delete toggle.endDate;
        }
        try {
            const res = await CustomBundleAPI("addToggleToBundle", [id], toggle);
            if (res.data.success) {
                toast.success("Toggle Added Successfully");
                fetchPackage();
                settoggleToBeAdded({
                    toggleValue: "",
                    discountAmount: "",
                    endDate: new Date(),
                    numberOfMonths: 0
                })
            }

        } catch (error) {
            toast.error("Failed to add Toggle");
            console.log("Add Toggle Error: ", error);
        }

    }

    const HandleToggleChange = (name: string, value: string) => {
        settoggleToBeAdded({
            ...toggleToBeAdded,
            [name]: value
        })
    }

    const [togglesModal, setTogglesModal] = useState(false);
    const [selectedToggle, setSelectedToggle] = useState(null);


    const [pricingsModal, setPricingsModal] = useState(false);

    const [showDate, setShowDate] = useState(false);
    const [category, setCategory] = useState("")
    const HandleCategoryChange = (name: string, value: string) => {
        setCategory(value)
    }

    const AddCategoryToBundle = async (category: string) => {
        //push the category to the bundleData
        if (!category) {
            toast.error("Please fill the category field");
            return;
        }
        if (bundleData.categories.includes(category)) {
            toast.error("Category already exists");
            return;
        }
        let categories = bundleData.categories;
        if (!categories) {
            categories = [];
        }
        categories.push(category.trim());
        setbundleData({
            ...bundleData,
            categories
        });
    }

    const [addBundleModal, setAddBundleModal] = useState(false);
    const [mode , setMode] = useState("add");
    const [selectedFeature, setSelectedFeature] = useState(null);

    return (
        <>
            <div className='border-2 rounded p-5'>
                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700"> Bundle: {bundleData?.boardName}</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mt-5 items-end'>
                    <div className="space-y-2">
                        <Label>Board Name</Label>
                        <Input
                            type="text"
                            placeholder="Enter Board Name"
                            value={bundleData?.boardName}
                            name="boardName"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Category</Label>
                        <Input
                            type="text"
                            placeholder="Enter Category"
                            value={bundleData?.metadata?.category}
                            name="category"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Entity</Label>
                        <Input
                            type="text"
                            placeholder="Enter Entity"
                            value={bundleData?.metadata?.entity}
                            name="entity"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Position</Label>
                        <Input
                            type="number"
                            placeholder="Enter Position"
                            value={bundleData?.position}
                            name="position"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='flex justify-end'>
                        <div className="space-y-2">
                            <Label> Published Status</Label>
                            <Switcher
                                isActive={undefined}
                                for={bundleData?._id}
                                onChange={() => {
                                    setbundleData({
                                        ...bundleData,
                                        isPublished: !bundleData?.isPublished
                                    });
                                }}
                                togglevalue={bundleData?.isPublished}
                                size="normal"
                            />
                        </div>
                    </div>
                    <div className="space-y-2 flex justify-end">
                        <ButtonFill
                            handleClick={() => {
                                UpdatePackage();
                            }}
                        >
                            Update Bundle
                        </ButtonFill>
                    </div>



                </div>
            </div>


            <div className='border-2 rounded p-5 mt-4'>
                <h2 className="text-2xl font-bold text-gray-700"> Categories </h2>
                <div className="space-y-2 grid grid-cols-1 md:grid-cols-3 gap-2">
                    <div className='flex justify-between items-center mt-4 gap-1'>
                        <Input
                            type="text"
                            placeholder="Enter Category"
                            value={category}
                            name="category"
                            onChange={HandleCategoryChange}
                        />
                        <ButtonFill
                            handleClick={() => {
                                AddCategoryToBundle(category);
                            }}
                        >
                            Add
                        </ButtonFill>
                    </div>
                    <div className='flex gap-2 mt-4 items-center flex-wrap'>
                        {bundleData?.categories?.map((category: string, index: number) => (
                            <div className='cursor-pointer flex items-center gap-2 bg-gray-100 p-1 shadow-md rounded-full px-4 border border-gray-300 text-sm' key={index}>
                                <p>{category}</p>
                                <span onClick={() => {
                                    let categories = bundleData.categories;
                                    categories.splice(index, 1);
                                    setbundleData({
                                        ...bundleData,
                                        categories
                                    });
                                }}>
                                    <Delete className='cursor-pointer text-gray-500 hover:text-primary w-2 h-2' />
                                </span>
                            </div>
                        ))}
                    </div>
                    <div className='flex justify-end'>
                        <ButtonFill
                            handleClick={() => {
                                UpdatePackage();
                            }}
                        >
                            Update Bundle
                        </ButtonFill>
                    </div>


                </div>
            </div>

            <div className='border-2 rounded p-5 mt-4'>

                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700">Toggles </h2>
                    <div className="space-y-2 flex justify-end">
                        <ButtonFill
                            handleClick={() => {
                                AddToggles(toggleToBeAdded);
                            }}
                        >
                            Add Toggle
                        </ButtonFill>
                    </div>

                </div>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-2 mt-5 items-end'>
                    <div className="space-y-2">
                        <Label>Toggle Value </Label>
                        <Input
                            type="text"
                            placeholder="Enter Toggle Value"
                            value={toggleToBeAdded?.toggleValue}
                            name="toggleValue"
                            onChange={HandleToggleChange}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Toggle Discount </Label>
                        <Input
                            type="text"
                            placeholder="Enter Discount"
                            value={toggleToBeAdded?.discountAmount}
                            name="discountAmount"
                            onChange={HandleToggleChange}
                        />
                    </div>
                    {/*Choice for either endDate or numberOfMonths */}
                    <div className="space-y-2">
                        <Label>Add End Date Instead of Number of Months</Label>
                        <Switcher
                            isActive={undefined}
                            for={toggleToBeAdded?.endDate}
                            onChange={() => {
                                setShowDate(!showDate);
                            }}
                            togglevalue={showDate}
                            size="normal"
                        />
                    </div>
                    {showDate ?
                        <div className="space-y-2">
                            <Label>End Date </Label>
                            <Input
                                type="date"
                                placeholder="Enter End Date"
                                value={toggleToBeAdded?.endDate}
                                name="endDate"
                                onChange={HandleToggleChange}
                            />
                        </div>
                        :
                        <div className="space-y-2">
                            <Label>Number of Months </Label>
                            <Input
                                type="number"
                                placeholder="Enter Number of Months"
                                value={toggleToBeAdded?.numberOfMonths}
                                name="numberOfMonths"
                                onChange={HandleToggleChange}
                            />
                        </div>
                    }
                </div>

                <div className=" bg-white shadow-md sm:rounded-lg mt-4">
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        SR No.
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Toggle Value
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Discount Amount
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Active
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        End Date
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Position
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bundleData?.togglesAndPricings?.map((toggle, index) => (
                                    <tr className="border-b" key={toggle?._id}>
                                        <td className="px-4 py-4">{index + 1}</td>
                                        <td className="px-4 py-3">{toggle?.toggleValue}</td>
                                        <td className="px-4 py-3 text-center">{toggle?.discountAmount}</td>
                                        <td className="px-4 py-3">
                                            <div className="mx-auto w-fit">
                                                <CustomBadges label={toggle?.active ? "Active" : "Inactive"} type={toggle?.active ? "success" : "error"} showIcon={false} />
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-center">{toggle?.endDate ? new Date(toggle?.endDate).toLocaleDateString() : toggle?.numberOfMonths}</td>
                                        <td className="px-4 py-3 text-center">{toggle?.position}</td>
                                        <td className="px-4 py-3 text-center">
                                            <div className="flex items-center justify-center" onClick={() => { setTogglesModal(true); setSelectedToggle(toggle) }}>
                                                <EditIcon size='w-6 h-6' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <EditToggleModal
                            active={togglesModal}
                            setModal={setTogglesModal}
                            onCancel={() => {
                                setTogglesModal(false);
                            }}
                            onConfirm={() => {
                                setTogglesModal(false);
                            }}
                            selectedToggle={selectedToggle}
                            packageId={id}
                            fetchPackage={fetchPackage}
                        />

                    </div>
                </div>

            </div>


            <div className='border-2 rounded p-5 mt-4'>

                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700">Bundles </h2>

                    <div className="space-y-2 flex justify-end">
                        <ButtonFill
                            handleClick={() => {
                                setAddBundleModal(true);
                                setMode("add");
                            }}
                        >
                            Add Bundle
                        </ButtonFill>
                    </div>
                </div>
                <div className=" bg-white shadow-md sm:rounded-lg mt-4">
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        SR No.
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Feature 
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Category
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Position
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Active
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {bundleData?.features?.map((feature, index) => (
                                    <tr className="border-b" key={feature?._id}>
                                        <td className="px-4 py-4">{index + 1}</td>
                                        <td className="px-4 py-3">{feature?.featureName}</td>
                                        <td className="px-4 py-3 text-center">{feature?.category}</td>
                                        <td className="px-4 py-3 text-center">{feature?.position}</td>
                                        <td className="px-4 py-3">
                                            {/* <div className="mx-auto w-fit">
                                                <CustomBadges label={feature?.active ? "Active" : "Inactive"} type={feature?.active ? "success" : "error"} showIcon={false} />
                                            </div> */}
                                        </td>
                                        <td className="px-4 py-3 text-center">
                                            <div className="flex items-center justify-center" onClick={() => { setAddBundleModal(true); setMode("edit"); setSelectedFeature(feature) }}>
                                                <EditIcon size='w-6 h-6' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <AddBundleModal
                    active={addBundleModal}
                    setModal={setAddBundleModal}
                    onCancel={() => {
                        setAddBundleModal(false);
                    }}
                    onConfirm={() => {
                        setAddBundleModal(false);
                    }}
                    packageId={id}
                    categories={bundleData?.categories}
                    fetchPackage={fetchPackage}
                    bundleData={bundleData}
                    selectedFeature={selectedFeature}
                    mode={mode}
                />

            </div>

        </>
    )

}

export default Overview
