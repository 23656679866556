const ConversionsTable = ({ filteredUsers, currentPage, setCurrentPage , FormatAccountCreatedDate}) => {

    const ModulesPerPage = 40;
  
    const handlePageChange = (page: number) => {
      setCurrentPage(page);
    };
  
    const GetFeaturesPurchase = (featuresPurchase: any) => {
      return featuresPurchase.map((feature) => ({
        planName: feature.planName,
      }));
    };
  
  
  
  
    return(
      <div className="bg-white shadow-md sm:rounded-lg mt-4">
      <div className="overflow-x-auto">
        <table className="w-full mb-20 text-sm text-left text-gray-500">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50">
            <tr>
              <th scope="col" className="px-4 py-4">SR#</th>
              <th scope="col" className="px-4 py-4">Full Name</th>
              <th scope="col" className="px-4 py-4">Username</th>
              <th scope="col" className="px-4 py-4">Phone Number</th>
              <th scope="col" className="px-4 py-4">Features Purchased</th>
              <th scope="col" className="px-4 py-4">City</th>
              <th scope="col" className="px-4 py-4">Education</th>
              <th scope="col" className="px-4 py-4">Account Created</th>
            </tr>
          </thead>
          <tbody>
            {filteredUsers.slice((currentPage - 1) * ModulesPerPage, currentPage * ModulesPerPage).map((user, index) => (
              <tr className="border-b" key={user._id}>
                <td className="px-4 py-3">{index + 1 + (currentPage - 1) * ModulesPerPage}</td>
                <td className="px-4 py-3">{user.fullname}</td>
                <td className="px-4 py-3">{user.username}</td>
                <td className="px-4 py-3">{user.phonenumber}</td>
                <td className="px-4 py-3">
                  {user.featuresPurchased && GetFeaturesPurchase(user.featuresPurchased).map((feature, index) => (
                    <div key={index} className='flex flex-row justify-between gap-1'>
                      <p>{feature.planName}</p>
                    </div>
                  ))}
                </td>
                <td className="px-4 py-3">{user.city}</td>
                <td className="px-4 py-3">
                  {user?.info?.educationSystem
                    ? user.info.educationSystem
                    : (user?.info?.exam
                      ? user.info.exam.map((ex) => ex).join(', ')
                      : 'No information available')}
                </td>
                <td className="px-4 py-3">{FormatAccountCreatedDate(user.accountcreateddate)}</td>
  
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <nav className="flex flex-col md:flex-row flex-wrap justify-between items-start md:items-center space-y-3 md:space-y-0 p-4" aria-label="Table navigation">
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * ModulesPerPage - ModulesPerPage + 1}
          </span>
          <span> to</span>
          <span className="font-semibold text-gray-900">
            {Math.min(currentPage * ModulesPerPage, filteredUsers.length)}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">
            {filteredUsers.length}
          </span>
        </span>
        <ul className="inline-flex flex-wrap cursor-pointer items-stretch -space-x-px">
          <li>
            <button className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={() => handlePageChange(Math.max(currentPage - 1, 1))}>
              <span className="sr-only">Previous</span>
              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </li>
          {Array.from({ length: Math.ceil(filteredUsers.length / ModulesPerPage) }).map((_, index) => (
            <li key={index}>
              <button className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 ${currentPage === index + 1 ? 'font-semibold text-primary-600 bg-primary-50' : ''}`} onClick={() => handlePageChange(index + 1)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li>
            <button className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover:text-gray-700" onClick={() => handlePageChange(Math.min(currentPage + 1, Math.ceil(filteredUsers.length / ModulesPerPage)))}>
              <span className="sr-only">Next</span>
              <svg className="w-5 h-5" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
              </svg>
            </button>
          </li>
        </ul>
      </nav>
    </div>
  
    )
  }
  

  export default ConversionsTable;