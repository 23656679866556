import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Image, message, Space, Tag } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { getBase64ImageData } from "utility/form/functions";
import * as Yup from "yup";
import UniversityModal from "./modal";
import { FormValues, UniversityData } from "./types";
const University: React.FC = () => {
  const [isAddModal, setIsAddModal] = useState<boolean>(false);
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [hierarchyData, setHierarchyData] = useState<UniversityData[]>([]);
  const [editMode, setEditMode] = useState<boolean>(false);

  const fetchHierarcyData = async () => {
    try {
      setIsFetchingData(true);
      const res = await apiRequest("getGalleryImages");
      if (res?.status === 200) {
        const data = res?.data;
        const formatedData = data?.map((item: UniversityData) => ({
          ...item,
          thumbnailImageUrl: item?.thumbnailImageUrl,
          isPublish: {
            id: item?._id,
            isPublish: item?.isPublish,
          },
        }));
        setHierarchyData(formatedData);
        setIsFetchingData(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    fetchHierarcyData();
  }, []);

  function getTagColor(index: number): string {
    const colors = [
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
    ];
    return colors[index % colors.length];
  }

  const validationSchema = Yup.object({
    category: Yup.array()
      .required("At least one tag is required")
      .min(1, "At least one tag is required"),
    universityName: Yup.string().required("Required"),
    thumbnailImage: Yup.mixed()
      .required("Thumbnail image is required")
      .test("is-valid-type", "Invalid image type", function (value: any) {
        console.log("value", value);
        if (!value) return true; // Skip validation if no value provided
        const newVal = value?.[0]?.type;
        return ["image/jpeg", "image/jpg", "image/png"].includes(
          newVal?.toLowerCase()
        );
      }),
    gallery: Yup.array()
      .required("Gallery images are required")
      .of(
        Yup.mixed().test(
          "is-valid-type",
          "Invalid image type",
          function (value: any) {
            if (!value) return true;
            const newVal = value?.type;
            return ["image/jpeg", "image/jpg", "image/png"].includes(
              newVal?.toLowerCase()
            );
          }
        )
      )
      .min(1, "At least one image is required in the gallery"),
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      entity: "",
      category: [],
      universityName: "",
      galleryGeneratedUrl: [],
      editId: "",
      gallery: [],
      thumbnailImage: [],
      thumbnailImageUrl: "",
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const base64gallery = await Promise.all(
          values?.gallery
            ?.filter((file: any) => !file?.isOldFile)
            ?.map(async (file: any) => {
              if (!file?.isOldFile) {
                if (!file?.url && !file?.preview) {
                  return await getBase64ImageData(file.originFileObj);
                }
              }
              return file?.preview || file?.url;
            })
        );
        const generatedGalleryUrl = values?.gallery
          ?.filter((file: any) => file?.isOldFile)
          ?.map((file: any) => file?.url);
        const base64ThumbnailImage = await Promise.all(
          values?.thumbnailImage
            ?.filter((file: any) => !file?.isOldFile)
            ?.map(async (file: any) => {
              if (!file?.url && !file?.preview) {
                return await getBase64ImageData(file.originFileObj);
              }
              return file?.preview || file?.url;
            })
        );

        const thumbnailImageUrl = values?.thumbnailImage
          ?.filter((file: any) => file?.isOldFile)
          ?.map((file: any) => file?.url);

        const payload = {
          generatedGalleryUrl,
          universityName: values?.universityName,
          category: process.env.REACT_APP_CATEGORY,
          thumbnailImageUrl: thumbnailImageUrl?.[0],
          gallery: base64gallery,
          thumbnailImageData: base64ThumbnailImage?.[0],
        };
        let res;
        if (editMode) {
          res = await apiRequest("updateHierarchyUniversity", payload, [
            values?.editId,
          ]);
        } else {
          res = await apiRequest("addHierarchyUniversity", payload);
        }
        if (res?.status === 200) {
          fetchHierarcyData();
          message.success(res?.data?.message);
          resetForm();
          setIsAddModal(false);
          setEditMode(false);
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const handleEdit = (data: UniversityData) => {
    const {
      galleryGeneratedUrl,
      gallery,
      thumbnailImageUrl,
      universityName,
      category,
    } = data;
    const formatedGalleryData = gallery?.map((item, index) => {
      const imgType = item
        .substring(item.lastIndexOf("/") + 1)
        ?.split(".")
        ?.pop();
      return {
        uid: index + 1,
        name: item,
        status: "done",
        url: item,
        type: `image/${imgType}`,
        isOldFile: true,
      };
    });
    const imgType = thumbnailImageUrl
      .substring(thumbnailImageUrl.lastIndexOf("/") + 1)
      ?.split(".")
      ?.pop();
    const formatedThumbnailImage = {
      uid: 1,
      name: "image.png",
      status: "done",
      url: thumbnailImageUrl,
      type: `image/${imgType}`,
      isOldFile: true,
    };

    // setValues({
    //   galleryGeneratedUrl,
    //   thumbnailImageUrl,
    //   universityName,
    //   category,
    //   gallery: formatedGalleryData,
    //   thumbnailImage: [formatedThumbnailImage],
    //   editId: data?._id,
    // });
    // setFile(formatedThumbnailImage);
    setIsAddModal(true);
    setEditMode(true);
  };

  const handleTableImageClick = (url: string) => {
    // setIsOpenImageTableModal(true);
  };

  const handlePublish = async (data: { id: string; isPublish: boolean }) => {
    try {
      const res = await apiRequest("updateHierarchyUniversitystatus", null, [
        data?.id,
      ]);
      if (res?.status === 200) {
        fetchHierarcyData();
        message.success(res?.data?.message);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const columns = [
    {
      title: "S.No",
      key: "index",
      render: (text: any, record: UniversityData, index: number) => index + 1,
    },
    {
      title: "Name",
      dataIndex: "universityName",
      key: "universityName",
    },
    {
      title: "Thumbnail",
      key: "thumbnailImageUrl",
      render: (text: any, record: UniversityData) => (
        <Image width={100} src={record?.thumbnailImageUrl} />
      ),
    },
    {
      title: "Category",
      key: "category",
      render: (text: any, record: UniversityData) => (
        <Space>
          {record?.category?.map((cat: string, index: number) => (
            <Tag color={getTagColor(index)} key={cat}>
              {cat}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Gallery",
      key: "gallery",
      render: (text: any, record: UniversityData) => (
        <Button
          type="primary"
          onClick={() => handleTableImageClick(record?.galleryGeneratedUrl[0])}
        >
          View Gallery
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (data: any) => (
        <Space>
          <GlobalPublishButton
            onChange={() => handlePublish(data)}
            isActive={data?.isPublish}
          />
          <Button type="default" onClick={() => handleEdit(data)}>
            Edit
          </Button>
        </Space>
      ),
    },
  ];

  return (
    <div>
      <Loader loading={isFetchingData} />
      <div className="main-content">
        <div className="flex justify-end mx-10">
          <CustomButton
            icon={<PlusOutlined />}
            text="Add New University"
            onClick={() => {
              setIsAddModal(true);
            }}
          />
        </div>
        <GlobalTable
          columns={columns}
          data={hierarchyData}
          loading={isFetchingData}
        />
        <UniversityModal isAddModal ={isAddModal} editMode={editMode} setIsAddModal={setIsAddModal} formik ={formik}/>
        {/* <GlobalModal
          visible={isOpenImageTableModal}
          title="Gallery"
          onCancel={() => setIsOpenImageTableModal(false)}
        >
          <Image.PreviewGroup>
            {imageTableUrl && (
              <Image
                src={imageTableUrl}
                onClick={() => setPreviewOpen(true)}
              />
            )}
          </Image.PreviewGroup>
          <Image
            preview={{ visible: previewOpen, onVisibleChange: (vis) => setPreviewOpen(vis) }}
            src={imageTableUrl}
          />
        </GlobalModal>
        <GlobalModal
          visible={thumbnailImagePreviewOpen}
          title="Thumbnail Preview"
          onCancel={() => setThumbnailImagePreviewOpen(false)}
        >
          {thumbnailImagePreview.map((url, index) => (
            <Image key={index} width={200} src={url} />
          ))}
        </GlobalModal> */}
      </div>
    </div>
  );
};

export default University;
