import { categoryValues } from "constants/constantvalues";
import { Dayjs } from "dayjs";
import { FormikProps } from "formik";
import React from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import { allCities, allStates } from "./cities-provinces-data";

interface SearchFieldsComponentProps {
  isOpenModal: boolean;
  formik: FormikProps<FilterFormValues>;
  setOpenModel: (isOpen: boolean) => void;
  BundlesData: Array<{ label: string; value: string }>;
}

interface FilterFormValues {
  startDate: Dayjs| null;
  endDate: Dayjs|  null;
  category: string | undefined;
  city: string | undefined;
  province: string | undefined;
  planNames: string[];
}

const SearchFieldsComponent: React.FC<SearchFieldsComponentProps> = ({
  isOpenModal,
  formik,
  setOpenModel,
  BundlesData,
}) => {
  const formatedCities = allCities?.map((item) => {
    return {
      ...item,
      label: item?.name,
      value: item?.name,
    };
  });

  const formatedStates = allStates?.map((item) => {
    return {
      ...item,
      label: item?.name === "Khyber Pakhtunkhwa" ? "KPK" : item?.name,
      value: item?.name === "Khyber Pakhtunkhwa" ? "KPK" : item?.name,
    };
  });

  const {
    isSubmitting,
  } = formik;

  return (
    <div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col md:flex-row gap-4">
          <div className="w-full md:w-1/5">
            <CustomFormField type="date" formik={formik} showTime={true} format="YYYY-MM-DD HH:mm" label="From Account Created Date" name="startDate" />
          </div>
          <div className="w-full md:w-1/5">
             <CustomFormField type="date" formik={formik} showTime={true} format="YYYY-MM-DD HH:mm" label="To Account Created Date" name="endDate" />
          </div>
          <div className="w-full md:w-1/6">
        
            <CustomFormField require={false} type="select" formik={formik} label="Category" name="category" options={categoryValues} />
          </div>
          <div className="w-full md:w-1/6">
          
            <CustomFormField require={false} type="select" formik={formik} label="City" name="city" options={formatedCities} />
          </div>
          <div className="w-full md:w-1/6">
            <CustomFormField require={false} type="select" formik={formik} label="Province" name="province" options={formatedStates} />
          </div>
          <div className="w-full md:w-1/6">
            <CustomFormField require={false} type="select" formik={formik} label="SKUs" name="planNames" options={BundlesData} mode="multiple" />
          </div>
          <div className="flex justify-end items-end w-full md:w-1/12">
            <CustomButton
              text="Search"
              onClick={formik.submitForm}
              disabled={isSubmitting}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchFieldsComponent;
