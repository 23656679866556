import { Button, message, Select } from "antd";
import React, { useEffect, useState } from "react";
import { OptionType, Pagination, PaginationComponentProps } from "./types"; // Assuming types are in a separate file
import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";

const PaginationComponent: React.FC<PaginationComponentProps> = ({
  formik,
}) => {
  const { values, setFieldValue, handleChange, errors, touched } = formik;
  const [allSubTopicsData, setAllSubTopicsData] = useState([]);
  const fetchData = async () => {
    try {
      const res = await apiRequest("getalltopics");
      if (res?.status === 200) {
        const topicsData = res?.data?.data;
        let allSubTopics = [];
        topicsData?.forEach((topic) => {
          topic?.subTopics?.forEach((subTopic) => {
            allSubTopics?.push(subTopic);
          });
        });
        const formatedAllSubTopics = allSubTopics?.map((subTopic) => ({
          value: subTopic?._id,
          label: subTopic?.subTopicName,
        }));
        setAllSubTopicsData(formatedAllSubTopics);
      }
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleAddpagination = () => {
    setFieldValue("paginations", [
      ...values?.paginations,
      { subTopic: "", startPageNo: "", endPageNo: "" },
    ]);
  };

  const handleRemovepagination = (index: number) => {
    const updatedpaginations = [...values?.paginations];
    updatedpaginations.splice(index, 1);
    setFieldValue("paginations", updatedpaginations);
  };

  const handlepaginationChange = (
    index: number,
    key: keyof Pagination,
    value: string
  ) => {
    const updatedpaginations = [...values?.paginations];
    updatedpaginations[index][key] = value;
    setFieldValue("paginations", updatedpaginations);
  };
  const filterOption = (input: string, option?: OptionType) =>
    (option?.label ?? "")?.toLowerCase()?.includes(input.toLowerCase());
  return (
    <>
      {values?.paginations?.length > 0 &&
        values?.paginations?.map((pagination: Pagination, index: number) => (
          <div
            key={index}
            className="overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-4 mb-4"
          >
            <div className="mx-auto p-2 pt-0">
              <div className="flex justify-between my-1 mb-2">
                <label
                  className="text-md font-bold text-gray-900 mt-2"
                  htmlFor={`paginationsubTopic${index}`}
                >
                  Pagination {index + 1}
                </label>
                <button
                  className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                  onClick={() => handleRemovepagination(index)}
                >
                  Remove Pagination
                </button>
              </div>
              <div className="md:p-2 w-full">
                <label
                  htmlFor="name"
                  className="text-md font-bold text-gray-900 mt-2.5"
                >
                  SubTopic <span className="text-red-600">*</span>
                </label>
                <div>
                  <Select
                    showSearch
                    placeholder="Select a subTopic"
                    id={`paginationsubTopic${index}`}
                    defaultValue={values?.subTopic || null}
                    onSelect={handleChange}
                    filterOption={filterOption}
                    value={pagination.subTopic}
                    onChange={(value, option: OptionType) =>
                      handlepaginationChange(index, "subTopic", option?.label)
                    }
                    className="my-1 py-0"
                    style={{ width: "100%" }}
                    options={allSubTopicsData}
                  />
                </div>

                {errors?.paginations?.[index]?.subTopic &&
                  touched?.paginations?.[index]?.subTopic && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors?.paginations?.[index]?.subTopic}
                    </div>
                  )}
              </div>
              <div className="flex flex-col md:flex-row gap-2">
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor={`paginationpageNo${index}`}
                    className="text-md font-bold text-gray-900 mt-2"
                  >
                    Start PageNo:
                  </label>
                  <input
                    id={`paginationStartpageNo${index}`}
                    type="number"
                    className="w-full bg-gray-100 bg-opacity-50 border text-black rounded focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base outline-none py-1 px-3 transition-colors duration-200 ease-in-out"
                    value={pagination.startPageNo}
                    onChange={(e) =>
                      handlepaginationChange(
                        index,
                        "startPageNo",
                        e.target.value
                      )
                    }
                  />
                  {errors?.paginations?.[index]?.startPageNo &&
                    touched?.paginations?.[index]?.startPageNo && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors?.paginations?.[index]?.startPageNo}
                      </div>
                    )}
                </div>
                <div className="w-full md:w-1/2">
                  <label
                    htmlFor={`paginationpageNo${index}`}
                    className="text-md font-bold text-gray-900 mt-2"
                  >
                    End PageNo:
                  </label>
                  <input
                    id={`paginationEndpageNo${index}`}
                    type="number"
                    className="w-full bg-gray-100 bg-opacity-50 border text-black rounded focus:border-red-500 focus:bg-white focus:ring-2 focus:ring-red-200 text-base outline-none py-1 px-3 transition-colors duration-200 ease-in-out"
                    value={pagination.endPageNo}
                    onChange={(e) =>
                      handlepaginationChange(index, "endPageNo", e.target.value)
                    }
                  />
                  {errors?.paginations?.[index]?.endPageNo &&
                    touched?.paginations?.[index]?.endPageNo && (
                      <div className="text-red-500 text-sm mt-1">
                        {errors?.paginations?.[index]?.endPageNo}
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        ))}

      <Button
        className="border-0 text-lg text-blue-500"
        icon={<PlusOutlined />}
        onClick={handleAddpagination}
      >
        Add {values?.paginations?.length > 0 ? <span>More</span> : null}{" "}
        Pagination
      </Button>
    </>
  );
};

export default PaginationComponent;
