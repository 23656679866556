import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const BasicGetAPIs: ApiInterface[] = [
  {
    url: "/api/get/get-institutes",
    method: "GET",
    name: "getAllInstituteNames",
  },
  {
    url: "/api/get/get-subjects",
    method: "GET",
    name: "getUniqueSubjects",
  },
  {
    url: "/api/get/get-topics",
    method: "GET",
    name: "getUniqueTopics",
  },
  {
    url: "/api/get/get-users",
    method: "GET",
    name: "getUserInfo",
  },
  {
    url: "/api/get/get-signup-statistics",
    method: "GET",
    name: "getSignupStatistics",
  },
];

export function BasicGetApi(
  name: string,
  params: string[] = [""],
  data: any = null,
  queryParams: Record<string, any> = {}
) {
  const api = BasicGetAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url =
    backendUrl + api.url + (params.length ? "/" + params.join("/") : "");

  // Convert queryParams object to a query string
  const queryString = new URLSearchParams(queryParams).toString();

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url: queryString ? `${url}?${queryString}` : url,
  });
}
