import { apiRequest } from "(apis)/api-interface/api-handler";
import EditIcon from "@material-ui/icons/Edit";
import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import { useFormik } from "formik";
import { useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import * as Yup from "yup";

const Decks = ({ id, getScheduleData, scheduledData, loading }) => {
  const [isAddingDeck, setIsAddingDeck] = useState(false);
  const [isEditMode, setIsIsEditMode] = useState(false);
  const initialValues = {
    deckName: "",
    deckLink: "",
    deckId: null,
  };
  const validationSchema = Yup.object().shape({
    deckName: isEditMode
      ? Yup.string().required("Name is required")
      : Yup.string()
          .required("Name is required")
          .test("unique", "Deck name already exist.", function (value) {
            const existingDeckNames = scheduledData?.deckLinks?.map(
              (deck) => deck.deckName
            );
            return !existingDeckNames?.includes(value);
          }),
    deckId: Yup.string().nullable(),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        setIsAddingDeck(true);
        const { deckName, deckId, deckLink } = values;
        const payload = {
          deckName,
          deckId:deckId?.trim(),
          deckLink:deckLink?.trim(),
        };
        let res;
        if (isEditMode) {
          res = await apiRequest("updateManageScheduleDeck", payload, [
            id,
            values?.deckId,
          ]);
        } else {
          res = await apiRequest("addManageScheduleDeck", payload, [id]);
        }
        if (res?.status === 200) {
          setIsAddingDeck(false);
          setIsIsEditMode(false);
          message.success(res?.data?.message);
          getScheduleData();
          resetForm();
        }
      } catch (error) {
        setIsAddingDeck(false);
        message.error(error?.response?.data?.message);
      }
    },
  });

  const { handleSubmit, setValues, resetForm } = formik;
  const handleEdit = (data) => {
    setValues({
      deckName: data?.deckName || "",
      deckLink: data?.deckLink || "",
      deckId: data?.deckId || "",
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsIsEditMode(true);
  };
  interface DataType {
    deckName: string;
    isPublished: boolean;
    deckLink: string;
    // Add other fields as needed
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "DECK NAME",
      dataIndex: "deckName",
      key: "deckName",
      width: 200,
    },
    {
      title: "DECK ID",
      dataIndex: "deckId",
      key: "deckId",
      width: 200,
    },
    {
      title: "DECK LINK",
      dataIndex: "deckLink",
      key: "deckLink",
      width: 200,
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      width: 100,
      render: (data) => {
        return (
          <div className="flex gap-4 justify-center">
            <div>
              <EditIcon
                onClick={() => handleEdit(data)}
                className="cursor-pointer"
                color="primary"
              />
            </div>
          </div>
        );
      },
    },
  ];
  const formattedDeckData = scheduledData?.deckLinks?.map(
    ({ deckId, deckLink, deckName, _id }, i) => {
      // Updated data mapping
      return {
        key: i + 1,
        deckId,
        deckName,
        deckLink,
        id: _id,
      };
    }
  );
  return (
    <div className="">
      <form onSubmit={handleSubmit} className="rounded-lg shadow p-4 bg-white">
        <div className="flex flex-row w-full">
          <div className="md:p-2 w-full flex gap-4">
            <div className=" w-1/2 relative">
              <CustomFormField
                name="deckId"
                label="Deck ID"
                type="text"
                formik={formik}
              />
            </div>
            <div className=" w-1/2 relative">
              <CustomFormField
                name="deckName"
                label=" Deck Name"
                type="text"
                formik={formik}
              />
            </div>
            <div className=" w-1/2 relative">
              <CustomFormField
                name="deckLink"
                label="Deck Link"
                type="text"
                formik={formik}
              />
            </div>
          </div>
          {/* </div> */}
        </div>
        <div className="flex justify-end">
          <CustomButton
            disabled={isAddingDeck}
            onClick={()=>formik.submitForm()}
            isLoading={formik.isSubmitting}
            text={`${
              isAddingDeck
                ? "Adding..."
                : isEditMode
                ? "Edit Confirm"
                : "Add Deck"
            }`}
          />
        </div>
      </form>
      <Loader loading={loading} />
      {formattedDeckData?.length > 0 && (
        <GlobalTable
          columns={columns}
          data={formattedDeckData}
          bordered={true}
          searchPlaceholder="Search Decks"
          loading={loading}
        />
      )}
    </div>
  );
};

export default Decks;
