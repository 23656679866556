
import axios from "axios";
import { backendUrl } from "../../../constants/config";
import { ApiInterface } from "../../../interfaces/global-interfaces";

const APIs: ApiInterface[] = [
    {
        url: "/api/deckgroups/addDeckGroup",
        method: "POST",
        name: "addDeckGroup"
    },
    {
        url: "/api/deckgroups/updateDeckGroup",
        method: "PATCH",
        name: "updateDeckGroup"
    },
    {
        url: "/api/deckgroups/getDeckGroups",
        method: "GET",
        name: "getDeckGroups"
    },
    {
        url: "/api/deckgroups/handle-publish",
        method: "GET",
        name: "handlePublish"
    },
    {
        url: "/api/deckgroups/get-decks",
        method: "GET",
        name: "getDecksOfDeckGroup"
    },
    {
        url: "/api/deckgroups/update-deckgroup-order",
        method: "PATCH",
        name: "updateDeckGroupOrder"
    }
];



export function DeckGroupAPI(name: string, params: string = "", data: any = null) {
    const api = APIs.find((api) => api.name === name);
    if (!api) {
        throw new Error("API not found");
    }

    const url = backendUrl + api.url + (params ? `/${params}` : "");
    console.log(url);

    return axios({
        method: api.method,
        data,
        url
    });
}

// import axios from "axios";
// import { backendUrl } from "../Constants/appConstants";

// export async function AddDeckGroup(payload) {
//     return axios.post(`${backendUrl}/api/addDeckGroup`, payload);
// }

// export async function UpdateDeckGroup(payload , id) {
//     return axios.patch(`${backendUrl}/api/updateDeckGroup`, {payload , id});
// }

// export async function GetDeckGroups() {
//     return axios.get(`${backendUrl}/api/getDeckGroups`);
// }

// export async function HandlePublish(id){
//     return axios.get(`${backendUrl}/api/deckgroups/handle-publish/${id}`)
// }

// export async function GetDecksOfDeckGroup(deckName, category){
//     return axios.get(`${backendUrl}/api/deckgroups/get-decks/${category}/${deckName}`)
// }

// export async function updateDeckGroupOrderApi(deckGroupName, payload){
//     return axios.patch(`${backendUrl}/api/deckgroups/update-deckgroup-order`, { deckGroupName , payload });
// }


