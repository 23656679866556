import React from 'react';
import CustomAntdModal from '../../shared-components/modal/CustomAntdModal';
import { Divider } from 'antd';

interface ViewScholarshipProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
    clickedInfoData: any;
}

const ViewScholarship: React.FC<ViewScholarshipProps> = ({ isOpen, setIsOpen,clickedInfoData }) => {
    console.log("clickedInfoData",clickedInfoData)
  return (
    <div>
      <CustomAntdModal
        isOpenModal={isOpen}
        setIsOpenModal={setIsOpen}
        title="Student Information"
        isFooter={false}
        okButtonProps={{ type: "primary" }}
      >
      <div className="flex flex-col md:flex-row gap-8 mt-6 md:mt-0">
      <div className=" w-full  md:w-1/3 bg-gray-300 shadow-md rounded-xl px-8 pt-6 pb-8 mb-4 ">
            <div className="flex flex-col justify-center items-center  gap-8 ">
              <div>
                <div className="relative inline-flex items-center justify-center w-40 h-40 overflow-hidden bg-gray-100 rounded-full dark:bg-gray-600">
                  <span className="font-extrabold text-6xl text-green-600  dark:text-gray-300">
                    {clickedInfoData?.Fname?.toUpperCase()?.charAt(0)}
                  </span>
                </div>
              </div>
              <div className="flex flex-col gap-4 ml-4">
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600"> Name</div>
                  <div className="text-black text-lg">
                    {clickedInfoData?.Fname}{" "}
                  </div>
                </div>
             
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">Phone</div>
                  <div className="text-black text-lg">{clickedInfoData?.PhoneNumber} </div>
                </div>
                <div className="flex flex-col gap-1 flex-wrap">
                  <div className=" text-gray-600">Email</div>
                  <div className="text-black text-xs font-semibold">{clickedInfoData?.Email} </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">City</div>
                  <div className="text-black text-lg">{clickedInfoData?.City} </div>
                </div>
                <div className="flex flex-col gap-1">
                  <div className=" text-gray-600">Status</div>
                  <div className="text-black text-lg">{clickedInfoData?.Status} </div>
                </div>
              </div>
            </div>
          </div>
          <div className='w-full md:w-2/3'>
<div>
   <div className='text-xl font-bold'>
   Detials
    </div>
    <div>
        <Divider>Reasons for Applying</Divider>
        <p>{clickedInfoData?.Reason}</p>
    </div>
    <div>
        <Divider>Why do you love PreMed.pk</Divider>
        <p>{clickedInfoData?.WhyPremed}</p>
    </div>
    <div>
        <Divider>Applied For</Divider>
        <p>{clickedInfoData?.AppliedFor}</p>
    </div>
</div>
          </div>
      </div>
      </CustomAntdModal>
    </div>
  );
};

export default ViewScholarship;






