import { ApiInterface } from "../api-interface/types";

export const topicsApis: ApiInterface[] = [
  {
    url: `/api/topics/getAllTopics`,
    method: "GET",
    name: "getalltopics",
  },
  {
    url: `/api/topics/addTopics`,
    method: "POST",
    name: "addTopic",
  },
  {
    url: `/api/topics/updateTopic/:id`,
    method: "PUT",
    name: "updateTopic",
  },
  {
    url: `/api/topics/updateTopicIsPublished/:id`,
    method: "PUT",
    name: "updateTopicPublish",
  },
];
