import { isAxiosError } from "axios";
import React, { useEffect, useState } from "react";
import { ConfirmationModal } from "../../../shared-components/ui/CustomModal";
import { Input, Label } from "../../../shared-components/ui/CustomForm";
import {
  ButtonFill,
  ButtonOutlined,
} from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { toast } from "react-hot-toast";
import { ModuleAPI } from "../../../(apis)/(shared-apis)/modules-apis";

export const CreateModule = (props) => {
  const InitialErrors = {
    moduleNameError: {
      error: false,
      message: "",
    },
  };
  const [errors, setErrors] = useState(InitialErrors);
  const initialState = {
    _id: "",
    moduleName: "",
    metadata: {
      entity: process.env.REACT_APP_ENTITY,
      category: process.env.REACT_APP_CATEGORY,
  },
    moduleDescription: "",
    imageAltText: "",
  };
  const [formData, setFormData] = useState(initialState);
  const [isLoading, setIsLoading] = useState(false);
  const validateForm = () => {
    const newErrors = { ...errors };
    let isError = false;
    if (!formData.moduleName) {
      newErrors.moduleNameError.error = true;
      newErrors.moduleNameError.message = "Module Name is required";
      isError = true;
    } else {
      newErrors.moduleNameError.error = false;
      newErrors.moduleNameError.message = "";
    }

    setErrors(newErrors);
    return isError;
  };

  useEffect(() => {
    if (props.module) {
      setFormData(props.module);
    }
  }, [props.module]);

  const handleInputChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [disableCreateButton, setDisableCreateButton] = useState(false);
  const resetAll = () => {
    setFormData(initialState);
    props.setModal(false);
    setConfirmationModal(false);
  };
  const Submit = async () => {
    if (validateForm()) {
      return;
    }
    setDisableCreateButton(true);

    try {
      let response = null;
      if (props.mode === "edit") {
        response = await ModuleAPI("updateModuleById", [formData._id], formData);
      } else {
        response = await ModuleAPI("addModule", [], formData);
      }
      if (response) {
        props?.mode === "edit"
          ? toast.success("Updated Successfully 🚀")
          : toast.success("Added Successfully 🚀");
        resetAll();
        console.log("response: ", response);
      }
    } catch (error) {
      console.log("Add Module Error: ", error);
    } finally {
      setIsLoading(false);
    }

    setDisableCreateButton(false);
  };

  const [confirmationModal, setConfirmationModal] = useState(false);

  return (
    <div
      id="createProductModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
    >
      <div className="relative w-full max-w-2xl max-h-full p-4">
        <div className="relative p-4 bg-white rounded-lg shadow ">
          <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
            <h3 className="text-lg font-semibold text-gray-900 ">
              {props.mode === "add" ? "Add New Module" : "Edit Module"}
            </h3>
            {/* <MyToast /> */}
            <button
              type="button"
              className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
              data-modal-target="createProductModal"
              data-modal-toggle="createProductModal"
              onClick={() => {
                props.setModal(false);
              }}
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="space-y-2">
            <div className="space-y-2">
              <Label>Module Name</Label>
              <Input
                type="text"
                placeholder="Enter Module Name"
                value={formData.moduleName}
                name="moduleName"
                onChange={handleInputChange}
                isError={errors.moduleNameError.error}
                errorMessage={errors.moduleNameError.message}
              />
            </div>

            <div className="flex items-center space-x-3 w-full ">
              <CustomDropDown
                label="Select Entity"
                options={["ENTRANCE EXAM", "HIGH SCHOOL", "UNDERGRADUATE"]}
                disabled={true}
                onSelect={(selectedEntity) =>
                  setFormData({
                    ...formData,
                    metadata: { ...formData.metadata, entity: selectedEntity },
                  })
                }
                width="w-full"
                value={formData?.metadata?.entity}
              />
            </div>
            <div className="flex items-center space-x-3 w-full ">
              <CustomDropDown
                label="Select Category"
                options={["PRE-MEDICAL", "MEDSCHOOL"]}
                disabled={true}
                onSelect={(selectedCategory) =>
                  setFormData({
                    ...formData,
                    metadata: {
                      ...formData.metadata,
                      category: selectedCategory,
                    },
                  })
                }
                width="w-full"
                value={formData?.metadata?.category}
              />
            </div>

            <div className="flex items-center justify-end gap-2">
              <ButtonOutlined
                disabled={isLoading}
                isLoading={isLoading}
                handleClick={() => {
                  if (
                    props.module &&
                    JSON.stringify(formData) !== JSON.stringify(props.module)
                  ) {
                    setConfirmationModal(true);
                  } else if (
                    formData.moduleName !== "" ||
                    formData.moduleDescription !== "" ||
                    formData.imageAltText !== ""
                  ) {
                    setConfirmationModal(true);
                  } else {
                    props.setModal(false);
                  }
                }}
              >
                Cancel
              </ButtonOutlined>
              <ButtonFill
                isLoading={isLoading}
                handleClick={Submit}
                disabled={disableCreateButton}
              >
                {props.mode === "add" ? "Create" : "Update"}
              </ButtonFill>
            </div>
          </div>
          <ConfirmationModal
            active={confirmationModal}
            onCancel={() => {
              setConfirmationModal(false);
            }}
            onConfirm={() => {
              setFormData(initialState);
              props.setModal(false);
              setConfirmationModal(false);
            }}
            message={"Are you sure you want to leave this page?"}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModule;
