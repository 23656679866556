import { ResponsiveBar } from "@nivo/bar";

const QuarterlySignUpsChart = ({ data }) => {
  const getQuarterlyData = (monthlyData) => {
    return Object.entries(monthlyData).map(([year, months]) => ({
      year,
      Q1: months["1"] + months["2"] + months["3"],
      Q2: months["4"] + months["5"] + months["6"],
      Q3: months["7"] + months["8"] + months["9"],
      Q4: months["10"] + months["11"] + months["12"],
    }));
  };

  const quarterlyData = getQuarterlyData(data.monthlySignupsData);
  if (!data) return;
  return (
    <ResponsiveBar
      data={quarterlyData}
      keys={["Q1", "Q2", "Q3", "Q4"]}
      indexBy="year"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Year",
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: "Signups",
        legendPosition: "middle",
        legendOffset: -40,
      }}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
      legends={[
        {
          dataFrom: "keys",
          anchor: "bottom-right",
          direction: "column",
          justify: false,
          translateX: 120,
          translateY: 0,
          itemsSpacing: 2,
          itemWidth: 100,
          itemHeight: 20,
          itemDirection: "left-to-right",
          itemOpacity: 0.85,
          symbolSize: 20,
          effects: [
            {
              on: "hover",
              style: {
                itemOpacity: 1,
              },
            },
          ],
        },
      ]}
      animate={true}
      //   motionStiffness={90}
      //   motionDamping={15}
    />
  );
};

export default QuarterlySignUpsChart;
