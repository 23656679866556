import { message } from "antd";
import React, { useEffect, useState } from "react";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { Board, DataState, Institute, Subject, Topic } from "./types";

import { FormikProps } from "formik";
import VaultFieldsComponent from "./vault-field-component";
interface VaultCommonComponentProps {
  
  formik: FormikProps<any>;
  vaultType: string;

  setIsEditMode?: (isOpen: boolean) => void;
}
const VaultCommonComponent: React.FC<VaultCommonComponentProps> = ({
  formik,vaultType
}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<DataState>({
    boardsData: [],
    allBoardsData: [],
    subjectData: [],
    allSubTopicsData: [],
    institutesData: [],
    allTopicsData: [],
    topicsData: [],
  });

  const fetchData = async () => {
    try {
      setLoading(true);

      const [topicsRes, boardsRes, subjectsRes, institutesRes] =
        await Promise.all([
          apiRequest("getalltopics"),
          apiRequest("gethierarchyboard"),
          apiRequest("getallsubjects"),
          apiRequest("getinstitutes"),
        ]);

      const newData: Partial<DataState> = {}; // Use Partial to allow missing properties

      if (institutesRes?.status === 200) {
        newData.institutesData = institutesRes.data.institutes.map(
          (item: Institute) => ({
            value: item.instituteName,
            label: item.instituteName,
          })
        );
      }

      if (topicsRes?.status === 200) {
        const topicsData: Topic[] = topicsRes.data.data;
        const allSubTopics = topicsData.flatMap(
          (topic: Topic) => topic.subTopics || []
        );
        newData.allSubTopicsData = allSubTopics.map((subTopic) => ({
          value: subTopic._id,
          label: subTopic.subTopicName,
        }));
        newData.topicsData = topicsData;
        newData.allTopicsData = topicsData;
      }

      if (boardsRes?.status === 200) {
        newData.boardsData = boardsRes.data.map((item: Board) => ({
          value: item.board,
          label: item.board,
        }));
        newData.allBoardsData = newData.boardsData;
      }

      if (subjectsRes?.status === 200) {
        newData.subjectData = subjectsRes.data.map((subject: Subject) => ({
          value: subject.name,
          label: subject.name,
        }));
      }
      setData((prevData) => ({ ...prevData, ...newData } as DataState));
    } catch (error) {
      message.error(error.response?.data?.message || error.message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const {
    boardsData,
    allBoardsData,
    subjectData,
    allSubTopicsData,
    institutesData,
    allTopicsData,
    topicsData,
  } = data;

  return (
    <>
        <div>
          <VaultFieldsComponent
          vaultType={vaultType}
            formik={formik}
            institutesData={institutesData}
            subjectData={subjectData}
            topicsData={topicsData}
            boardsData={boardsData}
          />
         
        </div>
  
    </>
  );
};

export default VaultCommonComponent;
