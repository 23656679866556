import { PlusOutlined } from "@ant-design/icons";
import { Input } from "antd";

import { Select, Upload } from "antd";
import { modulesReactQuill } from "constants/constantvalues";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
const { Option } = Select;

const TestimonialModal = (props) => {
  const uploadButtonStudentPicture = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Student Picture</div>
    </div>
  );
  const uploadButtonThumbnail = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload Thumbnail</div>
    </div>
  );
  const {
    handleSelectChange,
    formik,
    isAddModalVisible,
    isEditMode,
    clearSelectedVideo,
    handleChangeImage,
    handleChangeThumbnail,
    handleFileChangeVideo,
    handlePreview,
    videoPreviewUrl,
    file,
    boardValues,
    setIsAddModalVisible,
  } = props;
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    touched,
    errors,
    setFieldValue,
  } = formik;
  return (
    <div>
      <CustomAntdModal
        isOpenModal={isAddModalVisible}
        title={isEditMode ? `Edit Testimonial` : `Add Testimonial `}
        setIsOpenModal={setIsAddModalVisible}
        formik={formik}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col gap-2">
            <div className="flex flex-col md:flex-row gap-2">
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                  <CustomFormField
                    name="name"
                    label="Student Name"
                    type="text"
                    formik={formik}
                  />
                </div>
              </div>

              {/* Score */}
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                  <CustomFormField
                    name="score"
                    label="Score"
                    type="number"
                    formik={formik}
                  />
                </div>
              </div>
            </div>
            <div className="flex flex-col md:flex-row  gap-2">
              {/* Exam Name */}
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                  <CustomFormField
                    name="examName"
                    label="Exam Name"
                    type="text"
                    formik={formik}
                  />
                </div>
              </div>

              {/* University */}
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                  <CustomFormField
                    name="university"
                    label="University"
                    type="text"
                    formik={formik}
                  />
                </div>
              </div>
            </div>

            <div className="flex flex-col md:flex-row ">
              <div className="md:p-2 w-full md:w-1/2">
                <label
                  htmlFor="name"
                  className=" text-md font-bold text-gray-900 mt-2.5"
                >
                  Board <span className="text-red-600">*</span>
                </label>
                <div>
                  <Select
                    placeholder="Select Board"
                    className=" w-full my-1 py-0 "
                    onBlur={handleBlur}
                    onChange={(value) => {
                      handleSelectChange("board", value);
                    }}
                    value={
                      values?.board === "" ? "Select Board" : values?.board
                    }
                  >
                    {boardValues.map((board) => (
                      <Select.Option key={board} value={board}>
                        {board}
                      </Select.Option>
                    ))}
                  </Select>
                </div>
                {errors?.board && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors?.board}
                  </div>
                )}
              </div>
              <div className="md:p-2 w-full md:w-1/2">
                <CustomFormField
                  type="text"
                  name="classOf"
                  label="Class Of"
                  formik={formik}
                />
              </div>
            </div>

            <div className="flex flex-col md:flex-row  gap-2">
              {/* Type */}
              <div className="md:p-2 w-full md:w-1/2">
                <div className="relative">
                  <label
                    htmlFor="type"
                    className="text-md font-bold text-gray-900 mb-4"
                  >
                    Type<span className="text-red-700">*</span>
                  </label>
                  <Select
                    id="type"
                    value={values.type}
                    placeholder="Select Type"
                    onChange={(value) => {
                      setFieldValue("type", value);
                    }}
                    onBlur={handleBlur}
                    className="w-full "
                  >
                    <Option value="text">Text</Option>
                    <Option value="video">Video</Option>
                  </Select>
                  {touched.type && errors.type && (
                    <div className="text-red-500 text-sm mt-1">
                      {errors.type}
                    </div>
                  )}
                </div>
              </div>

              <div className="md:p-2 w-full md:w-1/2 flex items-end ">
                <div className="flex items-center ps-4 border border-gray-200 rounded w-full">
                  <input
                    id="bordered-checkbox-1"
                    type="checkbox"
                    onChange={handleChange}
                    name="isTopper"
                    value={values?.isTopper}
                    checked={values?.isTopper}
                    className="w-4 h-4 cursor-pointer text-pink-600 bg-gray-100 border-gray-300 rounded focus:ring-pink-500 accent-pink-600"
                  />
                  <label className="w-full py-2 ms-2 text-sm font-semibold text-gray-900 ">
                    Topper Review
                  </label>
                </div>
              </div>

              {/* Tags */}

              {/* {values?.type === "text" && (
              <div className="md:p-2 w-full md:w-1/2  ">
                <label
                  htmlFor="name"
                  className=" text-md font-bold text-gray-900 mt-2.5"
                >
                  Select Tag
                </label>
                <Select
                  showSearch
                //   optionFilterProp="children"
                  name="text.tag"
                  placeholder="Select Tag"
                //   defaultValue={values?.tag || ""}
                  value={values?.tag}
                  onChange={(value) => setFieldValue("text.tag", value)}
                  className="  my-1 py-0 "
                  style={{
                    width: "100%",
                  }}
                  options={tag}
                />
                {touched?.tag && errors?.tag && (
                  <div className="text-red-500 text-sm mt-1">
                    {errors.tag}
                  </div>
                )}
              </div>
            )} */}
            </div>
            {/* Text fields */}
            {values?.type === "text" && (
              <>
                <div className="flex flex-col md:flex-roww gap-2">
                  {/* Description */}
                  <div className="md:p-2 w-full mdm:w-1/2">
                    <div className="relative">
                      <label
                        htmlFor="text.description"
                        className="text-md font-bold text-gray-900 mb-4"
                      >
                        Description<span className="text-red-700">*</span>
                      </label>
                      <ReactQuill
                        className="bg-white rounded-sm text-black focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent "
                        theme="snow"
                        onChange={(html) => {
                          setFieldValue("text.description", html);
                        }}
                        value={values?.text?.description}
                        modules={modulesReactQuill}
                        placeholder="Enter Question Text"
                      />
                      {touched?.text &&
                        touched?.text?.description &&
                        errors?.text &&
                        errors?.text?.description && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors?.text?.description}
                          </div>
                        )}
                    </div>
                  </div>
                  {/* <div className="md:p-2 w-full md:w-1/2">
                    <div className="relative">
                      <label
                        htmlFor="text.description"
                        className="text-md font-bold text-gray-900 mb-4"
                      >
                        Description<span className="text-red-700">*</span>
                      </label>
                      <textarea
                        type="text"
                        id="text.description"
                        name="text.description"
                        value={values?.text?.description}
                        placeholder="Enter Description"
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="w-full globalInput"
                      />
                      {touched?.text &&
                        touched?.text?.description &&
                        errors?.text &&
                        errors?.text?.description && (
                          <div className="text-red-500 text-sm mt-1">
                            {errors?.text?.description}
                          </div>
                        )}
                    </div>
                  </div> */}
                  {/* Picture */}
                  <div className="md:p-2 w-full mdm:w-1/2">
                    <Upload
                      // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={
                        file ? [{ uid: "-1", name: "image", url: file }] : []
                      }
                      onPreview={handlePreview}
                      onChange={handleChangeImage}
                      accept="image/*"
                      maxCount={1}
                    >
                      {file ? null : uploadButtonStudentPicture}
                    </Upload>

                    {touched?.text?.picture && errors?.text?.picture && (
                      <div className="text-red-700">
                        {errors?.text?.picture}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
            {values?.type === "video" && (
              <>
                <div className="flex flex-col md:flex-row gap-2">
                  {/* Thumbnail */}
                  <div className="md:p-2 w-full md:w-1/2">
                    <Upload
                      // action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
                      listType="picture-card"
                      fileList={
                        file ? [{ uid: "-1", name: "image", url: file }] : []
                      }
                      onPreview={handlePreview}
                      onChange={handleChangeThumbnail}
                      accept="image/*"
                      maxCount={1}
                    >
                      {file ? null : uploadButtonThumbnail}
                    </Upload>

                    {touched?.video?.image && errors?.video?.image && (
                      <div className="text-red-700">{errors?.video?.image}</div>
                    )}
                  </div>

                  <div className="md:p-2 w-full md:w-1/2">
                    <div className="flex flex-col">
                      <div className="flex flex-row  items-center  bg-gray-100 p-2 rounded-md justify-center">
                        <div className="flex w-1/2 flex-col items-center gap-2">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-8 h-8"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                            />
                          </svg>
                          <button
                            className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                            onClick={(event) => {
                              event.preventDefault();
                              document.getElementById("videoFile").click();
                            }}
                          >
                            Upload Video
                          </button>
                          <p>File accepted: Video mp4</p>

                          <input
                            type="file"
                            id="videoFile"
                            name="videoFile"
                            accept="video/*"
                            className="hidden"
                            onChange={handleFileChangeVideo}
                          />
                        </div>
                        {isEditMode && values?.video.generatedVideoUrl ? (
                          <>
                            <div className="w-1/2 flex flex-col justify-center items-center gap-2">
                              <iframe
                                title="video"
                                className="w-52"
                                src={values?.video.generatedVideoUrl}
                              ></iframe>

                              {isEditMode &&
                                values?.video.generatedVideoUrl && (
                                  <button
                                    className="border  hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 my-0  px-4 rounded border-rose-500"
                                    onClick={clearSelectedVideo}
                                  >
                                    Remove Video
                                  </button>
                                )}
                            </div>
                          </>
                        ) : (
                          <>
                            {videoPreviewUrl && (
                              <div className="flex flex-col w-1/2 h-40 items-center gap-2 my-4  bg-gray-50 ">
                                <video
                                  controls
                                  className="h-40 w-40"
                                  style={
                                    {
                                      //   maxHeight: "240px",
                                    }
                                  }
                                >
                                  <source
                                    src={videoPreviewUrl}
                                    type="video/mp4"
                                  />
                                  Your browser does not support the video tag.
                                </video>
                                <div className="">
                                  <button
                                    className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                                    onClick={clearSelectedVideo}
                                  >
                                    Remove Video
                                  </button>
                                </div>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                    <div className="flex justify-center">
                      {touched?.video?.videoFile &&
                        errors?.video?.videoFile && (
                          <div className="text-red-700">
                            {errors?.video?.videoFile}
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </form>
      </CustomAntdModal>
    </div>
  );
};

export default TestimonialModal;
