export const initialValues = {
    type: "text", // Set default type
    name: "",
    score: "",
    examName: "",
    clickedTestimonialId: "",
    classOf: "",
    // videoFile: null,
    university: "",
    text: {
      picture: null,
      generatedPictureUrl: "",
      description: "",
      tags: [],
    },
    video: {
      generatedThumbnailUrl: "",
      generatedVideoUrl: "",
      videoFile: null,
      image: null,
    },
    metadata: {
      entity: "",
      category: "",
    },
    board: "",
    isTopper: false,
  };