import * as Yup from "yup";

const commonValidationSchema = Yup.object().shape({
  topicName: Yup.string().required("Topic Name is required"),
  // province: Yup.string().required("Province is required"),
  subject: Yup.string().required("Subject is required"),
  // desc: Yup.string().required("Description is required"),
  board: Yup.string().required("Board is required"),
  access: Yup.string().required("Access is required"),
  universities: Yup.array()
    .of(Yup.string())
    .nullable()
    .test("universities", "Universities is required", function (value) {
      if (
       process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
      ) {
        return value?.length > 0;
      }
      return true;
    }),
  year: Yup.string()
    .nullable()
    .test("year", "Year is required", function (value) {
      if (
        process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
      ) {
        return !!value;
      }
      return true;
    }),
});

const paginationSchema = Yup.array().of(
  Yup.object().shape({
    subTopic: Yup.string().required("Subtopic is required"),
    startPageNo: Yup.number().required("Start page number is required"),
    endPageNo: Yup.number().required("End page number is required")
      .test(
        "greater",
        "End page number should be greater than start page number",
        function (value) {
          const { startPageNo } = this.parent;
          console.log("val", value, startPageNo);
          if (value < startPageNo) {
            return false;
          }
          return true;
        }
      ),
  })
).required("Pagination is required");

const thumbnailImageSchema = Yup.mixed()
  .required("Thumbnail image is required")
  .test("is-valid-type", "Invalid image type", function (value: any) {
    if (!value) return true; // Skip validation if no value provided
    const newVal = value?.[0]?.type;
    return ["image/jpeg", "image/jpg", "image/png"].includes(
      newVal?.toLowerCase()
    );
  });
  const pdfFileSchema =  Yup.mixed().required().test("fileFormat", "Please upload a PDF file.", function (value:any) {
    if((value?.length ===0) ){
      return this.createError({
        message: "Please upload a PDF file.",
    })
  }      
    if (value?.length>0){
        const fileType = value?.[0]?.type
        return fileType === 'application/pdf'
    }

    return true
})

export { paginationSchema, commonValidationSchema, thumbnailImageSchema, pdfFileSchema };
