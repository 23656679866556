
import { X } from "@mui/icons-material";
import Modal from "./modal";

interface AddModalProps {
    ShowModel: any;
    setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
    GetReferences: any;
    boards: any;
    subjects: any;
    mode: any;
    editData?: any;
}

const BulkAddModal = ({
    ShowModel,
    setShowModel,
    GetReferences,
    boards,
    subjects,
    mode,
    editData,             
}: AddModalProps) => {


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">

                <div className="relative p-4 bg-white rounded-lg shadow ">
                <div className="absolute top-0 right-0 p-4">
                    <button
                        onClick={() => setShowModel(false)}
                        className="p-2 bg-white rounded-full"
                    >
                        <X />
                    </button>
                </div>

                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                       {mode === "edit" ? "Edit Reference PDF" : "Bulk Add New Reference PDF"}
                        </h3>
                    </div>
                    <Modal 
                        boards={boards}
                        subjects={subjects}
                        GetReferences={GetReferences}
                        setShowModel={setShowModel}
                        mode={mode}
                        editData={editData}
                    />
                </div>
            </div>
        </div>
    );
};

export default BulkAddModal;