import { QuestionReportingAPI } from '../../../(apis)/(shared-apis)/academics/question-reporting';
import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import CustomBadges from '../../../shared-components/ui/CustomBadges';
import { ManageIcon } from '../../../shared-components/ui/Icons';

export default function Page() {
    const [reports, setReports] = useState<any> ([])
    const [filteredReports, setFilteredReports] = useState([])

    const { id } = useParams();
    const GetQuestionReport = async () => {
        const response = await QuestionReportingAPI("questionReports", [`${id}`])
        setReports(response.data)
        setFilteredReports(response.data.reports)
    }

    useEffect(() => {
        GetQuestionReport()
    }, []);


    return (
        <div className="w-[95%] mx-auto h-full">
            {reports && reports.reports && reports.reports.length === 0 ? <div className="text-center text-2xl text-gray-500 mt-10">No Reports Found</div> :
            <>
            <Toaster />
            <div className="flex justify-between items-center">
                <h1 className="text-3xl text-gray-700 font-bold">Question Reporting</h1>
                <div className="flex items-center justify-end gap-2">
                    {reports.reports && reports.reports.length > 0 &&
                    <>
                    <CustomBadges label={`${reports.reports.filter((report: any) => report.status === "PENDING").length} Pending`} type="error" showIcon={false} textSize="lg" />
                    <CustomBadges label={`${reports.reports.filter((report: any) => report.status === "RESOLVED").length} Resolved`} type="success" showIcon={false} textSize="lg" />
                    </>
                    }
                </div>
            </div>
            <section className="my-5 antialiased">
                <div className="overflow-x-auto">
                    <table className="w-full mb-20 text-sm text-left text-gray-500">
                        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                            <tr>
                            <th scope="col" className="px-4 py-4">
                                Date 
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    User Report
                                </th>
                                <th scope="col" className="px-4 py-4">
                                    Problems
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Status
                                </th>
                                <th scope="col" className="px-4 py-3">
                                    Navigate
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                        {filteredReports.map((report: any, index: number) => (
                            <tr className="border-b text-gray-800" key={index}>
                                <td className="px-4 py-3">
                                    {new Date(report.reportedAt).toDateString()}
                                </td>
                                <td className="px-4 py-3">
                                    {report.problemText}
                                </td>
                                <td className="px-4 py-3">
                                    <div className='flex flex-row gap-2 flex-wrap'>
                                        {report.issues.map((problem: any) => (
                                            <CustomBadges label={problem} type="error" showIcon={false} textSize="xs" />
                                        ))}
                                    </div>
                                    </td>
                                <td className="px-4 py-3">
                                    <CustomBadges label={report.status} type={report.status === "PENDING" ? "error" : "success"} showIcon={false} textSize="xs" />
                                </td>
                                <td className="px-4 py-3">
                                    <div className="flex items-center gap-2" onClick={() => {
                                        window.open(`/editquestion/${reports.questionId}/${report._id}`, "_blank")}}
                                        >
                                        <ManageIcon color="text-blue-500" size="w-6 h-6" />
                                    </div>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                </div>
            </section>
            </>
}           
        </div>
    )
}
