import { useContext, useEffect, useState } from "react";
import { getLoggedInUser } from "./(apis)/(shared-apis)/auth-apis";
import AppRouter from "./routes/AppRouter";
import { UserContext } from "./state-management/user-context";
function App() {
  const PORT = process.env.REACT_APP_CATEGORY;
  useEffect(() => {
    if (PORT === "PREMEDICAL") {
      document.title = "Pre-Medical";
      document.documentElement.style.setProperty("--primary-color", "#EC5863");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "/favicons/premed.png");
    }
    if (PORT === "PRE-ENGINEERING") {
      document.title = "Engineering";
      document.documentElement.style.setProperty("--primary-color", "#4285F4");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "/logos/pre-engineering.png");
    }
    if (PORT === "MEDSCHOOL") {
      document.title = "MedSchool";
      document.documentElement.style.setProperty("--primary-color", "#FFA000");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/MedSchoolLogo.svg");
    }
    if (PORT === "FINANCE") {
      document.title = "Finance";
      document.documentElement.style.setProperty("--primary-color", "black");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/136b589c-0d40-46b1-8467-4cc844d7f992.png");
    }
    if (PORT === "ALLIED-HEALTH-SCIENCES") {
      document.title = "Allied Health Sciences";
      document.documentElement.style.setProperty("--primary-color", "#7156AD");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Variant=FINAL%201.svg");
    }
    if (PORT === "INTERNATIONAL") {
      document.title = "International";
      document.documentElement.style.setProperty("--primary-color", "#1FA2A0");
      const favicon = document.querySelector('favicon');
      favicon?.setAttribute("href", "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/SaudiPrep.png");
    }

  }, [PORT]);

  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Navigation must be used within a UserProvider");
  }
  const { user, setUser , isResolved , setResolved } = context;

  useEffect(() => {
    getLoggedInUser().then((res) => {
      setUser(res.data);
      setResolved(true);
    });
  }, []);

  const [redirectUrl, setRedirectUrl] = useState("");

  useEffect(() => {
    const currentBaseUrl = window.location.origin;
    const newRedirectUrl = window.location.href.substring(
      currentBaseUrl.length
    );

    setRedirectUrl(newRedirectUrl);
  }, []);

  return (
    <>
      <AppRouter />
    </>
  );
}

export default App;
