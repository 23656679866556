export const flattenErrors = (errors: any): string[] => {
    const flatErrors: string[] = [];
    const flatten = (obj: any) => {
      if (typeof obj === "string") {
        flatErrors.push(obj);
      } else if (Array.isArray(obj)) {
        obj.forEach((item) => flatten(item));
      } else if (typeof obj === "object" && obj !== null) {
        Object.values(obj).forEach((value) => flatten(value));
      }
    };
    flatten(errors);
    return flatErrors;
  };