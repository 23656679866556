import { FormikProps } from "formik";
import GlobalForm from "shared-components/form-fields/global-form";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

interface InstituteModalProps {
  showModal: boolean;
  setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
  formik: FormikProps<any>;
}

const InstituteModal: React.FC<InstituteModalProps> = ({
  showModal,
  setShowModel,
  formik,
}) => {
  const formData = {
    fields: [
      {
        name: "instituteName",
        label: "Institute Name",
        type: "text",
        size: 12,
        formContent: "input",
        require: true,
      },
      {
        name: "shortName",
        label: "Short Name",
        type: "text",
        size: 12,
        formContent: "input",
      },
      {
        name: "city",
        label: "City",
        type: "text",
        size: 12,
        formContent: "input",
      },
      {
        name: "logo",
        label: "Logo",
        type: "text",
        size: 12,
        formContent: "image",
        require: true,
      },
    ],
  };
  return (
    <div>
      <CustomAntdModal
        isOpenModal={showModal}
        setIsOpenModal={setShowModel}
        formik={formik}
        width={800}
        title="Institute"
      >
        <GlobalForm formData={formData} formik={formik} />
      </CustomAntdModal>
    </div>
  );
};

export default InstituteModal;
