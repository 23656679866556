import React from "react";
import LabelComponent from "./LabelComponent";
import CustomFormikError from "./CustomFormikError";
import { Input } from "antd";
import { FormikProps } from "formik";
import { getErrorString } from "../../utility/form/functions";

interface CustomInputFieldProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  require?: boolean;
}

const CustomInputField: React.FC<CustomInputFieldProps> = ({
  label,
  name,
  formik,
  onChange,
  type = "text",
  require=true,
}) => {
  const touched = !!formik?.touched[name];
  const error = getErrorString(formik?.errors[name]);
  return (
    <div>
      <LabelComponent value={label} require={require} />
      <Input
        id={name}
        name={name}
        type={type}
        disabled={false}
        onChange={(e) => {
          if (onChange) {
            onChange(e);
          } else {
            formik?.setFieldValue(name, e.target.value);
          }
        }}
        className="border "
        placeholder={`Enter ${label}`}
        value={formik?.values?.[name]}
      />
      <CustomFormikError
        name={name}
        touched={touched}
        error={error}
      />
    </div>
  );
};

export default CustomInputField;
