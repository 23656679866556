import { Image } from "antd";

export const columnsOfTestimonials = [
    {
        title: "Id",
        dataIndex: "_id",
        key: "_id",
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Score",
        dataIndex: "score",
        key: "score",
      },
      {
        title: "Exam Name",
        dataIndex: "examName",
        key: "examName",
      },
      {
        title: "University",
        dataIndex: "university",
        key: "university",
      },
      {
        title: "Text based Testimonials", // Empty title for the first group of columns
        colSpan: 2,
        dataIndex: "text",
        key: "text",
        children: [
          {
            title: "Student Picture",
            dataIndex: "pictureUrl",
            key: "pictureUrl",
            render: (pictureUrl) => {
              if (pictureUrl) {
                return (
                  <Image
                  width={50}
                  height={50}
                    src={pictureUrl}
                    alt="Profile"
                    className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                  />
                );
              }
              return null;
            },
          },
          // {
          //   title: "Description",
          //   dataIndex: "description",
          //   key: "description",
          // },
  
          {
            title: "Description",
            dataIndex: "description",
            key: "description",
            render: (description) => {
              if (description) {
                const isHTML = /<\/?[a-z][\s\S]*>/i.test(description);
                if (isHTML) {
                  return (
                    <div dangerouslySetInnerHTML={{ __html: description }} />
                  );
                } else {
                  return <span>{description}</span>;
                }
              }
              return null;
            },
          },
          // {
          //     title: "Tags",
          //   dataIndex: "tags",
          //   key: "tags",
          // },
        ],
      },
      {
        title: "Video based Testimonials", // Empty title for the second group of columns
        colSpan: 2,
        dataIndex: "video",
        key: "video",
        children: [
          {
            title: "Thumbnail",
            dataIndex: "videothumbnailUrl",
            key: "videothumbnailUrl",
            render: (url) => {
              if (url) {
                return (
                  <Image
                  width={50}
                  height={50}
                    src={url}
                    alt="Video Thumbnail"
                    className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                  />
                );
              }
              return null;
            },
          },
          {
            title: "Video",
            dataIndex: "videoUrl",
            key: "videoUrl",
            render: (url) => {
              if (url) {
                return (
                  <a
                    href={url}
                    target="_blank"
                    rel="noreferrer"
                    className="text-blue-500"
                  >
                    Watch Video
                  </a>
                );
              }
              return null;
            },
          },
        ],
      }
    ]