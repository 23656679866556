import { ApiInterface } from "./types";
import { subjectapis } from "../(shared-apis)/subject-api";
import { vaultapis } from "../(shared-apis)/vault-apis";
import { hierarchyapis } from "../(shared-apis)/hierarchy-api";
import { instituteapis } from "../(shared-apis)/institute-apis";
import { questionapis } from "../(shared-apis)/question-apis";
import { teacherApis } from "../(shared-apis)/teacher-apis";
import { featureApis } from "../../(apis)/(shared-apis)/feature-apis";
import { galleryApis } from "(apis)/(shared-apis)/gallery-api";
import { topicsApis } from "(apis)/(shared-apis)/topics-apis";
import { disciplineApis } from "(apis)/(shared-apis)/discipline-apis";
import { moduleApis } from "(apis)/(shared-apis)/module-apis";
import { marketingApis } from "(apis)/(shared-apis)/marketing-apis";
import { teamApis } from "(apis)/(shared-apis)/team-apis";
import { myOwnBundlesApis } from "(apis)/(shared-apis)/mybundles-apis";
import { DeckAPIs } from "(apis)/(shared-apis)/academics/decks-api";
import { disciplinesApis } from "(apis)/(shared-apis)/disciplines";
import { renewalRequestApis } from "(apis)/(shared-apis)/renewal-request-apis";
import { courseApis } from "(apis)/(shared-apis)/courses/course-apis";
import { announcementApis } from "(apis)/(shared-apis)/courses/announcement-apis";
import { courseAssignmentApis } from "(apis)/(shared-apis)/courses/assignment-apis";
import { planApis } from "(apis)/(shared-apis)/plan-apis";
import { scheduleApis } from "(apis)/(shared-apis)/courses/schedule-apis";
import { caApis } from "(apis)/(shared-apis)/ca-apis";
import { imageGalleryApis } from "(apis)/(shared-apis)/image-gallery-apis";
import { expertApis } from "(apis)/(shared-apis)/expert-api";
import { PackagesAPIs } from "(apis)/(shared-apis)/packages-apis";
import { generalApis } from "(apis)/(shared-apis)/general-apis";
import { financeApis } from "(apis)/(shared-apis)/finance-apis";
import { orderApis } from "(apis)/(shared-apis)/order-apis";
import { bankApis } from "(apis)/(shared-apis)/bank-details";
import { CourseSubscriptionAPIs } from "(apis)/(shared-apis)/courses/course-subscriptions";
export const APIs: ApiInterface[] = [
  ...vaultapis,
  ...subjectapis,
  ...hierarchyapis,
  ...instituteapis,
  ...questionapis,
  ...teacherApis,
  ...planApis,
  ...featureApis,
  ...galleryApis,
  ...topicsApis,
  ...disciplineApis,
  ...moduleApis,
  ...marketingApis,
  ...teamApis,
  ...myOwnBundlesApis,
  ...DeckAPIs,
  ...disciplinesApis,
  ...expertApis,
  ...renewalRequestApis,
  ...courseApis,
  ...announcementApis,
  ...courseAssignmentApis,
  ...scheduleApis,
  ...caApis,
  ...imageGalleryApis,
  ...PackagesAPIs,
  ...generalApis,
  ...financeApis,
  ...orderApis,
  ...bankApis,
  ...CourseSubscriptionAPIs,
];
