// dataProcessing.js

export const processDataByYear = (data) => {
    const result = {};
    let previousYearTotal = 0;
  
    data?.forEach((item) => {
      const [year, quarter] = item?._id?.split("-");
      if (!result[year]) {
        result[year] = { data: [], total: 0, growth: 0, growthQuantity: 0 };
      }
      result[year].data.push({ name: quarter, value: item.total });
      result[year].total += item.total;
    });
  
    Object.keys(result).forEach((year) => {
      if (previousYearTotal !== 0) {
        result[year].growthQuantity = result[year].total - previousYearTotal;
        result[year].growth = (
          ((result[year].total - previousYearTotal) / previousYearTotal) *
          100
        ).toFixed(1);
      }
      previousYearTotal = result[year].total;
    });
    return result;
  };
  
  export const aggregateDataByYear = (data) => {
    return data?.reduce((acc, item) => {
      const year = item?._id?.split("-")[0];
      if (!acc[year]) {
        acc[year] = 0;
      }
      acc[year] += item.total;
      return acc;
    }, {});
  };
  
  export const prepareYearlyPieChartData = (aggregatedData) => {
    return (
      aggregatedData &&
      Object.keys(aggregatedData)?.map((year) => ({
        name: year,
        value: aggregatedData[year],
      }))
    );
  };
  
  export const prepareBarChartDataOfMonths = (monthsData) => {
    return (
      monthsData &&
      Object.keys(monthsData)?.map((key) => {
        const [kk, year, month] = key.match(/\d{4}|\D+/g);
        return {
          name: `${month} ${year}`,
          value: monthsData[key],
        };
      })
    );
  };
  