import { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { UserAPI } from '../../(apis)/(shared-apis)/user-apis';
import ConversionSection from './components/conversion-section';
import ConversionsTable from './components/conversions-table';
import ReferalManager from './components/referral-table';

export default function Page() {
  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [triggers, setTriggers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFreeUsers, setShowFreeUsers] = useState(false);
  const [selectedTrigger, setSelectedTrigger] = useState('All');

  const GetAllUsers = async () => {
    setLoading(true);
    try {
      const response = await UserAPI('getUsersForConversions');
      const validUsers = response?.data?.users.filter((user: any) => user.phonenumber);

      setUsers(validUsers);
      setFilteredUsers(validUsers);

      const triggerCount = {};
      validUsers.forEach((user) => {
        const userTriggers = user.triggers || [];
        userTriggers.forEach((trigger: string) => {
          if (triggerCount[trigger]) {
            triggerCount[trigger] += 1;
          } else {
            triggerCount[trigger] = 1;
          }
        });
      });

      const triggerArray = Object.keys(triggerCount).map((key) => ({
        triggerName: key,
        value: triggerCount[key],
      }));
      setTriggers(triggerArray);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    GetAllUsers();
  }, []);


  const FilterUsersOnTrigger = (triggerName: string) => {
    if (!users.length || !triggerName) return;

    if (triggerName === 'All') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(users.filter((user) => user.triggers.includes(triggerName)));
    }
    setCurrentPage(1);
  };

  useEffect(() => {
    FilterUsersOnTrigger(selectedTrigger);
  }, [selectedTrigger, users]);

  const FilterAllFreeUsers = () => {
    if (!users.length) return;
    if (!filteredUsers.length) return;

    if (showFreeUsers) {
      setFilteredUsers(filteredUsers.filter((user) => !user.featuresPurchased?.length || user.featuresPurchased?.length === 0));
    } else {
      FilterUsersOnTrigger(selectedTrigger);
    }
  };

  useEffect(() => {
    FilterAllFreeUsers();
  }, [showFreeUsers]);


  const FormatAccountCreatedDate = (date: string) => {
    //dd-mm-yyyy
    const newDate = new Date(date);
    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    return `${day}-${month}-${year}`;
  }

  return (
    <div className='w-[95%] mx-auto h-full'>
      <h1 className='text-3xl text-gray-700 font-bold'>Conversion Manager</h1>
      <section className="my-5 antialiased">
        <div className="mx-auto">
          <div className="bg-white shadow-md sm:rounded-lg">
            {loading ? (
              <div className="flex items-center justify-center">
                <div className="absolute text-primary font-bold"> Loading... </div>
              </div>
            ) : (
              <>

                <ConversionSection
                  filteredUsers={filteredUsers}
                  FormatAccountCreatedDate={FormatAccountCreatedDate}
                  setSelectedTrigger={setSelectedTrigger}
                  selectedTrigger={selectedTrigger}
                  users={users}
                  triggers={triggers}
                  showFreeUsers={showFreeUsers}
                  setShowFreeUsers={setShowFreeUsers}
                />

                <ConversionsTable
                  filteredUsers={filteredUsers}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  FormatAccountCreatedDate={FormatAccountCreatedDate}
                />

              </>
            )}
          </div>
        </div>
        <Toaster />
      </section>

      <ReferalManager />

    </div>
  );
}
