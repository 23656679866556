
import { ApiInterface } from "../api-interface/types";

export const generalApis: ApiInterface[] = [
    {
        url: "/api/upload-file/add",
        method: "POST",
        name: "uploadFile",
    },
    
]