import { ApiInterface } from "../../api-interface/types";

const url = "/api/courses";

export const courseApis: ApiInterface[] = [
    {
        url: `${url}/get`,
        method: "GET",
        name: "getCourses",
    },
    {
        url: `${url}/getById/:id`,
        method: "GET",
        name: "getCourseById",
    },
    {
        url: `${url}/add`,
        method: "POST",
        name: "addCourse",
        contentType: "multipart/form-data",
    },
    {
        url: `${url}/update2/:id`,
        method: "PATCH",
        name: "updateCourse",
        contentType: "multipart/form-data",
    },
    {
        url: `${url}/updateActive/:id`,
        method: "PUT",
        name: "updateCourseActive",
    },
    {
        url: `${url}/delete/:id`,
        method: "DELETE",
        name: "deleteCourse",
    },
    {
        url: `${url}/addPricingModel/:courseId`,
        method: "POST",
        name: "addCoursePricing",
    },
    {
        url: `${url}/updatePricingModel/:courseId/:pricingModelId`,
        method: "PUT",
        name: "updateCoursePricing",
    },
    {
        url: `${url}/updateIspublishedPricingModel/:courseId/:pricingModelId`,
        method: "PUT",
        name: "updateCoursePricingPublish",
    },
    {
        url: `${url}/getCoursePricingModelById/:courseId/:pricingModelId`,
        method: "GET",
        name: "getCoursePricingById",
    },
    {
        url: `${url}/getCourseStudents/:courseId`,
        method: "GET",
        name: "getCourseStudents",
    },
    {
        url: `${url}/markAsWhatsappAdded/:courseId`,
        method: "PUT",
        name: "markAsWhatsappAdded",
    },
 
    {
        url: `${url}/addStudentToCourse/:courseId`,
        method: "POST",
        name: "addStudentToCourse",
    },
];
