 

 
import { apiRequest } from '(apis)/api-interface/api-handler'
import { notification } from 'antd'
import { useEffect, useState } from 'react'
const AddStudent = ({ courseData }) => {
    const [form, setForm] = useState({
        username: '',
    })
    const [pricingModels, setPricingModels] = useState<any[]>()
    const [disabled, setDisabled] = useState(false)
    const [selectedPricingModel, setSelectedPricingModel] = useState({
        SKUName: ""
    })
    useEffect(() => {
        setPricingModels(courseData?.pricingModel)
    }, [courseData])

    const AddStudent = async () => {
        setDisabled(true)
        if (form.username === ""){
            notification.error({
                message: 'Error',
                description: 'Please enter a username'
            })
            return
        }
        if (selectedPricingModel.SKUName === "") {
            notification.error({
                message: 'Error',
                description: 'Please select a pricing model'
            })
            return
        }
        
        try {
            const payload = {
                username: form.username,
                plan: selectedPricingModel
            }
            const response = await apiRequest("addStudentToCourse",payload,[courseData._id])
            if (response.data.success) {
                notification.success({
                    message: 'Success',
                    description: 'Student added successfully'
                })
                setForm({
                    username: ''
                })
                setSelectedPricingModel({
                    SKUName: ""
                })
            }
            else {
                notification.error({
                    message: 'Error',
                    description: response.data.message
                })
            }
            
        } catch (error) {
            console.log(error)
            notification.error({
                message: 'Error',
                description: "An error occured while adding student to course. Please try again later."
            })
            
        }
        setDisabled(false)
    }

    return (
        <div>
            <div className='mt-8'>
                <div className='flex flex-col'>
                    <h1 className='text-2xl font-bold'>Add a Student Manually</h1>
                    <p className='text-gray-500'>You can add a student manually by filling the form below</p>
                    <div className='mt-4'>
                        <input type="text" placeholder='Username' className='p-1 rounded border border-gray-300 shadow-md w-1/2 font-bold focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-opacity-50' value={form.username} onChange={(e) => setForm({ ...form, username: e.target.value })} />
                    </div>
                    <div className='mt-4'>
                        <div className='grid grid-cols-4 gap-4'>
                            {pricingModels && pricingModels.length > 0 &&
                                pricingModels?.map((pricingModel, index) => {
                                    return (
                                        <div key={index} className={`flex flex-col items-center text-gray-500 border border-gray-300 p-2 rounded cursor-pointer ${selectedPricingModel.SKUName === pricingModel.SKUName ? 'bg-green-400 text-white border-white' : ''}`} onClick={() => setSelectedPricingModel(pricingModel)}>
                                            <div className='text-sm font-bold'>
                                                {pricingModel.PlanDuration === 0 ? 'One Time' : `${pricingModel.PlanDuration} Months`}
                                            </div>
                                            <div className='text-xs mb-5'>{pricingModel.SKUName}</div>
                                            <div className='flex flex-col items-center gap-1'>
                                                {pricingModel.PreMedAccess.map((access, index) => {
                                                    return (
                                                        <div key={index} className='text-xs'>{access}</div>
                                                    )
                                                }
                                                )}
                                            </div>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className='mt-4'>
                        <div className="flex justify-end items-end">
                            <button className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-rose-400 disabled:opacity-50 disabled:cursor-not-allowed' 
                            disabled={disabled}
                                onClick={AddStudent}>Add Student</button>
                        </div>
                    </div>




                    {/* <div className='mt-4'>
                        <input type="text" placeholder='Name' className='w-full p-2 rounded border border-gray-300' value={form.Name} onChange={(e) => setForm({ ...form, Name: e.target.value })} />
                        <input type="text" placeholder='Course' className='w-full p-2 rounded border border-gray-300 mt-4' value={form.Course} onChange={(e) => setForm({ ...form, Course: e.target.value })} />
                        <input type="date" placeholder='Date' className='w-full p-2 rounded border border-gray-300 mt-4' value={form.Date} onChange={(e) => setForm({ ...form, Date: e.target.value })} />
                        <input type="time" placeholder='Time' className='w-full p-2 rounded border border-gray-300 mt-4' value={form.Time} onChange={(e) => setForm({ ...form, Time: e.target.value })} />
                        <textarea placeholder='Description' className='w-full p-2 rounded border border-gray-300 mt-4' value={form.Description} onChange={(e) => setForm({ ...form, Description: e.target.value })} />
                    </div> */}
                    <div className='mt-4'>
                        <div className="flex justify-end items-end">
                            {/* <button className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-rose-400' disabled={disabled}
                             onClick={SendEmail}> {disabled ? "Sending..." : "Send Email"}</button> */}
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}


export default AddStudent
