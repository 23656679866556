
import { DeckAPI } from "../../../../(apis)/(shared-apis)/academics/decks-api";
import { useState } from "react";
import toast from "react-hot-toast";

interface BulkAddModalProps {
    ShowModel: any;
    setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
    deckId: string;
    getQuestions: any;
    SetNotFoundIds: React.Dispatch<React.SetStateAction<string[]>>;
}

const BulkAddModal = ({
    ShowModel,
    setShowModel,
    deckId,
    getQuestions,
    SetNotFoundIds
}: BulkAddModalProps) => {

    const [questions, setQuestions] = useState([]);
    const [error, setError] = useState(null);

    const handleIds = (e: any) => {
        const ids = e.target.value.split("\n");
        setQuestions(ids);
    };

    const validateMongoIds = (array: string[]) => {
        const idPattern = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
        for (let i = 0; i < array.length; i++) {
            if (!idPattern.test(array[i])) {
                console.log(array[i]);
                return false;
            }
        }
        return true;
    };

    const validate = () => {
        const uniqueIds = new Set(questions);
        if (uniqueIds.size !== questions.length) {
            toast.error("Duplicate Questions Found");
            return false;
        }
        if (questions.some((id) => id.trim() === "")) {
            toast.error("Empty Questions Found");
            return false;
        }
        const regex = /^[0-9a-fA-F]{24}$/;
        if (questions.some((id) => !regex.test(id))) {
            toast.error("Invalid Questions Found");
            return false;
        }
        if (!validateMongoIds(questions)) {
            toast.error("Invalid Questions Found");
            return false;
        }
        setError(null);
        return true;
    };

    const handleAddQuestions = async () => {
        let quest = questions.map((id) => id.trim());
        setQuestions(quest);

        if (validate()) {
            const ids = questions.filter((id) => id.trim() !== "");
            const response = await DeckAPI("bulkPublish", "", { deckId, questionIds: ids });
            if (response.data.success) {
                const notFoundIds = response?.data?.NotFoundIds;
                if (notFoundIds) {
                    const isThereAnyQuestionAdded = response?.data?.FoundIds?.length > 0;
                    if (isThereAnyQuestionAdded) {
                        toast.success("Questions added successfully. Note: Some question IDs were invalid.");
                    } else {
                        toast.error("All Question IDs were invalid.");
                    }
                    SetNotFoundIds(notFoundIds);
                } else {
                    toast.success("Questions added successfully");
                }
                getQuestions();
                setShowModel(false);
                setQuestions([]);
            } else {
                toast.error("Error while adding questions");
            }
        }
    };

    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${ShowModel ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Bulk Add Questions
                        </h3>
                        <button
                            onClick={() => {
                                setShowModel(false);
                            }}
                            className="text-gray-700 focus:outline-none"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                className="w-6 h-6 hover:text-gray-900"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth={2}
                                    d="M6 18L18 6M6 6l12 12"
                                />
                            </svg>
                        </button>
                    </div>
                    <div className="flex flex-col gap-4">
                        <div className="flex flex-col gap-2">
                            <label className="text-sm font-medium text-gray-700">
                                Questions
                            </label>
                            <textarea
                                className="w-full h-32 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-grau-200 focus:border-transparent"
                                placeholder="Enter Questions"
                                value={questions.join("\n")}
                                onChange={handleIds}
                            />
                        </div>
                        {error && <p className="text-red-500">{error}</p>}
                        <div className="flex flex-row gap-2">
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-rose-500 rounded-md hover:bg-rose-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                onClick={() => {
                                    setShowModel(false);
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-4 py-2 text-sm font-medium text-white bg-rose-500 rounded-md hover:bg-rose-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
                                onClick={handleAddQuestions}
                            >
                                Add Questions
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BulkAddModal;