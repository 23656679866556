import ErrorIcon from "@material-ui/icons/Error";
import { useFormik } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import { apiRequest } from "(apis)/api-interface/api-handler";
import EditIcon from "@material-ui/icons/Edit";
import { message } from "antd";
import { ColumnsType } from "antd/es/table";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EyeIcon } from "shared-components/ui/Icons";
import CustomFormField from "shared-components/form-fields/custom-form-field";
const Notes = ({ scheduledData, id, getScheduleData, loading }) => {
  const [isUpdate, setIsUpdate] = useState(false);
  const [isEditMode, setIsIsEditMode] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const initialValues = {
    noteName: "",
    noteLink: null,
    noteId: null,
    file: null,
  };

  const validationSchema = Yup.object().shape({
    noteName: isEditMode
      ? Yup.string()
          .required("Name is required")
          .test("unique", "Note name already exist.", function (value: any) {
            const existingNotesExceptCurrent = scheduledData?.noteLinks?.filter(
              (noteId) => noteId._id !== values?.noteId
            );
            const existingNotesNames = existingNotesExceptCurrent?.map(
              (note) => note.noteName
            );
            return !existingNotesNames?.includes(value);
          })
      : Yup.string()
          .required("Name is required")
          .test("unique", "Note name already exist.", function (value) {
            const existingNoteNames = scheduledData?.noteLinks?.map(
              (note) => note.noteName
            );
            return !existingNoteNames?.includes(value);
          }),
    noteLink: Yup.string().nullable().url("Invalid URL format"),
    file: Yup.mixed()
      .nullable()
      .test("fileOrLink", function (value: any) {
        const { noteLink, file } = this.parent;
        if (!noteLink && !file) {
          return this.createError({
            message: "At least one of file or note link is required.",
          });
        }
        if (noteLink && file) {
          return this.createError({
            message:
              "Please choose either a pdf file or a note link, but not both.",
          });
        }
        if (file) {
          const fileType = value && value.type;
          if (!["application/pdf"].includes(fileType)) {
            return this.createError({
              message: "Please upload a PDF file.",
            });
          }
        }
        return true;
      }),
  });
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { noteName, noteLink, file } = values;
        const formData = new FormData();
        formData.append("noteName", noteName || "");
        formData.append("noteLink", noteLink || "");
        formData.append("file", file || "");
        let res;
        if (isEditMode) {
          res = await apiRequest("updateManageScheduleNote", formData, [
            id,
            values?.noteId,
          ]);
        } else {
          res = await apiRequest("addManageScheduleNote", formData, [id]);
        }
        if (res?.status === 200) {
          setIsIsEditMode(false);
          message.success(res?.data?.message);
          getScheduleData().then(() => console.log());
          setFieldValue("noteLink", "");
          clearSelectedFile();
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const {
    values,
    handleSubmit,
    setValues,
    setFieldValue,
    touched,
    errors,
  } = formik;

  const handleFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("file", file);
      setUploadedFileName(file.name); // Set the uploaded file name
    }
  };

  const clearSelectedFile = () => {
    setFieldValue("file", null);
    setUploadedFileName("");
    setFieldValue("noteLink", null);
    const fileInput = document.getElementById("file") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
    const noteLink = document.getElementById("noteLink") as HTMLInputElement;
    if (noteLink) {
      noteLink.value = "";
    }
  };
  const handleNoteIsPublish = async (data: any) => {
    const res = await apiRequest("updateManageSchedulePublishNote", null, [
      id,
      data?.id,
    ]);
    if (res?.status === 200) {
      getScheduleData().then(() => console.log());
      message.success(res?.data?.message);
    }
  };
  const handleEdit = (data: any) => {
    clearSelectedFile();
    setValues({
      ...data,
      noteName: data?.noteName || "",
      noteLink: data?.noteLink || "",
      noteId: data?.id || "",
    });

    window.scrollTo({ top: 0, behavior: "smooth" });
    setIsIsEditMode(true);
  };
  interface DataType {
    noteName: string;
    isPublished: boolean;
    noteLink: string;
    // Add other fields as needed
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "NOTES NAME", // Updated column title
      dataIndex: "noteName",
      key: "noteName",
      width: 200,
      //  display:'flex'
    },
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",
      align: "center",
      width: 50,
      render: (data) => {
        return (
          <div className="flex justify-center">
            <GlobalPublishButton
              isActive={data?.isPublished}
              onChange={() => handleNoteIsPublish(data)}
            />
          </div>
        );
      },
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      width: 100,
      render: (data) => {
        return (
          <div className="flex gap-4 justify-center">
            <div>
              <EditIcon
                onClick={() => handleEdit(data)}
                className="cursor-pointer"
                color="primary"
              />
            </div>
            <a
              rel="noreferrer"
              title="notes"
              href={`${data?.noteLink}`}
              target="_blank"
            >
              <EyeIcon />
            </a>
          </div>
        );
      },
    },
  ];
  const formattedNoteseData = scheduledData?.noteLinks?.map(
    ({ noteLink, noteName, isPublished, _id }, i) => {
      // Updated data mapping
      return {
        key: i + 1,
        noteLink, // Updated field name
        noteName, // Updated field name
        isPublished: {
          isPublished,
          id: _id,
        },
        id: _id,
      };
    }
  );
  return (
    <div className="">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="rounded-lg shadow p-4 bg-white "
      >
        <div className="flex flex-row  w-full">
          <div className="w-4/12">
            <div className="md:p-2 w-full">
              <div className="relative">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="noteName"
                  label="Note Name"
                />
              </div>
              <div className="relative">
                <CustomFormField
                  type="text"
                  formik={formik}
                  name="noteLink"
                  label="Note Link"
                />
              </div>
            </div>
          </div>
          <div className="w-1/12 text-center">
            <div className="flex justify-center items-center h-full">OR</div>
          </div>
          <div className="w-7/12 flex gap-3 ">
            <div className="w-full">
              <div className="flex flex-col">
                <div className="flex flex-row flex-wrap items-center  bg-gray-100 p-2 rounded-md justify-center">
                  <div className="flex w-1/2 flex-col items-center gap-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-8 h-8"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                      />
                    </svg>
                    <button
                      className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                      onClick={(event) => {
                        event.preventDefault();
                        document.getElementById("file").click();
                      }}
                    >
                      Upload PDF
                    </button>
                    <p>File accepted: PDF</p>
                    {isEditMode && values?.noteLink && (
                      <button
                        className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 my-0  px-4 rounded border-rose-500"
                        onClick={clearSelectedFile}
                      >
                        Remove PDF Preview
                      </button>
                    )}
                    <input
                      type="file"
                      id="file"
                      name="file"
                      accept="application/pdf"
                      className="hidden"
                      onChange={handleFileChange}
                    />
                  </div>
                  {isEditMode && values?.noteLink ? (
                    <>
                      <div className="flex flex-col items-center gap-2">
                        <iframe title="note" src={values?.noteLink}></iframe>
                      </div>
                    </>
                  ) : (
                    <>
                      {uploadedFileName && (
                        <>
                          <div className="w-1/2 flex flex-col justify-center items-center">
                            <p className="my-0">{uploadedFileName}</p>
                            <button
                              className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                              onClick={clearSelectedFile}
                            >
                              Remove PDF
                            </button>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="flex justify-center">
          {touched?.file && errors?.file && (
            <div className="text-red-700">
              <ErrorIcon /> {errors?.file as string}
            </div>
          )}
        </div>
        <CustomButton
          disabled={formik?.isSubmitting}
          onClick={()=>formik.submitForm()}
          text={`${
            isUpdate
              ? "Update Note"
              : formik?.isSubmitting
              ? "Adding.."
              : isEditMode
              ? "Edit Confirm"
              : "Add Note"
          }`}
        />
      </form>

      <Loader loading={loading} />
      {formattedNoteseData?.length > 0 && (
        <GlobalTable
          columns={columns}
          data={formattedNoteseData}
          bordered={true}
          searchPlaceholder="Search Notes"
          loading={loading}
        />
      )}
    </div>
  );
};

export default Notes;
