import React, { useState, createContext, ReactNode, Dispatch, SetStateAction } from "react";

// Define the User interface
interface User {
  isloggedin: boolean;
  username?: string;
  fullname: string;
  admin?: {
    pagepermissions?: string[];
  };
  _id?: string;
}

interface UserContextProps {
  user: User;
  setUser: Dispatch<SetStateAction<User>>;
  isResolved: boolean;
  setResolved: Dispatch<SetStateAction<boolean>>;
}

export const UserContext = createContext<UserContextProps | undefined>(undefined);

interface UserProviderProps {
  children: ReactNode;
}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [user, setUser] = useState<User>({
    isloggedin: false,
    fullname: "Not Logged In",
  });

  const [isResolved, setResolved] = useState(false);

  return (
    <UserContext.Provider value={{ user, setUser , isResolved, setResolved}}>
      {children}
    </UserContext.Provider>
  );
};
