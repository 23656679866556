import React from "react";
import CustomInputField from "./custom-input-field";
import { FormikProps } from "formik";
import CustomSelect from "./custom-select";
import CustomTextAreaField from "./custom-textarea-field";
import CustomVideoField from "./custom-video-field";
import CustomPdfField from "./custom-pdf-field";
import CustomImageField from "./custom-image-field";
import CustomDatePicker from "./custom-date-time-picker-field";
import CustomInputNumberField from "./custom-input-number-field";
interface CustomFormFieldProps {
  type:
    | "text"
    | "select"
    | "textarea"
    | "video"
    | "image"
    | "pdf"
    | "number"
    | "react-quill"
    | "date"
    |""
    ;
  name: string;
  label?: string;
  options?: Array<{ label: string; value: string }>;
  formik: FormikProps<any>;
  mode?: "tags" | "multiple";
  isMultipleImage?: boolean;
  showTime?: boolean;
  require?: boolean;
  format?: string;
  disabled?: boolean;
  isSetValue?: boolean;
  prefix?: string;
}
const CustomFormField: React.FC<CustomFormFieldProps> = ({
  type,
  mode,
  name,
  label,
  options,
  formik,
  require,
  isMultipleImage,
  showTime,
  format,
  disabled,
  prefix,
  isSetValue,
}) => {
  switch (type) {
    case "text":
      return <CustomInputField require={require} name={name} label={label} formik={formik} />;
    case "number":
      return <CustomInputNumberField prefix={prefix} name={name} label={label} formik={formik} />;
    case "select":
      return (
        <CustomSelect
          mode={mode}
          name={name}
          disabled={disabled}
          require={require}
          label={label}
          isSetValue={isSetValue}
          options={options}
          formik={formik}
        />
      );
    case "textarea":
      return (
        <CustomTextAreaField
          require={require}
          name={name}
          label={label}
          formik={formik}
        />
      );
    case "video":
      return (
        <CustomVideoField
          require={require}
          name={name}
          label={label}
          formik={formik}
        />
      );
    case "pdf":
      return (
        <CustomPdfField
          require={require}
          name={name}
          label={label}
          formik={formik}
        />
      );
    case "image":
      return (
        <CustomImageField
          isMultipleImage={isMultipleImage}
          name={name}
          label={label}
          formik={formik}
          require={require}
        />
      );
    case "date":
      return (
        <CustomDatePicker
          format={format}
          showTime={showTime}
          name={name}
          label={label}
          formik={formik}
          require={require}
        />
      );
    default:
      return null;
  }
};

export default CustomFormField;
