import {
  DeleteOutlined,
  EditOutlined,
  LeftOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import { DeleteIcon, EditIcon } from "shared-components/ui/Icons";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Name is required"),
  description: Yup.string()
    .nullable()
    .min(50, "Description must be at least 50 characters")
    .max(100, "Description must be at most 100 characters"),
  departmentOrderId: Yup.number().required("Department Order is required"),
});
const ManageDepartment = ({ isFinanceDepartment = false }) => {
  const [addPopup, setAddPopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [departmentData, setDepartmentData] = useState([]);
  const navigate = useNavigate();
  const fetchDepartments = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllDepartments");
      if (res.status === 200) {
        if (res.data.length > 0) {
          const updatedRow = res?.data?.map((item) => {
            return {
              ...item,
              key: item._id,
              id: item._id,
            };
          });
          setDepartmentData(updatedRow);
        } else {
          setDepartmentData([]);
        }
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    fetchDepartments();
  }, []);
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      editDepartmentId: "",
      departmentOrderId: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      // Add your submission logic here

      try {
        const { name, departmentOrderId, description, editDepartmentId } =
          values;
        const payload = {
          name,
          description,
          departmentOrderId,
        };
        let res;
        if (isEditMode) {
          res = await apiRequest("updateDepartment", payload, [
            editDepartmentId,
          ]);
        } else {
          res = await apiRequest("addDepartment", payload);
        }
        if (res.status === 200) {
          fetchDepartments();
          message.success(res?.data?.message);
          setAddPopup(false);
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { handleSubmit, setValues } = formik;
  const handleEdit = (record) => {
    const { id } = record;
    setValues({
      ...record,
      editDepartmentId: id,
    });
    setIsEditMode(true);
    setAddPopup(true);
  };
  const handleDelete = async (record: any) => {
    try {
      const res = await apiRequest("deleteDepartment", null, [record.id]);
      if (res.status === 200) {
        message.success(res?.data?.message);
        fetchDepartments();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const columns = [
    {
      title: "Department Name",
      dataIndex: "name",
      key: "name",
      render: (text: string) => <span>{text}</span>
    },
    {
      title: "Department Order Id",
      dataIndex: "departmentOrderId",
      key: "departmentOrderId",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <div className="flex gap-4">
          <div className="text-xl flex gap-4"  onClick={() => handleEdit(record)}>
         <EditIcon color="text-gray-800" size="w-6 h-6"/>
          
          </div>
          {/* <Popconfirm
              title="Delete the department"
              description="Are you sure to delete this department?"
              icon={<QuestionCircleOutlined style={{ color: "red" }} />}
              onConfirm={() => handleDelete(record)}
            >
              <DeleteOutlined className="cursor-pointer" />
            </Popconfirm> */}
        </div>
      ),
    },
  ];
  return (
    <div>
      <div className="">
        <div className={`flex ${!isFinanceDepartment?"justify-between items-between ":"justify-end items-end"}  gap-4`}>
          {!isFinanceDepartment && (
            <div>
              <LeftOutlined
                onClick={() => {
                  navigate("/manage/team");
                }}
              />
            </div>
          )}
          {!isFinanceDepartment && (
            <div>
              <h1 className="text-2xl font-bold">Manage Departments</h1>
            </div>
          )}
          <CustomButton
            text="Add New Department"
            onClick={() => {
              setAddPopup(true);
              setIsEditMode(false);
            }}
            icon={<PlusOutlined />}
          />
        </div>
        <div>
          <GlobalTable
            loading={isLoading}
            columns={columns}
            data={departmentData}
            size="large"
          />
        </div>
      </div>
      <CustomAntdModal
        isOpenModal={addPopup}
        title={isEditMode ? `Edit Department` : `Add Department `}
        setIsOpenModal={setAddPopup}
        formik={formik}
        width={800}
      >
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col  gap-2">
            <div className="w-full ">
              <CustomFormField
                type="text"
                name="name"
                formik={formik}
                label="Department Name"
              />
            </div>
          </div>
          <div className="w-full ">
            <CustomFormField
              type="number"
              name="departmentOrderId"
              formik={formik}
              label="Department Order"
            />
          </div>
          <div>
            <CustomFormField
              type="textarea"
              name="description"
              formik={formik}
              label="Description"
            />
          </div>
        </form>
      </CustomAntdModal>
    </div>
  );
};

export default ManageDepartment;
