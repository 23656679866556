import React, { useState, useEffect } from "react";
import axios from "axios";
import { backendUrl } from "../../../../../constants/config";
import { ButtonFill } from "../../../../../shared-components/ui/CustomButtons";


export default function ReferencesView({ mode, references, setReferences }) {

    const [allSubjects, setAllSubjects] = useState([
        "Biology",
        "Chemistry",
        "Physics",
        "English",
        "Logical Reasoning",
        "Science and Math Reasoning",
        "Science Reasoning",
        "Mathematics Reasoning",
        "Maths and Scientific Reasoning",
        "Psychological Test",
    ]);

    const [boards, setBoards] = useState([
        "Sindh",
        "Punjab",
        "Federal",
        "KPK",
        "Balochistan",
    ]);
    const defaultErrorStates = {
        error: false,
        message: "",
        index: -1,
    };


    const [pdfData, setPdfData] = useState([]);
    const [referencesError, setReferencesError] = useState(defaultErrorStates);
    const [selectedSubject, setSelectedSubject] = useState("");
    const [isPdfSelected, setIsPdfSelected] = useState(
        Array(references.length).fill(false)
    );


    const [selectedPdfLinks, setSelectedPdfLinks] = useState(
        Array(references.length).fill("")
    );

    useEffect(() => {
        axios.get(`${backendUrl}/api/referencepdfs`)
            .then((response) => {
                const pdfData = response.data.data;
                setPdfData(pdfData);
            })
            .catch((error) => {
                console.error("Error fetching PDF data:", error);
            });
    }, []);

    const renderPdfOptions = (reference, index) => {
        let filteredOptions = [];
        filteredOptions = pdfData
            .filter(
                (pdf) =>
                    pdf.board === reference.board &&
                    pdf.subject === selectedSubject &&
                    pdf.isPublished === true
            )
            .map((pdf) => (
                <option key={pdf.pdfLink} value={pdf._id}>
                    {pdf.pdfName}
                </option>
            ));

        const GetPDFName = (id) => {
            for (let i = 0; i < pdfData.length; i++) {
                if (pdfData[i]._id === id) {
                    return pdfData[i].pdfName;
                }
            }
            return "Unknown";
        }

        return (
            <select
                name="pdfLink"
                id={`pdfDropDown${index}`}
                value={
                    mode === 'edit' && reference.refId ? reference.refId : selectedPdfLinks[index]}
                className="_ref_departmentsDropDown"
                onChange={(e) => handlePdfLinkChange(e, index)}
            >
                {mode === 'edit' && reference.refId && (
                    <option value={reference.refId} key={reference.refId}>
                        {GetPDFName(reference.refId)}
                    </option>
                )}
                <option value="" key="">
                    Select a PDF Link
                </option>
                {filteredOptions}
            </select>
        );
    };

    const handleBoardChange = (e, index) => {
        const { value } = e.target;
        const updatedReferences = [...references];
        setReferencesError(defaultErrorStates);

        updatedReferences[index].board = value;
        updatedReferences[index].pdflink = "";

        setSelectedPdfLinks((prevLinks) => {
            const newLinks = [...prevLinks];
            newLinks[index] = value;

            const isValidSelection =
                !!value &&
                !!references[index].board &&
                references[index].pdflink !== "";

            setIsPdfSelected((prevSelected) => {
                const newSelected = [...prevSelected];
                newSelected[index] = isValidSelection;
                return newSelected;
            });

            return newLinks;
        });

        setSelectedPdfLinks((prevLinks) => {
            const newLinks = [...prevLinks];
            newLinks[index] = "";
            return newLinks;
        });

        setReferences(updatedReferences);
    };

    const handlePdfLinkChange = (e, index) => {
        const { value } = e.target;
        const updatedReferences = [...references];
        setReferencesError(defaultErrorStates);
        const isPdfAlreadySelected = updatedReferences.some(
            (ref, i) => i !== index && ref.refId === value
        );

        if (isPdfAlreadySelected) {
            setReferencesError({
                error: true,
                message: "This Pdf is Already Selected previously",
                index: index,
            });
            return;
        }

        const selectedPdf = pdfData.find((pdf) => pdf._id === value);
        if (selectedPdf) {
            updatedReferences[index].pdflink = selectedPdf.pdfLink;
            updatedReferences[index].refId = selectedPdf._id; // Set the refId to the selected _id
            setReferences(updatedReferences);

            setSelectedPdfLinks((prevLinks) => {
                const newLinks = [...prevLinks];
                newLinks[index] = value;

                const isValidSelection =
                    !!value &&
                    !!references[index].board &&
                    references[index].pdflink !== "";

                if (!isValidSelection) {
                    updatedReferences[index].startPage = null;
                    updatedReferences[index].endPage = null;
                }

                setIsPdfSelected((prevSelected) => {
                    const newSelected = [...prevSelected];
                    newSelected[index] = isValidSelection;
                    return newSelected;
                });

                return newLinks;
            });
        }
    };
    const addReference = () => {
        setReferences([
            ...references,
            {
                board: "",
                pdflink: "",
                startPage: "",
                endPage: "",
                refId: "",
                pdfSelectedText: "",
            },
        ]);
    };

    const renderPdfLinkPath = (link) => {
        if (!link.startsWith('https')) {
            link = 'https://' + link;
        }
        return link;
    };

    const handleViewPdfClick = (pdfLink, board) => {
        if (pdfLink && board) {
            window.open(renderPdfLinkPath(pdfLink), "_blank");
        }
    };
    const handleRemoveReference = (index) => {
        const updatedReferences = [...references];
        updatedReferences.splice(index, 1);
        setReferences(updatedReferences);

        // Remove the corresponding item from selectedPdfLinks
        setSelectedPdfLinks((prevLinks) => {
            const newLinks = [...prevLinks];
            newLinks.splice(index, 1);
            return newLinks;
        });

        // Update the isPdfSelected state as well
        setIsPdfSelected((prevSelected) => {
            const newSelected = [...prevSelected];
            newSelected.splice(index, 1);
            return newSelected;
        });
    };
    const handlePageChange = (e, index, pageType, refId) => {
        const { value } = e.target;

        const updatedReferences = [...references];

        updatedReferences[index][pageType] = value;
        const selectedPdf = pdfData.find((pdf) => pdf._id === refId);
        if (
            updatedReferences[index][pageType] > selectedPdf.totalPages ||
            updatedReferences[index][pageType] < 0
        ) {
            setReferencesError({
                error: true,
                message: "Page Number is out of range",
                index: index,
            });
            return;
        }
        if (
            updatedReferences[index].startPage &&
            updatedReferences[index].endPage &&
            parseInt(updatedReferences[index].startPage) >
            parseInt(updatedReferences[index].endPage)
        ) {
            setReferencesError({
                error: true,
                message: "Start Page must be less than or equal to End Page",
                index: index,
            });
        } else if (
            parseInt(updatedReferences[index].startPage) >
            parseInt(updatedReferences[index].endPage)
        ) {
            setReferencesError({
                error: true,
                message: "Start Page must be less than or equal to End Page",
                index: index,
            });
        } else {
            setReferencesError(defaultErrorStates);
        }

        setReferences(updatedReferences);
    };
    const handlePdfSelectedTextChange = (e, index) => {
        const { value } = e.target;
        const updatedReferences = [...references];
        updatedReferences[index].pdfSelectedText = value;
        setReferences(updatedReferences);
    };



    return (
        <>
            <div>
                <div
                    style={{
                        width: "auto",
                        marginBottom: "0.7rem",
                        marginTop: "1rem",
                    }}
                >
                    <h6 className='text-md font-bold text-rose-500 mt-2'> Subject:</h6>

                    <select
                        name="selectedSubject"
                        value={selectedSubject}
                        className="_ref_departmentsDropDown"
                        onChange={(e) => setSelectedSubject(e.target.value)}
                    >
                        <option value="">Select Subject</option>
                        {allSubjects.map((item) => (
                            <option key={item} value={item}>
                                {item}
                            </option>
                        ))}
                    </select>
                </div>

                <h6 className='text-md font-bold text-rose-500 mt-2'> References </h6>


                {references.map((reference, index) => (
                    <div className="_ref_container">
                        <h6
                            style={{
                                borderBottom: "1px solid gray",
                                display: "block",
                                width: "100%",
                                paddingBottom: "0.4rem",
                            }}
                        >
                            Reference {index + 1}
                        </h6>

                        {referencesError.error &&
                            referencesError.index === index && (
                                <span
                                    style={{
                                        color: "red",
                                        fontWeight: "600",
                                        marginBottom: "0.7rem",
                                        marginTop: "0.7rem",
                                    }}
                                >
                                    {referencesError.message}
                                </span>
                            )}
                        <div className="">
                            <div key={index} className="_ref_header">
                                <div style={{ width: "10%" }}>
                                    <label
                                        className="_ref_formbold-form-label"
                                        htmlFor="board"
                                    >
                                        Board:
                                    </label>
                                    <select
                                        name="board"
                                        value={reference.board}
                                        className="_ref_departmentsDropDown"
                                        onChange={(e) => handleBoardChange(e, index)}
                                    >
                                        <option value="">Select a Board</option>
                                        {boards.map((item) => (
                                            <option key={item} value={item}>
                                                {item}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div style={{ width: "60%" }}>
                                    <label
                                        className="_ref_formbold-form-label"
                                        htmlFor="pdfLink"
                                    >
                                        Select Pdf:
                                    </label>

                                    {renderPdfOptions(reference, index)}
                                </div>
                                <div>
                                    <label
                                        className="_ref_formbold-form-label"
                                        htmlFor="startPage"
                                    >
                                        Starting Page#
                                    </label>
                                    <input
                                        type="number"
                                        name="startPage"
                                        id={`startPage-${index}`}
                                        className="_ref_formbold-form-input"
                                        value={reference.startPage}
                                        disabled={!isPdfSelected[index]}
                                        onChange={(e) =>
                                            handlePageChange(
                                                e,
                                                index,
                                                "startPage",
                                                reference.refId
                                            )
                                        }
                                    />
                                </div>
                                <div>
                                    <label
                                        className="_ref_formbold-form-label"
                                        htmlFor="endPage"
                                    >
                                        Ending Page#
                                    </label>
                                    <input
                                        type="number"
                                        name="endPage"
                                        id={`endPage-${index}`}
                                        className="_ref_formbold-form-input"
                                        disabled={!isPdfSelected[index]}
                                        value={reference.endPage}
                                        onChange={(e) =>
                                            handlePageChange(
                                                e,
                                                index,
                                                "endPage",
                                                reference.refId
                                            )
                                        }
                                    />
                                </div>


                                <ButtonFill
                                    handleClick={() =>
                                        handleViewPdfClick(
                                            reference.pdflink,
                                            reference.board
                                        )
                                    }
                                    disabled={!isPdfSelected[index]}
                                >
                                    View Pdf
                                </ButtonFill>

                                <ButtonFill
                                    handleClick={() =>
                                        handleRemoveReference(index)
                                    }
                                    disabled={!isPdfSelected[index]}
                                >
                                    Remove
                                </ButtonFill>
                            </div>
                            <div className="">
                                <label
                                    className="_ref_formbold-form-label"
                                    htmlFor="endPage"
                                >
                                    Selected Text
                                </label>
                                <textarea
                                    name="pdfSelectedText"
                                    className="_ref_formbold-form-input"
                                    value={reference.pdfSelectedText}
                                    disabled={!isPdfSelected[index]}
                                    onChange={(e) =>
                                        handlePdfSelectedTextChange(e, index)
                                    }
                                />
                            </div>
                        </div>
                    </div>
                ))}
                <button
                    className="mb-4 bg-rose-500 hover:bg-rose-700 text-rose-200 font-bold py-2 px-4 rounded-sm"
                    disabled={
                        !(selectedSubject !== null && selectedSubject !== "")
                    }
                    onClick={addReference}
                >
                    Add Reference
                </button>
                <div className=""></div>
            </div>


        </>)
}
