import { useState } from "react";
import CustomDropDown from "shared-components/ui/CustomDropDown";

const MetaEntityDropDown = ({ value, getValue, noLabel }) => {
  const [data, setData] = useState([
    "HIGH SCHOOL",
    "ENTRANCE EXAM",
    "UNDERGRADUATE",
  ]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="my-2">
      <CustomDropDown
        disabled={isLoading}
        label="Entity"
        value={value}
        options={data}
        all={false}
        noLabel={noLabel}
        onSelect={(selectedValue) => {
          getValue("entity", selectedValue);
        }}
      />
    </div>
  );
};
export default MetaEntityDropDown;
