
export default function HighlightedHints({ questionText, hints }) {

    const escapeRegExp = (string: string) => {
        return string.replace(/[.*+?^${ }()|[\]\\]/g, '\\$&'); 
    };

    const regexPattern = new RegExp(hints.map(escapeRegExp).join('|'), 'gi');


    const highlightedText = questionText.replace(regexPattern, (match) => `<span style="color: red;">${match}</span>`);

    return (
        <div className='bg-white border border-gray-200 rounded-sm p-2'>
            <p className='text-sm font-semibold'>Highlighted Question</p>
            <div dangerouslySetInnerHTML={{ __html: highlightedText }} className='text-sm text-black mb-2' />
        </div>
    );
}
