import { ApiInterface } from "../../api-interface/types";

export const announcementApis: ApiInterface[] = [
    {
        url: "/api/announcements/add-announcement",
        method: "POST",
        name: "addAnnouncement",
    },
    {
        url: "/api/announcements/get-announcements/:id",
        method: "GET",
        name: "getAnnouncements",
    },
    {
        url: `/api/announcements/SendUpcomingClassAnnouncement`,
        method: "POST",
        name: "SendUpcomingAnnouncement",
    },
    {
        url: "/api/announcements/publish-announcement",
        method: "PUT",
        name: "publishAnnouncement",
    },
];
