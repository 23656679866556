import React from 'react'
import VaultTable from '../../components/vault-table'

const Notes = () => {
  return (
    <div>
      <VaultTable addApi="addnote" getApi="getnotes" updateApi="updatenote" publishApi="updatenotepublish" vaultType="Note" />
    </div>
  )
}

export default Notes
