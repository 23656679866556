import { ApiInterface } from "../api-interface/types";

const CAEndpoint = `/api/customer-assosiates`;

export const caApis: ApiInterface[] = [
  {
    url: `${CAEndpoint}/get-all-customer-associates-admin`,
    method: "GET",
    name: "getALLCa",
  },
  {
    url: `${CAEndpoint}/add`,
    method: "POST",
    name: "addCA",
  },
  {
    url: `${CAEndpoint}/updateCA/:id`,
    method: "PUT",
    name: "updateCA",
  },
  {
    url: `${CAEndpoint}/deleteCA/:id`,
    method: "DELETE",
    name: "deleteCA",
  },
  {
    url: `${CAEndpoint}/updatepublish/:id`,
    method: "PUT",
    name: "updatepublish",
  },
  {
    url: `${CAEndpoint}/updatechecked/:id`,
    method: "PUT",
    name: "updatechecked",
  },
];
