import {
  accessData,
  provinceData,
  uniYearData,
} from "constants/constantvalues";
import React from "react";
import GlobalForm from "shared-components/form-fields/global-form";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import { TopicalGuideModalProps } from "./types";
const TopicalGuideModal: React.FC<TopicalGuideModalProps> = ({
  formik,
  institutesData,
  setIsEditMode,
  setShowModel,
  isEditMode,
  ShowModel,
  boardsData,
  loading,
  allSubTopicsData,
  subjectData,
  topicsData,
}) => {
  const getFields = () => {
    const fields = [
      // Conditionally add universities and year fields
      ...(process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
        ? [
            {
              name: "universities",
              label: "Universities",
              type: "text",
              size: 6,
              mode: "multiple",
              formContent: "select",
              options: institutesData,
              require: true,
            },
            {
              name: "year",
              label: "Year",
              type: "text",
              size: 6,
              formContent: "select",
              options: uniYearData,
              require: true,
            },
          ]
        : []),
      {
        name: "province",
        label: "Province",
        type: "text",
        size: 6,
        formContent: "select",
        options: provinceData,
      },
      {
        name: "subject",
        label:
          process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
            ? "Discipline"
            : "Subject",
        type: "text",
        size: 6,
        formContent: "select",
        require: true,
        options: subjectData,
      },
      {
        name: "board",
        label: "Board",
        type: "text",
        size: 6,
        formContent: "select",
        require: true,
        options:
          process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
            ? institutesData
            : boardsData,
      },
      {
        name: "access",
        label: "Access",
        type: "text",
        size: 6,
        formContent: "select",
        require: true,
        options: accessData,
      },
      {
        name: "topicName",
        label: "Topic Name",
        type: "text",
        size: 12,
        formContent: "select",
        options:
          process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
            ? topicsData?.filter(
                (topic: any) =>
                  topic?.metadata?.category === process.env.REACT_APP_CATEGORY
              )
            : topicsData,
      },
      {
        name: "thumbnailImage",
        label: "Thumbnail Image",
        type: "text",
        size: 6,
        formContent: "image",
        require: true,
      },
      {
        name: "pdf",
        label: "PDF File",
        type: "text",
        size: 6,
        formContent: "pdf",
        require: true,
      },
      {
        name: "paginations",
        label: "Pagination",
        type: "text",
        size: 12,
        formContent: "array",
        items: [
          {
            name: "subTopic",
            label: "Sub Topic Name",
            type: "text",
            size: 6,
            formContent: "select",
            options: allSubTopicsData?.map((subTopic: any) => ({
              value: subTopic.subTopicName,
              label: subTopic.subTopicName,
            })),
          },
          {
            name: "startPageNo",
            label: "Start Page Number",
            type: "number",
            size: 6,
            formContent: "input",
          },
          {
            name: "endPageNo",
            label: "End Page Number",
            type: "number",
            size: 6,
            formContent: "input",
          },
        ],
      },
      {
        name: "description",
        label: "Description",
        type: "text",
        size: 12,
        formContent: "textarea",
        require: false,
      },

      // Add more fields as needed
    ];
    return fields;
  };

  const formData = {
    fields: getFields(),
  };

  return (
    <div>
      <CustomAntdModal
        isOpenModal={ShowModel}
        title={isEditMode ? `Edit Guides` : `Add Guides `}
        setIsOpenModal={setShowModel}
        formik={formik}
      >
        <div className="flex justify-end"></div>
        <GlobalForm formData={formData} formik={formik} />
      </CustomAntdModal>
    </div>
  );
};

export default TopicalGuideModal;
