import { apiRequest } from "(apis)/api-interface/api-handler";
import Button from "@material-ui/core/Button";
import { DatePicker, message } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import TagComponent from "shared-components/tag-component";
import * as Yup from "yup";
import {initialValues} from './initialValues'
const { RangePicker } = DatePicker;

const CourseInformation = ({
  id,
  getCourseData,
  courseData,
  loading,
  setLoading,
  teacherData,
}) => {
  const initialUrl = courseData?.videoPreview || null;
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(initialUrl);
  const [uploadedFileName, setUploadedFileName] = useState("");
  const validationSchema = Yup.object().shape({
    courseName: Yup.string()
      .required("Course Name is required")
      .min(4, "minimum 4 characters"),
    videoFile: Yup.mixed()
      .nullable()
      .test("fileType", "File must be a video", (value:any) => {
        if (!value) return true;
        return value.type.startsWith("video/mp4");
      })
      .test("fileSize", "File size must be less than 100MB", (value:any) => {
        if (!value) return true;
        return value && value.size <= 100 * 1024 * 1024;
      }),
    pdfFile: Yup.mixed()
      .nullable()
      .test("filepdf", function (value:any) {
        if (value) {
          console.log("value", value);
          const fileType = value && value.type;
          if (fileType !== "application/pdf") {
            return this.createError({
              message: "File must be a PDF",
            });
          }
        }
        return true;
      }),
    RepresentativeLink: Yup.string().url("Invalid URL format"),
    selectedTeacher: Yup.array().required("Teacher is required").min(1),
    selectedDuration: Yup.object().required("Duration is required"),
    desc: Yup.string()
      .required("Description is required")
      .min(50, "Minimum 50 characters")
      .max(200, "Maximum 200 characters"),
    // tagDetails: Yup.array()
    //   .required("Tag Details are required")
    //   .of(
    //     Yup.object().shape({
    //       description: Yup.string().required("Description is required"),
    //       logo: Yup.string().required("Logo is required"),
    //       position: Yup.number()
    //         .required("Position is required")
    //         .positive("Position must be positive"),
    //     })
    //   )
    //   .min(1, "Please add at least one tag"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values:any, { resetForm }) => {
      try {
        console.log("selected teacher", values.selectedTeacher);  
        const {
          courseName,
          pdfFile,
          tagDetails,
          desc,
          videoFile,
          RepresentativeLink,
          videoPreview,
          tag,
          courseXplans,
          selectedDuration,
          colorCode,
        } = values;

        const duration = {
          startDate: dayjs(selectedDuration?.startDate).format("YYYY-MM-DD"),
          endDate: dayjs(selectedDuration?.endDate).format("YYYY-MM-DD"),
        };
        console.log("values.selectedTeacher", values.selectedTeacher)
        const techers = values?.selectedTeacher?.[0]?.value ? values?.selectedTeacher?.map((teacher) => teacher.value) : values.selectedTeacher
        console.log("techers", techers)
        const formData = new FormData();
        formData.append("courseName", courseName || "");
        formData.append(
          "teacherId",techers
          
        );
        formData.append("videoPreview", videoPreview || "");
        formData.append("videoFile", videoFile || "");
        formData.append("RepresentativeLink", RepresentativeLink || "");
        formData.append("pdfFile", pdfFile || "");
        formData.append("tag", tag || "");
        formData.append("duration", JSON.stringify(duration) || "");
        formData.append("desc", desc || "");
        formData.append("courseXplans", courseXplans || []);
        formData.append("colorCode", colorCode || "");
        formData.append("tagDetails", JSON.stringify(tagDetails) || "");
        setLoading(true);
        // let res
        let res = await apiRequest("updateCourse",formData, [id]);
        if (res?.status === 200) {
          setLoading(false);
          message.success(res?.data?.message);
          getCourseData();
          clearSelectedPdfFile();
          clearSelectedVideo();
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message);
        setLoading(false);
      }
    },
  });
  const {
    values,
    handleSubmit,
    setFieldValue,
    setValues,
    touched,
    errors,
    handleChange,
  } = formik;
  useEffect(() => {
    console.log("courseData",courseData)
    setValues({
        ...courseData,
      selectedDuration: {
        startDate: dayjs(courseData?.duration?.startDate),
        endDate: dayjs(courseData?.duration?.endDate),
      },
      selectedTeacher:
        courseData?.teacherId?.map((teacher) => ({
          value: teacher?._id,
          label: teacher?.name,
        })) || [],
      tagDetails: courseData?.tagDetails || [
        {
          description: "",
          logo: "",
          isActive: true,
          position: "",
        },
      ],
    });
    // setFieldValue("selectedDuration", courseData?.duration)
    setVideoPreviewUrl(courseData?.videoPreview || null);
  }, [courseData, teacherData, setValues]);

  const handleFileChangeVideo = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      setFieldValue("videoFile", file);
      const videoUrl = URL.createObjectURL(file);
      setVideoPreviewUrl(videoUrl);
    }
  };

  const clearSelectedVideo = () => {
    setFieldValue("videoFile", null);
    setVideoPreviewUrl(null);
    const fileInput = document.getElementById("videoFile") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handlePdfFileChange = (event) => {
    const file = event.currentTarget.files[0];
    if (file) {
      clearSelectedPdfFile();
      setFieldValue("pdfFile", file);
      setUploadedFileName(file.name); // Set the uploaded file name
    }
  };

  const clearSelectedPdfFile = () => {
    setFieldValue("pdfFile", null);
    setFieldValue("courseOutline", null);
    setUploadedFileName("");
    const fileInput = document.getElementById("pdfFile") as HTMLInputElement;
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const [accessTags, setAccess] = useState([]);

  const GetAccessTags = async () => {
    try {
      const response = await apiRequest("getDistintTags");
      const formatedData = response?.data?.tags?.map((tag) => ({
        value: tag,
        label: tag,
      }));
      setAccess(formatedData);
    } catch (error) {
      message.error(error?.response?.data?.message || error.message);
    }
  };

  useEffect(() => {
    GetAccessTags();
  }, []);

  return (
    <div className="shadow-md bg-white p-4 rounded-md m-4">
      <form
        onSubmit={handleSubmit}
        encType="multipart/form-data"
        className="mx-4"
      >
        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full md:w-1/2  ">          
            <CustomFormField formik={formik} name="courseName"  type="text" label="Course Name"/>
          </div>

          <div className="md:p-2 w-full md:w-1/2  ">
            <div className="flex flex-col ">
              <label
                htmlFor="name"
                className=" text-md font-bold text-gray-900 mt-1"
              >
                Select Duration
              </label>
              <div>
                <RangePicker
                  className="w-full p-1"
                  name="selectedDuration"
                  format="YYYY-MM-DD"
                  value={[
                    values?.selectedDuration?.startDate,
                    values?.selectedDuration?.endDate,
                  ]}
                  onChange={(value) => {
                    setFieldValue("selectedDuration", {
                      startDate: value[0],
                      endDate: value[1],
                    });
                  }}
                />
              </div>
            </div>

            {touched?.selectedDuration && errors?.selectedDuration && (
              <div className="text-red-500 text-sm mt-1">
              {errors.selectedDuration as string}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full   ">
            <CustomFormField formik={formik} name="desc"  type="textarea" label="Course Description"/>  
          </div>
        </div>
        <div className="flex flex-col md:flex-row ">
          <div className="w-full md:w-1/3">
            <div className="md:p-2 w-full  ">
              <CustomFormField formik={formik} name="RepresentativeLink"  type="text" label="Representative Link"/> 
            </div>
            <div className="md:p-2 w-full   ">
              <CustomFormField name="selectedTeacher" formik={formik} type="select"   mode="multiple" options={teacherData} label="Select Teacher"/>
          
              <div>
               

            </div>
          </div>
          </div>

          <div className="w-full md:w-2/3">
            <div className=" flex gap-3 ">
              <div className="w-full">
                <div className="flex flex-col">
                  <div className="flex justify-center">
                    <h6 className="text-md font-bold text-gray-900 mt-2.5">
                      Upload Course outline{" "}
                    </h6>
                  </div>
                  <div className="flex flex-row flex-wrap items-center  bg-gray-100 p-2 rounded-md justify-center">
                    <div className="flex w-1/2 flex-col items-center gap-2">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-8 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                        />
                      </svg>
                      <button
                        className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                        onClick={(event) => {
                          event.preventDefault();

                          document.getElementById("pdfFile").click();
                        }}
                      >
                        Upload PDF
                      </button>
                      <p>File accepted: PDF</p>
                      {values?.courseOutline && (
                        <button
                          className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 my-0  px-4 rounded border-rose-500"
                          onClick={(e) => {
                            e.preventDefault();
                            clearSelectedPdfFile();
                          }}
                        >
                          Remove PDF Preview
                        </button>
                      )}
                      <input
                        type="file"
                        id="pdfFile"
                        name="pdfFile"
                        accept="application/pdf"
                        className="hidden"
                        onChange={handlePdfFileChange}
                      />
                    </div>
                    {values?.courseOutline ? (
                      <>
                        <div className="flex flex-col items-center gap-2">
                          <iframe src={values?.courseOutline}></iframe>
                        </div>
                      </>
                    ) : (
                      <>
                        {uploadedFileName && (
                          <>
                            <div className="w-1/2 flex flex-col justify-center items-center">
                              <p className="my-0">{uploadedFileName}</p>
                              <button
                                className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                                onClick={(e) => {
                                  e.preventDefault();
                                  clearSelectedPdfFile();
                                }}
                              >
                                Remove PDF
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </div>
                  {touched?.pdfFile && errors?.pdfFile && (
                    <div className="text-red-700">
                    {errors?.pdfFile as string}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="md:p-2 w-full md:w-1/2  ">      
          <CustomFormField formik={formik} name="tag"  type="text" label="Tag Name"/>
        </div>
        <div className="p-2">
          <div className="relative">
            <div className="flex flex-col">
              <h6 className="text-md font-bold text-gray-900 mt-2.5">
                Upload Course Video
              </h6>
              {videoPreviewUrl && (
                <div className="flex flex-col items-center gap-2 my-4  bg-gray-50 ">
                  <video
                    controls
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      maxHeight: "240px",
                      width: "100%",
                    }}
                  >
                    <source src={videoPreviewUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                  <h6 className="text-md font-bold text-gray-900 mt-2.5">
                    Course Preview
                  </h6>
                </div>
              )}
              <div className="flex flex-col flex-wrap items-center gap-2 bg-gray-100 p-2 rounded-md justify-center">
                <div className="flex flex-col items-center gap-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-8 h-8"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5m-13.5-9L12 3m0 0 4.5 4.5M12 3v13.5"
                    />
                  </svg>
                  <button
                    className="border hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500"
                    onClick={(event) => {
                      event.preventDefault();
                      document.getElementById("videoFile").click();
                    }}
                  >
                    Upload Video
                  </button>
                  <p className="mb-0">File accepted: MP4 (Max.100MB)</p>
                  <input
                    type="file"
                    id="videoFile"
                    name="videoFile"
                    accept="video/mp4"
                    className="hidden"
                    onChange={handleFileChangeVideo}
                  />
                  {videoPreviewUrl && (
                    <button
                      className="border hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500"
                      onClick={(e) => {
                        e.preventDefault();
                        clearSelectedVideo();
                      }}
                    >
                      Remove Video
                    </button>
                  )}
                </div>
              </div>
            </div>
            {touched?.videoFile && errors?.videoFile && (
              <div className="text-red-700">
              {errors?.videoFile as string}
              </div>
            )}
          </div>
        </div>

        <div className="flex flex-col md:flex-row ">
          <div className="md:p-2 w-full flex flex-col">
            <CustomFormField name="courseXplans" formik={formik} type="select" mode="multiple" options={accessTags} label="Select a course tag for plans"/> 
          </div>

          <div className="md:p-2 w-full md:w-1/2  ">
            <label
              htmlFor="name"
              className=" text-md font-bold text-gray-900 mt-2.5"
            >
              Color Code
            </label>
            <input
              type="color"
              id="colorCode"
              name="colorCode"
              value={values?.colorCode}
              onChange={handleChange}
              className="w-full custom-input-class"
            />
            {touched?.colorCode && errors?.colorCode && (
              <div className="text-red-500 text-sm mt-1">
              {errors.colorCode  as string}
              </div>
            )}
          </div>
        </div>

        <TagComponent formik={formik} />

        <div className="flex justify-end items-end mx-4 mt-4 ">
          <Button
            disabled={loading}
            variant="contained"
            type="submit"
            color="secondary"
          >
            {loading ? <>Saving..</> : <>Save</>}
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CourseInformation;
