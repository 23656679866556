import React, { useContext, useState } from "react";
import axios from "axios";
import "./careers.css";
import { UserContext } from "../../../state-management/user-context";
import toast from "react-hot-toast";
import { JobsApi } from "(apis)/(shared-apis)/job-apis";

const AddNewJob = () => {
  const context = useContext(UserContext);
  const { user } = context;

  // States For display Selected Background and Icon
  const initialImagesUrl = {
    selectedBackgroundImage: "",
    selectedIconImage: "",
  };

  const imgSizes = {
    bg: {
      width: 1920,
      height: 360,
    },
    icon: {
      width: 72,
      height: 72,
    },
  };

  const [imagesUrl, setImagesUrl] = useState(initialImagesUrl);

  const [formData, setFormData] = useState({
    department: "",
    formLink: "",
    backgroundImage: null,
    iconImage: null,
  });

  const initialErrorState = {
    backgroundImage: false,
    iconImage: false,
  };
  const [error, setError] = useState(initialErrorState);

  const handleInputChange = (e) => {
    const { name, value, files } = e.target;

    if (files) {
      const imageFile = files[0];
      const image = new Image();
      image.onload = () => {
        const imageWidth = image.width;
        const imageHeight = image.height;

        setError(initialErrorState);

        if (
          name === "backgroundImage" &&
          (imageWidth !== imgSizes.bg.width ||
            imageHeight !== imgSizes.bg.height)
        ) {
          setError((prevError) => ({
            ...prevError,
            backgroundImage: true,
          }));
          return;
        }

        if (
          name === "iconImage" &&
          (imageWidth !== imgSizes.icon.width ||
            imageHeight !== imgSizes.icon.width)
        ) {
          setError((prevError) => ({
            ...prevError,
            iconImage: true,
          }));
          return;
        }

        if (name === "backgroundImage") {
          setImagesUrl({
            ...imagesUrl,
            selectedBackgroundImage: URL.createObjectURL(imageFile),
          });
        }

        if (name === "iconImage") {
          setImagesUrl({
            ...imagesUrl,
            selectedIconImage: URL.createObjectURL(imageFile),
          });
        }

        setFormData((prevData) => ({ ...prevData, [name]: imageFile }));
      };

      image.src = URL.createObjectURL(imageFile);
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { department, formLink, backgroundImage, iconImage } = formData;

    if (!department || !formLink || !backgroundImage || !iconImage) {
      // showAlert(
      //   "Please fill in all the fields and upload both images.",
      //   "error"
      // );
      toast.error("Please fill in all the fields and upload both images.");
      //  alert("Please fill in all the fields and upload both images.");
      return;
    }

    const formDataToSend = new FormData();
    formDataToSend.append("department", department);
    formDataToSend.append("username", user?.username);
    formDataToSend.append("formLink", formLink);
    formDataToSend.append("backgroundImage", backgroundImage);
    formDataToSend.append("iconImage", iconImage);

    try {
      // const response = await axios.post(
      //   `${rootPath}/api/job/create`,
      //   formDataToSend,
      //   {
      //     headers: {
      //       "Content-Type": "multipart/form-data",
      //     },
      //   }
      // );
      const response = await JobsApi("createJob", [], formDataToSend);

      console.log("Job created:", response.data);

      // Reset the form after successful submission
      setFormData({
        department: "",
        formLink: "",
        backgroundImage: null,
        iconImage: null,
      });

      setImagesUrl(initialImagesUrl);

      //  Reset Error States
      setError(initialErrorState);
      toast.success("Success message! Job Created Successfully 🚀");
    } catch (error) {
      console.error("Error creating job:", error);
    }
  };

  return (
    <div>
      <div className="careers-formbold-main-wrapper">
        <div className="careers-formbold-form-wrapper">
          <form onSubmit={handleSubmit}>
            <h1 className="mmy-6">Add New Job </h1>
            <div className="careers-formbold-mb-5">
              <label
                className="careers-formbold-form-label careers-formbold-form-label-2"
                htmlFor="department"
              >
                Department:
              </label>
              <input
                type="text"
                name="department"
                id="department"
                placeholder="Enter department name here"
                className="careers-formbold-form-input"
                value={formData.department}
                onChange={handleInputChange}
              />
            </div>
            <div className="careers-formbold-mb-5">
              <label
                className="careers-formbold-form-label careers-formbold-form-label-2"
                htmlFor="formLink"
              >
                Form Link:
              </label>
              <input
                type="text"
                name="formLink"
                id="formLink"
                placeholder="Paste google form link here"
                className="careers-formbold-form-input"
                value={formData.formLink}
                onChange={handleInputChange}
              />
            </div>

            <div className="mb-6 pt-4">
              <label className="careers-formbold-form-label careers-formbold-form-label-2">
                Upload Background Image:
              </label>

              <p
                className={`careers-dimensionError ${
                  error.backgroundImage && "active"
                }`}
              >
                Background Image dimensions must be {imgSizes.bg.width} x{" "}
                {imgSizes.bg.height}
              </p>

              <div className="careers-formbold-mb-5 careers-formbold-file-input">
                <input
                  type="file"
                  name="backgroundImage"
                  id="backgroundImage"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                <label htmlFor="backgroundImage">
                  <div>
                    <span className="careers-formbold-browse"> Browse </span>
                  </div>
                </label>
              </div>

              {formData.backgroundImage && (
                <p className="imageSelectedAlert">
                  Selected Background Image: {formData.backgroundImage.name}
                </p>
              )}
            </div>

            {imagesUrl.selectedBackgroundImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <div className="lableTray">
                  <label className="careers-formbold-form-label careers-formbold-form-label-2">
                    New Background:
                  </label>
                </div>
                <div className="imageSnap">
                  <img
                    src={imagesUrl.selectedBackgroundImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}

            <div className="mb-6 pt-4">
              <label className="careers-formbold-form-label careers-formbold-form-label-2">
                Upload Icon:
              </label>

              <p
                className={`careers-dimensionError ${
                  error?.iconImage && "active"
                }`}
              >
                Icon dimensions must be {imgSizes.icon.width} x{" "}
                {imgSizes.icon.height}
              </p>

              <div className="careers-formbold-mb-5 careers-formbold-file-input">
                <input
                  type="file"
                  name="iconImage"
                  id="iconImage"
                  accept="image/*"
                  onChange={handleInputChange}
                />
                <label htmlFor="iconImage">
                  <div>
                    <span className="careers-formbold-browse"> Browse </span>
                  </div>
                </label>
              </div>
              {formData.iconImage && (
                <p className="imageSelectedAlert">
                  Selected Icon: {formData.iconImage.name}
                </p>
              )}
            </div>

            {imagesUrl.selectedIconImage !== "" && (
              <div className="mb-6 pt-4 careers-formbold-mb-5  ">
                <div className="lableTray">
                  <label className="careers-formbold-form-label careers-formbold-form-label-2">
                    New Icon:
                  </label>
                </div>
                <div className="iconSnap">
                  <img
                    src={imagesUrl.selectedIconImage}
                    alt="Background"
                    className=""
                  />
                </div>
              </div>
            )}

            <div>
              <button
                className="careers-formbold-btn careers-w-full"
                type="submit"
                style={{ marginTop: "5rem" }}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddNewJob;
