import React, { useEffect, useState, useContext } from "react";
import { Button, notification } from "antd";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { UserContext } from "../../state-management/user-context";

const HomePage = () => {
    const {user} = useContext(UserContext);
    const [pageNumber, setPageNumber] = useState(1);
    const [marketingUsers, setMarketingUsers] = useState([]);
    const [OriginalUsers, setOriginalUsers] = useState(null);
    const [selectedDateStart, setSelectedDateStart] = useState(null);
    const [selectedDateEnd, setSelectedDateEnd] = useState(null);
    const [UserLimit, setUserLimit] = useState<any>(0);
    const [City, setCity] = useState("");
    const [NumberBundles] = useState(0);
    const [CityCheck, setCityCheck] = useState(false);
    const [BundlesCheck, setBundlesCheck] = useState(false);
    const [BundlesData, setBundlesData] = useState([]);
    const [BundleSelected, setBundleSelected] = useState([]);
    const [BundleName, setBundleName] = useState([]);
    const [College, setCollege] = useState("");
    const [CollegeBoard, setCollegeBoard] = useState("");
    const [Province, setProvince] = useState("");
    const [ProvinceCheck, setProvinceCheck] = useState(false);
    const [FileUpload, setFileUpload] = useState<File>();
    const [Button1Text, setButton1Text] = useState("");
    const [Button1URL, setButton1URL] = useState("");
    const [Button2Text, setButton2Text] = useState("");
    const [Button2URL, setButton2URL] = useState("");
    const [TextNotification, setTextNotification] = useState("");

    const handleDateChangeEnd = async (date) => {
        setSelectedDateEnd(date);
    };

    const handleDateChange = async (date) => {
        setSelectedDateStart(date);
    };

    useEffect(() => {
      const fetchData = async () => {
        const response = await fetch(
            `${URL}/api/bundle`,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.json();
        setBundlesData(data.data);
      }
        fetchData();
    }, [])
    


    const [NoMoreData, SetNoMoreData] = useState(false);

    const fetchData = async (page) => {
        const response = await fetch(
            `${URL}/api/marketing/Non-Premium-Users-Filtered?page=${page}`,
            {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ selectedDateStart, selectedDateEnd }),
            }
        );
        const data = await response.json();
        return Object.keys(data).map((email) => ({
            email,
            ...data[email],
        }));
    };


    const handleNext = async () => {
        setPageNumber((prevPageNumber) => prevPageNumber + 1);
        setMarketingUsers([]);
        setOriginalUsers([]);
        if (CityCheck) {

            if (UserLimit === 0) {
                notification.error({
                    message: "Please enter the number of users you want"
                });
                return;
            }

            const response = await fetch(
                `${URL}/api/marketing/filter-by-city?page=${pageNumber + 1}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ city: City, itemsPerPage: UserLimit }),
                }
            );
            const data = await response.json();
            console.log(response.status)
            if (response.status !== 200) {
                notification.error({
                    message: "Failed to filter by city"
                });
                return;
            }
            else {
                notification.success({
                    message: "Filtered by city Please Wait for the data to load."
                });
            }
            const sortedData = Object.keys(data).map((email) => ({
                email,
                ...data[email],
            }));
            setMarketingUsers(sortedData);
            setOriginalUsers(sortedData);
            setCityCheck(true);
        }
        else if (BundlesCheck) {

            if (UserLimit === 0) {
                notification.error({
                    message: "Please enter the number of users you want"
                });
                return;
            }

            const response = await fetch(
                `${URL}/api/marketing/filter-by-bundlenumber?page=${pageNumber + 1}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ number: NumberBundles, itemsPerPage: UserLimit }),
                }
            );
            const data = await response.json();
            if (response.status !== 200) {
                notification.error({
                    message: "Failed to filter by BUNDLES Purchased"
                });
                return;
            }
            else {
                notification.success({
                    message: "Filtered by Number of Bundles Purchased Please Wait for the data to load."
                });
            }
            setMarketingUsers(data);
            setOriginalUsers(data);
            setBundlesCheck(true);
        }
        else if (ProvinceCheck) {

            if (UserLimit === 0) {
                notification.error({
                    message: "Please enter the number of users you want"
                });
                return;
            }

            const response = await fetch(
                `${URL}/api/marketing/filter-by-province?page=${pageNumber + 1}`,
                {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ province: Province, itemsPerPage: UserLimit }),
                }
            );
            const data = await response.json();
            console.log(response.status)
            if (response.status !== 200) {
                notification.error({
                    message: "Failed to filter by Province"
                });
                return;
            }
            else {
                notification.success({
                    message: "Filtered by Province Please Wait for the data to load."
                });
            }
            const sortedData = Object.keys(data).map((email) => ({
                email,
                ...data[email],
            }));
            setMarketingUsers(sortedData);
            setOriginalUsers(sortedData);
            setProvinceCheck(true);
        }
    };

    const UpdateResponse = async (username, response) => {
        if (response === "") {
            notification.error({
                message: "Please select a response",
            });
            return;
        }
        if (!user.username) {
            notification.error({
                message: "Please login again",
            });
            return;
        }
        const calledBy = user.username;
        const marketingResponse = response;
        const data = { username, marketingResponse, calledBy };
        try {
            const Response = await fetch(`${URL}/api/marketing/UpdateResponse`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
            const res = await Response.json();
            if (res.success) {
                notification.success({
                    message: res.message,
                });
            } else {
                notification.error({
                    message: res.message,
                });
            }
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            <link
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css"
                rel="stylesheet"
            />
            <script src="https://cdn.jsdelivr.net/npm/chart.js"></script>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/gh/Loopple/loopple-public-assets@main/riva-dashboard-tailwind/riva-dashboard.css" />
            <style>
                {`
                    /* Agregar estilos para la vista de dispositivos pequeños */
                    @media (max-width: 768px) {
                        .flex-wrap {
                            display: flex;
                            flex-wrap: wrap;
                        }
                        .section-small {
                            width: 50%;
                        }
                    }
                `}
            </style>

            <div className="min-h-screen items-center justify-center absolute ml-[40%] mt-[1rem] z-30 hidden" id="Form">
                <div className="max-w-md min-w-[130%] w-full p-6 bg-white rounded-lg shadow-lg border-[3px] border-rose-400">
                    <div className="flex items-center justify-center">
                        <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8 mb-6 ml-[35%]">Filter Users</h1>
                        {/* Close Button */}
                        <div className="cursor-pointer ml-[35%]" onClick={() => {
                            document.getElementById('Form').style.setProperty('display', 'none', 'important');
                        }}>
                            <i className="fas fa-times text-2xl text-rose-700"></i>
                        </div>
                    </div>
                    <div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Date</h1>
                            <div className="flex">
                                <DatePicker
                                    selected={selectedDateStart}
                                    onChange={handleDateChange}
                                    dateFormat="yyyy-MM-dd"
                                    isClearable
                                    placeholderText="Select starting date"
                                    className="ml-[2rem] border-2 border-gray-500 rounded-2xl p-2 text-center"
                                />
                                <DatePicker
                                    selected={selectedDateEnd}
                                    onChange={handleDateChangeEnd}
                                    dateFormat="yyyy-MM-dd"
                                    isClearable
                                    placeholderText="Select ending date"
                                    className="ml-[0.5rem] border-2 border-gray-500 rounded-2xl p-2 text-center"
                                />
                            </div>

                        </div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">City</h1>
                            <input type="text" id="city" name="city" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                setCity(e.target.value);
                                console.log(City);
                            }} />
                        </div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Province</h1>
                            <input type="text" id="province" name="province" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                setProvince(e.target.value);
                            }} />
                        </div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Type of Bundle Purchased</h1>
                            {BundlesData?.length !== 0 && (
                                <select
                                    id="bundleDropdown"
                                    onChange={(e) => {
                                        // Push the selected bundle to state
                                        // Check if already exists in BundleSelected and BundleName
                                        if (BundleSelected.includes(e.target.value)) {
                                            notification.error({
                                                message: "Bundle already selected"
                                            });
                                            return;
                                        }
                                        else {
                                            BundleSelected.push(e.target.value);
                                            setBundleSelected([...BundleSelected]);
                                        }

                                        if (BundleName.includes(e.target.options[e.target.selectedIndex].text)) {
                                            notification.error({
                                                message: "Bundle already selected"
                                            });
                                            return;
                                        }
                                        else {
                                            BundleName.push(e.target.options[e.target.selectedIndex].text);
                                            setBundleName([...BundleName]);
                                        }
                                    }}
                                    className="w-full border-2 rounded-md px-4 py-2 leading-5 transition duration-150 ease-in-out sm:text-sm sm:leading-5 resize-none focus:outline-none focus:border-rose-500"
                                >
                                    <option value="0">Select</option>
                                    {BundlesData.map((bundle, index) => (
                                        <option key={index} value={bundle._id}>
                                            {bundle.BundleName}
                                        </option>
                                    ))}
                                </select>
                            )}
                            {BundleName?.length !== 0 && (
                                // Print the selected bundles also catering if BundleSelected is one or multiple
                                <div>
                                    {BundleName.map((bundle, index) => (
                                        <div key={index} className="flex items-center justify-between" >
                                            <a
                                                className="flex  text-gray-500 py-2.5 px-4 my-4 rounded transition duration-200 bg-gradient-to-r from-rose-500 to-rose-300 hover:no-underline"
                                            >
                                                <i className="fas fa-address-book mr-2"></i>{bundle}
                                                <i className="fas fa-times text-2xl text-gray-700 cursor-pointer pl-2" onClick={() => {
                                                    BundleName.splice(index, 1);
                                                    setBundleName([...BundleName]);
                                                    BundleSelected.splice(index, 1);
                                                    setBundleSelected([...BundleSelected]);
                                                }}></i>
                                            </a>

                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div className="mb-6">
                            <h1 className="block mb-2 text-sm text-gray-600">College Name</h1>
                            <input type="text" id="collegeName" name="collegeName" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                setCollege(e.target.value);
                            }} />
                        </div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Number of Users</h1>
                            <input type="text" id="users" name="users" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                setUserLimit(e.target.value);
                            }} />
                        </div>
                        <button type="submit" className="w-32 bg-gradient-to-r from-rose-400 to-rose-600 text-white py-2 rounded-lg mx-auto block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 mb-2" onClick={async () => {

                            if (UserLimit === 0) {
                                notification.error({
                                    message: "Please enter the number of users you want"
                                });
                                return;
                            }

                            if ((selectedDateStart !== null && selectedDateEnd === null) || (selectedDateStart === null && selectedDateEnd !== null)) {
                                notification.error({
                                    message: "Please select both dates"
                                });
                                return;
                            }

                            notification.info({
                                message: "Filtering by Number of Bundles Purchased"
                            });

                            const response = await fetch(
                                `${URL}/api/marketing/filter-by-allCategories?page=${pageNumber}`,
                                {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ city: City, province: Province, college: College, collegeBoard: CollegeBoard, itemsPerPage: UserLimit, bundles: BundleSelected }),
                                });
                            const data = await response.json();
                            console.log(data);
                            if (response.status !== 200) {
                                notification.error({
                                    message: "Failed to filter by all categories"
                                });
                                return;
                            }
                            else {
                                notification.success({
                                    message: "Filtered by all categories Please Wait for the data to load."
                                });
                                setCityCheck(false);
                                document.getElementById('Form').style.setProperty('display', 'none', 'important');
                                setCity("");
                                setProvince("");
                                setCollege("");
                                setCollegeBoard("");
                                setBundlesCheck(false);
                                setUserLimit(0);
                            }
                            const sortedData = Object.keys(data).map((email) => ({
                                email,
                                ...data[email],
                            }));
                            setMarketingUsers(sortedData);
                            setOriginalUsers(sortedData);
                        }}>Filter</button>
                    </div>
                </div>
            </div>

            <div className="min-h-screen items-center justify-center absolute ml-[40%] mt-[1rem] z-30 hidden" id="Notification">
                <div className="max-w-md min-w-[130%] w-full p-6 bg-white rounded-lg shadow-lg border-[3px] border-rose-400">
                    <div className="flex items-center justify-center">
                        <h1 className="text-2xl font-semibold text-center text-gray-500 mt-8 mb-6 ml-[25%]">Send Notification</h1>
                        {/* Close Button */}
                        <div className="cursor-pointer ml-[25%]" onClick={() => {
                            document.getElementById('Notification').style.setProperty('display', 'none', 'important');
                        }}>
                            <i className="fas fa-times text-2xl text-rose-700"></i>
                        </div>
                    </div>
                    <div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Notification Icon</h1>
                            <input
                                id='imagefile'
                                type="file"
                                name="image"
                                accept=".jpg,.jpeg,.png"
                                onChange={(e) => {
                                    setFileUpload(e.target.files[0]);
                                }}
                                className="file-input"
                            />
                        </div>
                        <div className="mb-4">
                            <h1 className="block mb-2 text-sm text-gray-600">Notification Text</h1>
                            <input type="text" id="notifText" name="city" className="w-full px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                setTextNotification(e.target.value);
                            }} />
                        </div>
                        <div className="mb-4">
                            <div className="flex">
                                <h1 className="block mb-2 text-sm text-gray-600">Button #1 Text:</h1>
                                <input type="text" id="button1text" name="province" className="w-[50%] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                    setButton1Text(e.target.value);
                                }} />
                                <h1 className="block mb-2 text-sm text-gray-600">Button #1 Redirect URL (Include After premed.pk)</h1>
                                <input type="text" id="button1url" name="province" className="w-[50%] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                    setButton1URL(e.target.value);
                                }} />
                            </div>

                        </div>
                        <div className="mb-4">
                            <div className="flex">
                                <h1 className="block mb-2 text-sm text-gray-600">Button #2 Text:</h1>
                                <input type="text" id="button2text" name="province" className="w-[50%] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                    setButton2Text(e.target.value);
                                }} />
                                <h1 className="block mb-2 text-sm text-gray-600">Button #2 Redirect URL (Include After premed.pk)</h1>
                                <input type="text" id="button2url" name="province" className="w-[50%] px-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-rose-500" onChange={(e) => {
                                    setButton2URL(e.target.value);
                                }} />
                            </div>

                        </div>
                        <button type="submit" className="w-32 bg-gradient-to-r from-rose-400 to-rose-600 text-white py-2 rounded-lg mx-auto block focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-500 mb-2" onClick={async () => {

                            if (TextNotification === "") {
                                notification.error({
                                    message: "Please enter the notification text"
                                });
                                return;
                            }

                            if ((Button1Text !== "" && Button1URL === "") || (Button1Text === "" && Button1URL !== "")) {
                                notification.error({
                                    message: "Please enter both the button text and the button URL for Button 1"
                                });
                                return;
                            }

                            if ((Button2Text !== "" && Button2URL === "") || (Button2Text === "" && Button2URL !== "")) {
                                notification.error({
                                    message: "Please enter both the button text and the button URL for Button 2"
                                });
                                return;
                            }

                            if (FileUpload?.name === "") {
                                notification.error({
                                    message: "Please upload an image"
                                });
                                return;
                            }

                            console.log(FileUpload);

                            notification.info({
                                message: "Sending Notification"
                            });

                            const response = await fetch(
                                `${URL}/api/marketing/SendMarketingEmail`,
                                {
                                    method: "PUT",
                                    headers: {
                                        "Content-Type": "application/json",
                                    },
                                    body: JSON.stringify({ users: marketingUsers, Text: TextNotification, ActionButton1Text: Button1Text, ActionButton1Link: Button1URL, ActionButton2Text: Button2Text, ActionButton2Link: Button2URL, IconSrc: FileUpload.name}),
                                });
                            const data = await response.json();
                            console.log(data);
                            if (response.status !== 200) {
                                notification.error({
                                    message: "Failed to send notification"
                                });
                                return;
                            }
                            else {
                                notification.success({
                                    message: "Notification Sent Successfully"
                                });
                                document.getElementById('Notification').style.setProperty('display', 'none', 'important');
                                setFileUpload(null);
                                setTextNotification("");
                                setButton1Text("");
                                setButton1URL("");
                                setButton2Text("");
                                setButton2URL("");
                            }
                        }}>Send</button>
                    </div>
                </div>
            </div>

            <div className="MarketingNav fixed h-[50px] z-10">Notification Management</div>
            <div id="MarketingTable-Wrapper">
                <div id="MarketingTable2">
                    <div className="flex flex-col h-screen bg-red-100 -mt-4 w-[325%] -ml-4">
                        <div className="flex-1 flex">

                            <div className="flex-1 p-4  mt-[1.5%] ">
                                <div className="max-w-[1150px] w-full fixed z-10 border-4 border-red-300 rounded-full">
                                    <div className="absolute top-1 left-2 inline-flex items-center p-2">
                                        <i className="fas fa-search text-gray-400"></i>
                                    </div>
                                    <input
                                        className="w-[100%] h-10 pl-10 pr-4 py-1 text-base placeholder-gray-500 border rounded-full focus:shadow-outline"
                                        type="search"
                                        placeholder="Search User..."
                                        onChange={(e) => {
                                            const search = e.target.value;
                                            if (search === "") {
                                                setMarketingUsers(OriginalUsers);
                                                return;
                                            }

                                            const filteredData = OriginalUsers.filter((item) => {
                                                return item.fullname.toLowerCase().includes(search.toLowerCase());
                                            });
                                            setMarketingUsers(filteredData);
                                        }}
                                    />


                                </div>
                                <div>
                                    <button className="ml-[82%] fixed mt-1 border-2 border-red-500 rounded-2xl bg-red-300 text-white w-[5%] h-10 font-bold hover:bg-red-400" onClick={() => {
                                        document.getElementById('Form').style.setProperty('display', 'flex', 'important');
                                    }}>Filter</button>
                                </div>

                                <div className="w-[100%] mt-[1%]">
                                    <div className="flex flex-wrap -mx-3 mb-5">
                                        <div className="w-full max-w-full px-3 mb-6 mx-auto">
                                            <div className="relative flex-[1_auto] flex flex-col break-words min-w-0 bg-clip-border rounded-[.95rem] bg-white m-5">
                                                <div className="relative flex flex-col min-w-0 break-words border border-dashed bg-clip-border rounded-2xl border-stone-200 bg-light/30">
                                                    <div className="px-9 pt-5 flex justify-between items-stretch flex-wrap min-h-[70px] pb-0 bg-transparent">
                                                        <h3 className="flex flex-col items-start justify-center m-2 ml-0 font-medium text-xl/tight text-dark">
                                                            <span className="mr-3 font-semibold text-dark">User List</span>
                                                            <span className="mt-1 font-medium text-secondary-dark text-lg/normal">See all filtered users here</span>
                                                        </h3>
                                                        <div className="relative flex flex-wrap items-center my-2">
                                                            {marketingUsers?.length !== 0 && (
                                                                <Button
                                                                    style={{ marginLeft: '-15%', backgroundColor: '#ff4c5a', color: 'white', borderRadius: '12px' }}
                                                                    onClick={() => {
                                                                        document.getElementById('Notification').style.setProperty('display', 'flex', 'important');

                                                                    }}
                                                                >
                                                                    Send Notification
                                                                </Button>
                                                            )}
                                                            {/* Button to reset filters */}
                                                            <Button
                                                                style={{ marginLeft: '10%', backgroundColor: '#ff4c5a', color: 'white', borderRadius: '12px', boxShadow: '0 4px 8px 0 rgba(0,0,0,0.2)' }}
                                                                onClick={async () => {
                                                                    setMarketingUsers([]);
                                                                    setOriginalUsers([]);
                                                                    setPageNumber(1);
                                                                    setCity("");
                                                                    setCityCheck(false);
                                                                    setProvince("");
                                                                    setProvinceCheck(false);
                                                                    setCollege("");
                                                                    setCollegeBoard("");
                                                                    setBundlesCheck(false);
                                                                }}
                                                            >
                                                                Reset Filters
                                                            </Button>
                                                        </div>
                                                    </div>

                                                    <div className="flex-auto block py-8 pt-6 px-4">
                                                        <div className="overflow-x-auto">
                                                            <table className="max-w-[2000px] my-0 align-middle text-dark border-neutral-200">
                                                                <thead className="align-bottom">
                                                                    <tr className="font-semibold text-[0.95rem] text-secondary-dark">
                                                                        <th className="pb-3 px-6">Name</th>
                                                                        <th className="pb-3 px-[6.67%]">Email</th>
                                                                        <th className="pb-3 px-[13.33%]">City</th>
                                                                        <th className="pb-3 px-[20%]">Province</th>
                                                                        <th className="pb-3 px-[26.67%]">Number</th>
                                                                        <th className="pb-3 px-[33.33%]">School Name</th>
                                                                        <th className="pb-3 px-[40%]">Educational Board</th>
                                                                        <th className="pb-3 px-[46.67%]">Current Year</th>
                                                                        <th className="pb-3 px-[53.33%]">Parent's Full Name</th>
                                                                        <th className="pb-3 px-[60%]">Parent's Number</th>
                                                                        <th className="pb-3 px-[66.67%]">Preparing For</th>
                                                                        <th className="pb-3 px-[73.33%]">Academy Joined</th>
                                                                        <th className="pb-3 px-[80%]">Reason's for using</th>
                                                                        <th className="pb-3 px-[86.67%]">Bundles Purchased</th>
                                                                        <th className="pb-3 px-[93.33%]">Marketing Response</th>
                                                                        <th className="pb-3 px-[100%]">Marketing Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {/* marketing Users will be displayed here */}
                                                                    {marketingUsers.map((user, index) => (
                                                                        <tr key={index} className="text-dark">
                                                                            <td className="py-3 px-6">{user.fullname}</td>
                                                                            <td className="py-3 px-[5%]">{user.email}</td>
                                                                            <td className="py-3 px-[13%]">{user.city}</td>
                                                                            <td className="py-3 px-[20%]">{user.province}</td>
                                                                            <td className="py-3 px-[26.67%]">{user.phoneNumber}</td>
                                                                            <td className="py-3 px-[33.33%]">{user.school}</td>
                                                                            {/* <td className="py-3 px-[40%]">{user.BoardName}</td> */}
                                                                            {user.BoardName === "" ? (
                                                                                <td className="py-3 px-[40%]">Not Available</td>
                                                                            ) : (
                                                                                <td className="py-3 px-[40%]">{user.BoardName}</td>
                                                                            )}
                                                                            <td className="py-3 px-[46.67%]">{user.whichYear}</td>
                                                                            <td className="py-3 px-[53.33%]">{user.parentFullName}</td>
                                                                            <td className="py-3 px-[60%]">{user.parentContactNumber}</td>
                                                                            <td className="py-3 px-[66.67%]">{user.testpreparation}</td>
                                                                            <td className="py-3 px-[74.7%]">{user.academyJoined}</td>
                                                                            {user.intendFor.length === 0 ? (
                                                                                <td className="py-3 px-[80%]">Not Available</td>
                                                                            ) : (
                                                                                // Commas are added to separate the values
                                                                                <td className="py-3 px-[80%]">{user.intendFor.join(", ")}</td>
                                                                            )}
                                                                            <td className="py-3 px-[88%]">{user.BundlesPurchased?.length}</td>
                                                                            <td className="py-3 px-[94.2%]">{user.marketingResponse === "" ? "Not Updated" : user.marketingResponse}</td>
                                                                            <td className="py-3 px-[100%]"><select
                                                                                id="stateDropdown"
                                                                                onChange={(e) =>
                                                                                    UpdateResponse(user.email, e.target.value)
                                                                                }
                                                                            >
                                                                                <option value="">Action</option>
                                                                                <option value="Responded Negatively">
                                                                                    Responded Negatively
                                                                                </option>
                                                                                <option value="Responded Positively">
                                                                                    Responded Positively
                                                                                </option>
                                                                                <option value="Follow Up">Follow Up</option>
                                                                            </select></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                            {marketingUsers?.length === 0 && (
                                                                <div className="mt-[150px] pb-[150px] flex items-center justify-center">
                                                                    <h2 style={{ color: 'red', fontSize: '40px' }}>No Users Here</h2>
                                                                </div>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className="YetAnotherButton">
                {marketingUsers?.length !== 0 && (
                    <Button className="MBtn" onClick={handleNext} style={{ backgroundColor: '#ff4c5a', color: 'white', borderRadius: '12px' }}>
                        Load More
                    </Button>
                )}
            </div>
        </>
    );
};

export default HomePage;
