import { useDrag, useDrop } from 'react-dnd';
import { CopyIcon, EditIcon, EyeIcon, ManageIcon } from '../../../../shared-components/ui/Icons';
import CustomBadges from '../../../../shared-components/ui/CustomBadges';
import { Switcher } from '../../../../shared-components/ui/CustomForm';
import { toast } from 'react-hot-toast';
import { DeckAPI } from '../../../../(apis)/(shared-apis)/academics/decks-api';

export default function DraggableQuestion({ deck, index, moveQuestion, decks, setDecks, GetDecks, setMode, prepopulateData, setPopulated, setShowModel, deckGroupName, categoryName }) {

    const [, drag] = useDrag({
        type: 'DECKS',
        item: { index },
    });

    const [, drop] = useDrop({
        accept: 'DECKS',
        hover: (draggedItem: { index: any; }) => {
            if (draggedItem.index !== index) {
                moveQuestion(draggedItem.index, index);
                draggedItem.index = index;
            }
        },
    });

    const handlePublishedChange = async (id) => {
        const updateDecks = [...decks]
        const index = updateDecks.findIndex((deck) => deck._id === id)
        updateDecks[index].isPublished = !updateDecks[index].isPublished
        setDecks(updateDecks)

        const Response = await DeckAPI('handlePublish', id)
        console.log(Response)
        if (Response.data.success) {
            toast.success(Response.data.message)
            GetDecks()
        }
        else {
            toast.error(Response.data.message)
        }
    }

    return (
        <tr ref={(node) => drag(drop(node))} className="bg-white divide-y divide-gray-200 text-gray-800">
            <td className="cursor-pointer">
                <div className='flex flex-row justify-center items-center space-x-3'
                    onClick={() => { navigator.clipboard.writeText(deck._id); toast.success('Deck ID Copied') }}>
                    <CopyIcon size='w-6 h-6' color='text-gray-500' />
                </div>
            </td>
            <td className="px-4 py-4">
                <img className="w-10 h-10 rounded" src={deck.deckLogo} alt="" />
            </td>
            <td className="px-4 py-4">
                {deck.deckName}
            </td>
            <td className="px-4 py-4 text-center">
                <div className='flex flex-row justify-center items-center space-x-3'>
                    <CustomBadges
                        label={`${deck.questions.length}`}
                        showIcon={false}
                        type='warning'
                    />
                    <CustomBadges
                        label={`${deck.unpublishedQuestions.length}`}
                        showIcon={false}
                        type='success'
                    />
                    <CustomBadges
                        label={`${deck?.SAQs?.length || 0}`}
                        showIcon={false}
                        type='error'
                    />
                </div>
            </td>
            <td className="px-4 py-3 text-center">
                <div className="flex items-center justify-center cursor-pointer">
                    <Switcher
                        isActive={undefined}
                        for={deck._id}
                        onChange={() => handlePublishedChange(deck._id)}
                        togglevalue={deck.isPublished}
                        size="small"
                    />
                </div>
            </td>
            <td className="px-2 py-3 text-center">
                <div className='flex flex-row justify-center items-center space-x-3'
                    onClick={() => { window.open(`/deck/${deck.deckName}`, "_blank") }}>
                    <ManageIcon size='w-6 h-6' color='text-gray-500' />
                </div>
            </td>
            <td className="px-2 py-5 text-center" >
                <div className='flex flex-row justify-center items-center space-x-3' onClick={() => { window.open(`https://premed.pk/attempt/${categoryName}/${deckGroupName}/${deck.deckName}`, "_blank"); }}>

                    <EyeIcon size='w-6 h-6' color='text-gray-500' />
                </div>
            </td>

            <td className="px-2 py-3 text-center">
                <div className='flex flex-row justify-center items-center space-x-3'
                    onClick={() => {
                        setMode('edit')
                        setPopulated(deck)
                        setShowModel(true)
                    }}
                >
                    <EditIcon size='w-6 h-6' color='text-gray-500' />
                </div>
            </td>

        </tr>
    );
};