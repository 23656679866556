import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const AdminAccessAPIs: ApiInterface[] = [
    {
        url: "/api/admin-access/GetAdminUsers",
        method: "GET",
        name: "getAdminUsers",
    },
    {
      url: "/api/admin-access/GetUsersEmails",
      method: "GET",
      name: "getUsersEmails",
    },
    {
      url: "/api/admin-access/AddAdminUser",
      method: "POST",
      name: "addAdminUser",
    },
    {
      url: "/api/admin-access/RemoveAdminAccess",
      method: "POST",
      name: "removeAdminAccess",
    },
    {
      url: "/api/admin-access/UpdateAdminUserPermissions",
      method: "POST",
      name: "updateAdminUserPermissions",
    }

]

export function AccessAPI(name: string, params: string[] = [""], data: any = null) {
    const api = AdminAccessAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

