import { ApiInterface } from "../../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../../constants/config";

export const DeckAPIs: ApiInterface[] = [
    {
        url: "/api/decks/add-deck",
        method: "POST",
        name: "addDeck"
    },
    {
        url: "/api/decks/edit-deck",
        method: "PATCH",
        name: "editDeck"
    },
    {
        url: "/api/decks/get-decks",
        method: "GET",
        name: "getDecks"
    },
    {
        url: "/api/decks/get-deck-names",
        method: "GET",
        name: "getDeckNames"
    },
    {
        url: "/api/decks/update-order",
        method: "POST",
        name: "updateOrder"
    },
    {
        url: "/api/decks/publish-question",
        method: "POST",
        name: "unPublishDeckQuestion"
    },
    {
        url: "/api/decks/handle-publish",
        method: "GET",
        name: "handlePublish"
    },
    {
        url: "/api/decks/bulk-add-questions",
        method: "POST",
        name: "bulkPublish"
    },
    {
        url: "/api/decks/get-deck-statistics",
        method: "GET",
        name: "getDeckStatistics"
    },
    {
        url: "/api/decks/get-populated-decks",
        method: "GET",
        name: "getDeckQuestions"
    }
]

export function DeckAPI(name: string, params: string = "", data: any = null) {
    const api = DeckAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? `/${encodeURIComponent(params)}` : "");
  
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

// import axios from "axios";
// import { backendUrl } from "../Constants/appConstants";

// export async function AddDeck(payload, categoryName) {
//     return axios.post(`${backendUrl}/api/decks/add-deck`, payload,);
// }

// export async function EditDeck(payload, id) {
//     return axios.patch(`${backendUrl}/api/decks/edit-deck`, { payload, id });
// }

// export async function GetDecks() {
//     return axios.get(`${backendUrl}/api/decks/get-decks`);
// }

// export async function GetDeckNames() {
//     return axios.get(`${backendUrl}/api/decks/get-deck-names`);
// }

// export async function UpdateOrder(payload, id) {
//     return axios.post(`${backendUrl}/api/decks/update-order`, { payload, id });
// }

// export async function UnPublishDeckQuestion(deckId, questionId) {
//     return axios.post(`${backendUrl}/api/decks/publish-question`, { deckId, questionId });
// }

// export async function handlePublish(deckId) {
//     return axios.get(`${backendUrl}/api/decks/handle-publish/${deckId}`);
// }

// export async function BulkPublish(deckId, questionIds) {
//     return axios({
//         method: "POST",
//         withCredentials: true,
//         url: `${backendUrl}/api/decks/bulk-add-questions`,
//         data: { deckId, questionIds }
//     });
// }

// export async function GetDeckStatistics(deckId) {
//     return axios.get(`${backendUrl}/api/decks/get-deck-statistics/${deckId}`)
// }


// export function getDeckQuestions(deckName) {
//     return axios.get(backendUrl + `/api/decks/get-populated-decks/${deckName}`);
//   }
  