import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Alert, AlertTitle, IconButton, InputAdornment, TextField } from "@mui/material";
import { useContext, useEffect, useState } from "react";

import { UserContext } from "../../state-management/user-context";
import styles from './styles.module.css';
import { getLoggedInUser, login } from "../../(apis)/(shared-apis)/auth-apis";
import { useNavigate, useLocation } from 'react-router-dom';
import { Logos } from "../../constants/theme-config";

const LoginPage = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Navigation must be used within a UserProvider");
  }
  const { user, setUser } = context;

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (user.isloggedin) {
      navigate("/dashboard");
    }
  }, []);

  const [waitingOnResponse, SetWaitingOnResponse] = useState(false);

  const [showPassword, SetShowPassword] = useState(false);
  const [shakeUsername, SetShakeUsername] = useState(false);
  const [shakePassword, SetShakePassword] = useState(false);

  const [username, SetUsername] = useState("");
  const [password, SetPassword] = useState("");
  const [usernameValid, SetUsernameValid] = useState(true);
  const [passwordValid, SetPasswordValid] = useState(true);
  const [usernameErrorText, SetUsernameErrorText] = useState("");
  const [passwordErrorText, SetPasswordErrorText] = useState("");
  const [serverError, SetServerError] = useState(false);
  const [serverErrorText, SetServerErrorText] = useState("");

  function OnUsernameChange(event) {
    var newUsername = event.target.value.trim();
    SetUsername(newUsername);
    ValidateUsername(newUsername);
  }

  function ValidateUsername(newUsername) {
    if (newUsername.length === 0) {
      SetUsernameErrorText("Username cant be empty");
      SetUsernameValid(false);
      return false;
    } else {
      SetUsernameErrorText("");
      SetUsernameValid(true);
      return true;
    }
  }

  function OnPasswordChange(event) {
    var newPassword = event.target.value.trim();
    SetPassword(newPassword);
    ValidatePassword(newPassword);
  }
  function ValidatePassword(newPassword) {
    if (newPassword.length === 0) {
      SetPasswordErrorText("Password cant be empty");
      SetPasswordValid(false);
      return false;
    } else {
      SetPasswordErrorText("");
      SetPasswordValid(true);
      return true;
    }
  }

  function OnSubmitClicked() {
    var usernamevalid = ValidateUsername(username);
    var passwordvalid = ValidatePassword(password);
    SetShakeUsername(!usernamevalid);
    SetShakePassword(!passwordvalid);
    if (usernamevalid && passwordvalid) {
      SetWaitingOnResponse(true);
      SetServerError(false);
      SetServerErrorText(false);
      login({
        username: username.toLowerCase().trim(),
        password: password.trim(),
      })
        .then((res) => {
          SetWaitingOnResponse(false);
          ResolveServerResponse(res);
        })
        .catch(() => {
          SetWaitingOnResponse(false);
          SetServerError(true);
          SetServerErrorText("Network Error. Try Again");
        });
    }
  }

  function ResolveServerResponse(res) {
    var data = res.data;
    if (data.success === true) {
      getLoggedInUser().then((res) => {
        console.log("Logged in", res.data);
        setUser(res.data);
        const urlParams = new URLSearchParams(location.search);
        const redirectUrl = urlParams.get('redirect_url');

        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate('/dashboard');
        }
      });
    } else {
      SetServerError(true);
      if (data.status.includes("Already logged")) {
        console.log("Already logged in");
        const urlParams = new URLSearchParams(location.search);
        const redirectUrl = urlParams.get('redirect_url');
        if (redirectUrl) {
          navigate(redirectUrl);
        } else {
          navigate('/dashboard');
        }
      }
      SetServerErrorText(data.status);
    }
  }


  return (
    <div className="center-content w-screen  min-h-screen containerbg relative z-0">
      <div className="absolute w-screen h-screen inset-0 bg-white/90 z-10">

        <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
          <a href="#" className="flex items-center mb-6 text-2xl font-semibold text-gray-900">
            <img
              src={Logos[process.env.REACT_APP_CATEGORY]}
              alt="PreMed Logo"
              className="w-22 h-12"
            />
          </a>
          <div className="w-full bg-white rounded-lg shadow-xl md:mt-0 sm:max-w-md xl:p-0">
            <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
              <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl">
                Sign in to your account
              </h1>
              <div
                className={styles.ErrorAlert}
                style={serverError ? { display: "" } : { display: "none" }}
              >
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  {serverErrorText}
                </Alert>
              </div>
              <div className="text-sm text-gray-500">
                Please use your email and password from your premed.pk account. If you do not have access or encounter invalid credentials, kindly reach out to Hasnain Mankani at 0302-8609690.
              </div>
              <form className="space-y-4 md:space-y-6" action="#">
                <div>
                  <label for="email" className="block mb-2 text-sm font-medium text-gray-900">Your email</label>
                  <div
                    className={styles.InputField}
                    style={
                      shakeUsername ? { animation: "shake .5s" } : { animation: "" }
                    }
                    onAnimationEnd={() => {
                      SetShakeUsername(false);
                    }}
                  >
                    <TextField
                      fullWidth
                      id="outlined-basic"
                      label="Email"
                      error={!usernameValid}
                      variant="outlined"
                      helperText={usernameErrorText}
                      onChange={OnUsernameChange}
                      className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                    />
                  </div>

                </div>
                <div>
                  <label for="password" className="block mb-2 text-sm font-medium text-gray-900">Password</label>
                  <div
                    className={styles.InputField}
                    style={
                      shakePassword ? { animation: "shake .5s" } : { animation: "" }
                    }
                    onAnimationEnd={() => {
                      SetShakePassword(false);
                    }}
                  >
                    <TextField
                      className={styles.InputField}
                      fullWidth
                      id="outlined-basic"
                      label="Password"
                      error={!passwordValid}
                      variant="outlined"
                      type={showPassword ? "text" : "password"}
                      helperText={passwordErrorText}
                      onChange={OnPasswordChange}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => {
                                SetShowPassword(!showPassword);
                              }}
                            >
                              {showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex items-start">
                    <div className="flex items-center h-5">
                      <input id="remember" aria-describedby="remember" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300" required="" />
                    </div>
                    <div className="ml-3 text-sm">
                      <label for="remember" className="text-gray-500">Remember me</label>
                    </div>
                  </div>
                  <a href="https://wa.link/m3okv4" className="text-sm font-medium text-primary hover:underline">Forgot password?</a>
                </div>

                <button type="submit" className="w-full text-white bg-primary hover:bg-white hover:border hover-primary hover:text-primary focus:ring-4 focus:outline-none focus:ring-primary font-medium rounded-lg text-sm px-5 py-2.5 text-center transition duration-150 ease-in-out"
                  onClick={OnSubmitClicked}
                  disabled={waitingOnResponse}
                >
                  {waitingOnResponse ? (
                    <div className="flex items-center justify-center">
                      <div role="status">
                        <svg aria-hidden="true" className="w-6 h-6 text-gray-200 animate-spin fill-white" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                        </svg>
                        <span className="sr-only">Loading...</span>
                      </div>
                      <span className="ml-3">Loading...</span>
                    </div>
                  ) : (
                    <span className="ml-3">Sign in</span>
                  )}
                </button>

                <p className="text-sm font-light text-gray-500">
                  Don’t have an account yet? <a href="https://wa.link/npm8up" className="font-medium text-primary hover:underline"> Contact Admin  </a>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>

  );
};

export default LoginPage;
