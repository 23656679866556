import { PlusOutlined } from "@ant-design/icons";
import {
    message,
    Space,
    Table,
    Tag
} from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";

import { apiRequest } from "(apis)/api-interface/api-handler";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";
import GlobalTable from "shared-components/table/GlobalTable";
import { FormValues, HierarchyItem } from "./types";

const Hierarchy: React.FC = () => {
  const [isAddModal, setIsAddModal] = useState(false);
  const [isFetchingData, setIsFetchingData] = useState(false);
  const [hierarchyData, setHierarchyData] = useState<HierarchyItem[]>([]);
  const [editMode, setEditMode] = useState(false);
  useEffect(() => {
    fetchHierarcyData();
  }, []);

  const fetchHierarcyData = async () => {
    try {
      setIsFetchingData(true);
      const res = await apiRequest("getHierarchies");
      if (res?.status === 200) {
        const data = res?.data.map((item: HierarchyItem) => ({
          ...item,
          isTags: true,
          tags: item?.tags?.slice(0, 3),
          expandData: item?.tags,
          key: item?._id,
        }));
        setHierarchyData(data);
      }
    } catch (error: any) {
      message.error(error?.response?.data?.message || error?.message);
    } finally {
      setIsFetchingData(false);
    }
  };

  const formik = useFormik<FormValues>({
    initialValues: {
      board: "",
      Tags: [],
      editId: "",
    },
    validationSchema: Yup.object({
      board: Yup.string().required("Required"),
      Tags: Yup.array().min(1, "At least one tag is required"),
    }),
    onSubmit: async (values, { resetForm }) => {
      try {
        const payload = {
          tags: values?.Tags,
          board: values?.board,
          category: process.env.REACT_APP_CATEGORY,
          entity: process.env.REACT_APP_ENTITY,
        };
        const res = editMode
          ? await apiRequest("updateHierarchy", payload, [values?.editId])
          : await apiRequest("addHierarchy", payload);

        if (res?.status === 200) {
          message.success(
            editMode
              ? "Hierarchy Updated Successfully "
              : "Hierarchy Added Successfully"
          );
          fetchHierarcyData();
          resetForm();
          setIsAddModal(false);
          setEditMode(false);
        }
      } catch (error: any) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const handleEdit = (data: HierarchyItem) => {
    const { board, expandData } = data;
    formik.setValues({
      Tags: expandData || [],
      board,
      editId: data?._id,
    });
    setIsAddModal(true);
    setEditMode(true);
  };

  const getTagColor = (index: number): string => {
    const colors = [
      "green",
      "cyan",
      "blue",
      "geekblue",
      "purple",
      "magenta",
      "red",
      "volcano",
      "orange",
      "gold",
      "lime",
    ];
    return colors[index % colors.length];
  };

  const columns = [
    {
      title: "Board",
      dataIndex: "board",
      key: "board",
    },
    Table.EXPAND_COLUMN,
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      render: (tags: string[]) => (
        <div className="flex flex-wrap gap-1">
          {tags.map((tag, index) => (
            <Tag color={getTagColor(index)} key={tag}>
              {tag}
            </Tag>
          ))}
        </div>
      ),
    },
    Table.SELECTION_COLUMN,
    {
      title: "Actions",
      key: "actions",
      align: "center" as const,
      render: (data: HierarchyItem) => (
        <Space size="middle">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-7 h-7 cursor-pointer text-black hover:text-rose-200"
            onClick={() => handleEdit(data)}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L10.582 16.07a4.5 4.5 0 0 1-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 0 1 1.13-1.897l8.932-8.931Zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0 1 15.75 21H5.25A2.25 2.25 0 0 1 3 18.75V8.25A2.25 2.25 0 0 1 5.25 6H10"
            />
          </svg>
        </Space>
      ),
    },
  ];
  return (
    <div>
      <div className="mx-4">
        <div className="flex justify-end ">
          <CustomButton
            icon={<PlusOutlined />}
            text="Add New Hierarchy"
            onClick={() => {
              formik.resetForm();
              setEditMode(false);
              setIsAddModal(true);
            }}
          />
        </div>
        <GlobalTable
          isExpandable={true}
          tableName="Manage Hierarchy"
          loading={isFetchingData}
          columns={columns}
          data={hierarchyData}
        />
        <CustomAntdModal
          isOpenModal={isAddModal}
          width={768}
          title={editMode ? `Edit Hierarchy` : `Add Hierarchy `}
          setIsOpenModal={setIsAddModal}
          formik={formik}
        >
          <div className="flex flex-col ">
            <div className="md:p-2 w-full ">
              <CustomFormField
                type="text"
                name="board"
                label="Board"
                formik={formik}
              />
            </div>
            <div className="md:p-2 w-full ">
              <CustomFormField
                mode="tags"
                type="select"
                name="Tags"
                label="Tags"
                formik={formik}
              />
            </div>
          </div>
        </CustomAntdModal>
      </div>
    </div>
  );
};
export default Hierarchy;
