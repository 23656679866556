import React, { useEffect, useState } from 'react';

import { SnapCourseModalProps, Option } from './types';
import { accessData, provinceData, uniYearData  } from 'constants/constantvalues';
import CustomAntdModal from 'shared-components/modal/CustomAntdModal';
import GlobalForm from 'shared-components/form-fields/global-form';

const SnapCourseModal: React.FC<SnapCourseModalProps> = ({
  formik,
  institutesData,
  setIsEditMode,
  setShowModel,
  isEditMode,
  ShowModel,
  boardsData,
  loading,
  allSubTopicsData,
  subjectData,
  topicsData,
  teachersData,
  originalTopisData,
}) => {
  const [subTopicOptions, setSubTopicOptions] = useState<Option[]>([]);
  useEffect(() => {
    const selectedTopic = originalTopisData?.find((topic) => topic?.topicName === formik?.values?.topicName);
    if (selectedTopic) {
      setSubTopicOptions(
        selectedTopic?.subTopics?.map((subTopic) => ({
          label: subTopic.subTopicName,
          value: subTopic.subTopicName,
        }))
      );
    } else {
      setSubTopicOptions([]);
    }
  }, [formik.values.topicName, topicsData, originalTopisData]);

  const formData = {
    fields: [
    
      // Conditionally add universities and year fields
      ...( process.env.REACT_APP_CATEGORY === 'MEDSCHOOL'
        ? [
            {
              name: 'universities',
              label: 'Universities',
              type: 'text',
              size: 6,
              mode: 'multiple',
              formContent: 'select',
              options: institutesData,
              require: true,
            },
            {
              name: 'year',
              label: 'Year',
              type: 'text',
              size: 6,
              formContent: 'select',
              options: uniYearData,
              require: true,
            },
          ]
        : []),
      {
        name: 'province',
        label: 'Province',
        type: 'text',
        size: 6,
        formContent: 'select',
        options: provinceData,
      },
      {
        name: 'subject',
        label:
         process.env.REACT_APP_CATEGORY === 'MEDSCHOOL'
            ? 'Discipline'
            : 'Subject',
        type: 'text',
        size: 6,
        formContent: 'select',
        require: true,
        options: subjectData,
      },
      {
        name: 'board',
        label: 'Board',
        type: 'text',
        size: 6,
        formContent: 'select',
        require: true,
        options: boardsData,
      },
      {
        name: 'access',
        label: 'Access',
        type: 'text',
        size: 6,
        formContent: 'select',
        require: true,
        options: accessData,
      },
      {
        name: 'topicName',
        label: 'Topic Name',
        type: 'text',
        size: 6,
        formContent: 'select',
        require: true,
        options: topicsData,
      },
      {
        name: 'subTopicName',
        label: 'SubTopic Name',
        type: 'text',
        size: 6,
        formContent: 'select',
        options: subTopicOptions,
      },
      {
        name: 'instructor',
        label: 'Instructor Name',
        type: 'text',
        size: 6,
        formContent: 'select',
        require: true,
        isSetValue: true,
        options: teachersData,
      },
      {
        name: 'thumbnailImage',
        label: 'Thumbnail Image',
        type: 'text',
        size: 12,
        formContent: 'image',
        require: true,
      },
      {
        name: 'videoLink',
        label: 'Video Link',
        type: 'text',
        size: 6,
        formContent: 'input',
      },
      {
        name: 'video',
        label: 'Video File',
        type: 'text',
        size: 6,
        formContent: 'video',
        require: true,
      },
      {
        name: 'learningObjectives',
        label: 'Learning Objectives',
        type: 'text',
        size: 12,
        formContent: 'react-quill',
        require: true,
      },
      {
        name: 'description',
        label: 'Description',
        type: 'text',
        size: 12,
        formContent: 'textarea',
        require: false,
      },
     
      // Add more fields as needed
    ],
  };

  return (
    <div>
      <CustomAntdModal  isOpenModal={ShowModel}
        title={isEditMode ? `Edit Snap Course` : `Add Snap Course `}
        setIsOpenModal={setShowModel}
        formik={formik}
   >
    
        <GlobalForm formData={formData} formik={formik} />
      </CustomAntdModal>
    </div>
  );
};

export default SnapCourseModal;
