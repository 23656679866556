import React from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import { FormikErrors, FormikProps } from "formik";

interface SubTopic {
  subTopicName: string;
}

interface FormValues {
  subTopics: SubTopic[];
}

interface SubtopicComponentProps {
  formik: FormikProps<FormValues>;
}

const SubtopicComponent: React.FC<SubtopicComponentProps> = ({ formik }) => {
  const { values, setFieldValue, errors, touched } = formik;

  const handleAddSubTopic = () => {
    setFieldValue("subTopics", [...values.subTopics, { subTopicName: "" }]);
  };

  const handleRemoveSubTopic = (index: number) => {
    const updatedSubTopics = [...values.subTopics];
    updatedSubTopics.splice(index, 1);
    setFieldValue("subTopics", updatedSubTopics);
  };

  const handleSubTopicChange = (index: number, key: keyof SubTopic, value: string) => {
    const updateSubTopics = [...values.subTopics];
    updateSubTopics[index][key] = value;
    setFieldValue("subTopics", updateSubTopics);
  };

  return (
    <div>
      <div>
        {values?.subTopics.map((subTopic, index) => (
          <div
            key={index}
            className={`overflow-hidden bg-white shadow-md sm:rounded-lg text-semibold p-2 mb-4`}
          >
            <div className="mx-auto p-2 pt-0">
              <div className="flex justify-between my-1 mb-2">
                <label
                  className="text-md font-bold text-green-500 mt-2"
                  htmlFor={`subTopicName${index}`}
                >
                  Subtopic {index + 1}
                </label>
                {index > 0 && (
                  <CustomButton
                    onClick={() => handleRemoveSubTopic(index)}
                    text="Remove"
                  />
                )}
              </div>
              <div>
                <label
                  htmlFor={`subTopicName${index}`}
                  className="text-md font-bold text-gray-900 mt-2"
                >
                  Subtopic Name:
                </label>
                <input
                  type="text"
                  id={`subTopicName${index}`}
                  name={`subTopicName${index}`}
                  className="w-full custom-input-class"
                  value={subTopic.subTopicName}
                  onChange={(e) =>
                    handleSubTopicChange(index, "subTopicName", e.target.value)
                  }
                />
              </div>
              {errors?.subTopics && errors?.subTopics[index] && (errors?.subTopics[index] as FormikErrors<SubTopic>).subTopicName &&
                touched.subTopics && touched.subTopics[index]?.subTopicName && (
                  <span className="text-red-500">
                    {(errors?.subTopics[index] as FormikErrors<SubTopic>).subTopicName}
                  </span>
                )}
            </div>
          </div>
        ))}
        <CustomButton
          onClick={handleAddSubTopic}
          text={`Add ${values.subTopics.length > 0 ? "More " : ""}`}
        />
      </div>
    </div>
  );
};

export default SubtopicComponent;
