import React, { useState, useEffect, useContext } from 'react';
import { backendUrl } from "../../constants/config";
import { notification } from 'antd';
import { UserContext } from "../../state-management/user-context";
import { FlashcardsAPI } from "../../(apis)/(shared-apis)/flashcard-apis";

export default function EditQuestion() { // Change component name to start with uppercase

  const {user} = useContext(UserContext);
  const [questionDetails, setquestionDetails] = useState<any>({});
  const [id, setId] = useState('');
  const [reportHandle, setreportHandle] = useState(false);
  const [flashCardID, setflashCardID] = useState('');

  useEffect(() => {

    const fetchData = async () => {
      const url = window.location.href;
      let id_temp = url.split("/").pop();

      if (id_temp === 'true') {
        setreportHandle(true);
        setflashCardID(url.split("/").slice(-2)[0]);
        id_temp = url.split("/").slice(-3)[0];
      }
      else {
        id_temp = url.split("/").slice(-3)[0];
      }

      setId(id_temp);

      const response = await FlashcardsAPI('GetQuestionById', id_temp);
      if (response) {
        const data = response;
        console.log(data.question);
        setquestionDetails(data.question);
      }
      else {
        notification.error({
          message: 'Error',
          description: 'Failed to fetch data'
        });
      }
    }

    fetchData();
  }, []);

  const SubmitFlashcard = async () => {
    const response = await FlashcardsAPI('EditFlashcard', "", { questionId: id, newFlashcard: questionDetails });

    if (response) {
      notification.success({
        message: 'Success',
        description: 'Flashcard updated successfully'
      });
      window.location.reload();
    }
    else {
      notification.error({
        message: 'Error',
        description: 'Failed to update flashcard'
      });
    }
  }

  const handleReport = async (status) => {
    const response = await FlashcardsAPI('ChangeStatusReport', "", { reportId: flashCardID, status: status, fixedBy: user.username });

    if (response) {
      notification.success({
        message: 'Success',
        description: 'Report handled successfully'
      });
      window.location.href = '/flashcardReports';
    }
    else {
      notification.error({
        message: 'Error',
        description: 'Failed to handle report'
      });
    }
  }

  return (
    <>
      <div className='w-full min-h-10 flex justify-center text-center bg-red-500 text-white pb-2 pt-2'>
        <h1 className='text-3xl font-[400]'>Edit Flashcard Screen</h1>
      </div>
      <div className="w-full lg:w-8/12 px-4 mx-auto mt-6">
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                Edit Flashcard
              </h6>
              <button
                className="bg-rose-500 text-white hover:bg-rose-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={SubmitFlashcard}
              >
                Submit
              </button>
            </div>
          </div>
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <form>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Question Details
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="username"
                    >
                      Question Text
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-5 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="username"
                      value={questionDetails?.question}
                      onChange={(e) => setquestionDetails({ ...questionDetails, question: e.target.value })}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Answer
                    </label>
                    <input
                      type="email"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="email"
                      value={questionDetails?.answer}
                      onChange={(e) => setquestionDetails({ ...questionDetails, answer: e.target.value })}
                    />
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="first-name"
                    >
                      Reference
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="first-name"
                      value={questionDetails?.reference}
                      onChange={(e) => setquestionDetails({ ...questionDetails, reference: e.target.value })}
                    />
                  </div>
                </div>
                <div className="w-full px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="last-name"
                    >
                      Tags
                    </label>
                    <input
                      type="text"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="last-name"
                      value={questionDetails?.tags}
                      onChange={(e) => setquestionDetails({ ...questionDetails, tags: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
                Image
              </h6>
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="address"
                    >
                      Upload Image
                    </label>
                    <input
                      type="file"
                      accept='/.jpg, .jpeg, .png'
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="address"
                      value={questionDetails?.image}
                      onChange={(e) => setquestionDetails({ ...questionDetails, image: e.target.value })}
                    />
                  </div>
                </div>
              </div>

              <hr className="mt-6 border-b-1 border-blueGray-300" />
              <div className="flex flex-wrap">
                <div className="w-full lg:w-12/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="about-me"
                    >
                      Description
                    </label>
                    <textarea
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      id="about-me"
                      rows={4}
                      defaultValue={questionDetails?.description}
                      onChange={(e) => setquestionDetails({ ...questionDetails, description: e.target.value })}
                    ></textarea>
                  </div>
                </div>
              </div>
            </form>
          </div>
          {reportHandle && (
            <div className='flex justify-end pb-4 mr-4'>
              <button
                className="bg-rose-500 text-white hover:bg-rose-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleReport('Denied');
                }}
              >
                Deny Report
              </button>
              <button
                className="bg-rose-500 text-white hover:bg-rose-600 font-bold uppercase text-xs ml-4 px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                type="button"
                onClick={() => {
                  handleReport('Resolved');
                }}
              >
                Resolve Report
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  )
}
