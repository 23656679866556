import React from "react";
import CustomFormField from "shared-components/form-fields/custom-form-field";
import CustomAntdModal from "shared-components/modal/CustomAntdModal";

const AssignmentModal = ({
  isAssignmentModel,
  isEditMode,
  formik,
  setIsAssignmentModel,
}) => {
  return (
    <div>
      <CustomAntdModal
        isOpenModal={isAssignmentModel}
        setIsOpenModal={setIsAssignmentModel}
        formik={formik}
      >
        <div className="flex flex-col  ">
          <div className="flex flex-col md:flex-row">
            <div className="md:p-2 w-full md:w-1/2  ">
              <CustomFormField
                name="name"
                type="text"
                label="Assignment Name"
                formik={formik}
              />
            </div>

            <div className="md:p-2 w-full md:w-1/2  ">
              <CustomFormField
                name="dueDate"
                type="date"
                format="YYYY-MM-DD hh:mm A "
                label="Select due Date"
                formik={formik}
                showTime={true}
              />
            </div>
          </div>
          <div className="md:p-2 w-full   ">
            <CustomFormField
              name="desc"
              type="textarea"
              label="Course Description"
              formik={formik}
            />
          </div>
          <div className="w-full ">
            <CustomFormField
              name="pdfFile"
              type="pdf"
              label="Upload PDF Files"
              formik={formik}
            />
          </div>
        </div>
      </CustomAntdModal>
    </div>
  );
};

export default AssignmentModal;
