import { ApiInterface } from "../api-interface/types";

export const disciplineApis: ApiInterface[] = [
  {
    url: "/api/discipline",
    method: "POST",
    name: "addDiscipline",
  },
  {
    url: "/api/discipline",
    method: "GET",
    name: "getAllDisciplines",
  },
  {
    url: "/api/discipline/:id",
    method: "GET",
    name: "getDisciplineById",
  },
  {
    url: "/api/discipline/:id",
    method: "PUT",
    name: "updateDisciplineById",
  },
  {
    url: "/api/discipline/publish/:id",
    method: "PUT",
    name: "updateDisciplinePublishedStatusById",
  },
];
