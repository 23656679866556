

import { apiRequest } from '(apis)/api-interface/api-handler'
import { WhatsApp } from '@material-ui/icons'
import { notification } from 'antd'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
const Students = ({ courseData, setCoursedata, user, getStudents, activatedSubscrptions, deactivatedSubscrptions, expiredSubscrptions, access, loading }) => {
    const { id } = useParams()

    const [totalSubscriptions, setTotalSubscriptions] = React.useState(0)
    const [totalSubscriptionsToday, setTotalSubscriptionsToday] = React.useState(0)
    // const [expiredSubscriptions, setExpiredSubscriptions] = React.useState(0)
    const [totalAmountActive, setTotalAmountActive] = React.useState(0)
    const [totalAmountDeactivated, setTotalAmountDeactivated] = React.useState(0)

    useEffect(() => {
        setTotalSubscriptions(activatedSubscrptions.length + deactivatedSubscrptions.length + expiredSubscrptions.length)
        let TotalAmountActive = 0;
        activatedSubscrptions.forEach(user => {
            user.OrderDetails.forEach(order => {
                TotalAmountActive += parseInt(order.PaidAmount)
            })
        })
        setTotalAmountActive(TotalAmountActive)

        let TotalAmountDeactivated = 0;
        deactivatedSubscrptions.forEach(user => {
            user.OrderDetails.forEach(order => {
                TotalAmountDeactivated += parseInt(order.PaidAmount)
            })
        })
        setTotalAmountDeactivated(TotalAmountDeactivated)

        const today = new Date()
        const formattedDate = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
        const todaySubscriptions = activatedSubscrptions.filter(subscription => {
            const date = new Date(subscription.SubscriptionStartDate)
            const formattedSubscriptionDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
            return formattedSubscriptionDate === formattedDate
        })
        setTotalSubscriptionsToday(todaySubscriptions.length)
        // const expiredSubscriptions = expiredSubscrptions.filter(subscription => {
        //     const date = new Date(subscription.SubscriptionEndDate)
        //     return date < today
        // }
        // )
        // setExpiredSubscriptions(expiredSubscriptions.length)
    }, [activatedSubscrptions, deactivatedSubscrptions, expiredSubscrptions])


    const HandleActivation = async (studentid, Status) => {
        try {
            if (!user) {
                notification.error({
                    message: 'Error',
                    description: 'Please login to activate subscription'
                })
            }
            const username = user[0].username;
            const payload = {
                user: username,
                courseId: id,
                userId: studentid,
                status: Status
            }
            const Response = await apiRequest("handleSubscriptionActivation", payload)
            if (Response.data.success) {
                notification.success({
                    message: 'Success',
                    description: Response.data.message
                })
                getStudents()
            }
        }
        catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occured while activating subscription'
            })
        }
    }

    const AddToWhatsapp = async (studentid) => {
        try {
            if (!user) {
                notification.error({
                    message: 'Error',
                    description: 'Please login to add to whatsapp'
                })
            }
            const Response = await apiRequest("markAsWhatsappAdded", { userId: studentid }, [id])
            console.log(Response)
            if (Response.data.success) {
                notification.success({
                    message: 'Success',
                    description: Response.data.message
                })
                if (Response.data.message === "Whatsapp added successfully") {
                    setCoursedata(prevState => {
                        return {
                            ...prevState,
                            whatsappAdded: [...prevState.whatsappAdded, studentid]
                        }
                    })
                }
                else {
                    setCoursedata(prevState => {
                        return {
                            ...prevState,
                            whatsappAdded: prevState.whatsappAdded.filter(id => id !== studentid)
                        }
                    })
                }
            }
        }
        catch (error) {
            notification.error({
                message: 'Error',
                description: 'An error occured while adding to whatsapp'
            })
        }
    }



    const FormatDate = (date: Date | string): string => {
        //dd-mm-yyyy
        const d = new Date(date)
        let day: number | string = d.getDate()
        let month: number | string = d.getMonth() + 1
        const year = d.getFullYear()
        if (day < 10) {
            day = `0${day}`
        }
        if (month < 10) {
            month = `0${month}`
        }
        return `${day}-${month}-${year}`
    }

    const Duration = (startDate: Date | string, endDate: Date | string): string => {
        // Ensure the input parameters are converted to Date objects
        const start = new Date(startDate);
        const end = new Date(endDate);

        // Calculate the difference in milliseconds
        const diff = end.getTime() - start.getTime(); // Using getTime() to get milliseconds

        // Convert milliseconds to days
        const days = diff / (1000 * 60 * 60 * 24);

        // Return the number of days as a string
        return days.toFixed(0);
    };


    const [selectedState, setSelectedState] = React.useState("Active Subscriptions");

    const CalculateTotalSubscriptionAmount = (orderDetails) => {
        if (!orderDetails) {
            return 0;
        }

        let total = 0;
        orderDetails.forEach(order => {
            total += parseInt(order.PaidAmount)
        })
        return total;
    }

    const OpenOrderLinks = (orderDetails) => {
        if (!orderDetails) {
            return;
        }
        orderDetails.forEach(order => {
            window.open(order.PaymentProof, "_blank")
        })
    }

    const DownloadCsv = (currentFilter) => {
        const headers = [
            "Full Name",
            "Email",
            "Phone",
            "Subscription Amount",
            "Subscription Time",
            "Subscription Start Date",
            "Subscription End Date",
            "Coupon Code",
            "Plan",
            "Status"
        ];
       
        const students = currentFilter === "Active Subscriptions" ? activatedSubscrptions : 
                            currentFilter === "Deactivated Subscriptions" ? deactivatedSubscrptions : 
                            expiredSubscrptions;

        const csv = students.map(user => {
            return {
                "Full Name": user.UserID.fullname,
                "Email": user.UserID.username,
                "Phone": user.UserID.phonenumber,
                "Subscription Amount": CalculateTotalSubscriptionAmount(user.OrderDetails),
                "Subscription Time": Duration(user.SubscriptionStartDate, user.SubscriptionEndDate),
                "Subscription Start Date": FormatDate(user.SubscriptionStartDate),
                "Subscription End Date": FormatDate(user.SubscriptionEndDate),
                "Coupon Code": user.Referral,
                "Plan": `${user.PlanName} - ${user.SubscriptionAmount}`,
                "Status": user.SubscriptionStatus
            }
        });
    
        const csvString = [
            headers.join(','), // Add the headers as the first line
            ...csv.map(row => {
                return Object.values(row).join(',')
            })
        ].join('\n');
    
        const a = document.createElement('a');
        a.href = 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString);
        a.target = '_blank';
        a.download = currentFilter === "Active Subscriptions" ? 'Active Subscriptions.csv' : 
                     currentFilter === "Deactivated Subscriptions" ? 'Deactivated Subscriptions.csv' : 
                     'Expired Subscriptions.csv';
        a.click();
    }
    

    return (
        <>
            {loading ? <div className='fixed top-0 left-0 w-full h-full flex justify-center items-center'>
                <div className='bg-white p-4 rounded'>
                    <h1 className='text-lg font-bold'>Loading...</h1>
                </div>
            </div> :
                <div className='mt-8'>
                    <div className='flex flex-col'>
                        <h1 className='text-2xl font-bold'>Enrolled Students</h1>
                        <div className='mt-4 flex flex-row flex-wrap gap-4'>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Total Subscriptions</h1>
                                <h1 className='text-2xl font-bold'>{totalSubscriptions}</h1>
                            </div>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Total Subscriptions Today</h1>
                                <h1 className='text-2xl font-bold'>{totalSubscriptionsToday}</h1>
                            </div>
                            <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                <h1 className='text-lg font-bold'>Expired Subscriptions</h1>
                                <h1 className='text-2xl font-bold'>{expiredSubscrptions && expiredSubscrptions.length}</h1>
                            </div>
                            {access !== "teacher" &&
                                <>
                                    <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                        <h1 className='text-lg font-bold'>Total Amount Active</h1>
                                        <h1 className='text-2xl font-bold'>{totalAmountActive}</h1>
                                    </div>
                                    <div className='flex flex-col border-r-2 border-gray-300 pr-4'>
                                        <h1 className='text-lg font-bold'>Total Amount Deactivated</h1>
                                        <h1 className='text-2xl font-bold'>{totalAmountDeactivated}</h1>
                                    </div>
                                </>
                            }

                        </div>

                        <div className='mt-4 flex flex-row flex-wrap border-2 border-gray-300 rounded p-2'>
                            <button className={`px-4 py-2 rounded ${selectedState === "Active Subscriptions" ? 'bg-rose-500 text-white' : 'bg-white text-gray-500'}`} onClick={() => setSelectedState("Active Subscriptions")}>Active Subscriptions
                                ( {activatedSubscrptions.length})
                            </button>
                            <button className={`px-4 py-2 rounded ${selectedState === "Deactivated Subscriptions" ? 'bg-rose-500 text-white' : 'bg-white text-gray-500'}`} onClick={() => setSelectedState("Deactivated Subscriptions")}>Deactivated Subscriptions
                                ( {deactivatedSubscrptions.length})
                            </button>
                            <button className={`px-4 py-2 rounded ${selectedState === "Expired Subscriptions" ? 'bg-rose-500 text-white' : 'bg-white text-gray-500'}`} onClick={() => setSelectedState("Expired Subscriptions")}>
                                Expired Subscriptions
                                ( {expiredSubscrptions && expiredSubscrptions.length})
                            </button>
                            <button className='px-4 py-2 rounded bg-rose-500 text-white ml-8' onClick={() => DownloadCsv(selectedState)}>Download CSV</button>
                        </div>

                        {selectedState === "Active Subscriptions" ? <>
                            <h1 className='text-md font-bold mt-4 w-full bg-rose-500 p-2 rounded text-white shadow-md'>Active Subscriptions </h1>
                            <div className='mt-4 flex flex-col'>
                                {activatedSubscrptions && activatedSubscrptions.length === 0 && <h1 className='text-lg font-bold'>No Active Subscriptions</h1>}
                                <div className="overflow-x-auto">
                                    <table className="w-full mb-20 text-sm text-left text-gray-500">
                                        <thead className="text-xs text-gray-800 uppercase bg-gray-50">
                                            <tr>
                                                <th scope="col" className="px-4 py-4 text-left">
                                                    SR#
                                                </th>
                                                <th scope="col" className="px-4 py-4">
                                                    Full Name
                                                </th>
                                                <th scope="col" className="px-4 py-4">
                                                    Email
                                                </th>
                                                <th scope="col" className="px-4 py-2 text-center">
                                                    Phone
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Subscription Amount
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Subscription Time
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Subscription Start Date
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Subscription End Date
                                                </th>
                                                <th scope="col" className="px-4 py-4 text-center">
                                                    Coupon Code
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-center">
                                                    Plan
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-center">
                                                    Status
                                                </th>
                                                <th scope="col" className="px-4 py-3 text-center">
                                                    Actions
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {activatedSubscrptions && activatedSubscrptions?.map((student, index) => (
                                                <tr key={index} className='bg-gray-50 text-gray-700'>
                                                    <td className="px-4 py-4">{index + 1}</td>
                                                    <td className="px-4 py-4">{student?.UserID?.fullname}</td>
                                                    <td className="px-4 py-4">{student?.UserID?.username}</td>
                                                    <td className="px-4 py-4 text-center">{student?.UserID?.phonenumber}</td>
                                                    <td className="px-4 py-4 text-center">{CalculateTotalSubscriptionAmount(student?.OrderDetails)}</td>
                                                    <td className="px-4 py-4 text-center">{Duration(student?.SubscriptionStartDate, student?.SubscriptionEndDate)} Days</td>
                                                    <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionStartDate)}</td>
                                                    <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionEndDate)}</td>
                                                    <td className="px-4 py-4 text-center">{student?.Referral}</td>
                                                    <td className="px-4 py-4 text-center">{student?.PlanName} - {student?.SubscriptionAmount} </td>
                                                    <td className="px-4 py-4 text-center">{student?.SubscriptionStatus}</td>
                                                    <td className="px-4 py-4 text-center">
                                                        {access !== "teacher" &&
                                                            <div className='flex flex-row gap-2'>
                                                                <button className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-opacity-80 transition duration-300 ease-in-out cursor-pointer' onClick={() => HandleActivation(student.UserID._id, "Turned Off")}>Deactivate</button>
                                                                <button className={`
                                                        ${courseData?.whatsappAdded?.includes(student._id) ? "bg-green-500" : "bg-rose-500"}
                                                        text-white px-4 py-2 rounded hover:bg-opacity-80 transition duration-300 ease-in-out cursor-pointer
                                                        `} onClick={() => AddToWhatsapp(student._id)}>
                                                                    <WhatsApp />
                                                                </button>
                                                                <button className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-opacity-80 transition duration-300 ease-in-out cursor-pointer' onClick={() => OpenOrderLinks(student.OrderDetails)}>Proof</button>
                                                            </div>
                                                        }
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </>
                            : selectedState === "Deactivated Subscriptions" ?
                                <>
                                    <h1 className='text-md font-bold mt-4 w-full bg-rose-500 p-2 rounded text-white shadow-md'>Deactivated Subscriptions </h1>
                                    <div className='mt-4 flex flex-col'>
                                        {deactivatedSubscrptions && deactivatedSubscrptions.length === 0 && <h1 className='text-lg font-bold'>No Deactivated Subscriptions</h1>}
                                        <div className="overflow-x-auto">
                                            <table className="w-full text-sm text-left text-gray-500">
                                                <thead className="text-xs text-gray-800 uppercase bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-4 py-4 text-left">
                                                            SR#
                                                        </th>
                                                        <th scope="col" className="px-4 py-4">
                                                            Full Name
                                                        </th>
                                                        <th scope="col" className="px-4 py-4">
                                                            Email
                                                        </th>
                                                        <th scope="col" className="px-4 py-2 text-center">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Amount
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Time
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Start Date
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription End Date
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            SubscriptionStatus
                                                        </th>
                                                        <th scope="col" className="px-4 py-3 text-center">
                                                            Actions
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {deactivatedSubscrptions && deactivatedSubscrptions?.map((student, index) => (
                                                    <tbody>
                                                        <tr key={index} className='bg-gray-50 text-gray-700'>
                                                            <td className="px-4 py-4">{index + 1}</td>
                                                            <td className="px-4 py-4">{student?.UserID?.fullname}</td>
                                                            <td className="px-4 py-4">{student?.UserID?.username}</td>
                                                            <td className="px-4 py-4 text-center">{student?.UserID?.phonenumber}</td>
                                                            <td className="px-4 py-4 text-center">{CalculateTotalSubscriptionAmount(student?.OrderDetails)}</td>
                                                            <td className="px-4 py-4 text-center">{Duration(student?.SubscriptionStartDate, student?.SubscriptionEndDate)} Days</td>
                                                            <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionStartDate)}</td>
                                                            <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionEndDate)}</td>
                                                            <td className="px-4 py-4 text-center">{student?.SubscriptionStatus}</td>
                                                            <td className="px-4 py-4 text-center">
                                                                {access !== "teacher" &&
                                                                    <div className='flex gap-2'>
                                                                        <button className='bg-rose-500 text-white px-4 py-2 rounded' onClick={() => HandleActivation(student.UserID._id, "Active")}>Activate Subscription </button>
                                                                        <button className='bg-rose-500 text-white px-4 py-2 rounded hover:bg-opacity-80 transition duration-300 ease-in-out cursor-pointer' onClick={() => OpenOrderLinks(student.OrderDetails)}>Proof</button>
                                                                    </div>
                                                                }
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </>
                                : selectedState === "Expired Subscriptions" &&
                                <>
                                    <h1 className='text-md font-bold mt-4 w-full bg-rose-500 p-2 rounded text-white shadow-md'>Expired Subscriptions </h1>
                                    <div className='mt-4 flex flex-col'>
                                        {expiredSubscrptions && expiredSubscrptions.length === 0 && <h1 className='text-lg font-bold'>No Expired Subscriptions</h1>}
                                        <div className="overflow-x-auto">
                                            <table className="w-full text-sm text-left text-gray-500">
                                                <thead className="text-xs text-gray-800 uppercase bg-gray-50">
                                                    <tr>
                                                        <th scope="col" className="px-4 py-4 text-left">
                                                            SR#
                                                        </th>
                                                        <th scope="col" className="px-4 py-4">
                                                            Full Name
                                                        </th>
                                                        <th scope="col" className="px-4 py-4">
                                                            Email
                                                        </th>
                                                        <th scope="col" className="px-4 py-2 text-center">
                                                            Phone
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Amount
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Time
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription Start Date
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            Subscription End Date
                                                        </th>
                                                        <th scope="col" className="px-4 py-4 text-center">
                                                            SubscriptionStatus
                                                        </th>
                                                    </tr>
                                                </thead>
                                                {expiredSubscrptions && expiredSubscrptions?.map((student, index) => (
                                                    <tbody>
                                                        <tr key={index} className='bg-gray-50 text-gray-700'>
                                                            <td className="px-4 py-4">{index + 1}</td>
                                                            <td className="px-4 py-4">{student?.UserID?.fullname}</td>
                                                            <td className="px-4 py-4">{student?.UserID?.username}</td>
                                                            <td className="px-4 py-4 text-center">{student?.UserID?.phonenumber}</td>
                                                            <td className="px-4 py-4 text-center">{CalculateTotalSubscriptionAmount(student?.OrderDetails)}</td>
                                                            <td className="px-4 py-4 text-center">{Duration(student?.SubscriptionStartDate, student?.SubscriptionEndDate)} Days</td>
                                                            <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionStartDate)}</td>
                                                            <td className="px-4 py-4 text-center">{FormatDate(student?.SubscriptionEndDate)}</td>
                                                            <td className="px-4 py-4 text-center">Expired</td>
                                                        </tr>
                                                    </tbody>
                                                ))}
                                            </table>
                                        </div>
                                    </div>
                                </>
                        }







                    </div>

                </div>
            }

        </>
    )
}

export default Students