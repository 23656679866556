import { MoreOutlined } from "@ant-design/icons";
import { Dropdown, Image, message, Table } from "antd";
import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon } from "shared-components/ui/Icons";
import AddMember from "./AddMember";
import { UpdateMember } from "./UpdateMember";
import { apiRequest } from "(apis)/api-interface/api-handler";
const Team = () => {
  const [data, setData] = useState([]);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [isOpenImageTableModal, setIsOpenImageTableModal] = useState(false);
  const [addPopup, setAddPopup] = useState(false);
  const [imageTableUrl, setImageTableUrl] = useState(null);
  const [editData, setEditData] = useState(null);
  const [departmentData, setDepartmentData] = useState([]);
  const [departmentDataForFilteration, setDepartmentDataForFilteration] =
    useState([]);
  const navigate = useNavigate();
  const handleEdit = (data) => {
    setEditData(data);
    setIsEditMode(true);
  };

  const fetchTeamMembers = async () => {
    try {
      setIsLoading(true);
      const res = await apiRequest("getAllTeamMembers");
      if (res?.data?.teamMembers?.length > 0) {
        const updatedRow = res?.data?.teamMembers?.map((item) => {
          return {
            ...item,
            key: item._id,
            id: item._id,
            publish: {
              publish: item.publish,
              id: item._id,
            },
            aboutMe:
              item.aboutMe?.length > 50
                ? `${item.aboutMe.substring(0, 50)}...`
                : item.aboutMe,
            expandData: item.aboutMe,
            // department: item.department?.name,
          };
        });
        console.log("updatedRow", updatedRow);
        setData(updatedRow);

      } else {

        setData([]);
      }
    } catch (error) {
     message.error(error?.response?.data?.message || error?.message);
    }finally{
      setIsLoading(false);
    }
  };
  const fetchDepartments = async () => {
    try {
      const res = await apiRequest("getAllDepartments");
      if (res.status === 200) {
        if (res.data.length > 0) {
          const updatedRow = res?.data?.map((item) => {
            return {
              name: item.name,
              id: item._id,
              label: item.name,
              value: item._id,
            };
          });
          const filterationData = res?.data?.map((item) => {
            return {
              text: item.name,
              value: item.name,
            };
          });
          setDepartmentDataForFilteration(filterationData);
          setDepartmentData(updatedRow);
        }else{

          setDepartmentData([]);
        }

      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);  
    }
  };
  const handlePublish = async (data) => {
    try {
      const res = await apiRequest("updateTeamPublish",null,[data?.id]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        fetchTeamMembers();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([fetchTeamMembers(), fetchDepartments()]);
    };
    fetchData();
  }, []);
  // useEffect(() => {
  
  //   setDepartmentData(departmentData);
  // }, [departmentData, isLoading]);
  const handleTableImageClick = (url) => {
    setImageTableUrl(url);
    setIsOpenImageTableModal(true);
  };

  const columns = [
    {
      title: "Image",
      dataIndex: "imageURL",
      key: "imageURL",
      flex: 1,
      render: (url) => (
        <Image
          src={url}
          width={50}
          height={50}
          alt="Profile"
          className="rounded-full border border-red-400 cursor-pointer"
        />
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      flex: 1,
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
      flex: 1,
    },
    {
      title: "Department",
      dataIndex: "department",
      key: "department",
      flex: 1,
      render: (data) => {
        return (
          <>
            <div>{data?.name}</div>
          </>
        );
      },
      filters: departmentDataForFilteration,
      onFilter: (value, record) =>
        record?.department?.name.indexOf(value) === 0,
    },

    Table.EXPAND_COLUMN,
    {
      title: "About Me",
      dataIndex: "aboutMe",
      key: "aboutMe",
      flex: 1,
    },
    Table.SELECTION_COLUMN,

    {
      title: "Publish",
      dataIndex: "publish",
      key: "publish",
      align: "center",
      flex: 1,
      render: (data) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.publish}
            onChange={() => handlePublish(data)}
          />
        </div>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      align: "center",
      width: 100,
      render: (data) => {
        const items = [
          {
            key: "1",
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.socials?.linkedInURL}
              >
                LinkedIn
              </a>
            ),
          },
          {
            key: "2",
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.socials?.instagramURL}
              >
                Instagram
              </a>
            ),
          },
          {
            key: "3",
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.socials?.facebookURL}
              >
                Facebook
              </a>
            ),
          },
          {
            key: "4",
            label: (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={data?.socials?.twitterURL}
              >
                Twitter
              </a>
            ),
          },
        ];
        return (
          <>
            <div className="flex gap-4 justify-center">
              <div>
                  
                  <div onClick={() => handleEdit(data)}>
                <EditIcon size="w-6 h-6"
                />

                </div>
              </div>
              {/* <a href={data?.imageURL} target="_blank">
            <VisibilityIcon />
          </a> */}
              <Dropdown
                menu={{
                  items: items,
                }}
                placement="bottomLeft"
                arrow={{
                  pointAtCenter: true,
                }}
              >
                <MoreOutlined className="cursor-pointer text-2xl" />
              </Dropdown>
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <div className="mx-8">
        <h1 className="text-2xl font-bold">Manage Team Members</h1>
      
          <Loader  loading={isLoading} />
     
          <>
            <div className="flex justify-end items-end gap-4">
              <CustomButton
                text="Add New Team Member"
                onClick={() => {
                  // setOpen(true)
                  setAddPopup(true);
                  if (departmentData.length === 0) {
                message.error("Please add department first");
                  }
                }}
              />
              <CustomButton
                text="Manage Department"
                onClick={() => {
                  // setOpen(true)
                  navigate("/manage/department");
                  // setAddPopup(true)
                }}
              />
            </div>
            <div>
              <GlobalTable
                columns={columns}
                data={data}
                loading={isLoading}
                isExpandable={true}  />
            </div>
          </>
     
        {departmentData.length > 0 ? (
          <AddMember
            fetchTeamMembers={fetchTeamMembers}
            opened={addPopup}
            setOpened={setAddPopup}
            editData={editData}
            isEditMode={isEditMode}
            departmentData={departmentData}
          />
        ) : null}

        {departmentData.length > 0 ? (
          <>
            <UpdateMember
              fetchTeamMembers={fetchTeamMembers}
              opened={isEditMode}
              setOpened={setIsEditMode}
              data={editData}
              departmentData={departmentData}
              setData={setEditData}
            />
          </>
        ) : (
          <></>
        )}

    
        {/* table image view */}
      </div>
    </div>
  );
};

export default Team;
