import { CategoryInterface } from "../interfaces";
import { CategoryAPI } from "../../../../(apis)/(shared-apis)/academics/category-apis";
import { Switcher } from "../../../../shared-components/ui/CustomForm";
import toast from "react-hot-toast";
import { Image } from "antd";
import { EditIcon, ManageIcon } from "shared-components/ui/Icons";

interface TableViewProps {
  categories: CategoryInterface[];
  setCategories: (categories: CategoryInterface[]) => void;
  setOriginalCategories: (categories: CategoryInterface[]) => void;
  setIsEditMode: (isEditMode: boolean) => void;
  setShowModel: (showModel: boolean) => void;
    setClickRowData: (clickRowData: any) => void;
}

const TableView = ({
  categories,
  setCategories,
  setOriginalCategories,
  setIsEditMode,
  setShowModel,
  setClickRowData,
}: TableViewProps) => {
  const HandlePublish = async (id: string) => {
    const newCategories = categories.map((category: CategoryInterface) => {
      if (category._id === id) {
        category.isPublished = !category.isPublished;
      }
      return category;
    });
    setCategories(newCategories);

    try {
      const response = await CategoryAPI("updateCategoryPublish", "", { _id: id });
      if (response.data.success) {
        toast.success("Category Updated Successfully");
        const newCategories = await CategoryAPI("getCategories");
        setCategories(newCategories.data.data);
        setOriginalCategories(newCategories.data.data);
      } else {
        toast.error("Category Published Failed");
      }
    } catch (error) {
      console.log(error);
      toast.error("Category Published Failed");
    }
  };

  return (
    <div className="overflow-x-auto">
      <table className="w-full mb-20 text-sm text-left text-gray-500">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr>
            <th scope="col" className="px-4 py-4">
              Logo
            </th>
            <th scope="col" className="px-4 py-4">
              Category Name
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              Status
            </th>
            <th scope="col" className="px-4 py-3">
              Navigate
            </th>
            <th scope="col" className="px-4 py-3">
              Action
            </th>
          </tr>
        </thead>
        <tbody>
          {categories.map((cat: CategoryInterface) => (
            <tr className="border-b" key={cat._id}>
              <td className="px-4 py-3">
                <Image
                  width={50}
                  height={50}
                  className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                  src={cat.logoUrl}
                />
              </td>
              <td className="px-4 py-3 uppercase text-bold">
                {cat?.categoryName}
              </td>
              <td className="px-4 py-3">
                <div className="mx-auto w-fit">
                  <Switcher
                    isActive={undefined}
                    for={cat?._id}
                    onChange={() => {
                      HandlePublish(cat?._id);
                    }}
                    togglevalue={cat?.isPublished}
                    size="small"
                  />
                </div>
              </td>
              <td
                className="px-6 py-5 text-center w-[10%]"
                onClick={() => {
                  window.open(`/deckgroups/${cat.categoryName}`, "_blank");
                }}
              >
                <ManageIcon size="w-6 h-6" />
              </td>
              <td
                className="px-6 py-5 text-center w-[10%]"
                onClick={() => {
                  setIsEditMode(true);
                  setShowModel(true);
                  setClickRowData(cat);
                }}
              >
                <EditIcon size="w-6 h-6" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableView;
