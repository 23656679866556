import { ApiInterface } from "../../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../../constants/config";

export const CourseSubscriptionAPIs: ApiInterface[] = [
    {
        url: "/api/subscriptions/statistics",
        method: "POST",
        name: "getCoursesStatistics",
    },

]

export function CourseSubscriptionAPI(name: string, params: string[] = [""], data: any = null) {
    const api = CourseSubscriptionAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

