import { useState } from "react";
import CustomDropDown from "shared-components/ui/CustomDropDown";

const MetaCategoryDropDown = ({ value, getValue, noLabel }) => {
  const [data, setData] = useState([
    "MEDSCHOOL",
    "PRE-ENGINEERING",
    "PRE-MEDICAL",
    "BUSINESS-MANAGMENT",
    "INTERMEDIATE",
    "CAMBRIDGE",
    "ALLIED HEALTH SCIENCES",
  ]);
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="my-2">
      <CustomDropDown
        disabled={isLoading}
        label="Category"
        value={value}
        options={data}
        all={false}
        noLabel={noLabel}
        onSelect={(selectedValue) => {
          getValue("category", selectedValue);
        }}
      />
    </div>
  );
};
export default MetaCategoryDropDown;
