import { useEffect, useState } from "react";
import {
  ExternalLinkIcon,
  SearchIcon,
} from "../../../shared-components/ui/Icons";
import { ButtonOutlined } from "../../../shared-components/ui/CustomButtons";
import CustomDropDown from "../../../shared-components/ui/CustomDropDown";
import { CommissionAPI } from "../../../(apis)/(shared-apis)/latest-commission-api";
import { formatNumberWithCommas, renderChips } from "../utils";
// import { Input, Label } from "../../../shared-components/ui/CustomForm";
// import { Image, Upload } from "antd";
// import { PlusOutlined } from "@ant-design/icons";
// import { UserContext } from "../../../state-management/user-context";
// import toast, { Toaster } from "react-hot-toast";
import { ReimbursementModal } from "./components/reimbursement-modal";

const ReimbursementRequests = () => {
  const [reimbursements, setReimbursements] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("All");
  const [selectRole, setSelectRole] = useState("All");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  useEffect(() => {
    fetchReimbursements();
  }, [refreshData]);

  async function fetchReimbursements() {
    setIsLoading(true);
    try {
      const response = await CommissionAPI("getAllReimbursements");
      // getAllReimbursementsApi();
      console.log("Response: ", response);
      if (response?.data?.isSuccess) {
        const data = response.data.data;
        console.log("Data: ", data);
        setReimbursements(data);
      }
    } catch (error) {
      console.error("Error fetching reimbursements:", error);
    } finally {
      setIsLoading(false);
    }
  }

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  const filteredReimbursements = reimbursements.filter(
    (reimbursement) =>
      (selectStatus === "All" || reimbursement.status === selectStatus) &&
      (selectRole === "All" || selectRole === reimbursement?.role) &&
      (reimbursement?.email
        ?.toLowerCase()
        ?.includes(searchQuery?.toLowerCase()) ||
        reimbursement?.name.includes(searchQuery))
  );

  const handleActionButton = (reimbursement) => {
    setModalData(reimbursement);
    setIsModalOpen(true);
  };

  return (
    <>
      {/* =========================== */}
      {/* =========================== */}
      {/*  ReimbursementForm Modal*/}
      <ReimbursementModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        data={modalData}
      />
      {/* =========================== */}
      {/* =========================== */}

      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-xl font-bold text-gray-500 mb-5">
            Reimbursement Requests
          </h2>
          <ButtonOutlined
            handleClick={() => setRefreshData((prev) => prev + 1)}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className="bg-white shadow-md sm:rounded-lg">
                <div className="flex items-end p-4 space-x-3">
                  <CustomDropDown
                    label="Status"
                    options={["All", "Pending", "Approved", "Rejected"]}
                    onSelect={(selectedStatus) =>
                      handleSelectStatusChange(selectedStatus.toString())
                    }
                    width="min-w-[12rem]"
                    value={selectStatus}
                  />
                  <CustomDropDown
                    label="Role"
                    options={[
                      "All",
                      "ASSOCIATE",
                      "HEAD",
                      "MANAGER",
                      "PROVINCIAL_DIRECTOR",
                      "NATIONAL_DIRECTOR",
                    ]}
                    onSelect={(selectedStatus) => setSelectRole(selectedStatus)}
                    width="min-w-[12rem]"
                    value={selectRole}
                  />
                  <div className="relative w-full">
                    <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                      <SearchIcon color="text-gray-400" />
                    </div>
                    <input
                      type="text"
                      id="simple-search"
                      className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
                      placeholder="Search by Order ID or Email"
                      value={searchQuery}
                      onChange={handleSearchQueryChange}
                    />
                  </div>
                </div>
                <table className="w-full mb-20 text-sm text-left text-gray-500">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                    <tr>
                      <th scope="col" className="px-4 py-4">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-4">
                        User Email
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Status
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Role
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Date Requested
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Amount Requested
                      </th>
                      <th scope="col" className="px-4 py-4">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredReimbursements &&
                      filteredReimbursements?.length > 0 &&
                      filteredReimbursements?.map((reimbursement) => (
                        <tr className="border-b" key={reimbursement?._id}>
                          <td className="px-4 py-3">{reimbursement?.name}</td>
                          <td className="px-4 py-3">{reimbursement?.email}</td>
                          <td className="px-4 py-3">{reimbursement?.status}</td>
                          <td className="px-4 py-3">
                            {renderChips(reimbursement.role)}
                          </td>
                          <td className="px-4 py-3">
                            {new Date(
                              reimbursement?.dateRequested
                            )?.toLocaleDateString()}
                          </td>
                          <td className="px-4 py-3">
                            {formatNumberWithCommas(
                              reimbursement?.requestedAmount
                            )}
                          </td>
                          <td className="px-4 py-3">
                            <button
                              className=""
                              onClick={() => {
                                handleActionButton(reimbursement);
                              }}
                            >
                              <ExternalLinkIcon />
                            </button>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

// export const ReimbursementModal = (props) => {
//   const minimumAmountUserCanRequest = 5000;
//   const [user, setUser] = useContext(UserContext);
//   const [requestedAmount, setRequestedAmount] = useState(0);
//   const [pendingAmount, setPendingAmount] = useState(0);
//   const [requestType, setRequestType] = useState("Reject Request");
//   const [isLoading, setIsLoading] = useState(false);
//   const [data, setData] = useState(null);
//   const resetForm = () => {
//     setRequestedAmount(0);
//     setPendingAmount(0);
//     setRequestType("Reject Request");
//     setIsLoading(false);
//     setData(null);
//   };

//   useEffect(() => {
//     resetForm();
//   }, []);

//   useEffect(() => {
//     if (props?.data) {
//       console.log("Modal Data: ", props?.data);
//       const pendingAmount = props?.data?.requestedAmount;
//       setPendingAmount(pendingAmount);
//       setRequestedAmount(pendingAmount);
//       setData(props?.data);
//     }
//   }, [props?.data]);

//   const handleInputChange = (name, value) => {
//     setRequestedAmount(value);
//   };

//   const closeModal = () => {
//     setRequestedAmount(0);
//     setPendingAmount(0);
//     props.setIsModalOpen(false);
//   };

//   const submit = async (e) => {
//     e.preventDefault();
//     console.log("Inside On submit: ", requestedAmount);
//     if (!requestedAmount || requestedAmount <= 0) {
//       toast.error("Invalid Amount");
//       return;
//     }

//     setIsLoading(true);

//     // =======================
//     // REQUEST REJECTED
//     // =======================
//     if (requestType === "Reject Request") {
//       try {
//         const body = {
//           id: data?._id,
//           status: REQUEST_STATUS.Rejected,
//           approvedBy: user?.username,
//         };

//         console.log("data: ", body);
//         const response = await updateReimbursementStatus(body);

//         console.log(
//           "updateReimbursementStatus Response: ",
//           response,
//           response?.data?.isSuccess
//         );
//         if (response?.data?.isSuccess) {
//           toast.success("Rejected Successfully.");

//           resetForm();
//           setTimeout(() => {
//             props?.setIsModalOpen(false);
//           }, 2000);
//         }
//       } catch (error) {
//         console.error("Error updateReimbursementStatus:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }

//     // =======================
//     // REQUEST APPROVED
//     // =======================
//     else if (requestType === "Approve Request") {
//       if (!file) {
//         toast.error("Please upload reciept screenshot");
//         return;
//       }
//       try {
//         const formData = new FormData();
//         formData.append("id", data?._id);
//         formData.append("status", REQUEST_STATUS.Approved);
//         formData.append("approvedBy", user?.username);

//         if (file) {
//           formData.append("screenShot", file);
//         }

//         console.log("formData: ", formData);
//         const response = await approveReimbursement(formData);

//         console.log(
//           "approveReimbursement Response: ",
//           response,
//           response?.data?.isSuccess
//         );
//         if (response?.data?.isSuccess) {
//           toast.success("Approved Successfully.");

//           resetForm();
//           setTimeout(() => {
//             props?.setIsModalOpen(false);
//           }, 2000);
//         }
//       } catch (error) {
//         console.error("Error updateReimbursementStatus:", error);
//       } finally {
//         setIsLoading(false);
//       }
//     }
//   };

//   // ========================
//   // ===Screen Shot Logic ===
//   // ========================

//   const getBase64 = (file) =>
//     new Promise((resolve, reject) => {
//       const reader = new FileReader();
//       reader.readAsDataURL(file);
//       reader.onload = () => resolve(reader.result);
//       reader.onerror = (error) => reject(error);
//     });

//   const [previewOpen, setPreviewOpen] = useState(false);
//   const [previewImage, setPreviewImage] = useState("");
//   const [file, setFile] = useState(null);
//   const [screenshot, setScreenshot] = useState("");
//   const [generatedPictureUrl, setGeneratedPictureUrl] = useState(null);

//   const handlePreview = async (file) => {
//     if (!file.url && !file.preview) {
//       file.preview = await getBase64(file.originFileObj);
//     }
//     setPreviewImage(file.url || file.preview);
//     setPreviewOpen(true);
//   };

//   const handleChangeImage = async (info) => {
//     if (info?.file?.status === "removed") {
//       setScreenshot("");
//       setFile("");
//       setGeneratedPictureUrl("");
//     } else {
//       const base64 = await getBase64(info?.file?.originFileObj);
//       setScreenshot(base64);
//       setFile(base64);
//     }
//   };

//   const uploadButtonStudentPicture = (
//     <div>
//       <PlusOutlined />
//       <div style={{ marginTop: 8 }}>Upload Screenshot</div>
//     </div>
//   );

//   // ========================
//   // ===Screen Shot Logic ===
//   // ========================

//   return (
//     <div
//       id="deleteModal"
//       tabIndex={-1}
//       aria-hidden="true"
//       className={`${
//         props.isModalOpen ? "" : "hidden"
//       } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40 backdrop-blur-sm`}
//     >
//       <Toaster />

//       <div className="relative p-4 w-full max-w-md max-h-full">
//         {/* Modal content */}
//         <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
//           <button
//             type="button"
//             className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
//             data-modal-toggle="deleteModal"
//             onClick={() => closeModal()}
//           >
//             <svg
//               aria-hidden="true"
//               className="w-5 h-5"
//               fill="currentColor"
//               viewBox="0 0 20 20"
//               xmlns="http://www.w3.org/2000/svg"
//             >
//               <path
//                 fillRule="evenodd"
//                 d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
//                 clipRule="evenodd"
//               />
//             </svg>
//             <span className="sr-only">Close modal</span>
//           </button>

//           <p className="mb-4 text-gray-500 text-xl ddark:text-gray-300">
//             Reimbursement Form
//           </p>

//           <form
//             onSubmit={(e) => submit(e)}
//             className="text-left my-5 space-y-5"
//           >
//             <CustomDropDown
//               label="Select Request Type"
//               options={[`Reject Request`, "Approve Request"]}
//               onSelect={(type) => {
//                 setRequestType(type);
//                 if (type === "Reject Request") {
//                   setRequestedAmount(pendingAmount);
//                 }
//               }}
//               width="w-full"
//               value={requestType}
//               isError={false}
//               errorMessage={""}
//             />

//             {requestType == "Approve Request" ? (
//               <>
//                 <div className="space-y-2">
//                   <Label>Enter Amount</Label>
//                   <Input
//                     type="number"
//                     value={requestedAmount}
//                     name="requestedAmount"
//                     onChange={handleInputChange}
//                     isError={false}
//                     errorMessage={""}
//                   />
//                 </div>

//                 <div className="md:p-2 w-full">
//                   <Upload
//                     listType="picture-card"
//                     fileList={file ? [{ uid: "-1", url: file }] : []}
//                     onPreview={handlePreview}
//                     onChange={handleChangeImage}
//                     accept="image/*"
//                     maxCount={1}
//                   >
//                     {file ? null : uploadButtonStudentPicture}
//                   </Upload>
//                   {previewImage && (
//                     <Image
//                       preview={{
//                         visible: previewOpen,
//                         onVisibleChange: (visible) => setPreviewOpen(visible),
//                         afterClose: () => setPreviewImage(""),
//                       }}
//                       src={previewImage}
//                       style={{ display: "none" }}
//                     />
//                   )}
//                 </div>
//               </>
//             ) : null}

//             <p className="mb-4 text-gray-700 text-center font-semibold text-lg ddark:text-gray-300">
//               Amount : {formatNumberWithCommas(requestedAmount)}
//             </p>
//             <div className="flex justify-center items-center space-x-4">
//               <ButtonOutlined handleClick={() => closeModal()}>
//                 cancel
//               </ButtonOutlined>

//               <ButtonFill type="Submit" disabled={isLoading}>
//                 {isLoading ? "Loading.." : " Submit"}
//               </ButtonFill>
//             </div>
//           </form>
//         </div>
//       </div>
//     </div>
//   );
// };

export default ReimbursementRequests;
