
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

export default function QuestionTextContainer({ form, setForm, modules }) {

    const addHint = () => {
        const selectedText = window.getSelection().toString();
        if (selectedText) {
            if (form.hints.includes(selectedText)) {
                return;
            }
            setForm({
                ...form,
                hints: [...form.hints, selectedText],
            });
        }
    };

    const clearHint = () => {
        setForm({
            ...form,
            hints: [],
        });
    };

    return (
        <>
            <div className='flex flex-row flex-wrap items-center justify-between'>
                <h6 className='text-md font-bold text-rose-500 mt-2'> Question Text:</h6>
                <div className='flex flex-row flex-wrap items-center gap-2'>
                    <button className="border border-green-500 hover:bg-green-500 hover:text-white text-green-500 font-bold py-1 px-4 rounded border-green-500" onClick={addHint}>Add Hint</button>
                    <button className="border border-rose-500 hover:bg-rose-500 hover:text-white text-rose-500 font-bold py-1 px-4 rounded border-rose-500" onClick={clearHint}>Clear Hint</button>
                </div>
            </div>
            <ReactQuill
                className="bg-white rounded-sm text-black focus:outline-none focus:ring-2 focus:ring-gray-200 focus:border-transparent"
                theme="snow"
                onChange={(html) => {
                    setForm({
                        ...form,
                        questionText: html
                    })
                }}
                value={form.questionText}
                modules={modules}
                placeholder="Enter Question Text"
            />
        </>
    )
}
