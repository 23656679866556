import { apiRequest } from "(apis)/api-interface/api-handler";
import { useEffect, useState } from "react";
import { CAMainTable } from "./table";
import AddCAModal from "./add-modal";


const CAManager = () => {
  const [campus_associates, setcampus_associates] = useState([]);
  const [originalcampus_associates, setoriginalcampus_associates] = useState([]);
  useEffect(() => {
    apiRequest("getALLCa")
      .then((response) => {
        setcampus_associates(response.data.data)
        setoriginalcampus_associates(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [])
  const [showModel, setShowModel] = useState(false);

  return (
    <>
      <div className="flex justify-center text-xl font-bold">
        <h2>Campus Associates</h2>
      </div>
      <div className="flex w-full justify-center">
        <CAMainTable campus_associates={campus_associates} setcampus_associates={setcampus_associates} originalcampus_associates={originalcampus_associates} setoriginalcampus_associates={setoriginalcampus_associates} setShowModel={setShowModel} />
        <AddCAModal
          setcampus_associates={setcampus_associates}
          ShowModel={showModel}
          setShowModel={setShowModel}
        />

      </div>
    </>

  )
}

export default CAManager