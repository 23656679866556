
const filteroutGeneratedImagesUrls = (values:any) => {
    const url = values?.filter((file) => file?.isOldFile)
    ?.map((file:any) => {
      return file?.url;
    });
    return url
}

export default filteroutGeneratedImagesUrls
