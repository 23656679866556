import CustomSelect from "shared-components/form-fields/custom-select";

import {
  accessData,
  provinceData,
  uniYearData,
} from "constants/constantvalues";
import CustomTextAreaField from "shared-components/form-fields/custom-textarea-field";

const VaultFieldsComponent = ({
  formik,
  institutesData,
  subjectData,
  topicsData,
  vaultType,
  boardsData,
}) => {
  const { values } = formik;
  return (
    <div className="flex flex-col gap-2">
      {process.env.REACT_APP_CATEGORY === "MEDSCHOOL" && (
          <div className="flex flex-col md:flex-row ">
            <div className="md:p-2 w-full md:w-1/2">
              <CustomSelect
                formik={formik}
                label={" Select  Universities"}
                mode="multiple"
                name="universities"
                options={institutesData}
              />
            </div>
            <div className="md:p-2 w-full md:w-1/2">
              <CustomSelect
                formik={formik}
                name="year"
                label={"  Select  Year"}
                options={uniYearData}
              />
            </div>
          </div>
        )}
      <div className="flex flex-col md:flex-row ">
        <div className="md:p-2 w-full   md:w-1/2   ">
          <CustomSelect
            formik={formik}
            label={" Province"}
            name="province"
            options={provinceData}
          />
        </div>
        <div className="md:p-2 w-full  md:w-1/2 ">
          <CustomSelect
            formik={formik}
            name="subject"
            label={
              process.env.REACT_APP_CATEGORY === "MEDSCHOOL" 
                ? "Discipline"
                : "Subject"
            }
            options={subjectData}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row ">
        <div className="md:p-2 w-full   md:w-1/2   ">
          <CustomSelect
            formik={formik}
            name="board"
            label={" Board"}
            options={boardsData}
          />
        </div>
        <div className="md:p-2 w-full  md:w-1/2 ">
          <CustomSelect
            formik={formik}
            name="access"
            label={" Access"}
            options={accessData}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-2">
        <div className={`w-full  ${ vaultType  !=="Shortlisting" ?"md:w-1/2":""}` }>
          <div className="md:p-2 w-full   ">
            <CustomSelect
              formik={formik}
              label={" Topic Name "}
              name="topicName"
              options={topicsData?.map((topic: any) => ({
                label: topic.topicName,
                value: topic.topicName,
              }))}
            />
          </div>
        </div>
        {
          vaultType  !=="Shortlisting"  &&    <div className="md:p-2 w-full  md:w-1/2  ">
          <CustomSelect
            formik={formik}
            label={" SubTopic Name"}
            name="subTopicName"
            options={
              topicsData
                ?.find((topic) => {
                  return topic.topicName === values?.topicName;
                })
                ?.subTopics?.map((subTopic) => ({
                  label: subTopic.subTopicName,
                  value: subTopic.subTopicName,
                })) || []
            }
          />
        </div>
        }
     
      </div>
      <div className="flex flex-col gap-2">
        <CustomTextAreaField formik={formik} label="Description" name="desc" />
      </div>
    </div>
  );
};

export default VaultFieldsComponent;
