import { Divider, message, Tabs } from "antd";
import React, { useEffect, useState } from "react";
import Last7DaysBarChart from "./last7-days-bar-chart";
import MonthlySignUpsBarChart from "./monthly-signups-barchart";
import "./NewStats.css";
import PieChartComponent from "./piechart-component";
import QuarterlySignUpsBarChart from "./quarterly-signups-barchart";
import TodaysBarChar from "./todays-barchar";
import YearlySignUpsPieChart from "./yearly-signups-piechart";
import { backendUrl } from "constants/config";
import { categoryValues } from "constants/constantvalues";
import Loader from "shared-components/Loader";
import { Coupon, SignupStats, TrialCoupon } from "./types";
import {
  processDataByYear,
  aggregateDataByYear,
  prepareYearlyPieChartData,
  prepareBarChartDataOfMonths,
} from "./functions";
export default function Statistics() {
  const URL = backendUrl + "/api";
  const [signupStats, setSignupStats] = useState<SignupStats>({});
  const [isFetchingData, setIsFetchingData] = useState<boolean>(false);
  const [Coupons, SetCoupons] = useState<Coupon[]>([]);
  const [trialCode, setTrialCode] = useState<TrialCoupon[]>([]);
  const [totalTrialCodes, setTotalTrialCodes] = useState<number>(0);
  const [totalCoupons, setTotalCoupons] = useState<number>(0);
  const [searchByTrialCode, setSearchByTrialCode] = useState<string>("");
  const [searchByCoupon, setSearchByCoupon] = useState<string>("");
  const [TrialCoupons, setTrialCoupons] = useState<TrialCoupon[]>([]);
  const [OtherTrialStats, setOtherTrialStats] = useState<TrialCoupon[]>([]);

  useEffect(() => {
    const TrialCoupons = async () => {
      try {
        const response = await fetch(`${URL}/api/user/UniqueCodesTrial`);

        if (!response.ok) {
          throw new Error("🤳Failed to fetch data");
        }

        const data = await response.json();
        // data contains "Associate COde:" followed by the trial code
        // Remove "Associate Code:" from the trial code
        const trimmedData = data.aggregateResult.map((element) => {
          return {
            ...element,
            trialCode: element._id.replace("Associate Code:", "").trim(),
          };
        });
        setTrialCoupons(trimmedData);
      } catch (error) {
        console.error("🤢Error:", error);
      }
    };

    TrialCoupons();
  }, []);

  useEffect(() => {
    const TrialCoupons = async () => {
      try {
        const response = await fetch(`${URL}/api/user/TrialStatistics`);

        if (!response.ok) {
          throw new Error("🤳Failed to fetch data");
        }

        const data = await response.json();
        // data contains "Associate COde:" followed by the trial code
        // Remove "Associate Code:" from the trial code
        const trimmedData = data.aggregateResult.map((element) => {
          return {
            ...element,
            trialCode: element._id.replace("Associate Code:", "").trim(),
          };
        });
        setOtherTrialStats(trimmedData);
      } catch (error) {
        console.error("🤢Error:", error);
      }
    };

    TrialCoupons();
  }, []);

  const fetchStats = async (category = "ALL") => {
    try {
      setIsFetchingData(true);
      const response = await fetch(
        `${URL}/api/marketing/get-new-statistics/${category}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const ResponseToJson = await response.json();
      if (ResponseToJson?.isSuccess) {
        if (ResponseToJson?.data) {
          setSignupStats(ResponseToJson?.data);
        } else {
          setSignupStats({});
          // message.error(response?.message);
        }
      }
    } catch (error) {
      message.error(error?.message);
    } finally {
      setIsFetchingData(false);
    }
  };

  useEffect(() => {
    const fetchTrialCodes = async () => {
      try {
        const response = await fetch(`${URL}/api/trialcodes/getTrialCodes`);

        if (!response.ok) {
          throw new Error("🤳Failed to fetch data");
        }

        const data = await response.json();
        const trialCodeCountArray = data.trialCodeCount;

        // Set trialCode state with the array

        const totalTrialCodes = trialCodeCountArray.reduce(
          (total, item) => total + item.count,
          0
        );

        setTrialCode(trialCodeCountArray);

        setTotalTrialCodes(totalTrialCodes);
      } catch (error) {
        console.error("🤢Error:", error);
      }
    };

    const CouponsUsage = async () => {
      try {
        const response = await fetch(`${URL}/api/marketing/coupon-usage`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        });

        const ResponseToJson = await response.json();

        const totalCoupons = ResponseToJson.length;
        setTotalCoupons(totalCoupons);
        SetCoupons(ResponseToJson.splice(0, 366));
      } catch (error) {}
    };
    fetchStats();
    CouponsUsage();
    fetchTrialCodes();
  }, []);

  const trialCodefilteredData = trialCode.filter((dataTrialCode) => {
    const searchByTrialCodeFilter =
      searchByTrialCode === ""
        ? true
        : dataTrialCode.trialCode.includes(searchByTrialCode);

    return searchByTrialCodeFilter;
  });

  const couponsfilteredData = Coupons.filter((dataCoupons) => {
    const searchByCouponsFilter =
      searchByCoupon === "" ? true : dataCoupons._id.includes(searchByCoupon);

    return searchByCouponsFilter;
  });
  const formatedCategoryValues = categoryValues?.map((category) => {
    return { key: category?.value, label: category?.value };
  });
  const categoriesValueWithAll = [
    { key: "ALL", label: "ALL" },
    ...formatedCategoryValues,
  ];

  // Inside your component
  const yearlyData = processDataByYear(signupStats?.quarterlySignUps);
  const aggregatedData = aggregateDataByYear(signupStats?.quarterlySignUps);
  const YearlypieChartData = prepareYearlyPieChartData(aggregatedData);
  const barChartDataOfMonths = prepareBarChartDataOfMonths(
    signupStats?.monthsData
  );
  return (
    <>
      <Loader loading={isFetchingData} />
      <div className="mx-10 font-bold text-2xl">PreMed.PK Statistics</div>
      <div className="mainContainer">
        <Tabs
          type="card"
          defaultActiveKey="ALL"
          items={categoriesValueWithAll}
          onChange={(key) => fetchStats(key)}
        />
        <div className="flex  flex-wrap gap-2"></div>
        <div className="subTableHeading">Overall stats</div>
        <div className="parentContainer">
          <div className="boxContainer">
            <div className="boxContent">
              <h4 className="boxText">Total Users:</h4>
              <h2 className="boxNumbers">{signupStats?.totalUsers}</h2>
            </div>
            <div className="boxContent">
              <h4 className="boxText">SignUps Today:</h4>
              <h2 className="boxNumbers">{signupStats?.signUpsToday}</h2>
            </div>
            <div className="boxContent">
              <h4 className="boxText">SignUp Last Week:</h4>
              <h2 className="boxNumbers">{signupStats?.signUpsLastWeek}</h2>
            </div>
            <div className="boxContent">
              <h4 className="boxText">SignUps this Month:</h4>
              <h2 className="boxNumbers">{signupStats?.signUpsThisMonth}</h2>
            </div>
            <div className="boxContent">
              <h4 className="boxText">SignUps Last Month:</h4>
              <h2 className="boxNumbers">{signupStats?.signUpsLastMonth}</h2>
            </div>
          </div>
        </div>
        <div className="flex  flex-wrap shadow-2xl p-10">
          {Object.keys(yearlyData)?.map((year) => (
            <div className="w-full md:w-1/2 shadow-md rounded-lg">
              <PieChartComponent
                key={year}
                year={year}
                data={yearlyData[year]?.data}
                total={yearlyData[year]?.total}
                growth={yearlyData[year]?.growth}
                growthQuantity={yearlyData[year]?.growthQuantity}
              />
            </div>
          ))}
        </div>

        <div className="shadow-xl p-4 rounded-xl">
          <h1 className="p-8 font-bold text-xl">Yearly Sign-Ups Data</h1>
          <YearlySignUpsPieChart data={YearlypieChartData} />
        </div>
        <div className="shadow-xl p-4 rounded-xl">
          <h1 className="p-8 font-bold text-xl">Quartly Sign-Ups Data</h1>
          <QuarterlySignUpsBarChart data={signupStats?.quarterlySignUps} />
        </div>
        <div className="shadow-xl p-4 rounded-xl">
          <h1 className="p-8 font-bold text-xl">Monthly Sign-Ups Data</h1>
          <MonthlySignUpsBarChart data={barChartDataOfMonths} />
        </div>
        <div className="flex flex-col gap-4 shadow-lg rounded-xl p-4 ">
          <h1 className="p-8 font-bold text-xl">
            November-November SignUps Data
          </h1>
          {signupStats?.seasons?.map((data, index) => {
            return (
              <div key={index}>
                <div className="flex  justify-between gap-2">
                  <div>
                    <div className="font-semibold">{data?.season}</div>
                    <div>{data?.month}</div>
                  </div>
                  <div>{data?.total}</div>
                  <div></div>
                </div>
                <Divider />
              </div>
            );
          })}
        </div>
        <div className="subTableHeading">Today's Statistics</div>

        <div className="parentContainer">
          <TodaysBarChar data={signupStats?.signUpsTodayTimeline} />
        </div>
        <div className="subTableHeading">Signs - Last 7 days</div>
        <div className="parentContainer">
          <Last7DaysBarChart data={signupStats?.last7Days} />
        </div>

        <div className="parentStatisticsContainer">
          <div className="couponContainer">
            <div className="statsTableText">Coupon Statistics</div>
            <div className="statsInputParent">
              <input
                className="statsInputFeild"
                type="text"
                placeholder="Enter Coupon ID"
                value={searchByCoupon}
                onChange={(e) =>
                  setSearchByCoupon(e.target.value.toUpperCase())
                }
              />
            </div>
            <div className="statsSubTableText">
              {" "}
              Total Coupons: {totalCoupons}{" "}
            </div>
            {couponsfilteredData?.map((coupon, index) => (
              <div key={index} className="couponStats">
                <h3>
                  {coupon._id}
                  {" : "}
                </h3>
                <h3> {coupon.count} </h3>
              </div>
            ))}
          </div>
          <div className="couponContainer">
            <div className="statsTableText">Trial Statistics</div>
            <div className="statsInputParent">
              <input
                className="statsInputFeild"
                type="text"
                placeholder="Enter TrialCode ID"
                value={searchByTrialCode}
                onChange={(e) =>
                  setSearchByTrialCode(e.target.value.toUpperCase())
                }
              />
            </div>

            <div className="statsSubTableText">
              Total TrialCodes: {totalTrialCodes}{" "}
            </div>

            {trialCodefilteredData.map((code, index) => (
              <div key={index} className="couponStats">
                <h3>
                  {code.trialCode}
                  {" : "}
                </h3>
                <h3> {code.count} </h3>
              </div>
            ))}
          </div>
        </div>

        <div className="parentStatisticsContainer">
          <div className="couponContainer">
            <div className="statsTableText">Associate Codes in Trial</div>
            <div className="statsInputParent">
              <input
                className="statsInputFeild"
                type="text"
                placeholder="Enter Coupon ID"
                value={searchByCoupon}
                onChange={(e) =>
                  setSearchByCoupon(e.target.value.toUpperCase())
                }
              />
            </div>
            <div className="statsSubTableText">
              {" "}
              Total Coupons: {TrialCoupons?.length}{" "}
            </div>
            {TrialCoupons?.map((coupon, index) => (
              <div key={index} className="couponStats">
                <h3>
                  {coupon.trialCode}
                  {" : "}
                </h3>
                <h3> {coupon.count} </h3>
              </div>
            ))}
          </div>
          <div className="couponContainer">
            <div className="statsTableText">Other Referals in Trial</div>
            <div className="statsInputParent">
              <input
                className="statsInputFeild"
                type="text"
                placeholder="Enter Coupon ID"
                value={searchByCoupon}
                onChange={(e) =>
                  setSearchByCoupon(e.target.value.toUpperCase())
                }
              />
            </div>
            <div className="statsSubTableText">
              Total Referals: {OtherTrialStats?.length}{" "}
            </div>

            {OtherTrialStats?.map((code, index) => (
              <div key={index} className="couponStats">
                <h3>{code.trialCode}</h3>
                <h3> {code.count} </h3>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
