export const initialValues = {
    topicName: "",
    province: "",
    subject: "",
    pdfUrl: "",
    desc: "",
    pdfFile: null,
    noteId: null,
 
    thumbnailImage: null,
    subTopicName: "",
    paginations: [{
        subTopic: "",
        startPageNo: "",
        endPageNo: "",
    }],
    board: "",
    access: "",
    universities: [],
    year: "",
  };

  export const NoteInitialValues = {
    topicName: "",
    province: "",
    subject: "",
    pdfUrl: "",
    desc: "",
    pdfFile: null,
    noteId: null,
 
    thumbnailImage: null,
    subTopicName: "",
    paginations: [{
        subTopic: "",
        startPageNo: "",
        endPageNo: "",
    }],
    board: "",
    access: "",
    universities: [],
    year: "",
  };