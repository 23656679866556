import React, { useContext, useEffect } from "react";
import { UserContext } from "../../state-management/user-context";
import "./dashboard.css";
import { useNavigate } from "react-router-dom";
import { LockFilled } from "@ant-design/icons";
import { AcademicRoutes, ExpertSolution, FinanceRoutes, Marketing, Plans, VaultRoutes } from "constants/constantvalues";

// Define the type for user context

const Dashboard: React.FC = () => {
  // Use the UserContext and provide the User type
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Layout must be used within a UserProvider");
  }
  const { user } = context;
  useEffect(() => {
    if (user.isloggedin === false) {
      window.location.href = "/login";
    }
  }, [user.isloggedin]);

  let sections = []

  if (process.env.REACT_APP_CATEGORY === 'FINANCE'){
    sections = [
      { title: 'Finance', routes: FinanceRoutes },
    ];
  }
  else {
    sections = [
      { title: 'Academic', routes: AcademicRoutes },
      { title: 'Vault', routes: VaultRoutes },
      { title: 'Expert Solution', routes: ExpertSolution },
      { title: 'Plans', routes: Plans },
      { title: 'Marketing', routes: Marketing },
    ];
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl text-center text-gray-700 font-bold mt-4">
          Welcome, {user.fullname}!
        </h1>
        <p className="text-center text-gray-700 mt-4">
          You have successfully logged in to the dashboard. You can now access
          the features of the dashboard. {user?.username}
        </p>
      </div>

      {user.isloggedin ?
      <div className="w-full md:w-[90%] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-4">
        {sections.map((section, index) => (
          <Section key={index} title={section.title} routes={section.routes} />
        ))}
      </div>
      : 
      <div className="flex flex-col items-center justify-center">
        <h1 className="text-4xl text-center text-gray-700 font-bold mt-4">
          You are not logged in!
        </h1>
        <p className="text-center text-gray-700 mt-4">
          Please login to access the dashboard.
        </p>
      </div>
      }
      {/* 
      <p className="smth-para">
        If the page you're attempting to access isn't loading, please click the
        link again or copy-paste it into the URL box.
      </p>

      <div className="demovideo">
        <video
          src="/DemoVideo.mp4"
          controls
          style={{
            objectFit: "contain",
          }}
        />
      </div> */}
    </>
  );
};

const Section = ({ title, routes }) => (
  <div className="bg-[#F7F6F7] flex flex-col justify-start shadow-xl rounded-xl p-4 min-h-32">
    <h1 className="text-2xl text-gray-700 font-bold text-left">
      {title}
    </h1>
    <div className="flex flex-wrap justify-start items-center gap-2 mt-4">
      <RouteButtons routes={routes} />
    </div>
  </div>
);


const RouteButtons = ({ routes }) => {
  const navigate = useNavigate();
  const context = useContext(UserContext);
  if (!context) {
    throw new Error("Layout must be used within a UserProvider");
  }
  const { user } = context;
  const HasPerm = (perm: string[]): boolean => {
    if (!user || !user.admin) {
      console.error("User or user.admin is undefined");
      return false;
    }

    if (!Array.isArray(user?.admin?.pagepermissions)) {
      console.error("user.admin.pagepermissions is not an array");
      return false;
    }

    return perm.some(p =>
      user?.admin?.pagepermissions!.some(pp => pp.toLowerCase() === p.toLowerCase())
    );
  }



  return (
    <div className="flex flex-wrap justify-start items-center gap-2 mt-4">
      {routes.map((route, index) => (
        <button
          key={index}
          className="flex gap-2 bg-primary text-white text-sm font-semibold py-1 px-4 rounded shadow-md hover:bg-white border border-primary hover:border-primary hover:text-primary focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-opacity-50 transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed"
          onClick={() => navigate(route.path)}
          disabled={!HasPerm(route.permissions)}
        >
          {HasPerm(route.permissions) ? "" : <LockFilled />}
          {route.name}
        </button>
      ))}
    </div>
  );
}


export default Dashboard;
