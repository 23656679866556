
const Logos: { [key: string]: string } = {
    "PRE-MEDICAL": "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/PreMed%20Logo%204.svg",
    "PRE-ENGINEERING": "/logos/pre-engineering.png",
    "MEDSCHOOL": "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/MedSchoolLogo.svg",
    "FINANCE": "https://premedpk-cdn.sgp1.digitaloceanspaces.com/ManulaUpload/b66229b9-4408-4c05-b1be-d8b986f1ba72.png",
    "ALLIED-HEALTH-SCIENCES": "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/Variant=FINAL%201.svg",
    "INTERNATIONAL": "https://premedpk-cdn.sgp1.cdn.digitaloceanspaces.com/CDN/SaudiPrep.png",
}

export { Logos }

