export const OrderDefaultValues = {
    _id: "",
    UserID: "",
    UserName: "",
    Referal: "",
    AddonSKU: "",
    TotalAmount: "",
    CouponUsed: false,
    CouponCode: "",
    DiscountAmount: "",
    PaidAmount: "",
    PaymentMethod: "",
    OrderStatus: "",
    CreationDate: new Date(),
    ActionDate: new Date(),
    CouponComission: 0,
    BundlePurchased: {
      BundleId: "",
      BundlePrice: 0,
      BundleDiscount: 0,
    },
    BundlesPurchased: [],
    CouponDiscount: 0,
    PaymentProof: "",
    subscriptions: [],
    plans: [
      {
        PlanID: "",
        PlanDuration: 0,
      },
    ],
    planCourses: [
      {
        courseIds: [""],
        duration: 0,
      },
    ],
    type: "",
    access: [""],
    Duration: 0,
    TransactionID: "",
    Products: "",
  }