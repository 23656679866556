import React, { useState, useEffect } from 'react'
import { backendUrl } from "../../constants/config";
import { notification } from 'antd';
import { FlashcardsAPI } from "../../(apis)/(shared-apis)/flashcard-apis";

export default function FlashcardReports() {

    const [pendingReports, setpendingReports] = useState([]);
    const [pendingReportsOriginal, setpendingReportsOriginal] = useState([]);
    const [searchUsername, setSearchUsername] = useState('');
    const [searchSubject, setSearchSubject] = useState('');
    const [searchChapter, setSearchChapter] = useState('');

    useEffect(() => {

        const fetchData = async () => {
            const response = await FlashcardsAPI('GetPendingReports');
            if (response) {
                const data = await response;
                setpendingReports(data.reports);
                setpendingReportsOriginal(data.reports);
            }
            else {
                notification.error({
                    message: 'Error',
                    description: 'Failed to fetch data'
                });
            }
        }

        fetchData();

    }, []);

    useEffect(() => {
        if (searchUsername === '') {
            setpendingReports(pendingReportsOriginal);
        }
        else {
            setpendingReports(pendingReportsOriginal.filter(report => report.UserName.toLowerCase().includes(searchUsername.toLowerCase())));
        }
    }, [searchUsername]);

    useEffect(() => {
        if (searchSubject === '') {
            setpendingReports(pendingReportsOriginal);
        }
        else {
            setpendingReports(pendingReportsOriginal.filter(report => report.Subject.toLowerCase().includes(searchSubject.toLowerCase())));
        }
    }, [searchSubject]);

    useEffect(() => {
        if (searchChapter === '') {
            setpendingReports(pendingReportsOriginal);
        }
        else {
            setpendingReports(pendingReportsOriginal.filter(report => report.ChapterName.toLowerCase().includes(searchChapter.toLowerCase())));
        }
    }, [searchChapter]);

    return (
        <>
            <div className="-my-2 py-2 overflow-x-auto sm:-mx-6 sm:px-6 lg:-mx-8 pr-10 lg:px-8">
                <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 overflow-hidden bg-white shadow-lg px-12">
                    <div className="flex justify-between">
                        <div className="inline-flex border rounded-3xl w-3/12 px-2 lg:px-6 h-12 bg-transparent">
                            <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
                                <div className="flex w-full">
                                    <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                                        <svg width="18" height="18" className="w-4 lg:w-auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.9993 16.9993L13.1328 13.1328" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <input type="text" className='w-full bg-transparent text-gray-800 pl-2' placeholder="Search by Username" onChange={(e) => {
                                        setSearchUsername(e.target.value);
                                    }} value={searchUsername}/>
                                </div>
                            </div>
                        </div>
                        <div className="inline-flex border rounded-3xl w-3/12 px-2 lg:px-6 h-12 bg-transparent">
                            <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
                                <div className="flex w-full">
                                    <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                                        <svg width="18" height="18" className="w-4 lg:w-auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.9993 16.9993L13.1328 13.1328" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <input type="text" className='w-full bg-transparent text-gray-800 pl-2' placeholder="Search by Chapter Name" onChange={(e) => {
                                        setSearchChapter(e.target.value);
                                    }} value={searchChapter}/>
                                </div>
                            </div>
                        </div>
                        <div className="inline-flex border rounded-3xl w-3/12 px-2 lg:px-6 h-12 bg-transparent">
                            <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
                                <div className="flex w-full">
                                    <span className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none lg:px-3 py-2 whitespace-no-wrap text-grey-dark text-sm">
                                        <svg width="18" height="18" className="w-4 lg:w-auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                            <path d="M16.9993 16.9993L13.1328 13.1328" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round" />
                                        </svg>
                                    </span>
                                    <input type="text" className='w-full bg-transparent text-gray-800 pl-2 focus:border-none' placeholder="Search by Subject" onChange={(e) => {
                                        setSearchSubject(e.target.value);
                                    }} value={searchSubject}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="align-middle inline-block min-w-full shadow overflow-hidden bg-white shadow-dashboard px-8 pt-3 rounded-bl-lg rounded-br-lg">
                    <table className="min-w-full">
                        <thead>
                            <tr>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left leading-4 text-rose-500 tracking-wider">ID</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Submitted By</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Subject</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Chapter Name</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Status</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Created At</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-left text-sm leading-4 text-rose-500 tracking-wider">Complaint</th>
                                <th className="px-6 py-3 border-b-2 border-gray-300 text-center text-sm leading-4 text-rose-500 tracking-wider">Action</th>
                            </tr>
                        </thead>
                        <tbody className="bg-white">
                            {pendingReports.map((report, index) => {
                                return (
                                    <tr key={index}>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                            <div className="flex items-center">
                                                <div>
                                                    <div className="text-sm leading-5 text-gray-800">{report._id}</div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b border-gray-500">
                                            <div className="text-sm leading-5 text-blue-900">{report.UserName}</div>
                                        </td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{report.Subject}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{report.ChapterName}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{report.status}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{report.createdAt}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">{report.Complaint}</td>
                                        <td className="px-6 py-4 whitespace-no-wrap border-b text-blue-900 border-gray-500 text-sm leading-5">
                                            <button className="bg-rose-500 text-white hover:bg-rose-600 font-bold uppercase text-xs px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none mr-1 ease-linear transition-all duration-150"
                                                onClick={() => {
                                                    window.location.href = `/editFlashcard/${report.flashcardId}/${report._id}/true`;
                                                }}>
                                                Edit Question
                                            </button>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}
