import React from 'react';
import { Button, Upload, UploadProps } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormikProps } from 'formik';
import LabelComponent from './LabelComponent';
import CustomFormikError from './CustomFormikError';
import { getErrorString } from "../../utility/form/functions";

interface CustomPdfFieldProps {
  label: string;
  name: string;
  formik: FormikProps<any>;
  require?: boolean;
  isMultiple?: boolean;
}

const CustomPdfField: React.FC<CustomPdfFieldProps> = ({ label, name, formik, require = false, isMultiple = false }) => {
    const touched = !!formik.touched[name];
    const error = getErrorString(formik.errors[name]);
  return (
    <>
      <div className="flex flex-col ">
        <LabelComponent value={label} require={require} />
        <Upload
          // listType="text"
          fileList={formik.values[name] || []}
          onChange={(info) => {
            console.log("info", info)
            formik.setFieldValue(name, info.fileList);
          }}
          accept=".pdf"
          beforeUpload={() => false}
          multiple={isMultiple}
          className="w-full"
          maxCount={1}
        >
          <Button icon={<UploadOutlined />}>Click to Upload</Button>
        </Upload>
      <CustomFormikError
        name={name}
        touched={touched}
        error={error}
        />
        </div>
    </>
  );
};

export default CustomPdfField;
