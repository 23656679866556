import { ButtonFill, ButtonOutlined } from "./CustomButtons";

interface ConfirmationModalProps {  
  active: boolean;
  message: string;
  onCancel: () => void;
  onConfirm: () => void;
}

export const ConfirmationModal = (props : ConfirmationModalProps) => {
  return (
    <div
      id="deleteModal"
      tabIndex={-1}
      aria-hidden="true"
      className={`${
        props.active ? "" : "hidden"
      } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
    >
      <div className="relative p-4 w-full max-w-md max-h-full">
        {/* Modal content */}
        <div className="relative p-4 text-center bg-white rounded-lg shadow ddark:bg-gray-800 sm:p-5">
          <button
            type="button"
            className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center ddark:hover:bg-gray-600 ddark:hover:text-white"
            data-modal-toggle="deleteModal"
            onClick={() => props.onCancel()}
          >
            <svg
              aria-hidden="true"
              className="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
            <span className="sr-only">Close modal</span>
          </button>
          <svg
            className="text-gray-400 ddark:text-gray-500 w-11 h-11 mb-3.5 mx-auto"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
              clipRule="evenodd"
            />
          </svg>
          <p className="mb-4 text-gray-500 ddark:text-gray-300">
            {props.message}
          </p>
          <div className="flex justify-center items-center space-x-4">
            <ButtonOutlined handleClick={() => props.onCancel()}>
              {" "}
              cancel
            </ButtonOutlined>

            <ButtonFill type="danger" handleClick={() => props.onConfirm()}>
              {" "}
              Confirm
            </ButtonFill>
          </div>
        </div>
      </div>
    </div>
  );
};
