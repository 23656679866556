import React from 'react';
import GlobalPublishButton from 'shared-components/ui/CustomButtons';
import { EditIcon } from 'shared-components/ui/Icons';

interface CourseData {
  value: string;
  label: string;
}

interface PricingbundleCardProps {
  isPlansPricingCard?: boolean;
  coursesData?: CourseData[];
  data: {
    Price: number;
    SKUName: string;
    courses: string[];
    isPublished: boolean;
    _id: string;
    Description: string;
    BundlePoints: string[];
    PreMedAccess: string[];
  };
  handleIsPublishToggle: (id: string) => void;
  handleEditCard: (data: any) => void;
}

const PricingbundleCard: React.FC<PricingbundleCardProps> = ({
  isPlansPricingCard = false,
  coursesData = [],
  data,
  handleIsPublishToggle,
  handleEditCard,
}) => {
  const { Price, SKUName, courses, isPublished, _id, Description, BundlePoints, PreMedAccess } = data;

  const matchedCourseNames = coursesData
    ? coursesData.filter(course => courses?.includes(course.value)).map(course => course.label)
    : [];

  return (
    <div className="h-full p-6 rounded-lg border-2 border-pink-500 flex flex-col relative overflow-hidden">
      {Price > 20000 && (
        <span className="bg-pink-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
          POPULAR
        </span>
      )}
      <h2 className="text-sm tracking-widest title-font mb-1 font-medium">
        {SKUName}
      </h2>
      <h1 className="text-3xl mt-2 text-gray-900 leading-none flex items-center pb-4 mb-4 border-b border-gray-200">
        <span>PKR.{Price}</span>
      </h1>
      {BundlePoints?.map((str, i) => (
        <p key={i} className="flex items-center text-gray-600 mb-2">
          <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-rose-400 text-white rounded-full flex-shrink-0">
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2.5"
              className="w-3 h-3"
              viewBox="0 0 24 24"
            >
              <path d="M20 6L9 17l-5-5" />
            </svg>
          </span>
          {str}
        </p>
      ))}

      <div>
        <h6>Accessibility</h6>
        {PreMedAccess?.map((str, i) => (
          <p key={i} className="flex items-center text-gray-600 mb-2">
            <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-rose-400 text-white rounded-full flex-shrink-0">
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2.5"
                className="w-3 h-3"
                viewBox="0 0 24 24"
              >
                <path d="M20 6L9 17l-5-5" />
              </svg>
            </span>
            {str}
          </p>
        ))}
      </div>

      {isPlansPricingCard && matchedCourseNames.length > 0 && (
        <div>
          <h6>Courses</h6>
          {matchedCourseNames.map((str, i) => (
            <p key={i} className="flex items-center text-gray-600 mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-rose-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5" />
                </svg>
              </span>
              {str}
            </p>
          ))}
        </div>
      )}

      <p className="text-xs text-gray-500 mt-3">
        {Description}
      </p>
      <div className="flex justify-between">
        <div>
          <GlobalPublishButton isActive={isPublished} onChange={() => handleIsPublishToggle(_id)} />
        </div>
        <div  onClick={() => handleEditCard(data)} >
          <EditIcon size='w-6 h-6' />
        </div>
      </div>
    </div>
  );
};

export default PricingbundleCard;
