import React from "react";
import { ErrorIcon, InfoIcon, SuccessIcon, WarningIcon } from "./Icons";

const Badges = {
  success: {
    icon: <SuccessIcon />,
    color: "bg-emerald-100 text-emerald-700",
  },
  error: {
    icon: <ErrorIcon />,
    color: "bg-red-100 text-red-700",
  },
  warning: {
    icon: <WarningIcon />,
    color: "bg-amber-100 text-amber-700",
  },
  info: {
    icon: <InfoIcon />,
    color: "bg-teal-100 text-teal-700",
  },
  light: {
    icon: <InfoIcon />,
    color: "bg-pink-100 text-pink-700",
  },
  gray: {
    icon: <InfoIcon />,
    color: "bg-gray-100 text-gray-700",
  },
};

interface CustomBadgesProps {
  type?: "success" | "error" | "warning" | "info" | "light" | "gray";
  label?: string;
  rounded?: "none" | "sm" | "md" | "lg" | "full";
  textSize?:
    | "xs"
    | "sm"
    | "base"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl";
  customTheme?: boolean;
  color?: string;
  fontWeight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold"
    | "black";
  showIcon?: boolean;
}

const CustomBadges = ({
  type = "success",
  label = "Unknown",
  rounded = "full",
  textSize = "xs",
  customTheme = false,
  color = "",
  fontWeight = "semibold",
  showIcon = true,
}: CustomBadgesProps) => {
  const roundedClass = `rounded-${rounded}`;
  const textSizeClass = `text-${textSize}`;
  let badgeStyle = "";
  if (customTheme) {
    badgeStyle = `inline-flex items-center justify-center ${roundedClass} px-2.5 py-1 ${color}    cursor-pointer space-x-1`;
  } else {
    badgeStyle = `inline-flex items-center justify-center ${roundedClass} px-2.5 py-1 ${Badges[type].color} cursor-pointer space-x-1`;
  }

  const fontWeightStyle = `font-${fontWeight}`;
  return (
    <span className={badgeStyle}>
      {showIcon && Badges[type].icon}
      <p
        className={`whitespace-wrap  ${textSizeClass} capitalize  ${fontWeightStyle}`}
      >
        {label}
      </p>
    </span>
  );
};
export default CustomBadges;
