import React, { useState } from "react";
import { Checkbox } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CustomButton from "shared-components/form-fields/custom-button";

interface Column {
  title: string;
  dataIndex: string;
}

interface Plan {
  planName: string;
  subscriptionStartDate: string;
  subscriptionEndDate: string;
}

interface RowData {
  [key: string]: any;
  PurchasedPlan?: Plan[];
}

interface ExportCSVProps {
  setIsExportCSV: (isOpen: boolean) => void;
  columns: Column[];
  data: RowData[];
}

const ExportCSV: React.FC<ExportCSVProps> = ({ setIsExportCSV, columns, data }) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);
  const [filename, setFilename] = useState<string>("data"); // Default filename

  const handleCheckboxChange = (field: string) => {
    setSelectedColumns((prev) => {
      if (prev.includes(field)) {
        return prev.filter((col) => col !== field);
      } else {
        return [...prev, field];
      }
    });
  };

  const handleCheckAll = () => {
    if (selectedColumns.length === columns.length) {
      setSelectedColumns([]);
    } else {
      setSelectedColumns(columns.map((col) => col.dataIndex));
    }
  };

  const handleExport = () => {
    exportToCSV();
  };

  const exportToCSV = () => {
    const filteredData = data?.map((row) => {
      const newRow: { [key: string]: any } = {};
      selectedColumns?.forEach((col) => {
        if (col === "PurchasedPlan" && row[col]) {
          // Format PurchasedPlan into a readable string
          newRow[col] = row[col]
            .map((plan) =>
              `${plan?.planName} (Start: ${dayjs(plan?.subscriptionStartDate).format("YYYY-MM-DD")} - End: ${dayjs(plan?.subscriptionEndDate).format("YYYY-MM-DD")})`
            )
            .join("; ");
        } else {
          newRow[col] = row[col];
        }
      });
      return newRow;
    });

    function convertToCSV(arr: any): string {
      const array = [Object.keys(arr[0])].concat(arr);
      return array
        .map((it) => {
          return Object.values(it).toString();
        })
        .join("\n");
    }

    // Convert the array of objects to CSV
    const csv = convertToCSV(filteredData);

    // Create a Blob from the CSV string
    const blob = new Blob([csv], { type: "text/csv" });

    // Create a link element
    const link = document.createElement("a");

    // Set the download attribute with a filename
    link.setAttribute("download", `${filename}.csv`);

    // Create a URL for the Blob and set it as the href attribute
    link.href = URL.createObjectURL(blob);

    // Append the link to the body
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
  };

  return (
    <div className="mx-8">
      <div className="text-xl ">Select Columns for Exporting</div>
      <div className="flex justify-between">
        <div className="mb-4">
          <CustomButton
            size="small"
            text="back"
            icon={<LeftOutlined />}
            onClick={() => setIsExportCSV(false)}
          />
        </div>
        <Checkbox
          indeterminate={
            selectedColumns?.length > 0 &&
            selectedColumns?.length < columns.length
          }
          onChange={handleCheckAll}
          checked={selectedColumns?.length === columns.length}
        >
          Check All
        </Checkbox>
      </div>
      <div className="flex justify-between">
        {[0, 10, 20].map((start, index) => (
          <div
            key={index}
            className="flex flex-col gap-3 justify-start items-start"
          >
            {columns.slice(start, start + 10).map((col, colIndex) => (
              <Checkbox
                key={colIndex}
                value={col.dataIndex}
                checked={selectedColumns.includes(col.dataIndex)}
                onChange={() => handleCheckboxChange(col.dataIndex)}
              >
                {col.title}
              </Checkbox>
            ))}
          </div>
        ))}
      </div>
      <div className="flex gap-4 justify-end mt-4">
        <input
          type="text"
          value={filename}
          onChange={(e) => setFilename(e.target.value)}
          placeholder="Enter filename"
          className="border border-gray-300 p-2 rounded"
        />
        <CustomButton
          text="Export CSV"
          onClick={handleExport}
          disabled={selectedColumns?.length === 0}
        />
      </div>
    </div>
  );
};

export default ExportCSV;
