import React, { useEffect, useState } from "react";

import { Select } from "antd";
// @ts-ignore
import styles from "./style.module.css";
import { useParams } from "react-router-dom";

import { useContext } from "react";
import { notification } from "antd";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";

export default function ExpertSubjectManage() {
  const { subject } = useParams();
  const [Loading, SetLoading] = useState(false);
  const [ExpertUsernames, SetExpertUsernames] = useState([]);
  const [selectedUsername, setSelectedUsername] = useState("All");
  const [Doubts, setDoubts] = useState([]);
  const [Original, setOriginal] = useState([]);
  const { user } = useContext(UserContext);
  const [TotalSolved, SetTotalSolved] = useState(0);
  const [TotalPending, SetTotalPending] = useState(0);
  const [Down, setDown] = useState(false);
  const [CurrentMonth, setCurrentMonth] = useState(new Date().getMonth() + 1);
  const [DoubtsFirst, setDoubtsFirst] = useState([]);
  const [OriginalFirst, setOriginalFirst] = useState([]);

  const ChangeDown = () => {
    setDown(!Down);
  };

  function capitalizeFirstLetter(str) {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  useEffect(() => {
    // GetExpertEmails()
    //   .then((res) => {
    //     SetExpertUsernames(res.data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    apiRequest("getexpertemails")
      .then((res) => {
        SetExpertUsernames(res.data);
      })
      .catch((err) => {
        console.log(err);
      });

    apiRequest("getsubjectstats", { subject })
      .then((res) => {
        SetTotalSolved(res.data.totalsolved);
        SetTotalPending(res.data.totalpending);
      })
      .catch((err) => {
        console.log(err);
      });

    // GetSubjectStats(subject)
    //   .then((res) => {
    //     SetTotalSolved(res.data.totalsolved);
    //     SetTotalPending(res.data.totalpending);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  }, []);

  const handleUsernameChange = async (value) => {
    SetLoading(true);
    setSelectedUsername(value);
    if (value === "All") {
      //   fetchDoubts();
    } else {
      setDoubts([]);
      FetchDoubt(value);
      FetchDoubtsFirst(value);
    }
    SetLoading(false);
  };
  const [loading, setLoading] = useState(false);
  const FetchDoubt = async (value) => {
    try {
      //   const response = await fetch(`${URL}/GetExpertSolvedDoubts`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ subject: subject, username: value }),
      //   });
      const response = await apiRequest("GetExpertSolvedDoubts", {
        subject,
        username: value,
      });
      const data = response.data;
      setDoubts(data.result);
      setOriginal(data.result);
    } catch (error) {
      console.error("Error fetching doubts:", error);
    }
    setLoading(false);
  };

  const FetchDoubtsFirst = async (value) => {
    try {
      //   const response = await fetch(`${URL}/GetExpertSolvedDoubtsFirst`, {
      //     method: "POST",
      //     headers: {
      //       "Content-Type": "application/json",
      //     },
      //     body: JSON.stringify({ subject: subject, username: value }),
      //   });
      const response = await apiRequest("GetExpertSolvedDoubtsFirst", {
        subject: subject,
        username: value,
      });
      const data = response.data;
      setDoubtsFirst(data.result);
      setOriginalFirst(data.result);
    } catch (error) {
      console.error("Error fetching doubts:", error);
    }
  };

  const ReviseDoubt = (id) => {
    apiRequest("redodoubt", { id }).then((res) => {
      console.log(res);
      if (res.data.success) {
        notification.success({
          message: "Success",
          description: "Doubt Revise Requested",
        });
        setDoubts([]);
        FetchDoubt(selectedUsername);
      }
    });
  };

  useEffect(() => {
    if (Down) {
      setDoubts([]);
      setDoubtsFirst(OriginalFirst);
    } else {
      setDoubts(Original);
      setDoubtsFirst([]);
    }
  }, [Down]);

  const Reset = () => {
    setDoubts([]);
    setOriginal([]);
    setSelectedUsername("All");
    setDoubtsFirst([]);
    setOriginalFirst([]);
  };

  const MonthsMapping = {
    1: "January",
    2: "February",
    3: "March",
    4: "April",
    5: "May",
    6: "June",
    7: "July",
    8: "August",
    9: "September",
    10: "October",
    11: "November",
    12: "December",
  };

  const Month = MonthsMapping[CurrentMonth];

  return (
    <>
      <div className="ml-[5.3%]">
        <button
          className="bg-rose-400 border-2 border-red-600 min-w-[10%] rounded-2xl text-white"
          onClick={Reset}
        >
          Reset
        </button>
      </div>

      <div className="flex flex-col justify-center items-center">
        <div className="container centerdiv">
          <div className={styles.manageContainer}>
            <div className={styles.Bar2}>
              <div className={`${styles.alignLeft} text-left`}>
                <h6>
                  <b>Subject: </b> {capitalizeFirstLetter(subject)}
                </h6>
              </div>
              <div></div>
              <div className={`${styles.alignLeft} text-left`}>
                <h6>
                  <b>Manager: </b> {user?.fullname}
                </h6>
              </div>
            </div>
            <div className={styles.Bar2}>
              <div className={`${styles.alignLeft} text-left`}>
                <h6>
                  <b>Solved Questions:</b> {TotalSolved}
                </h6>
              </div>
              <div></div>
              <div className={`${styles.alignLeft} text-left`}>
                <h6>
                  <b>Pending Questions:</b> {TotalPending}
                </h6>
              </div>
            </div>
          </div>
        </div>

        <div className="container centerdiv">
          <div className={styles.SelectionDiv}>
            <div>
              <Select
                className={styles.ExpertsDropdown}
                showSearch
                placeholder="Select Username"
                optionFilterProp="children"
                onChange={handleUsernameChange}
                // filterOption={(input, option) => {}}
                //   option?.children
                //     ?.toLowerCase()
                //     ?.indexOf(input.toLowerCase()) >= 0
                // }
                value={selectedUsername} // Set the selected value
              >
                {/* <Select.Option value="All">All</Select.Option>*/}
                {ExpertUsernames &&
                  ExpertUsernames.map((username) => {
                    return (
                      <Select.Option value={username} key={username}>
                        {username}
                      </Select.Option>
                    );
                  })}
              </Select>
            </div>
            <div className={styles.SolvedDisplay}>
              <h6>
                <b>Question Solved:</b> {Doubts.length}
              </h6>
            </div>
          </div>
        </div>

        {!Down && (
          <div className="container my-4">
            {Doubts.length > 0 && (
              <h2 className="font-bold text-2xl mb-4">
                Solved Questions of {selectedUsername}
              </h2>
            )}
            <TableContainer className="table-container">
              <Table
                className="styled-table"
                style={{
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow className={styles.tableheader}>
                    <TableCell id={styles.tabledb} style={{ width: "41%" }}>
                      Tags
                    </TableCell>
                    <TableCell id={styles.tabledb} style={{ width: "41%" }}>
                      Question Text
                    </TableCell>
                    <TableCell id={styles.tabledb} style={{ width: "4%" }}>
                      {Down ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-down cursor-pointer"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          onClick={ChangeDown}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M18 13l-6 6" />
                          <path d="M6 13l6 6" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-up cursor-pointer ml-[134%]"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          onClick={ChangeDown}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M18 11l-6 -6" />
                          <path d="M6 11l6 -6" />
                        </svg>
                      )}
                    </TableCell>
                    <TableCell
                      id={styles.tabledb}
                      style={{ width: "4%" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Loading ? (
                    <div className="container text-center my-4">
                      <h5>Loading...</h5>
                    </div>
                  ) : null}
                  {Doubts && Doubts.length === 0 && Original.length === 0 ? (
                    <div className="container text-center my-4">
                      <h5>No Doubts Solved Yet</h5>
                    </div>
                  ) : (
                    <>
                      {Doubts &&
                        Doubts.map((doubt) => (
                          <TableRow key={doubt.id} className="table-row">
                            <TableCell id={styles.TagCell}>
                              {doubt.Tags.map((tag) => (
                                <div id={styles.Tag}>{tag.name}</div>
                              ))}
                            </TableCell>
                            <TableCell
                              id="table-cell"
                              style={{ cursor: "pointer" }}
                            >
                              {doubt.QuestionText.slice(0, 45)
                                ?.replace("<p>", "")
                                .replace("</p>", "")
                                .replace("<span", "")
                                .replace("</span>", "") + "..."}
                            </TableCell>
                            <TableCell id="table-cell">
                              <button
                                className={styles.Button}
                                onClick={() => {
                                  window.open(`${doubt?.videoLink}`, "_blank");
                                }}
                              >
                                Watch
                              </button>
                            </TableCell>
                            <TableCell id="table-cell">
                              <button
                                className={styles.Button1}
                                onClick={() => ReviseDoubt(doubt._id)}
                              >
                                Revise
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
        {Down && (
          <div className="container my-4">
            <h2 className="font-bold text-2xl mb-4">
              Solved Questions of {selectedUsername} from 1st {Month}
            </h2>
            <TableContainer className="table-container">
              <Table
                className="styled-table"
                style={{
                  width: "100%",
                }}
              >
                <TableHead>
                  <TableRow className={styles.tableheader}>
                    <TableCell id={styles.tabledb} style={{ width: "41%" }}>
                      Tags
                    </TableCell>
                    <TableCell id={styles.tabledb} style={{ width: "41%" }}>
                      Question Text
                    </TableCell>
                    <TableCell id={styles.tabledb} style={{ width: "4%" }}>
                      {Down ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-down cursor-pointer"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          onClick={ChangeDown}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M18 13l-6 6" />
                          <path d="M6 13l6 6" />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-arrow-up cursor-pointer"
                          width="30"
                          height="30"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="#ffffff"
                          fill="none"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          onClick={ChangeDown}
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M12 5l0 14" />
                          <path d="M18 11l-6 -6" />
                          <path d="M6 11l6 -6" />
                        </svg>
                      )}
                    </TableCell>
                    <TableCell
                      id={styles.tabledb}
                      style={{ width: "4%" }}
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {Loading ? (
                    <div className="container text-center my-4">
                      <h5>Loading...</h5>
                    </div>
                  ) : null}
                  {DoubtsFirst &&
                  DoubtsFirst.length === 0 &&
                  OriginalFirst.length === 0 ? (
                    <div className="container text-center my-4">
                      <h5>No Doubts Solved Yet</h5>
                    </div>
                  ) : (
                    <>
                      {DoubtsFirst &&
                        DoubtsFirst.map((doubt) => (
                          <TableRow key={doubt.id} className="table-row">
                            <TableCell id={styles.TagCell}>
                              {doubt.Tags.map((tag) => (
                                <div id={styles.Tag}>{tag.name}</div>
                              ))}
                            </TableCell>
                            <TableCell
                              id="table-cell"
                              style={{ cursor: "pointer" }}
                            >
                              {doubt.QuestionText.slice(0, 45)
                                ?.replace("<p>", "")
                                .replace("</p>", "")
                                .replace("<span", "")
                                .replace("</span>", "") + "..."}
                            </TableCell>
                            <TableCell id="table-cell">
                              <button
                                className={styles.Button}
                                onClick={() => {
                                  window.open(`${doubt?.videoLink}`, "_blank");
                                }}
                              >
                                Watch
                              </button>
                            </TableCell>
                            <TableCell id="table-cell">
                              <button
                                className={styles.Button1}
                                onClick={() => ReviseDoubt(doubt._id)}
                              >
                                Revise
                              </button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        )}
      </div>
    </>
  );
}
