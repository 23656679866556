import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { routes } from "./routes";
import Layout from "./Layout";
import LoginPage from "../shared-pages/login-page/login-form";
import ErrorPage from "../shared-pages/404/page";

const AppRouter = () => {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<LoginPage />} />
          <Route path="/login" element={<LoginPage />} />
          {routes?.map((route, index) => {
            return (
              <Route
                key={index}
                path={route.path}
                element={ <Layout children ={route?.component} permissions={route?.roles} redirectUrl={route?.path}/>}
              >
              </Route>
            );
          })}
          <Route path="*" element={<ErrorPage/>} />
        </Routes>
      </Router>
    </div>
  );
};

export default AppRouter;
