import { ConfigProvider, Tabs } from "antd";
import React from "react";
import './custom-tabs.css'; 
const CustomTabs = ({ items }) => {
  return (
    <div>
      <ConfigProvider
        theme={{
          components: {
            Tabs: {
              /* here is your component tokens */
              // cardBg: 'red',
              // horizontalItemMargin	: '50px',
              itemActiveColor: 'red',
              horizontalItemPadding:'10px',
              inkBarColor:"white",
              verticalItemPadding:"0px",
              cardPadding:"0px",
             
            },
          },
        }}
      >
        <div className="!mb-10">
        <Tabs
          // tabBarStyle={{backgroundColor: "#f0f2f5" }}
          // tabPosition="left"
          tabBarGutter={40}
          size="large"
          // className="font-bold"
           className="custom-tabs"
          tabBarStyle={{ padding:"0px", margin:"0px", border: "1px solid #f0f2f5", borderRadius: "5px" }}
          // type="card"
          defaultActiveKey="0"
          items={items}
          onChange={(key) => console.log("Selected Tab:", key)}
        />

        </div>
      </ConfigProvider>
    </div>
  );
};

export default CustomTabs;
