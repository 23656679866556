import { ApiInterface } from "../api-interface/types";

 export const hierarchyapis:ApiInterface[] = [
    {
        url: "/api/hierarchy/getBundleTags",
        method: "GET",
        name: "getBundleTags",
    },
    {
        url: "/api/hierarchy/get",
        method: "GET",
        name: "getHierarchies",
    },
    {
        url: `/api/hierarchy/getBundleTags`,
        method: "GET",
        name: "getDistintTags",
      },
    {
        url: "/api/hierarchy/getBoards",
        method: "GET",
        name: "gethierarchyboard",
    },
    {
        url: "/api/hierarchy/add",
        method: "POST",
        name: "addHierarchy",
    },
    {
        url: "/api/hierarchy/update/:id",
        method: "PATCH",
        name: "updateHierarchy",
    },
    {
        url: "/api/manage-institutes/getAll",
        method: "GET",
        name: "getHierarchyUniversities",
    },
    {
        url: "/api/hierarchy/university/add",
        method: "POST",
        name: "addHierarchyUniversity",
    },
    {
        url: "/api/hierarchy/university/update/:id",
        method: "PATCH",
        name: "updateHierarchyUniversity",
    },
    {
        url: "/api/hierarchy/university/updateStatus/:id",
        method: "PATCH",
        name: "updateHierarchyUniversitystatus",
    },
]