import React from 'react'
import VaultTable from '../../components/vault-table'

const Mnemonics = () => {
  return (
    <div>
      <VaultTable addApi="addmnemonic" getApi="getmnemonics" updateApi="updatemnemonic" publishApi="updatemnemonicpublish" vaultType="Mnemonic"  isPagenation={false}/>
    </div>
  )
}

export default Mnemonics
