import { Spin } from "antd";
import React from "react";

interface LoaderProps {
  loading: boolean;
}

const Loader: React.FC<LoaderProps> = ({ loading }) => {
  if (!loading) {
    return null;
  }

  return (
    <div className="fixed inset-0 bg-slate-100 bg-opacity-70 flex items-center justify-center z-50">
      <Spin size="large" />
    </div>
  );
};

export default Loader;
