import axios from "axios";
import { backendUrl } from "../../constants/config";
import { ApiInterface } from "interfaces/global-interfaces";

 export const ModuleApis:ApiInterface[] = [
    {
        url: "/api/module",
        method: "GET",
        name: "getModules",
 
    },
    {
        url: "/api/module/publish",
        method: "PUT",
        name: "updateModulePublishedStatusById",
    },
    {
        url: "/api/module",
        method: "POST",
        name: "addModule",
    },
    {
        url: "/api/module",
        method: "PUT",
        name: "updateModuleById",
    },
]

    
export function ModuleAPI(name: string, params: string[] = [""], data: any = null) {
    const api = ModuleApis.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

