import { FormikProps } from 'formik';
import React from 'react'
import CustomFormField from 'shared-components/form-fields/custom-form-field'
import CustomAntdModal from 'shared-components/modal/CustomAntdModal'
interface UniversityModalProps {
    isAddModal: boolean;
    editMode: boolean;
    setIsAddModal: (isOpen: boolean) => void;
    formik: FormikProps<any>; 
  }
  const UniversityModal: React.FC<UniversityModalProps> = ({ 
    isAddModal, 
    editMode, 
    setIsAddModal, 
    formik 
  }) => {
  return (
    <div>
         <CustomAntdModal
          isOpenModal={isAddModal}
          title={editMode ? "Edit University" : "Add New University"}
          setIsOpenModal={setIsAddModal}
          formik={formik}
        >
          <form>
            <CustomFormField
              type="text"
              name="universityName"
              formik={formik}
              label="Thumbnail image"
            />
            <div className="flex flex-col md:flex-row ">
              <div className="w-full md:w-1/2">
                <CustomFormField
                  type="image"
                  name="thumbnailImage"
                  formik={formik}
                  label="Thumbnail image"
                />
              </div>
              <div className="w-full md:w-1/2">
                <CustomFormField
                  type="image"
                  name="gallery"
                  formik={formik}
                  isMultipleImage={true}
                  label="Gallery"
                />
              </div>
            </div>
          </form>
        </CustomAntdModal>
    </div>
  )
}

export default UniversityModal
