
import { ApiInterface } from "../../interfaces/global-interfaces";

export const instituteapis:ApiInterface[] = [
    {
        url: "/api/manage-institutes/getAll",
        method: "GET",
        name: "getinstitutes",
    },
    {
        url: "/api/manage-institutes/add",
        method: "POST",
        name: "addinstitute",
    },
    {
        url: "/api/manage-institutes/update/:InstituteId",
        method: "PUT",
        name: "updateinstitute",
    },
    {
        url: "/api/manage-institutes/updateActive/:InstituteId",
        method: "PUT",
        name: "updateinstitutepublish",
    },
]


