import { Input } from 'antd';
import CustomFormikError from './CustomFormikError';
import { getErrorString } from "../../utility/form/functions";
import LabelComponent from './LabelComponent';
import { FormikProps } from 'formik';
const { TextArea } = Input;
interface CustomTextAreaFieldProps {
    label: string;
    require?: boolean;
    name: string;
    onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    formik: FormikProps<any>;
  }
  
  const CustomTextAreaField: React.FC<CustomTextAreaFieldProps> = ({ label, require = false, name, onChange, formik }) => {
    const touched = !!formik.touched[name];
    const error = getErrorString(formik.errors[name]);
  return (
    <>
    <LabelComponent value={label} require={require} />
    <TextArea
      id={name}
      name={name}
      variant="outlined"
      disabled={false}
      onChange={(e) => {
        if (onChange) {
          onChange(e);
        } else {
          formik.setFieldValue(name, e?.target?.value);
        }
      }}
      className="border "
      placeholder={`Enter ${label}`}
      value={formik.values[name]}
      // size="small"
    
    />
    <CustomFormikError
      name={name}
      touched={touched}
      error={error}
    />
  </>
  )
}

export default CustomTextAreaField
