import { ApiInterface } from "../../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../../constants/config";

export const QuestionReportsAPIs: ApiInterface[] = [
    {
        url: "/api/reports",
        method: "GET",
        name: "questionReports",
    },
    {
      url: "/api/reports/solve",
      method: "PUT",
      name: "solveReport",
    },
    {
      url: "/api/reports/reject",
      method: "PUT",
      name: "rejectReport",
    }

]

export function QuestionReportingAPI(name: string, params: string[] = [""], data: any = null) {
    const api = QuestionReportsAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }


// import axios from "axios";
// import { backendUrl } from "../Constants/appConstants";

// const apiURL = `${backendUrl}/api`;
// const endpoint = "latestreport";
// const url = `${apiURL}/${endpoint}`;

// export async function getLatestReportAPI() {
//     return axios.get(`${url}/all`);
// }

// export async function getLatestReportBySubjectAPI(subjectId) {
//     return axios.get(`${url}/${subjectId}`);
// }

// export async function solveReport(questionID, reportId, fixedby ) {
//     // pass fixedby as body
//     return axios.put(`${url}/solve/${questionID}/${reportId}`, fixedby);
// }

// export async function rejectReport(questionID, reportId, fixedby ) {
//     // pass fixedby as body
//     return axios.put(`${url}/reject/${questionID}/${reportId}`, fixedby);
// }