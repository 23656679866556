import { message } from "antd";
import React from "react";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { columnsWithoutRender } from "./columns";
import { EditIcon } from "shared-components/ui/Icons";
import GlobalTable from "shared-components/table/GlobalTable";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { FormikProps } from "formik";
interface InstituteTableProps {
  getInstituteData: () => void;
  formik: FormikProps<any>;
  setIsEditMode: React.Dispatch<React.SetStateAction<boolean>>;
  setShowModel: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
  instituteData: any[];
}

const InstituteTable: React.FC<InstituteTableProps> = ({
  getInstituteData,
  formik,
  setIsEditMode,
  setShowModel,
  loading,
  instituteData,
}) => {
  const handleIsPublish = async ({ id }) => {
    try {
      const res = await apiRequest("updateinstitutepublish", null, [id]);
      if (res?.data?.isSuccess) {
        getInstituteData();
        message.success(res?.data?.message);
      }
    } catch (error) {
      console.log("error", error);
      message.error(error?.response?.data?.message || error?.message);
    }
  };
  const handleEdit = (data) => {
    formik.resetForm();
    setIsEditMode(true);
    const { logoUrl } = data;
    const imgType = logoUrl
      ?.substring(logoUrl?.lastIndexOf("/") + 1)
      ?.split(".")
      ?.pop();
    const formatedLogo = {
      uid: 1,
      name: logoUrl,
      status: "done",
      url: logoUrl,
      type: `image/${imgType}`,
      isOldFile: true,
    };
    const { _id } = data;
    formik.setValues({
      ...data,
      logo: [formatedLogo],
      instituteEditId: _id,
    });
    setShowModel(true);
  };
  const columns = [
    ...columnsWithoutRender,
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",

      render: (data) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handleIsPublish(data)}
          />
        </div>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
      render: (data: any) => (
        <div className="flex gap-4 justify-center">
          <div onClick={() => handleEdit(data)}>
            <EditIcon size="w-6 h-6" />
          </div>
        </div>
      ),
    },
  ];
  return (
    <div>
      <GlobalTable
        loading={loading}
        tableName={"Manage Institutions"}
        columns={columns}
        data={instituteData}
      />
    </div>
  );
};

export default InstituteTable;
