import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const PackagesAPIs: ApiInterface[] = [
    {
        url: "/api/packages/add-package",
        method: "POST",
        name: "addPackage",
    },
    {
        url: "/api/packages/get-packages",
        method: "GET",
        name: "getPackages",
    },
    {
        url: "/api/packages/update-package-published-status-by-id",
        method: "PUT",
        name: "updatePackagePublishedStatusById",
    },
    {
        url: "/api/packages/get-package-by-id",
        method: "GET",
        name: "getPackageById",
    },
    {
        url: "/api/packages/update-package",
        method: "PATCH",
        name: "updatePackage",
    },
    {
        url: "/api/packages/update-package/:id",
        method: "PATCH",
        name: "updatePackageById",
    },
    //  tags apis
    {
        url: `/api/packages/updatePackageTags/:packageId/:tagId`,
        method: "PUT",
        name: "updatePackageTag",
        // contentType: "multipart/form-data",
      },
      {
        url: `/api/packages/updateIspublishedTags/:PlanId/:tagId`,
        method: "PUT",
        name: "updatePackageTagPublish",
      },
      {
        url: `/api/packages/addPackageTags/:PackageId`,
        method: "POST",
        name: "addPackageTag",
      },
    {
        url: "/api/packages/add-toggle-to-package",
        method: "POST",
        name: "addToggleToPackage",
    },
    {
        url: "/api/packages/update-toggle",
        method: "PUT",
        name: "updateToggle",
    },
    {
        url: "/api/packages/add-sku-to-package",
        method: "POST",
        name: "addSKUToPackage",
    },
    {
        url: "/api/packages/update-sku",
        method: "PUT",
        name: "updateSKU",
    },
    {
        url: "/api/packages/get-skus",
        method: "GET",
        name: "getSKUs",
    },
    {
        url: "/api/packages/handle-published-status-of-sku",
        method: "POST",
        name: "HandleSKUPublishedStatus",

    }
]

export function PackagesAPI(name: string, params: string[] = [""], data: any = null) {
    const api = PackagesAPIs.find((api) => api.name === name);
    if (!api) {
      throw new Error("API not found");
    }
  
    const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");
  
    
    return axios({
      method: api.method,
      data,
      withCredentials: true,
      url
    });
  }

