import { ApiInterface } from "../api-interface/types";

export const teamApis: ApiInterface[] = [
  {
    url: "/api/teams/get-teams-by-admin-site",
    method: "GET",
    name: "getAllTeamMembers",
  },
  {
    url: "/api/teams",
    method: "POST",
    name: "addTeamMember",
  },
  {
    url: "/api/teams/:id",
    method: "PUT",
    name: "updateTeamMember",
  },
  {
    url: "/api/teams/publishStatus/:id",
    method: "PUT",
    name: "updateTeamPublish",
  },
  // department apis
  {
    url: "/api/teams/getAllDepartments",
    method: "GET",
    name: "getAllDepartments",
  },
  {
    url: "/api/teams/addDepartment",
    method: "POST",
    name: "addDepartment",
  },
  {
    url: "/api/teams/deleteDepartment/:id",
    method: "DELETE",
    name: "deleteDepartment",
  },
  {
    url: "/api/teams/updateDepartment/:id",
    method: "PUT",
    name: "updateDepartment",
  },


];
