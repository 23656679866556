
import axios from "axios";
import { backendUrl } from "constants/config";

export function login(props: { username: string; password: string }) {
    var data = axios({
        method: "POST",
        data: {
            username: props.username,
            password: props.password,
        },
        withCredentials: true,
        url: backendUrl + "/admin/auth/login",
    });
    return data;
}


export function getLoggedInUser() {
    var data;
    data = axios({
        method: "GET",
        withCredentials: true,
        url: backendUrl + "/admin/auth/LoggedInUser",
    });
    return data;
}

//admin/auth/create-admin-user
export function createAdminUser(formData: any) {
    var data = axios({
        method: "POST",
        data: formData,
        withCredentials: true,
        url: backendUrl + "/admin/auth/create-admin-user",
    });
    return data;
}


export function logout() {
    var data;
    data = axios({
        method: "GET",
        withCredentials: true,
        url: backendUrl + "/logout",
    });
    return data;
}
