
import { Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import CustomDropDown from '../../../../shared-components/ui/CustomDropDown';
import { ButtonFill, ButtonOutlined } from '../../../../shared-components/ui/CustomButtons';
import { DeckGroupAPI } from '../../../../(apis)/(shared-apis)/academics/deckgroups-api';
import toast, { Toaster } from 'react-hot-toast';

export default function DeckGroupModal({ ShowModel, setShowModel, category, GetCategory, mode, editData, setEditData }) {

    const [disabled, setDisabled] = useState(false)


    const initialErrors = {
        deckGroupNameError: {
            error: false,
            message: "",
        },
        deckGroupImageError: {
            error: false,
            message: "",
        },
        deckCategoryError: {
            error: false,
            message: "",
        },
        deckTypeError: {
            error: false,
            message: "",
        }
    };

    useEffect(() => {
        if (mode === 'edit') {
            setFormData({
                deckGroupName: editData.deckGroupName,
                deckGroupDisclaimer: editData.deckGroupDisclaimer,
                deckGroupImage: editData.deckGroupImage,
                deckCategory: editData.deckCategory,
                deckType: editData.deckType,
                isPublished: editData.isPublished,
                province: editData.province,
                metadata: {
                    entity: process.env.REACT_APP_ENTITY,
                    category: process.env.REACT_APP_CATEGORY
                }
            })
        }
    }, [mode, editData])


    const [errors, setErrors] = useState(initialErrors);


    const [formData, setFormData] = useState({
        deckGroupName: "",
        deckGroupDisclaimer: "",
        deckGroupImage: "",
        deckCategory: "",
        deckType: "",
        isPublished: true,
        province: "",
        metadata: {
            entity: process.env.REACT_APP_ENTITY,
            category: process.env.REACT_APP_CATEGORY
        }
    })


    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked } = e.target;
        if (name === "isPublished") {
            setFormData({
                ...formData,
                [name]: checked,
            });
        } else {
            setFormData({
                ...formData,
                [name]: value,
            });
        }
    };

    const CheckValidity = () => {
        const newErrors = { ...errors };
        let isError = false;
        if (!formData.deckGroupName) {
            newErrors.deckGroupNameError.error = true;
            newErrors.deckGroupNameError.message = "Group Name is required";
            isError = true;
        } else {
            newErrors.deckGroupNameError.error = false;
            newErrors.deckGroupNameError.message = "";
        }
        if (!formData.deckGroupImage) {
            newErrors.deckGroupImageError.error = true;
            newErrors.deckGroupImageError.message = "Group Image is required";
            isError = true;
        } else {
            newErrors.deckGroupImageError.error = false;
            newErrors.deckGroupImageError.message = "";
        }
        if (!formData.deckType) {
            newErrors.deckTypeError.error = true;
            newErrors.deckTypeError.message = "Group Type is required";
            isError = true;
        } else {
            newErrors.deckTypeError.error = false;
            newErrors.deckTypeError.message = "";
        }
        setErrors(newErrors);
        return isError;
    };


    const Submit = async () => {
        CheckValidity()
        if (CheckValidity()) {
            return;
        }

        setDisabled(true)

        const data = {
            deckGroupName: formData.deckGroupName,
            deckGroupDisclaimer: formData.deckGroupDisclaimer,
            deckGroupImage: formData.deckGroupImage,
            deckCategory: category,
            deckType: formData.deckType,
            isPublished: formData.isPublished,
            province: formData.province,
            metadata: {
                entity: process.env.REACT_APP_ENTITY,
                category: process.env.REACT_APP_CATEGORY
            }
        }

        const Response = await DeckGroupAPI("addDeckGroup", "", data)
        if (Response.data.success) {
            toast.success(Response.data.message)
            setShowModel(false);
            setFormData({
                deckGroupName: "",
                deckGroupDisclaimer: "",
                deckGroupImage: "",
                deckCategory: "",
                deckType: "",
                isPublished: false,
                province: "",
                metadata: {
                    entity: process.env.REACT_APP_ENTITY,
                    category: process.env.REACT_APP_CATEGORY
                }
            })
            GetCategory()
        } else {
            toast.error(Response.data.message)
        }
        setDisabled(false)
    }

    const UploadImageAndConvertTobase64 = (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormData({
                ...formData,
                deckGroupImage: reader.result as string
            })
        };
    }

    const EditGroup = async () => {
        setDisabled(true)
        const data = {
            deckGroupName: formData.deckGroupName,
            deckGroupDisclaimer: formData.deckGroupDisclaimer,
            deckGroupImage: formData.deckGroupImage,
            deckCategory: category,
            deckType: formData.deckType,
            isPublished: formData.isPublished,
            province: formData.province,
            metadata: {
                entity: process.env.REACT_APP_ENTITY,
                category: process.env.REACT_APP_CATEGORY
            }
        }

        try {
            const Response = await DeckGroupAPI("updateDeckGroup", "", { payload: data, id: editData._id })
            if (Response.data.success) {
                toast.success(Response.data.message)
                setShowModel(false);
                setFormData({
                    deckGroupName: "",
                    deckGroupDisclaimer: "",
                    deckGroupImage: "",
                    deckCategory: "",
                    deckType: "",
                    isPublished: false,
                    province: "",
                    metadata: {
                        entity: process.env.REACT_APP_ENTITY,
                        category: process.env.REACT_APP_CATEGORY
                    }
                })
                GetCategory()
                setEditData({})
            } else {
                toast.error(Response.data.message)
            }
        }
        catch (err) {
            console.log(err)
            toast.error("An error occurred")
        }
        setDisabled(false)
    }


    const deckOptions = (process.env.REACT_APP_ENTITY === "UNDERGRADUATE" && process.env.REACT_APP_CATEGORY === "MEDSCHOOL")
        ? ['Viva', 'Proff', 'Module']
        : ['Yearly', 'Topical'];


    return (
        <>
            <Toaster />
            <div
                id="createProductModal"
                tabIndex={-1}
                aria-hidden="true"
                className={`${ShowModel ? "" : "hidden"
                    } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
            >
                <div className="relative w-full max-w-2xl max-h-full p-4">
                    <div className="relative p-4 bg-white rounded-lg shadow ">
                        <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
                            <h3 className="text-lg font-semibold text-gray-900 ">
                                {mode === 'edit' ? "Edit Deck Group" : "Add Deck Group"}
                            </h3>
                            <button
                                type="button"
                                className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                                data-modal-target="createProductModal"
                                data-modal-toggle="createProductModal"
                                onClick={() => {
                                    setShowModel(false);
                                }}
                            >
                                <svg
                                    aria-hidden="true"
                                    className="w-5 h-5"
                                    fill="currentColor"
                                    viewBox="0 0 20 20"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                                <span className="sr-only">Close modal</span>
                            </button>
                        </div>
                        <div className="space-y-3 mt-3">
                            <div>
                                <label className="block text-gray-900 ">
                                    <span>Deck  Group Name *</span>
                                </label>
                                <input
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                                    type="text"
                                    placeholder="Enter Group Name"
                                    name="deckGroupName"
                                    onChange={handleInputChange}
                                    value={formData.deckGroupName}
                                />
                                {errors.deckGroupNameError.error && (
                                    <span className="text-xs text-red-500">
                                        {errors.deckGroupNameError.message}
                                    </span>
                                )}
                            </div>

                            <div>
                                <label className="block text-gray-900 ">
                                    <span>Deck Group Disclaimer</span>
                                </label>
                                <textarea
                                    className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light h-15"
                                    placeholder="Enter Group Disclaimer"
                                    name="deckGroupDisclaimer"
                                    onChange={ (e: React.ChangeEvent<HTMLTextAreaElement>) => { setFormData({...formData, deckGroupDisclaimer: e.target.value})}}
                                    value={formData.deckGroupDisclaimer}
                                />
                            </div>

                            <div className="flex flex-col md:flex-row gap-2">
                                <CustomDropDown
                                    label='Please Select Province'
                                    options={['Punjab', 'Sindh', 'KPK', 'Balochistan', 'Gilgit Baltistan', 'AJK', 'Federal']}
                                    onSelect={(value) => {
                                        setFormData({
                                            ...formData,
                                            province: value
                                        })
                                    }}
                                    value={formData.province}
                                />


                                <CustomDropDown
                                    label="Select Deck Type"
                                    options={deckOptions}
                                    onSelect={(value) => {
                                        setFormData({
                                            ...formData,
                                            deckType: value,
                                        });
                                    }}
                                    value={formData.deckType}
                                    isError={errors.deckTypeError.error}
                                    errorMessage={errors.deckTypeError.message}
                                />
                            </div>

                            <div>
                                <label className="block text-gray-900 ">
                                    <span>Deck Group Image *</span>
                                </label>
                                <input
                                    className="w-full rounded  bg-transparent py-1.5 px-2 font-normal outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                                    type="file"
                                    placeholder="Enter Group Image"
                                    name="deckGroupImage"
                                    onChange={UploadImageAndConvertTobase64}
                                    accept='image/png, image/jpeg'
                                />
                                {
                                    errors.deckGroupImageError.error && (
                                        <span className="text-xs text-red-500">
                                            {errors.deckGroupImageError.message}
                                        </span>
                                    )
                                }
                            </div>

                            {formData.deckGroupImage && (
                                <div className="flex items-center space-x-2">
                                    <span>Preview</span>
                                    <img
                                        src={formData.deckGroupImage}
                                        alt="Group Image"
                                        className="w-10 h-10"
                                    />
                                </div>
                            )}

                            <div className="flex items-center space-x-2">
                                <input
                                    type="checkbox"
                                    name="isPublished"
                                    onChange={handleInputChange}
                                    checked={formData.isPublished}
                                />
                                <span>Is Published</span>
                            </div>

                            <div className="flex items-center justify-end gap-2">
                                <ButtonOutlined
                                    handleClick={() => {
                                        setEditData({})
                                        setFormData({
                                            deckGroupName: "",
                                            deckGroupDisclaimer: "",
                                            deckGroupImage: "",
                                            deckCategory: "",
                                            deckType: "",
                                            isPublished: false,
                                            province: "",
                                            metadata: {
                                                entity: process.env.REACT_APP_ENTITY,
                                                category: process.env.REACT_APP_CATEGORY
                                            }
                                        })
                                        setShowModel(false);
                                    }}
                                >
                                    Cancel
                                </ButtonOutlined>
                                {mode === 'add' ?
                                    <ButtonFill
                                        handleClick={Submit}
                                        disabled={disabled}
                                    >
                                        Add Group
                                    </ButtonFill> :
                                    <ButtonFill
                                        handleClick={EditGroup}
                                        disabled={disabled}
                                    >
                                        Update Group
                                    </ButtonFill>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
