import React from "react";
import CustomTabs from "shared-components/custom-tabs";
import Notes from "./notes";
import Lectures from "./lectures";
import Decks from "./decks";

interface ScheduleProps {
  scheduledData: any; // You should define a proper type based on your data
  id: string;
  loading: boolean;
  getScheduleData: () => void;
  setLoading: (loading: boolean) => void;
}

const ScheduleTabs: React.FC<ScheduleProps> = ({
  scheduledData,
  id,
  loading,
  getScheduleData,
  setLoading,
}) => {
  const items = [
    {
      key: "0",
      label: "Lectures",
      children: (
        <Lectures
          setLoading={setLoading}
          scheduledData={scheduledData}
          id={id}
          loading={loading}
          getScheduleData={getScheduleData}
        />
      ),
    },
    {
      key: "1",
      label: "Notes",
      children: (
        <Notes
          scheduledData={scheduledData}
          id={id}
          loading={loading}
          getScheduleData={getScheduleData}
        />
      ),
    },
    {
      key: "2",
      label: "Decks",
      children: (
        <Decks
          //   setLoading={setLoading}
          scheduledData={scheduledData}
          id={id}
          loading={loading}
          getScheduleData={getScheduleData}
        />
      ),
    },
  ];

  return <CustomTabs items={items} />;
};

export default ScheduleTabs;
