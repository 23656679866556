import { apiRequest } from '(apis)/api-interface/api-handler';
import { Typography } from '@mui/material';
import { notification } from 'antd';
import { useEffect, useState } from 'react';

const EditImagesModal = ({ image, setimages, showModel, setShowModel }) => {
  const [formData, setFormData] = useState({
    name:image.name,
    description:image.description,
    imageUpdates: [],
  });

  const [imagePreviewUrls, setImagePreviewUrls] = useState([]);
  const [errors, setErrors] = useState({
    nameError: {
      error: false,
      message: '',
    },
    descriptionError: {
      error: false,
      message: '',
    },
    imageFilesError: {
      error: false,
      message: '',
    },
  });

  useEffect(() => {
    setImagePreviewUrls(image.imageFiles || []);
  }, [image.imageFiles]);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    
    setFormData({
        ...formData,
        [name]: value
    });
};
  const handleFileChange = (event) => {
    const files = event.target.files;
    const newImageFiles = Array.from(files);
  
    Promise.all(newImageFiles.map((file:any) => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve(reader.result);
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    }))
      .then(base64DataArray => {
        setFormData({
          ...formData,
          imageUpdates: base64DataArray,
        });
        setImagePreviewUrls(base64DataArray); // Update image preview URLs
      })
      .catch(error => {
        console.error('Error reading file:', error);
      });
  };

  const checkValidity = () => {
    const newErrors = { ...errors };
    let isError = false;
    if (formData.name === "") {
      newErrors.nameError.error = true;
      newErrors.nameError.message = " Name is required";
      isError = true;
     }
    if (formData.description === "") {
      newErrors.descriptionError.error = true;
      newErrors.descriptionError.message = " Description is required";
      isError = true;
      }
    if (formData.imageUpdates.length === 0) {
    //   newErrors.imageFilesError.error = true;
    //   newErrors.imageFilesError.message = 'Image Files are required';
    //   isError = true;
    } else {
      newErrors.imageFilesError.error = false;
      newErrors.imageFilesError.message = '';
    }

    setErrors(newErrors);
    return isError;
  };

  const submit = async (e) => {
    e.preventDefault();
    if (checkValidity()) {
      return;
    }
  
    try {

      const base64DataArray = formData.imageUpdates.map(imageData => ({ imageData }));
      const data={
        name: formData.name,
        description: formData.description,
        imageUpdates:base64DataArray
      }
     // { imageUpdates: base64DataArray }
      const response = await apiRequest("updateImages",data,[image._id]);
      if (response.data.success) {
        notification.success({
          message: 'Success',
          description: response.data.message,
        });
        setFormData({
          name:"",
          description:"",
          imageUpdates: [],
        });
        setShowModel(false);
      } else {
        notification.error({
          message: 'Error',
          description: response.data.message,
        });
      }
      const Response2 = await apiRequest("getAllImageList");

    if (Response2.data.success) {
        setimages(Response2.data.data);
    }
    } catch (error) {
      console.error('Error uploading images:', error);
      if (error.response) {
        console.error('Server response data:', error.response.data);
        console.error('Server response status:', error.response.status);
      }
      notification.error({
        message: 'Error',
        description:
          error.response?.data.message ||
          'Failed to upload images. Please try again.',
      });
    }
  };
  

  return (
    <>
      <div
        id="addImageGalleryModal"
        tabIndex={-1}
        aria-hidden="true"
        className={`${
          showModel ? '' : 'hidden'
        } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen bg-gray-200/40`}
      >
        <div className="relative w-full max-w-2xl max-h-full p-4">
          <div className="relative p-4 bg-white rounded-lg shadow ">
            <div className="flex items-center justify-between pb-2 mb-2 border-b rounded-t sm:mb-5">
              <h3 className="text-lg font-semibold text-gray-900 ">
                 Update Images to Gallery
              </h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                data-modal-target="addImageGalleryModal"
                data-modal-toggle="addImageGalleryModal"
                onClick={() => {
                  setShowModel(false);
                }}
              >
                <svg
                  aria-hidden="true"
                  className="w-5 h-5"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
                <span className="sr-only">Close modal</span>
              </button>
            </div>
            <div className="space-y-3 mt-3">
              <div>
              <div>
                            <label className="block text-gray-900 ">
                              <span>Name *</span>
                           </label>
                          <input
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                               type="text"
                               name="name"
                               value={formData.name}
                             onChange={handleInputChange}
                         />
                         {errors.nameError.error && (
                            <span className="text-xs text-red-500">
                                 {errors.nameError.message}
                              </span>
                          )}
                      </div>
                      <div>
                            <label className="block text-gray-900 ">
                              <span>Details *</span>
                           </label>
                          <input
                              className="w-full rounded border-[1.5px] border-stroke bg-transparent py-1.5 px-2  outline-none transition focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiterdisabled:text-gray-400 disabled:font-light font-light"
                               type="text"
                               name="description"
                               value={formData.description}
                             onChange={handleInputChange}
                         />
                         {errors.descriptionError.error && (
                            <span className="text-xs text-red-500">
                                 {errors.descriptionError.message}
                              </span>
                          )}
                      </div>
                <label className="block text-gray-900 ">
                  <span>Image Files *</span>
                </label>
                <input
                  id="imageFileInput"
                  type="file"
                  accept="image/*"
                  multiple
                  onChange={handleFileChange}
                  style={{ margin: '10px 0' }}
                />
                <Typography variant="body2">Previews:</Typography>
                {imagePreviewUrls.map((previewUrl, index) => (
                  <img
                    key={index}
                    src={previewUrl}
                    alt={`Preview ${index}`}
                    style={{ maxWidth: '100%', marginBottom: 10 }}
                  />
                ))}
                {errors.imageFilesError.error && (
                  <span className="text-xs text-red-500">
                    {errors.imageFilesError.message}
                  </span>
                )}
              </div>
              <div className="flex items-center justify-end gap-2">
                <button
                  className="px-5 py-3 mt-2 text-gray-500 border bg-white rounded-md hover:bg-gray-600"
                  onClick={() => {
                    setShowModel(false);
                  }}
                >
                  Cancel
                </button>
                <button
                  className="px-5 py-3 mt-2 text-white bg-rose-500 rounded-md"
                  onClick={submit}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditImagesModal;
