
import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { PlusOutlined } from '@ant-design/icons';
import toast, { Toaster } from 'react-hot-toast';
import { CategoryAPI } from '../../../(apis)/(shared-apis)/academics/category-apis';
import { ButtonFill } from '../../../shared-components/ui/CustomButtons';
import DeckGroupModal from './components/add-deckgroup';
import { TableView } from './components/table-view';
import { DeckGroupInterface } from './interface';


export default function Main() {

    const { categoryName } = useParams()
    const [deckGroups, setDeckGroups] = useState<DeckGroupInterface[]>([])
    const [orginalDeckGroups, setOrginalDeckGroups] = useState<DeckGroupInterface[]>([])
    const [ShowModel, setShowModel] = useState(false)

    const GetCategory = async () => {
        const Response = await CategoryAPI("getCategoryDeckGroups", categoryName)
        setDeckGroups(Response?.data?.category?.category)
        setOrginalDeckGroups(Response.data.category.category)
    }

    useEffect(() => {
        GetCategory()
    }, [])

    const UpdateCategoryOrder = async () => {
        const orderOfIds = deckGroups.map((deckGroup) => deckGroup._id)
        const Response = await CategoryAPI("updateCategoryOrder", "", { categoryName, payload: orderOfIds })
        if (Response.data.success) {
            toast.success(Response.data.message)
            GetCategory()
        }
        else {
            toast.error(Response.data.message)
        }
    }

    const [mode, setMode] = useState('add')
    const [editData, setEditData] = useState({})



    return (
        <div className="w-[95%] mx-auto h-full">
            <div>
                <Toaster />
                <div className='flex flex-row flex-wrap items-center justify-between'>
                    <div className='flex flex-row flex-wrap items-center gap-2'>
                        <h1 className="text-3xl font-semibold text-gray-900">{categoryName}</h1>
                    </div>
                    <div className='flex flex-row gap-2'>
                        <ButtonFill
                            handleClick={UpdateCategoryOrder}
                            disabled={JSON.stringify(deckGroups) === JSON.stringify(orginalDeckGroups)}
                        >
                            Update Deck
                        </ButtonFill>
                        <ButtonFill
                            handleClick={() => {
                                setShowModel(true)
                                setMode('add')
                            }}
                            icon={<PlusOutlined />}
                        >
                            Add Deck Group
                        </ButtonFill>
                    </div>
                </div>
            </div>
            
            <TableView
                deckGroups={deckGroups}
                setDeckGroups={setDeckGroups}
                categoryName={categoryName}
                setShowModel={setShowModel}
                setMode={setMode}
                setEditData={setEditData}
                GetCategory={GetCategory}
            />

            <DeckGroupModal 
                ShowModel={ShowModel} 
                setShowModel={setShowModel} 
                category={categoryName} 
                GetCategory={GetCategory} 
                mode={mode} 
                editData={editData} 
                setEditData={setEditData} 
            />

        </div>


    )
}

