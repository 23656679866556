import { DeckGroupAPI } from '../../../(apis)/(shared-apis)/academics/deckgroups-api'
import { Select, notification } from 'antd'
import React, { useEffect, useState } from 'react'
import { DndProvider, useDrag, useDrop } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import toast, { Toaster } from 'react-hot-toast'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useParams } from 'react-router-dom'
import { CopyIcon, EditIcon, EyeIcon, ManageIcon } from '../../../shared-components/ui/Icons'
import CustomBadges from '../../../shared-components/ui/CustomBadges'
import { Switcher } from '../../../shared-components/ui/CustomForm'
// import { AddDeck, EditDeck, handlePublish } from '../../APIs/DeckAPI'
// import { GetDecksOfDeckGroup, updateDeckGroupOrderApi } from '../../APIs/DeckGroupAPI'
// import Navigation from '../../Components/Navigation'
// import { categoryValues, entityValues } from '../../Pages/Plans/components/constants'
// import { getDistintTags } from '../../APIs/PlanAPI'
import TableContainer from './components/table-view'
import { ButtonFill } from '../../../shared-components/ui/CustomButtons'
import DeckAddModal from './components/add-deck-modal'


export default function Main() {
    const { deckGroupName, categoryName } = useParams()

    const [isLoading, setIsLoading] = useState(false)

    const [decks, setDecks] = useState([])
    const [originalDecks, setOriginalDecks] = useState([])
    const [ShowModel, setShowModel] = useState(false);

    const GetDecks = async () => {
        const Response = await DeckGroupAPI("getDecksOfDeckGroup", categoryName + "/" + deckGroupName)
        setDecks(Response.data.data.decks)
        setOriginalDecks(Response.data.data.decks)
    }

    useEffect(() => {
        GetDecks()
    }, [])

    const moveQuestion = (fromIndex: number, toIndex:number) => {
        const updatedDecks = [...decks];
        const [movedDecks] = updatedDecks.splice(fromIndex, 1);
        updatedDecks.splice(toIndex, 0, movedDecks);
        setDecks(updatedDecks);
    };


    const [mode, setMode] = useState('add')
    const [populated, setPopulated] = useState(null)


    const UpdateOrder = async () => {
            const orderOfIds = decks.map((deck) => deck._id)
            const Response = await DeckGroupAPI("updateDeckGroupOrder","", { deckGroupName, payload:orderOfIds })
            if (Response.data.success) {
              toast.success(Response.data.message)
              GetDecks()
            }
            else {
                toast.error(Response.data.message)
            }
    }

    return (
        <div className="w-[95%] mx-auto h-full">
            <Toaster />
            <div className='flex flex-row flex-wrap items-center justify-between'>
                <h1 className="text-3xl text-gray-700 font-bold">Decks Manager</h1>
                <div className='flex flex-row gap-2'>
                    <ButtonFill
                        handleClick={UpdateOrder}
                        disabled={JSON.stringify(decks) === JSON.stringify(originalDecks)}
                    >
                        Update Order
                    </ButtonFill>
                    <ButtonFill 
                        handleClick={() => {
                            setShowModel(true)
                            setMode('add')
                        }}
                    >
                        Add Deck
                    </ButtonFill>
                </div>
            </div>

            <section className="my-5 antialiased">
                <div className="mx-auto">
                    {isLoading ? (
                        "Loading..."
                    ) : (
                        <div className="bg-white shadow-md sm:rounded-lg">
                            <TableContainer
                                decks={decks}
                                setDecks={setDecks}
                                GetDecks={GetDecks}
                                moveQuestion={moveQuestion}
                                setMode={setMode}
                                prepopulateData={populated}
                                setPopulated={setPopulated}
                                setShowModel={setShowModel}
                                deckGroupName={deckGroupName}
                                categoryName={categoryName}
                            />
                        </div>
                    )}
                </div>
            </section>


            <DeckAddModal 
                deckGroupName={deckGroupName} 
                ShowModel={ShowModel}
                setShowModel={setShowModel} 
                GetDecks={GetDecks} 
                categoryName={categoryName} 
                mode={mode} 
                prepopulateData={populated} 
                setPopulated={setPopulated} 
                setMode={setMode} 
            />

        </div>
    )
}
