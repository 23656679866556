import { getBase64ImageData } from "./functions";

const identifyAndConvertNewImagesToBase64 = async (data: any) => {
  console.log("data", data);  
  const base64ThumbnailImage = await Promise.all(
    data
      ?.filter((file) => !file?.isOldFile)
      ?.map(async (file) => {
        if (!file?.url && !file?.preview) {
          return await getBase64ImageData(file.originFileObj);
        }
        return file?.preview || file?.url;
      })
  );
  return base64ThumbnailImage;
};

export default identifyAndConvertNewImagesToBase64;
