import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

interface QuarterlySignUpsBarChartProps {
  data: {
    _id: string;
    total: number;
  }[];
}

const QuarterlySignUpsBarChart: React.FC<QuarterlySignUpsBarChartProps> = ({ data }) => {
  return (
    <BarChart
      width={800}
      height={400}
      data={data}
      margin={{
        top: 20, right: 30, left: 20, bottom: 5,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="_id" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="total" fill="#8884d8" />
    </BarChart>
  );
};

export default QuarterlySignUpsBarChart;
