import { Image, message } from "antd";
import React from "react";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import { columns } from "./columns";
import { EssentialStuffData, EssentialTableProps } from "./types";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import { generateAntdPdfObjectFromUrl } from "utility/form/generate-antd-pdf-object-from-url";

const EssentialTable: React.FC<EssentialTableProps> = ({
  handleEssentialIsPublish,
  loading,
  essentialStuffData,
  setShowModel,
  formik,
  isEditMode,
  ShowModel,
  setIsEditMode,
}) => {
  const { resetForm, setValues } = formik;

  const handleEdit = (data: EssentialStuffData) => {
    resetForm();
    console.log("data", data);
    const { _id, imageUrl, pdfUrl , expandData} = data;
    const thb = generateAntdImageObjectFromUrl(imageUrl)
    const pdfFormated = generateAntdPdfObjectFromUrl(pdfUrl)
    setValues({
      ...data,
      description:expandData,
      essentialId: _id,
      image: thb ? [thb] : null,
      pdf: pdfFormated ? [pdfFormated] : null,
    });
    setIsEditMode(true);
    setShowModel(true);
  };

  const handleViewRedirect = (data: EssentialStuffData) => {
    const { pdfUrl, imageUrl } = data;
    if (!pdfUrl && !imageUrl) {
      message.error("No file uploaded");
    } else if (pdfUrl) {
      window.open(pdfUrl);
    } else if (imageUrl) {
      window.open(imageUrl);
    }
  };

  columns?.forEach((column:any) => {
    switch (column.dataIndex) {
      case "imageUrl":
        column.render = (pictureUrl: string) => {
          if (pictureUrl) {
            return (
              <Image
                width={50}
                height={50}
                className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
                src={pictureUrl}
              />
            );
          }
          return null;
        };
        break;
  
      case "isPublished":
        column.render = (data: boolean, record: EssentialStuffData) => (
          <div className="flex justify-center">
            <GlobalPublishButton
              isActive={data}
              onChange={() => handleEssentialIsPublish({ id: record._id })}
            />
          </div>
        );
        break;
  
      case "actions":
        column.render = (text,data: EssentialStuffData) => (
          <div className="flex gap-4 justify-center">
            <div onClick={() => handleEdit(data)}>
              <EditIcon size="w-6 h-6" color="primary" />
            </div>
            <div onClick={() => handleViewRedirect(data)}>
              <EyeIcon size="w-6 h-6" />
            </div>
          </div>
        );
        break;
  
      default:
        break;
    }
  });

  const formatedEssentialStuffData = essentialStuffData?.map((item, i) => ({
    key: i + 1,
    ...item,
    expandData: item?.description,
    description:
      item?.description?.length > 10
        ? `${item?.description.slice(0, 10)}...`
        : item?.description,
    id: item?._id,
    isPublished: item?.isPublished,
  }));

  return (
    <div>
      <GlobalTable
        loading={loading}
        columns={columns}
        data={formatedEssentialStuffData}
      />
    </div>
  );
};

export default EssentialTable;
