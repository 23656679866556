import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast';
import { Label } from 'recharts';
import { ButtonFill, ButtonOutlined } from 'shared-components/ui/CustomButtons';
import { Input, Switcher } from 'shared-components/ui/CustomForm';

export const EditToggleModal = (props) => {

    const [formData, setFormData] = useState({
        _id: "",
        toggleValue: "",
        discountAmount: "",
        active: true,
        position: 1000,
        showDate: false,
        endDate: new Date(),
        numberOfMonths: 0
    })

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (props.selectedToggle) {
            setIsLoading(true);
            setFormData(props.selectedToggle);

            if (typeof props.selectedToggle.numberOfMonths === "undefined" || props.selectedToggle.numberOfMonths === null) {
                setFormData({
                    ...props.selectedToggle,
                    showDate: true,
                    endDate: props.selectedToggle.endDate ? new Date(props.selectedToggle.endDate) : new Date()
                });
            } else {
                setFormData({
                    ...props.selectedToggle,
                    showDate: false,
                    numberOfMonths: props.selectedToggle.numberOfMonths
                });
            }

            setIsLoading(false);
        } 
    }, [props.selectedToggle]);

    const Submit = async () => {
        try {
            if (!formData.toggleValue || formData.toggleValue === "") {
                toast.error("Toggle Value is required");
                return;
            }
            if (!formData.discountAmount || formData.discountAmount === "") {
                toast.error("Discount Amount is required");
                return;
            }
            if (!formData.position || formData.position < 0) {
                toast.error("Position is required");
                return;
            }
            formData.toggleValue = formData.toggleValue.trim();
            formData.discountAmount = formData.discountAmount.trim();
            const response = await PackagesAPI("updateToggle", [props.packageId], formData);
            if (response.data.success) {
                toast.success("Toggle Updated Successfully");
                props.setModal(false);
                props.fetchPackage();
            }
            else {
                toast.error("Failed to update Toggle");
            }
        } catch (error) {
            toast.error("Failed to update Toggle");
        }

    }

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value
        })
    }



    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Edit Toggle
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>

                    {isLoading ? <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
                        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{ top: "50%" }}>
                            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
                        </span>
                    </div> :
                    <div className='grid grid-cols-1 md:grid-cols-2 gap-4'>
                        <div className="space-y-2">
                            <Label> Toggle Value</Label>
                            <Input
                                type="text"
                                placeholder="Enter Toggle Value"
                                value={formData?.toggleValue}
                                name='toggleValue'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label> Discount Amount</Label>
                            <Input
                                type="text"
                                placeholder="Enter Discount Amount"
                                value={formData?.discountAmount}
                                name='discountAmount'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className="space-y-2">
                            <Label> Position</Label>
                            <Input
                                type="number"
                                placeholder="Enter Position"
                                value={formData?.position}
                                name='position'
                                onChange={handleInputChange}
                            />
                        </div>
                        <div className='flex items-center justify-between gap-2'>
                            <div className="text-sm text-gray-500">Active Status</div>
                            <Switcher
                                isActive={undefined}
                                for={formData?._id}
                                onChange={() => {
                                    setFormData({
                                        ...formData,
                                        active: !formData?.active
                                    })
                                }}
                                togglevalue={formData?.active}
                                size="normal"
                            />
                        </div>
                        <div className='flex items-center justify-between gap-2'>
                            <div className="text-sm text-gray-500">Change Date</div>
                            <Switcher
                                isActive={undefined}
                                for={formData?._id + "date"}
                                onChange={() => {
                                    setFormData({
                                        ...formData,
                                        showDate: !formData?.showDate
                                    })
                                }}
                                togglevalue={formData?.showDate}
                                size="normal"
                            />
                        </div>
                        {formData?.showDate ?
                            <div className="space-y-2">
                                <Label>End Date </Label>
                                <Input
                                    type="date"
                                    placeholder="Enter End Date"
                                    value={formData?.endDate}
                                    name="endDate"
                                    onChange={handleInputChange}
                                />
                            </div>
                            :
                            <div className="space-y-2">
                                <Label>Number of Months </Label>
                                <Input
                                    type="number"
                                    placeholder="Enter Number of Months"
                                    value={formData?.numberOfMonths}
                                    name="numberOfMonths"
                                    onChange={handleInputChange}
                                />
                            </div>
                        }


                    </div>
                    }

                    <div className="flex items-center justify-end gap-2">
                        <ButtonOutlined
                            handleClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            Cancel
                        </ButtonOutlined>

                        <ButtonFill handleClick={Submit}>
                            Edit Toggle
                        </ButtonFill>
                    </div>

                </div>
            </div>
        </div>
    )
}
