import { PackagesAPI } from "(apis)/(shared-apis)/packages-apis";
import { useState } from "react";
import toast from "react-hot-toast";
import {
    ButtonFill,
    ButtonOutlined,
} from "../../../shared-components//ui/CustomButtons";
import { Input, Label } from "../../../shared-components//ui/CustomForm";
import { CustomBundleAPI } from "(apis)/(shared-apis)/custom-bundle-apis";

interface DisciplineFormProps {
    _id?: string;
    board: string;
    position: number;
    metadata: {
        entity: string;
        category: string;
    };
    // fetchPackages?: () => void;
}


export const DisciplineForm = (props) => {
    const InitialErrors = {
        board: {
            error: false,
            message: "",
        },
        position: {
            error: false,
            message: "",
        },
    };
    const [errors, setErrors] = useState(InitialErrors);
    const initialState = {
        board: "",
        position: 1000,
        metadata: {
            entity: process.env.REACT_APP_ENTITY,
            category: process.env.REACT_APP_CATEGORY,
        },
    };
    const [formData, setFormData] = useState<DisciplineFormProps>(initialState);
    const [isLoading, setIsLoading] = useState(false);

    const validateForm = () => {
        const newErrors = { ...errors };
        let isError = false;
        if (!formData.board) {
            newErrors.board.error = true;
            newErrors.board.message = "Board Name is required";
            isError = true;
        } else {
            newErrors.board.error = false;
            newErrors.board.message = "";
        }
        setErrors(newErrors);
        return isError;
    };

    const handleInputChange = (name: string, value: string) => {
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const [disableCreateButton, setDisableCreateButton] = useState(false);
    const resetAll = () => {
        setFormData(initialState);
        props?.setModal(false);
    };

    const Submit = async () => {
        if (validateForm()) {
            return;
        }
        setDisableCreateButton(true);

        try {
            setIsLoading(true);
            let response = null;
            response = await CustomBundleAPI("addCustomBundle", [], formData);
            if (response.data.success) {
                toast.success("Added Successfully 🚀");
                resetAll();
                props?.fetchPackages();
            }
            else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.log("Add Discipline Error: ", error);
        } finally {
            setIsLoading(false);
        }

        setDisableCreateButton(false);
    };


    return (
        <div
            id="createProductModal"
            tabIndex={-1}
            aria-hidden="true"
            className={`${props?.active ? "" : "hidden"
                } overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[1000]  flex justify-center items-center w-full h-screen backdrop-blur-sm !space-x-0`}
        >
            <div className="relative w-full max-w-2xl max-h-full p-4">
                <div className="relative p-4 bg-white rounded-lg shadow ">
                    <div className="flex items-center justify-between pb-4 mb-4 border-b rounded-t sm:mb-5">
                        <h3 className="text-lg font-semibold text-gray-900 ">
                            Add New Plan
                        </h3>
                        <button
                            type="button"
                            className="text-gray-400 bg-transparent hover.bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center "
                            data-modal-target="createProductModal"
                            data-modal-toggle="createProductModal"
                            onClick={() => {
                                props?.setModal(false);
                            }}
                        >
                            <svg
                                aria-hidden="true"
                                className="w-5 h-5"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="evenodd"
                                    d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                                    clipRule="evenodd"
                                />
                            </svg>
                            <span className="sr-only">Close modal</span>
                        </button>
                    </div>
                    <div className="space-y-2">
                        <div className="space-y-2">
                            <Label>Plan Name</Label>
                            <Input
                                type="text"
                                placeholder="Enter Plan Name"
                                value={formData.board}
                                name="board"
                                onChange={handleInputChange}
                                isError={errors.board.error}
                                errorMessage={errors.board.message}
                            />
                        </div>

                        <div className="space-y-2">
                            <Label>Position</Label>
                            <Input
                                type="number"
                                placeholder="Enter Position"
                                value={formData.position}
                                name="position"
                                onChange={handleInputChange}
                                isError={errors.position.error}
                                errorMessage={errors.position.message}
                            />
                        </div>

                        <div className="flex items-center justify-end gap-2">
                            <ButtonOutlined
                                handleClick={() => {
                                    props?.setModal(false);
                                }}
                            >
                                Cancel
                            </ButtonOutlined>

                            <ButtonFill isLoading={isLoading} handleClick={Submit} disabled={disableCreateButton}>
                                Create Custom Bundle
                            </ButtonFill>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default DisciplineForm;
