import * as Yup from "yup";
export const validationSchema = Yup.object().shape({
    pdf: Yup.mixed()
      .required("Pdf is required")
      .test("filepdf", function (value) {
        if (value) {
          const fileType = value && value?.[0]?.type;
          if (!["application/pdf"].includes(fileType)) {
            return this.createError({
              message: "Please upload a PDF file.",
            });
          }
        }
        return true;
      }),
    thumbnailImage: Yup.mixed()
      .required("Thumbnail image is required")
      .test("is-valid-type", "Invalid image type", function (value) {
        if (!value) return true; // Skip validation if no value provided
        const newVal = value?.[0]?.type;
        return ["image/jpeg", "image/jpg", "image/png"].includes(
          newVal?.toLowerCase()
        );
      }),
    // paginations: Yup.array().of(
    //   Yup.object().shape({
    //     subTopic: Yup.string().required("Subtopic is required"),
    //     startPageNo: Yup.number().required("Page number is required"),
    //     endPageNo: Yup.number().nullable().test("greater", "End page number should be greater than start page number", function (value) {
    //       const { startPageNo } = this.parent;
    //       if (value < startPageNo) {
    //         return false;
    //       }
    //       return true
    //     }),
    //   })
    // ),
    subject: Yup.string().required("Subject is required"),
    board: Yup.string().required("Board is required"),
    access: Yup.string().required("Access is required"),
    universities: Yup.array()
      .of(Yup.string())
      .nullable()
      .test("universities", "Universities is required", function (value) {
        if (
          process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
        ) {
          return value?.length > 0;
        }
        return true;
      }),
    year: Yup.string()
      .nullable()
      .test("year", "Year is required", function (value) {
        if (
          process.env.REACT_APP_CATEGORY === "MEDSCHOOL"
        ) {
          return !!value;
        }
        return true;
      }),
  });