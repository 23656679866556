interface PageHeaderProps {
    mode?: string;
    PopulateStates?: () => void;
    form?: any;
    setForm?: React.Dispatch<React.SetStateAction<any>>;
}

export default function PageHeader({ mode, PopulateStates, form, setForm }: PageHeaderProps) {
    return (
        <div className="mx-auto p-12 pt-2 pb-2">
            <div className='flex flex-row flex-wrap items-center justify-between'>
                <div className='flex flex-row flex-wrap items-center gap-2'>
                    <h1 className="text-2xl text-gray-700 font-bold">
                        {mode === 'edit' ? 'Edit Question' : 'Add New Question'}
                    </h1>
                </div>

                <div className='flex flex-row flex-wrap items-center justify-end gap-2'>
                    {mode !== 'edit' &&
                        <button className="bg-rose-500 hover:bg-rose-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                            PopulateStates()
                        }
                        }>
                            Retrieve Freezed
                        </button>}

                    {mode !== 'edit' &&
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded" onClick={() => {
                            setForm({ ...form, isSAQ: !form.isSAQ })
                        }
                        }>
                            {form.isSAQ ? 'SAQ' : 'MCQ'}
                        </button>
                    }
                </div>

            </div>
        </div>
    );
}
