import { apiRequest } from "(apis)/api-interface/api-handler";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ApiResponseTypeData,
  DoubtResponseType,
  ExpertTableInterface,
} from "./types";
import { ExpertColumn } from "./expert-column";
import { AxiosResponse } from "axios";
import GlobalTable from "shared-components/table/GlobalTable";
import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import DoubtQuestion from "../../components/doubt-question";

const Experts = () => {
  const [loading, setLoading] = useState<boolean>(false);

  const [data, setData] = useState<ExpertTableInterface[]>([]);
  const [viewDoubt, setViewDoubt] = useState<string | null>(null);
  const [DoubtDisplay, SetDoubtDisplay] = useState<DoubtResponseType | null>(
    null
  );
  const column = useMemo<typeof ExpertColumn>(() => {
    let actions = {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      width: "100px",
      render: (_: any, record: ExpertTableInterface) => (
        <Button
          size="middle"
          icon={<PlusOutlined size={24} />}
          type="text"
          className="bg-red-500 hover:!bg-red-400 text-white"
          onClick={() => {
            handleDoubtImage(record?.id);
          }}
        >
          View
        </Button>
      ),
    };
    return ExpertColumn.concat(actions);
  }, []);

  const moveWordToEnd = (tags: string[], word: string): string[] => {
    const filteredTags = tags.filter(
      (tag) => tag.toLocaleLowerCase() !== word.toLocaleLowerCase()
    );
    const wordCount = tags.length - filteredTags.length;
    return [...filteredTags, ...Array(wordCount).fill(word)];
  };

  const formatData = useCallback(
    (res: AxiosResponse<ApiResponseTypeData[]>) => {
      return res?.data?.map((item) => ({
        tags: moveWordToEnd(
          [item.resource, item.subject, item.topic].filter(
            (i) => i !== null && i !== undefined
          ),
          "Others"
        ),
        source: item?.questionID ? "Premed QBank" : "Own Question",
        description:
          item.description?.length > 30
            ? item.description.slice(0, 30) + "..."
            : item.description,
        id: item?._id,
      }));
    },
    []
  );

  useEffect(() => {
    setLoading(true);
    apiRequest<ApiResponseTypeData[]>("fetchDoubts")
      .then((res) => {
        const formattedData = formatData(res);
        setData(formattedData);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  }, [formatData]);

  const handleDoubtImage = (id: string): void => {
    setViewDoubt(id);
    getImage(id);
  };

  const getImage = (id: string): void => {
    apiRequest<DoubtResponseType>(`GetImage`, { id })
      .then((res) => {
        SetDoubtDisplay(res?.data);
        // const imageSrc = ImageToBlob(res?.data?.img as string);
        // SetImage(imageSrc);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {!viewDoubt && (
        <GlobalTable
          columns={column}
          data={data}
          loading={loading}
          tableName="Experts"
          // isExpandable
        />
      )}
      {viewDoubt && (
        <DoubtQuestion
          DoubtDisplay={DoubtDisplay}
          setViewDoubt={setViewDoubt}
          setDoubtDisplay={SetDoubtDisplay}
        />
      )}
    </div>
  );
};

export default Experts;
