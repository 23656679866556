
import { ApiInterface } from "../api-interface/types";

export const subjectapis: ApiInterface[] = [
    {
        url: "/api/subject",
        method: "GET",
        name: "getallsubjects",
    },
    {
        url: "/api/subject",
        method: "GET",
        name: "getSubjects",
    }
]