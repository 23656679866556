import { ApiInterface } from "../api-interface/types";

export const imageGalleryApis: ApiInterface[] = [
  {
    url: "/api/customer-assosiates/add-gallary-image",
    method: "POST",
    name: "addImages",
  },
  {
    url: "/api/customer-assosiates/get-all-gallary-images",
    method: "GET",
    name: "getAllImageList",
  },
  {
    url: "/api/customer-assosiates/delete-gallary-image/:id",
    method: "DELETE",
    name: "deleteimageList",
  },
  {
    url: "/api/customer-assosiates/updatestatus/:id",
    method: "PUT",
    name: "updatestatusvalue",
  },
  {
    url: "/api/customer-assosiates/updateImages/:id",
    method: "PUT",
    name: "updateImages",
  },
];
