import { apiRequest } from '(apis)/api-interface/api-handler';
import { message } from 'antd';
import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import CommonFAQS from 'shared-components/common-faqs';
import { UserContext } from 'state-management/user-context';
const FAQs = ({ id,fetchPackage,packageData}) => {
  const {user} = useContext(UserContext);
  const history = useNavigate();
  // Formik setup
const formik  = useFormik({
    initialValues: {
      faqs: [{ question: '', answer: '' }],
    },
    onSubmit: async (values, { resetForm }) => {
      try {
        if (!user?._id) {
          message.error('Please Login first');
          history('/login');
          return;
        }
        const { faqs } = values;
        const faqData = faqs.map(faq => ({
          question: faq.question,
          answer: faq.answer
        }));
        // Update course API call
        const res = await apiRequest("updatePackageById",{faqs:faqData}, [id]);
        if (res?.status === 200) {
          message.success(res?.data?.message);
          fetchPackage();
          resetForm();
        }
      } catch (error) {
        console.error("Error:", error);
        message.error(error?.response?.data?.message || error.message);
      }
    },
  });
  const {
    setValues,
  } = formik;
  useEffect(() => {
    if(packageData?.faqs?.length >0){
      setValues({
        faqs:packageData?.faqs || [{ question: '', answer: '' }],
      });

    }
  }, [packageData, setValues]);


  return (
    <div className='m-0'>
    
    <CommonFAQS
      formik={formik}
      data={packageData} />
    
    </div>
  );
};

export default FAQs;
