export default function HintsView({ hints, setForm, form }) {
    return (
        <>
            {hints?.length > 0 && (
                <div className='flex flex-row flex-wrap items-center gap-2'>
                    <h6 className="text-md font-bold text-rose-500 mt-2">Hints: </h6>
                    <div className='flex flex-row flex-wrap items-center gap-2'>
                        {hints.map((hint: string, index: number) => (
                            <div key={index} className='inline-flex flex-wrap items-center px-4 py-1 rounded-sm text-sm font-medium bg-rose-400 text-rose-200'>
                                <span className="mb-1">{hint}</span>
                                <button className='ml-2
                                flex items-center justify-center
                                ' onClick={() => { setForm({ ...form, hints: form?.hints.filter((item) => item !== hint) }) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 text-white hover:text-gray-900 transition ease-in-out duration-150">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                                    </svg>
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </>
    )
}
