import { ApiInterface } from "../../interfaces/global-interfaces";
import axios from "axios";
import { backendUrl } from "../../constants/config";

export const UserAPIs: ApiInterface[] = [
  {
    url: "/api/user/get-users-for-conversions",
    method: "GET",
    name: "getUsersForConversions",
  },
  {
    url: "/api/user/get-users-referrals",
    method: "GET",
    name: "getUsersReferrals",
  },
  {
    url: "/api/user/getUserbyEmail",
    method: "POST",
    name: "getUserByEmail",
  },
  {
    url: "/api/user/resetPassword",
    method: "PUT",
    name: "resetPassword",
  },
  {
    url: "/api/user/delete-purchased-user-plan",
    method: "DELETE",
    name: "deletePurchasedUserPlan",
  },
  {
    url: "/api/user/delete-purchased-user-subscription",
    method: "DELETE",
    name: "deletePurchasedUserSubscription",
  },
  {
    url: "/api/user/add-manual-access",
    method: "POST",
    name: "addManualAccess",    
  }
]

/*

export async function updateCoins(payload) {
  try {
    return axios.put(`${url}/updateCoins`, payload);
  } catch (error) {
    console.log("Failed to update coins");
    return error;
  }
}

export async function updateBundles(payload) {
  try {
    return axios.put(`${url}/updateBundles`, payload);
  } catch (error) {
    console.log("Failed to update bundles");
    return error;
  }
}


export async function getUserForChanges( payload ) {
  return axios.post(`${url}/get-all-users-for-changes` , payload);
}

export async function updateUserSubs( payload ) {
  return axios.post(`${url}/update-user-subs` , payload);
}

export async function updateUserFeaturesOfSelectedSKU( payload ) {
  return axios.post(`${url}/update-user-features-of-selected-sku` , payload);
}

export async function addUserTagOfSelectedSKU( payload ) {
  return axios.post(`${url}/add-user-tag-of-selected-sku` , payload);
}

export async function getUserForConversions() {
  return axios.get(`${url}/get-users-for-conversions`);
}


export async function getUserReferrals() {
  return axios.get(`${url}/get-users-referrals`);
}

export async function giveManualAccessUsers(payload) {
  return axios.post(`${url}/add-manual-access`, payload);
}

*/
export function UserAPI(name: string, params: string[] = [""], data: any = null) {
  const api = UserAPIs.find((api) => api.name === name);
  if (!api) {
    throw new Error("API not found");
  }

  const url = backendUrl + api.url + (params ? "/" + params.join("/") : "");

  console.log("data", url);

  return axios({
    method: api.method,
    data,
    withCredentials: true,
    url
  });
}

