import { PlusOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { apiRequest } from "(apis)/api-interface/api-handler";
import { message } from "antd";
import CustomButton from "shared-components/form-fields/custom-button";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import InstituteModal from "./modal";
import InstituteTable from "./table";
const ManageInstitution = () => {
  const [instituteData, setInstitutesData] = useState([]);
  const [showModal, setShowModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const getInstituteData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getinstitutes");
      if (res?.data?.isSuccess) {
        const resData = res?.data?.institutes;
        if (resData?.length > 0) {
          const formatedData = resData.map((item) => {
            return {
              ...item,
              entity: item?.metadata?.entity,
              category: item?.metadata?.category,
              instituteName: item?.instituteName,
              key: item._id,
              isPublished: {
                isPublished: item?.isPublished,
                id: item._id,
              },
            };
          });
          setInstitutesData(formatedData);
        } else {
          setInstitutesData([]);
          message.error("No data found");
        }
        setLoading(false);
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    getInstituteData();
  }, []);
  const formik = useFormik({
    initialValues: {
      entity: "",
      category: "",
      instituteName: "",
      instituteEditId: "",
      logo: "",
      shortName: "",
      city: "",
    },
    validationSchema: Yup.object({
    
      instituteName: isEditMode
        ? Yup.string()
            .required("Institute is required")
            .test("unique", "Note name already exist.", function (value) {
              const existingExceptCurrent = instituteData?.filter(
                (instituteEditId) =>
                  instituteEditId._id !== values?.instituteEditId
              );
              const existingNames = existingExceptCurrent
                ?.map(
                  (note) =>
                    note.category === values?.category
                      ? note.instituteName
                      : null
                  // (note) => note.instituteName
                )
                .filter((val) => val !== null);
              return !existingNames?.includes(value);
            })
        : Yup.string()
            .required("Institute is required")
            .test("unique", "Institute name already exist.", function (value) {
              const existinginstituteNames = instituteData
                ?.map(
                  (note) =>
                    note.category === values?.category
                      ? note.instituteName
                      : null
                  // (note) => note.instituteName
                )
                .filter((val) => val !== null);

              return !existinginstituteNames?.includes(value);
            }),
      logo: Yup.mixed()
        .required("Logo is required")
        .test("is-valid-type", "Invalid image type", function (value) {
          const newVal = value?.[0]?.type;
          if (!value?.[0]) {
            return this.createError({
              message: "Please upload a logo image",
            });
          }
          if (newVal) {
            return ["image/jpeg", "image/jpg", "image/png"].includes(
              newVal?.toLowerCase()
            );
          }
          return true;
        }),
    }),
    onSubmit: async (values) => {
      try {
        const { instituteName, shortName, city, instituteEditId } = values;
        const base64logo = await identifyAndConvertNewImagesToBase64(values?.logo);
        console.log("base64logo", base64logo);
        const logoUrl = filteroutGeneratedImagesUrls(values?.logo);
        const payload = {
          logoUrl: logoUrl?.[0],
          logo: base64logo?.[0],
          instituteName,
          shortName,
          city,
          metadata: {
            entity: process.env.REACT_APP_ENTITY,
            category: process.env.REACT_APP_CATEGORY,
          },
        };
        let res;
        if (isEditMode) {
          res = await apiRequest("updateinstitute", payload, [instituteEditId]);
        } else {
          res = await apiRequest("addinstitute", payload);
        }
        if (res?.data?.isSuccess) {
          getInstituteData();
          setIsEditMode(false);

          setShowModel(false);
          formik.resetForm();
          message.success(res?.data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    },
  });
  const { values } = formik;
  return (
    <div>
      <div className="mx-4">
        <div className="flex justify-end mx-10">
          <CustomButton
            icon={<PlusOutlined />}
            text="Add New Institution"
            onClick={() =>{
              setShowModel(true)
              setIsEditMode(false)
              formik.resetForm()
            }}
          />
        </div>
        <InstituteTable
          getInstituteData={getInstituteData}
          formik={formik}
          setIsEditMode={setIsEditMode}
          setShowModel={setShowModel}
          loading={loading}
          instituteData={instituteData}
        />

        <InstituteModal
          showModal={showModal}
          setShowModel={setShowModel}
          formik={formik}
        />
      </div>
    </div>
  );
};

export default ManageInstitution;
