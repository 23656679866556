import { PlusOutlined } from "@ant-design/icons";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { Image, message } from "antd";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import Loader from "shared-components/Loader";
import GlobalTable from "shared-components/table/GlobalTable";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import { EditIcon, EyeIcon } from "shared-components/ui/Icons";
import { snapCoursesInitialValues } from "shared-pages/vault/components/initialvalues";
import { convertToFormData } from "utility/form/converttoformdata";
import filteroutGeneratedImagesUrls from "utility/form/filteroutGeneratedImagesUrls";
import { generateAntdImageObjectFromUrl } from "utility/form/generate-antd-image-object-from-url";
import identifyAndConvertNewImagesToBase64 from "utility/form/identifyAndConvertNewImagesToBase64";
import { columnsWithoutRender } from "./columns";
import SnapCourseModal from "./modal";
import { validationSchema } from "./schema";
import { Institute, Teacher, Topic } from "./types";
const SnapCourses: React.FC = () => {
  const [ShowModel, setShowModel] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [boardsData, setBoardData] = useState<Institute[]>([]);
  const [subjectData, setSubjectData] = useState<Institute[]>([]);
  const [allSubTopicsData, setAllSubTopicsData] = useState<Institute[]>([]);
  const [snapCoursesData, setSnapCoursesData] = useState([]);
  const [topicsData, setTopicsData] = useState<Institute[]>([]);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [originalTopisData, setOriginalTopisData] = useState<Topic[]>([]);
  const [institutesData, setInstitutesData] = useState<Institute[]>([]);
  const [teachersData, setTeacherData] = useState<Teacher[]>([]);


  const formik = useFormik({
    initialValues: snapCoursesInitialValues,
    validationSchema,
    onSubmit: async (values) => {
      try {
        const base64ThumbnailImage = await identifyAndConvertNewImagesToBase64(values?.thumbnailImage);
        const thumbnailImageUrl = filteroutGeneratedImagesUrls(values?.thumbnailImage);
        const updatedValues = {
          ...values,
          video: values?.video?.[0]?.originFileObj,
          thumbnailImageUrl: thumbnailImageUrl?.[0],
          thumbnailImage: base64ThumbnailImage?.[0],
          category: process.env.REACT_APP_CATEGORY,
          entity: process.env.REACT_APP_ENTITY,
        };
        const formData = convertToFormData(updatedValues);
        setLoading(true);
        let res;
        if (isEditMode) {
          res = await apiRequest("updateSnapCourse", formData, [
            values?.snapCourseId,
          ]);
        } else {
          res = await apiRequest("postSnapCourse", formData);
        }
        if (res?.status === 200) {
          setLoading(false);
          setIsEditMode(false);
          setShowModel(false);
          getSnapCoursesData();
          message.success(res?.data?.message);
          resetForm();
        }
      } catch (error: any) {
        console.error(error);
        message.error(error?.response?.data?.message);
        setLoading(false);
      }
    },
  });
  const {  setValues, resetForm } = formik;
  const getSnapCoursesData = async () => {
    try {
      setLoading(true);
      const res = await apiRequest("getAllSnaps");
      if (res?.data?.success) {
        const resData = res?.data?.data;
        setSnapCoursesData(resData);
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const [
        snapsRes,
        topicsRes,
        boardsRes,
        subjectsRes,
        teacherRes,
        institutesRes,
      ] = await Promise.all([
        apiRequest("getAllSnaps"),
        apiRequest("getalltopics"),
        apiRequest("gethierarchyboard"),
        apiRequest("getallsubjects"),
        apiRequest("getTeachers"),
        apiRequest("getinstitutes"),
      ]);

      if (snapsRes?.data?.success) {
        setSnapCoursesData(snapsRes?.data?.data);
      }
      if (topicsRes?.data?.data?.length > 0) {
        const topicsData = topicsRes?.data?.data;
        setOriginalTopisData(topicsData)
        const topicsOptions = topicsData?.map((topic: any) => ({
          value: topic?._id,
          label: topic?.topicName,
        }));
        let allSubTopics = [];

        // Iterate over each topic
        topicsData?.forEach((topic:any) => {
          // Iterate over each subtopic of the current topic
          topic?.subTopics?.forEach((subTopic) => {
            // Push the subtopic into the allSubTopics array
            allSubTopics?.push(subTopic);
          });
        });
        const formatedAllSubTopics = allSubTopics?.map((subTopic) => ({
          value: subTopic._id,
          label: subTopic.subTopicName,
        }));
        setAllSubTopicsData(formatedAllSubTopics);
        setTopicsData(topicsOptions);
        // setAllTopicsData(topicsRes?.data?.data);
        // setOriginalTopisData(topicsRes?.data?.data);
      }
 

      if (boardsRes?.data?.length> 0) {
        const boardOptions = boardsRes?.data?.map((board: any) => ({
          value: board?.board,
          label: board?.board,
        }));
        setBoardData(boardOptions);
      }
      
      if (subjectsRes?.data?.length > 0) {
        const subjectOptions = subjectsRes?.data?.map((subject: any) => ({
          value: subject?._id,
          label: subject?.name,
        }));
        setSubjectData(subjectOptions);
      }
      if ( teacherRes?.data?.teachers?.length > 0) {
        const teacherOptions = teacherRes?.data?.teachers?.map((teacher: any) => ({
          value: teacher?._id,
          label: teacher?.name,
        }));
        setTeacherData(teacherOptions);
      }

      if (institutesRes?.data?.isSuccess) {
        const instituteOptions = institutesRes?.data?.institutes?.map(
          (institute: any) => ({
            value: institute?._id,
            label: institute?.instituteName,
          })
        );
        setInstitutesData(instituteOptions);
      }

   
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      message.error(error?.response?.data?.message || error?.message);
      console.error(error);
    }
  };
  const handleViewRedirect = (data:any) => {
    const { videoUrl } = data;
    if (!videoUrl) {
      message.error("No file uploaded");
    } else if (videoUrl) {
      window.open(videoUrl);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleCourseModal = () => {
    resetForm();
    setIsEditMode(false);
    setShowModel(true);
  };

  const columns = [
    {
      title: "Thumbnail",
      dataIndex: "thumbnailImageUrl",
      flex: 1,
      key: "thumbnailImageUrl",
      render: (pictureUrl: string) => {
        if (pictureUrl) {
          return (
            <Image
              width={50}
              height={50}
              className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
              src={pictureUrl}
            />
          );
        }
        return null;
      },
    },
   ...columnsWithoutRender,
    {
      title: "Instructor",
      dataIndex: "instructor",
      key: "instructor",
      flex: 1,
      render: (instructorId: string) => {
        const instructor = teachersData.find(
          (teacher) => teacher.value === instructorId
        );
        return instructor?.label;
      },
    },
    {
      title: "IS ACTIVE",
      dataIndex: "isPublished",
      key: "isPublished",
     align: "center" as const,
      width: 100,
      render: (data:any) => (
        <div className="flex justify-center">
          <GlobalPublishButton
            isActive={data?.isPublished}
            onChange={() => handlePublished(data)}
          />
        </div>
      ),
    },
    {
      title: "ACTIONS",
      key: "actions",
     align: "center" as const,
      width: 100,
      render: (record: any) => (
        <div className="flex gap-4 justify-center">
          <div>
            <div onClick={() => handleEdit(record)}>
              <EditIcon />
            </div>
          </div>
          <div onClick={() => handleViewRedirect(record)}>
            <EyeIcon />
          </div>
        </div>
      ),
    },
  ];

  const handlePublished = async (data: any) => {
    const res = await apiRequest("updatePublished", null, [data?.id]);
    if (res?.status === 200) {
      message.success(res?.data?.message);
      getSnapCoursesData();
    }
  };

  const handleEdit = (data) => {
    resetForm();
    const { expandData, id, thumbnailImageUrl, learningObjectives, videoUrl } =
      data;
    const thb = generateAntdImageObjectFromUrl(thumbnailImageUrl);

    const videoFormated = {
      uid: 1,
      name: videoUrl,
      status: "done",
      url: videoUrl,
      type: `video/mp4`,
      isOldFile: true,
    };

    const formatedLearningObjectives = learningObjectives
      ?.map((objective) => {
        return `<h3>${objective.heading}</h3>
                <ul>
                    ${objective.points
                      .map((point) => `<li>${point}</li>`)
                      .join("")}
                </ul>`;
      })
      .join("");

    setValues({
      ...data,
      description: expandData,
      snapCourseId: id,
      thumbnailImage: thb ? [thb] : null,

      video: videoFormated ? [videoFormated] : null,
      learningObjectives: formatedLearningObjectives,
    });
    setIsEditMode(true);
    setShowModel(true);
  };
  const formatedSnapCoursesData = snapCoursesData?.map((item, i) => {
    return {
      key: i + 1,
      ...item,
      expandData: item?.description,
      description:
        item?.description?.length > 10
          ? `${item?.description.slice(0, 10)}...`
          : item?.description,

      id: item?._id,
      isPublished: {
        isPublished: item?.isPublished,
        id: item?._id,
      },
    };
  });
  return (
    <>
      <Loader loading={loading} />
      <div className="maincontainer">
      <div className="flex justify-end">
        <CustomButton
          text="Add Course"
          onClick={handleCourseModal}
          icon={<PlusOutlined />}
        />

      </div>

        <div className="pt-2">
          <GlobalTable
            columns={columns}
            tableName="Snap Courses"
            data={formatedSnapCoursesData}
            loading={loading}
          />
        </div>

        {ShowModel && (
          <SnapCourseModal
            formik={formik}
            institutesData={institutesData}
            originalTopisData={originalTopisData}
            isEditMode={isEditMode}
            loading={loading}
            ShowModel={ShowModel}
            boardsData={boardsData}
            allSubTopicsData={allSubTopicsData}
            subjectData={subjectData}
            topicsData={topicsData}
            setIsEditMode={setIsEditMode}
            setShowModel={setShowModel}
            teachersData={teachersData}
          />
        )}
      </div>
    </>
  );
};

export default SnapCourses;
