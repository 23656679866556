import { apiRequest } from "(apis)/api-interface/api-handler";
import { PlusOutlined } from "@ant-design/icons";
import { Image, Table } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import { EditIcon } from "shared-components/ui/Icons";
import TeacherModal from "./modal";
interface TeacherData {
    _id: string;
    name: string;
    intro: string;
    imageUrl: string;
    [key: string]: any; // Allow additional dynamic keys if needed
}

const Teacher: React.FC = () => {
    const [teacherData, setTeacherData] = useState<TeacherData[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [clickedTeacherData, setClickedTeacherData] = useState(null);

    // Handle edit action
    const handleEdit = useCallback((data: TeacherData) => {
        setClickedTeacherData(data);
        setShowModal(true);
        setIsEditMode(true);
    }, []);

    // Fetch all teachers
    const getAllTeachers = useCallback(async () => {
        setLoading(true);
        try {
            const res = await apiRequest("getTeachers");
            if (res?.status === 200) {
                const formattedTeacherData = res?.data?.teachers?.map((data: TeacherData, i: number) => {
                    const { intro, _id } = data;
                    return {
                        ...data,
                        key: i + 1,
                        intro: intro.length > 50 ? `${intro.slice(0, 50)}...` : intro,
                        id: _id,
                        expandData: intro,
                    };
                });
                setTeacherData(formattedTeacherData || []);
            }
        } catch (error) {
            console.error('Failed to fetch teachers:', error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        getAllTeachers();
    }, [getAllTeachers]);

    const columns = [
        {
            title: 'Image',
            dataIndex: 'imageUrl',
            key: 'imageUrl',
            width: 100,
            render: (imageUrl: string) => <Image width={50} height={50} src={imageUrl} alt="Teacher" />,
        },
        { 
            title: 'Teacher Name', 
            dataIndex: 'name', 
            key: 'name', 
            width: 100, 
            headerStyle: { background: 'red', color: 'white' } 
        },
        Table.EXPAND_COLUMN,
        { 
            title: 'Teacher Intro', 
            dataIndex: 'intro', 
            key: 'intro', 
            width: 200 
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Actions',
            key: 'actions',
            width: 90,
            render: (data: TeacherData) => (
                <div>
                    <div onClick={() => handleEdit(data)}>
                    <EditIcon  size="w-6 h-6" />  
                    </div>
                </div>
            ),
        },
    ];
    return (
        <>
        
            <div className='m-10'>
                <div className="flex justify-end mx-10">
                    <CustomButton
                        icon={<PlusOutlined />}
                        text="Add New Teacher"
                        onClick={() => {
                            setClickedTeacherData(null);
                            setIsEditMode(false);
                            setShowModal(true);
                        }}
                    />
                </div>
                <GlobalTable 
                    loading={loading} 
                    tableName={'Manage Teachers'} 
                    data={teacherData} 
                    columns={columns} 
                    isExpandable={true} 
                />
                <TeacherModal
                    setShowModal={setShowModal} 
                    getAllTeachers={getAllTeachers} 
                    showModal={showModal} 
                    isEditMode={isEditMode} 
                    setIsEditMode={setIsEditMode} 
                    clickedTeacherData={clickedTeacherData}
                />
            </div>
        </>
    );
};

export default Teacher;
