import { useEffect, useState } from "react";

import { SearchIcon } from "../../shared-components//ui/Icons";
import { ButtonFill, ButtonOutlined } from "../../shared-components//ui/CustomButtons";
import CustomDropDown from "../../shared-components//ui/CustomDropDown";
import DisciplineForm from "./components/discipline-modal";
import ViewDisciplines from "./components/view-disciplines";
import { DisciplinesAPI } from "../../(apis)/(shared-apis)/disciplines";
// import { getAllDisciplinesApi } from "../../APIs/DisciplineApi";

const DisciplineManager = () => {
  const [disciplines, setDisciplines] = useState([]);
  const [allDisciplines, setAllDisciplines] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectStatus, setSelectStatus] = useState("Active");
  const [isLoading, setIsLoading] = useState(false);
  const [refreshData, setRefreshData] = useState(1);
  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      try {
        const response = await DisciplinesAPI("getDisciplines");
        if (response.status === 200) {
          const data = response?.data?.disciplines || [];
          const filteredDisciplines = data.filter((discipline: any) => {
            return discipline.isPublished;
          });
          setDisciplines(filteredDisciplines);
          setAllDisciplines(data);
          setSelectStatus("Active");
        }
      } catch (error) {
        console.error("Error getting disciplines data:", error);
      } finally {
        setIsLoading(false);
      }
    }
    fetchData();
  }, [refreshData]);

  useEffect(() => {
    if (!allDisciplines) return;
    const NewData = allDisciplines?.filter((discipline) => {
      if (!discipline.disciplineName || !discipline.disciplineDescription)
        return false;

      const matchesSearch =
        discipline?.disciplineName
          ?.toLowerCase()
          ?.includes(searchQuery?.toLowerCase()) ||
        discipline?.disciplineDescription
          .toLowerCase()
          .includes(searchQuery.toLowerCase());
      return matchesSearch;
    });
    setDisciplines(NewData);
  }, [searchQuery]);

  const handleSearchQueryChange = (e) => {
    setSearchQuery(e.target.value);
    if (e.target.value === "") {
      setDisciplines(allDisciplines);
      return;
    }
  };

  useEffect(() => {
    if (selectStatus === "Active" && allDisciplines) {
      const filteredDisciplines = allDisciplines.filter((discipline) => {
        return discipline.isPublished;
      });
      setDisciplines(filteredDisciplines);
    } else if (selectStatus === "InActive" && allDisciplines) {
      const filteredDisciplines = allDisciplines?.filter((discipline) => {
        return !discipline.isPublished;
      });
      setDisciplines(filteredDisciplines);
    } else if (allDisciplines) {
      setDisciplines(allDisciplines);
    }
  }, [selectStatus]);

  const handleSelectStatusChange = (selectedStatus) => {
    setSelectStatus(selectedStatus);
  };

  return (
    <>
      <div className="w-full pb-20 px-5">
        <div className="flex items-center justify-between pb-1 mt-5">
          <h2 className="text-2xl font-bold text-gray-700">Disciplines</h2>
          <ButtonOutlined
            handleClick={() => {
              setRefreshData((prev) => prev + 1);
            }}
          >
            Refresh
          </ButtonOutlined>
        </div>
        <section className="my-5 antialiased">
          <div className="mx-auto">
            {isLoading ? (
              "Loading..."
            ) : (
              <div className=" bg-white shadow-md sm:rounded-lg">
                <TableTop
                  searchQuery={searchQuery}
                  handleSearchQueryChange={handleSearchQueryChange}
                  setDisciplines={setDisciplines}
                />
                <div className="flex items-center p-4 space-x-3">
                  <CustomDropDown
                    label="Published Status"
                    options={["All", "Active", "InActive"]}
                    onSelect={(selectedStatus) =>
                      handleSelectStatusChange(selectedStatus.toString())
                    }
                    width="min-w-[12rem]"
                    value={selectStatus}
                  />
                </div>

                <ViewDisciplines
                  currentDiscipline={disciplines}
                  setDisciplines={setDisciplines}
                />
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export const TableTop = ({
  searchQuery,
  handleSearchQueryChange,
  setDisciplines,
}) => {
  const [openCreateDisciplineModal, setDisciplineModal] = useState(false);

  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
      <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
        <ButtonFill
          handleClick={() => {
            setDisciplineModal(true);
          }}
        >
          Add Discipline
        </ButtonFill>
      </div>
      <DisciplineForm
        mode="add"
        active={openCreateDisciplineModal}
        setModal={setDisciplineModal}
        onCancel={() => {
          setDisciplineModal(false);
        }}
        onConfirm={() => {
          setDisciplineModal(false);
        }}
        setDisciplines={setDisciplines}
      />
    </div>
  );
};

export default DisciplineManager;
