export default function QuestionTip({ form, setForm }) {
    return (
        <>
            <div className="flex flex-col justify-between mt-2 pb-4">
                <h6 className='text-md font-bold text-rose-500 mt-2'> Question Tip:</h6>
                <textarea
                    className="bg-gray-50 rounded p-4 shadow-md border border-gray-100 focus:outline-none focus:border-indigo-500 text-sm px-4 py-2 h-20 resize placeholder-gray-800 placeholder-opacity-60 placeholder-font-italic"
                    onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => {
                        setForm({
                            ...form,
                            tip: e.target.value,
                        });
                    }}
                    value={form.tip}
                    name="questionTip"
                    placeholder="Enter Question Tip"
                />
            </div>
        </>
    )
}