import { ApiInterface } from "../api-interface/types";

const PlansEndpoint = "/api/plans";
const url = PlansEndpoint

export const planApis: ApiInterface[] = [
  {
    url: `${url}/get`,
    method: "GET",
    name: "getPlans",
  },
  {
    url: `${url}/getBoards`,
    method: "GET",
    name: "getBoardsData",
  },
  {
    url: `${url}/getById/:id`,
    method: "GET",
    name: "getPlanById",
  },
  {
    url: `${url}/add`,
    method: "POST",
    name: "addPlan",
  },
  {
    url: `${url}/update/:id`,
    method: "PATCH",
    name: "updatePlan",
  },
  {
    url: `${url}/updateActive/:id`,
    method: "PUT",
    name: "updatePlanActive",
  },
  {
    url: `${url}/delete/:id`,
    method: "DELETE",
    name: "deletePlan",
  },
  {
    url: `${url}/addPricingModel/:PlanId`,
    method: "POST",
    name: "addPlansPricing",
  },
  {
    url: `${url}/addPlanTags/:PlanId`,
    method: "POST",
    name: "addPlanTag",
  },
  {
    url: `${url}/updatePricingBunddleOrder`,
    method: "POST",
    name: "updatePricingBunddleOrder",
  },
  {
    url: `${url}/updatePricingModel/:PlanId/:pricingModelId`,
    method: "PUT",
    name: "updatePlansPricing",
  },
  {
    url: `${url}/updatePlanTags/:PlanId/:tagId`,
    method: "PUT",
    name: "updatePlanTag",
  },
  {
    url: `${url}/updateIspublishedPricingModel/:PlanId/:pricingModelId`,
    method: "PUT",
    name: "updatePlansPricingPublish",
  },
  {
    url: `${url}/updateIspublishedTags/:PlanId/:tagId`,
    method: "PUT",
    name: "updatePlanTagPublish",
  },
  {
    url: `${url}/getPlanPricingModelById/:PlanId/:pricingModelId`,
    method: "GET",
    name: "getPlanPricingModelById",
  },
  {
    url: `${url}/getPlanstudents/:PlanId`,
    method: "GET",
    name: "getPlanstudents",
  },

  // testimonials - api 🎟️
  {
    url: `${url}/addTestimonial`,
    method: "POST",
    name: "addTestimonial",
    contentType: "multipart/form-data",
  },
  {
    url: `${url}/getTestimonials`,
    method: "POST",
    name: "getTestimonials",
  },
  {
    url: `${url}/updateTestimonial/:id`,
    method: "PATCH",
    name: "updateTestimonial",
    contentType: "multipart/form-data",
  },
  {
    url: `${url}/updateActiveTestimonial/:id`,
    method: "PUT",
    name: "updateTestimonialActive",
  },

  {
    url: `/api/plans/getPlan/:category`,
    method: "POST",
    name: "getCategoryPlans",
  },
  {
    url: `/api/plans/create-plan-order`,
    method: "POST",
    name: "requestplanSubscription",
  },
  {
    url: `/api/orders/get-plan-skus`,
    method: "GET",
    name: "getPlanSKUs",
  },
  {
    url: `/api/orders/get-coupon-codes`,
    method: "GET",
    name: "getCouponCodes",
  },
];

 
