import { ApiInterface } from "../../interfaces/global-interfaces";

export const moduleApis: ApiInterface[] = [
  {
    url: "/api/module",
    method: "POST",
    name: "addModule",
  },
  {
    url: "/api/module",
    method: "GET",
    name: "getAllModules",
  },
  {
    url: "/api/module/:id",
    method: "GET",
    name: "getModuleById",
  },
  {
    url: "/api/module/:id",
    method: "PUT",
    name: "updateModuleById",
  },
  {
    url: "/api/module/publish/:id",
    method: "PUT",
    name: "updateModulePublish",
  },
];
