
export const modules = {
    toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        [{ 'script': 'sub' }, { 'script': 'super' }],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': 'center' }, { 'align': 'right' }, { 'align': 'justify' }, { 'align': 'left' }],
    ],
}

export const OptionDefaultValues = {
    OptionLetter: '',
    OptionText: '',
    IsCorrect: false,
    ExplanationText: '',
    optionImage: '',
    explanationImage: ''
}

export const QuestionDefaultValues = {
    id: '',
    deckName: '',
    questionText: '',
    QuestionImage: '',
    hints: [],
    ExplanationText: '',
    ExplanationImage: '',
    tip: '',
    isSAQ: false,
    SAQAnswer: '',
    SAQImage: '',
    type: '',
    university: [],
    year: 0,
    entity: process.env.REACT_APP_ENTITY,
    category: process.env.REACT_APP_CATEGORY,
    board: [],
    subject: '',
    topic: '',
    subTopic: [],
    miscTags: [],
    bookReferences : [],
}
