import { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Edit } from "@material-ui/icons";
import { UserContext } from "../../state-management/user-context";
import { JobsApi } from "(apis)/(shared-apis)/job-apis";
import { Switcher } from "shared-components/ui/CustomForm";
// import JobModal from "./components/JobModal";

const ManageJobs = () => {
  const [jobs, setJobs] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [publishedOption, setPublishedOption] = useState("all");
  const context = useContext(UserContext);
  const { user } = context;

  const [isModalOpen, setModalOpen] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");

  const [updatedData, setUpdatedData] = useState<{
    status?: boolean;
    isPublished?: boolean;
    isOpen: boolean;
    _id: null;
    username?: string;
  }>({
    isPublished: false,
    isOpen: false,
    _id: null,
    username: user?.username,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await JobsApi(`getAllJobs`);
        if (response) {
          setDepartments(response?.data?.departments);
          setJobs(response.data.jobs);
        }
      } catch (error) {
        console.error("Error getting job data:", error);
      }
    }
    fetchData();
  }, []);

  const alertMessage = "Job is not published, please publish the job first";
  const handlePublishedToggle = async (id, newStatus, openStatus) => {
    setUpdatedData({
      isPublished: newStatus,
      _id: id,
      username: user?.username,
      isOpen: newStatus ? openStatus : false,
    });
    const confirmMessage = newStatus
      ? "Publish this job?"
      : "Unpublish this job?";
    setConfirmationMessage(confirmMessage);
    setModalOpen(true);
  };

  const handleStatusChanged = async () => {
    console.log("inside handle Status Changed");
    console.log(updatedData);
    try {
      console.log("inside try");
      if (
        updatedData.isPublished !== null &&
        updatedData.isOpen !== null &&
        updatedData._id !== null &&
        updatedData.username !== null
      ) {
        const response = await JobsApi(
          `updateJobPublishedStatus`,
          [],
          updatedData
        );

        console.log("response", response);
        if (response.data.success) {
          const updatedJobs = jobs.map((job) =>
            job?._id === updatedData._id
              ? {
                  ...job,
                  isPublished: updatedData.isPublished,
                  isOpen: updatedData.isOpen,
                }
              : job
          );
          setJobs(updatedJobs);
        }
      }
    } catch (error) {
      console.error("Error updating job status:", error);
    }

    setModalOpen(false);

    setUpdatedData({
      status: false,
      _id: null,
      username: user?.username,
      isOpen: false,
    });
  };
  const filteredJobs = jobs
    .filter(
      (job) => !selectedDepartment || job?.department === selectedDepartment
    )
    .filter(
      (job) =>
        (!selectedDepartment || job?.department === selectedDepartment) &&
        (publishedOption === "all" ||
          (publishedOption === "published" && job?.isPublished) ||
          (publishedOption === "unpublished" && !job?.isPublished))
    );

//   const [modal, setModal] = useState<{
//     isOpen: boolean;
//     type: "Add" | "Edit";
//     jobData?: any;
//   }>({
//     isOpen: true,
//     type: "Add",
//     jobData: null,
//   });

  return (
    <div className="careers-manageJobContainer">
      {/* <JobModal modal={modal} setModal={setModal} /> */}
      <div className="careers-filterContainer my-10">
        <select
          name="departments"
          id="departments"
          className="careers-departmentsDropDown"
          value={selectedDepartment}
          onChange={(e) => setSelectedDepartment(e.target.value)}
        >
          <option value="">All Departments</option>
          {departments?.map((department, index) => (
            <option key={index} value={department}>
              {department}
            </option>
          ))}
        </select>
        <select
          name="publishedStatus"
          id="publishedStatus"
          className="careers-departmentsDropDown"
          value={publishedOption}
          onChange={(e) => setPublishedOption(e.target.value)}
        >
          <option value="all">All</option>
          <option value="published">Published</option>
          <option value="unpublished">Unpublished</option>
        </select>

        <Link
          className="careers-formbold-btn careers-linkButton"
          to="/addNewJob"
        >
          Add New Job
        </Link>
      </div>

      <table className="careers-styled-table w-full">
        <thead className="text-xs text-gray-700 uppercase bg-gray-50">
          <tr className="border-b">
            <th scope="col" className="px-4 py-3">
              Id
            </th>
            <th scope="col" className="px-4 py-3 ">
              Department
            </th>
            <th scope="col" className="px-4 py-3">
              Created By
            </th>
            <th scope="col" className="px-4 py-3">
              Background Img
            </th>
            <th scope="col" className="px-4 py-3">
              Icon
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              Form Link
            </th>

            <th scope="col" className="px-4 py-3 text-center">
              Edit Published
            </th>
            <th scope="col" className="px-4 py-3 text-center">
              Edit Job
            </th>
          </tr>
        </thead>

        <tbody>
          {filteredJobs.length > 0 ? (
            filteredJobs.map((job, index) => (
              <tr className="border-b " key={index}>
                <td className="px-4 py-3 text-gray-900 whitespace-nowrap">
                  {index + 1}
                </td>
                <td>{job?.department}</td>
                <td>{job?.createdBy}</td>
                <td className="flexx items-center justify-center">
                  <img
                    src={job?.backgroundImageURL}
                    alt=""
                    className="careers-jobBgSnapShot w-44"
                  />
                </td>
                <td className="p-2">
                  <img
                    src={job?.iconImageURL}
                    alt=""
                    className="careers-jobIconSnapShot w-14"
                  />
                </td>
                <td className="careers-formLink">
                  <button
                    className="careers-openFormbutton"
                    type="button"
                    onClick={() => window.open(job?.formLink, "_blank")}
                  >
                    Open Link
                  </button>
                </td>
                <td className=" text-center flexx items-center justify-center">
                  <Switcher
                    isActive={job?.isPublished}
                    for={"Job-Status"}
                    onChange={() => {
                      handlePublishedToggle(
                        job?._id,
                        !job?.isPublished,
                        job?.isOpen
                      );
                    }}
                    togglevalue={job?.isPublished}
                    size="small"
                  />
                </td>

                <td className="flex items-center justify-center">
                  <Link
                    to={`/EditJob/${job?._id}`}
                    className="careers-statusToogleTd"
                  >
                    <Edit />
                  </Link>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={6}>No jobs available</td>
            </tr>
          )}
        </tbody>
      </table>

      {isModalOpen && (
        <div id="modal" className="careers-modal">
          <div className="careers-modal-content">
            <p>{confirmationMessage}</p>
            {confirmationMessage !== alertMessage ? (
              <div className="careers-modal-buttons">
                <button
                  id="confirmBtn"
                  className="careers-modal-btn confirm-btn"
                  onClick={() => handleStatusChanged()}
                >
                  Confirm
                </button>
                <button
                  id="cancelBtn"
                  className="careers-modal-btn cancel-btn"
                  onClick={() => setModalOpen(false)}
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="careers-modal-buttons">
                <button
                  id="cancelBtn"
                  className="careers-modal-btn cancel-btn"
                  onClick={() => setModalOpen(false)}
                >
                  Close Modal
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ManageJobs;
