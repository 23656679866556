import { apiRequest } from "(apis)/api-interface/api-handler";
import { message, notification } from "antd";
import { useState } from "react";
import EditImagesModal from "./edit-modal";
import { EditIcon } from "shared-components/ui/Icons";
export function ImagesMainTable({ images, setimages, setShowModel }) {
  const [currentPage, setCurrentPage] = useState(1);
  const ImagesPerPage = 10;

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleDeleteImage = async (id) => {
    const response = await apiRequest("deleteimageList", null, [id]);
    if (response.data.success) {
      notification.success({
        message: "Success",
        description: response.data.message,
      });
      // Refresh images after deletion
      const newImages = await apiRequest("getAllImageList");
      setimages(newImages.data.data);
    } else {
      notification.error({
        message: "Error",
        description: response.data.message,
      });
    }
  };
  return (
    <div className="w-full m-12 pt-4 rounded shadow-lg bg-white">
      <div className="flex flex-wrap items-center justify-between px-4 pb-4 border-b">
        <>
          <button
            className={`relative flex h-12 w-full items-center justify-center px-8 before:absolute before:inset-0 before:rounded-lg before:border before:border-gray-200 before:bg-rose-500 before:bg-gradient-to-b before:transition before:duration-300 hover:before:scale-105 active:duration-75 active:before:scale-95 sm:w-max my-2`}
            onClick={() => {
              setShowModel(true);
            }}
          >
            <div className={`relative flex items-center space-x-3 text-white`}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 4.5v15m7.5-7.5h-15"
                />
              </svg>
              <span className=" text-base font-semibold">Add New Images</span>
            </div>
          </button>
        </>
      </div>
      <div className="p-2 overflow-x-auto">
        <table className="w-full table-auto">
          <thead>
            <tr className="flex justify-between shadow p-4 text-sm text-left text-gray-500 ">
              <th className="px-6 pb-3 font-medium w-1/4">SR#</th>
              <th className="px-6 pb-3 font-medium w-1/4">Name</th>
              <th className="px-6 pb-3 font-medium w-1/4">Details</th>
              <th className="px-6 pb-3 font-medium w-1/6">Date</th>{" "}
              {/* New header for date */}
              <th className="px-6 pb-3 font-medium w-1/6">City</th>{" "}
              {/* New header for city */}
              <th className="align-self-start px-6 pb-3 font-medium w-1/4">
                <span>Image</span>
              </th>
              <th className="px-6 pb-3 font-medium w-1/12">Published</th>
              <th className="px-6 pb-3 font-medium w-1/12">Edit</th>
            </tr>
          </thead>
          <tbody>
            {images
              .slice(
                (currentPage - 1) * ImagesPerPage,
                currentPage * ImagesPerPage
              )
              .map((image, index) => (
                <ImageRow
                  image={image}
                  index={index}
                  key={index}
                  setimages={setimages}
                />
              ))}
          </tbody>
        </table>
      </div>
      <nav
        className="flex flex-col md:flex-row justify-between items-start md:items-center space-y-3 md:space-y-0 p-4"
        aria-label="Table navigation"
      >
        <span className="text-sm font-normal text-gray-500 space-x-2">
          <span> Showing</span>
          <span className="font-semibold text-gray-900 ">
            {currentPage * ImagesPerPage - ImagesPerPage + 1}
          </span>
          <span> of</span>
          <span className="font-semibold text-gray-900">{images.length}</span>
        </span>
        <ul className="inline-flex items-stretch -space-x-px">
          <li>
            <div
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-l-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => {
                if (currentPage === 1) {
                  return;
                }
                handlePageChange(currentPage - 1);
              }}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </li>
          {Array.from({ length: Math.ceil(images.length / ImagesPerPage) }).map(
            (_, index) => (
              <li key={index}>
                <div
                  className={`flex items-center justify-center text-sm py-2 px-3 leading-tight text-gray-500 bg-white border border-gray-300 hover-bg-gray-100 cursor-pointer ${
                    currentPage === index + 1
                      ? "font-semibold text-primary-600 bg-primary-50"
                      : ""
                  }`}
                  onClick={() => handlePageChange(index + 1)}
                >
                  {index + 1}
                </div>
              </li>
            )
          )}
          <li>
            <div
              className="flex items-center justify-center h-full py-1.5 px-3 ml-0 text-gray-500 bg-white rounded-r-lg border border-gray-300 hover:bg-gray-100 hover-text-gray-700"
              onClick={() => {
                if (currentPage === Math.ceil(images.length / ImagesPerPage)) {
                  return;
                }
                handlePageChange(currentPage + 1);
              }}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-5 h-5"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </li>
        </ul>
      </nav>
    </div>
  );
}

function ImageRow({ image, index, setimages }) {
  const handlePublish = async () => {
    try {
      const response = await apiRequest("updatestatusvalue", null, [image._id]);
      if (response.data.success) {

        message.success(response.data.message);
        const newImages = await apiRequest("getAllImageList");
        setimages(newImages.data.data);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleDelete = async () => {
    const Response = await apiRequest("deleteimageList", null, [image._id]);
    if (Response.data.success) {
      message.success(Response.data.message);
    } else {
      message.error(Response.data.message);
    }
    const Response2 = await apiRequest("getAllImageList");

    if (Response2.data.success) {
      setimages(Response2.data.data);
    }
  };
  const [showModel, setShowModel] = useState(false);
  const handleEdit = () => {
    setShowModel(true);
  };
  return (
    <>
      <tr
        className="flex justify-between shadow p-4 text-sm text-left text-gray-500"
        key={index}
      >
        <td className="px-6 pb-3 font-medium w-1/5">{index + 1}</td>
        <td className="px-6 pb-3 font-medium w-1/5">{image.name}</td>
        <td className="px-6 pb-3 font-medium w-1/5">{image.description}</td>
        <td className="px-6 pb-3 font-medium w-1/6">
          {new Date(image.date).toLocaleDateString()}
        </td>{" "}
        {/* New table data for date */}
        <td className="px-6 pb-3 font-medium w-1/6">{image.city}</td>{" "}
        {/* New table data for city */}
        <td className="align-self-start px-6 pb-3 font-medium w-1/5">
          {image.imageFiles.length > 0 && (
            <img
              src={image.imageFiles[0]}
              alt={`image-0`}
              style={{
                maxWidth: "100px",
                maxHeight: "100px",
                marginRight: "5px",
              }}
            />
          )}
        </td>
        <td className="px-6 pb-3 font-medium w-1/12">
          <label className="flex items-center flex-start cursor-pointer">
            <div className="relative ml-10">
              <input
                type="checkbox"
                className="sr-only"
                checked={image.published}
                onChange={() => handlePublish()}
              />
              <div
                className={`block 
                              ${
                                image.published ? "bg-green-400" : "bg-gray-200"
                              }
                               w-12 h-6 rounded-full`}
              ></div>
              <div
                className={`dot absolute 
                              ${
                                image.published
                                  ? "left-1 top-1"
                                  : "left-7 top-1"
                              }
                               bg-white w-4 h-4 rounded-full transition`}
              ></div>
            </div>
          </label>
        </td>
        <td className=" px-6 mx-4 pb-3 font-medium w-1/12">
          <button
            className=" focus:outline-none"
            onClick={() => handleEdit()}
          >
          <EditIcon  size="w-6 h-6"/>
          </button>
        </td>
        {/* <td className="flex items-center px-6 py-5 w-1/12">
          <button className="text-red-600 hover:text-red-800 focus:outline-none" onClick={() => handleDelete()}>
            <DeleteIcon sx={{ marginRight: 1 }} />
         
          </button>
        </td> */}
      </tr>
      {showModel && (
        <EditImagesModal
          image={image}
          setimages={setimages}
          showModel={showModel}
          setShowModel={setShowModel}
        />
      )}
    </>
  );
}
