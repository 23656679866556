import React, { useState, useEffect, useContext } from "react";
import { notification } from "antd";
// @ts-ignore
import styles from "./styles.module.css";


import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { UserContext } from "state-management/user-context";
import { apiRequest } from "(apis)/api-interface/api-handler";

// Types

type Statistics = {
  Pendingcount: number;
  Solvedcount: number;
  UserRating: number;
  UserCoins: number;
};

type Doubt = {
  _id: string;
  resource?: string;
  subject?: string;
  topic?: string;
  description: string;
  questionID?: string;
  ExpireDate?: string;
  img?: any;
};

export default function ExpertDashboard() {
  const { user } = useContext(UserContext);
  const [statistics, setStatistics] = useState<Statistics | undefined>();
  const [doubts, setDoubts] = useState<Doubt[]>([]);
  const [viewDoubt, setViewDoubt] = useState<boolean>(false);
  const [doubtDisplay, setDoubtDisplay] = useState<Doubt | undefined>();
  const [image, setImage] = useState<string | null>(null);
  const [rating, setRating] = useState<number>(0);
  const [uCoins, setCoins] = useState<number>(0);

  const navigate = useNavigate();

  const getUserStats = async () => {
    if (!user || !user.username) {
      return;
    }
    const response = await apiRequest("ExpertStatistics", {
      username: user?.username,
    });
    const data = response.data;
    setStatistics(data);
    setRating(parseFloat(data?.UserRating?.toFixed(2) || 0.0));
    setCoins(data.UserCoins);
  };

  useEffect(() => {
    const fetchDoubts = async () => {
      if (!user || !user.username) {
        return;
      }
      const response = await apiRequest("GetExpertsPendingDoubts", {
        username: user?.username,
      });
      const data = response.data;
      setDoubts(data);
    };
    fetchDoubts();
    getUserStats();
  }, [user]);

  const getImage = async (id: string) => {
    const response = await apiRequest("GetImage", {
      id,
    });
    const data = response.data;
    setDoubtDisplay(data);
    const imageSrc = ImageToBlob(data.img);
    setImage(imageSrc);
  };

  const handleViewDoubt = (id: string) => {
    setViewDoubt(true);
    getImage(id);
  };

  const takeDoubt = async () => {
    const username = user?.username;
    if (!username) {
      notification.error({
        message: "Login to take doubt",
        description: "Please login to take doubt",
      });
      return;
    }

    navigate("/VideoInterface", { state: { Doubt: doubtDisplay } });
  };

  const unTakeDoubt = async (id: string) => {
    const response = await apiRequest("UnTakeDoubt", {
      id,
    });
    const data = response.data;
    if (data.success) {
      notification.success({
        message: "Doubt Returned",
        description: "Doubt has been returned to the pool",
      });
      setViewDoubt(false);
      setDoubtDisplay(undefined);
      setImage(null);
      const fetchDoubts = async () => {
        const response = await apiRequest("GetExpertsPendingDoubts", {
          username: user?.username,
        });
        const data = response.data;
        setDoubts(data);
      };
      fetchDoubts();
    } else {
      notification.error({
        message: "Error",
        description: "Error in returning doubt",
      });
    }
  };

  return (
    <>
      <div className={styles.Navigation}>
        <h4>Experts Solution</h4>
      </div>

      {!viewDoubt && (
        <>
          <div className={styles.Center}>
            <div className={styles.LogoDisplay}>
              <div className={`container ${styles.Logo}`}>
                <img src={"/DoubtLogo.png"} alt="Expert Solve" width={100} />
                <div className={styles.Headings}>
                  <h4>
                    Expert <span>Solution</span>
                  </h4>
                  <h6>
                    Get top-notch video solution answers to your MDCAT questions
                  </h6>
                  <h6>from top-merit experts 🙌🏻</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="container my-4">
            <div className={styles.Bar}>
              <h5 className="text-left">My Questions</h5>
              <button>
                <Link className={styles.Link} to="/Experts">
                  Back
                </Link>
              </button>
            </div>

            <div className={styles.StatisticsHolder}>
              <div className={styles.Statistics}>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Pending Questions</h6>
                  <h6>{statistics?.Pendingcount}</h6>
                </div>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Answered Questions</h6>
                  <h6>{statistics?.Solvedcount}</h6>
                </div>
              </div>
              <div className={styles.Statistics}>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Coins Earned</h6>
                  <div className={styles.StatsInner}>
                    <h6>{uCoins}</h6>
                    <img src={"/Coin.png"} width={20} height={20} alt="Coins" />
                  </div>
                </div>
                <div className={styles.StatisticsBox}>
                  <h6 className={styles.Head}>Rating</h6>
                  <div className={styles.StatsInner}>
                    <h6>{rating}</h6>
                    <img src={"/Star.png"} width={20} height={20} alt="Star" />
                  </div>
                </div>
              </div>
            </div>

            <TableContainer className="table-container">
              <Table className="styled-table">
                <TableHead>
                  <TableRow className={styles.tableheader}>
                    <TableCell id={styles.tabledb}>Tags</TableCell>
                    <TableCell id={styles.tabledb}>Source</TableCell>
                    <TableCell id={styles.tabledb}>Description</TableCell>
                    <TableCell id={styles.tabledb}></TableCell>
                    <TableCell id={styles.tabledb}>Expires At</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {doubts.map((doubt) => (
                    <TableRow key={doubt._id} className="table-row">
                      <TableCell id={styles.TagCell}>
                        {doubt.resource && (
                          <div id={styles.Tag}>{doubt.resource}</div>
                        )}
                        {doubt.subject && (
                          <div id={styles.Tag}>{doubt.subject}</div>
                        )}
                        {doubt.topic && (
                          <div id={styles.Tag}>{doubt.topic}</div>
                        )}
                      </TableCell>
                      <TableCell id="table-cell">
                        {doubt.questionID ? (
                          <div id={styles.Source}>Premed QBank</div>
                        ) : (
                          <div id={styles.Source2}>Own Question</div>
                        )}
                      </TableCell>
                      <TableCell id="table-cell">
                        {doubt.description.length > 35
                          ? `${doubt.description.slice(0, 35)}...`
                          : doubt.description}
                      </TableCell>
                      <TableCell id="table-cell">
                        <button
                          className={styles.Button}
                          onClick={() => {
                            handleViewDoubt(doubt._id);
                          }}
                        >
                          View Doubt
                        </button>
                      </TableCell>
                      <TableCell id="table-cell">
                        {doubt?.ExpireDate
                          ? new Date(doubt.ExpireDate).toLocaleTimeString([], {
                              hour: "2-digit",
                              minute: "2-digit",
                            })
                          : "N/A"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </>
      )}

      {viewDoubt && (
        <div className="container">
          <div className={styles.Bar}>
            <h5 className="text-left">View Question</h5>
            <button
              onClick={() => {
                setViewDoubt(false);
                setImage(null);
              }}
            >
              <img src={"/Back.png"} width={20} height={20} alt="Back" />
              <span className="ml-2">Back</span>
            </button>
          </div>
          <div className={styles.DoubtContainer}>
            <div className={styles.DoubtQuestion}>
              <h4>{doubtDisplay?.description}</h4>
              {image && <img src={image} alt="Doubt Image" />}
            </div>
            <div className={styles.ButtonContainer}>
              <button
                className={styles.Button}
                onClick={() => {
                  takeDoubt();
                }}
              >
                Take Doubt
              </button>
              <button
                className={styles.Button}
                onClick={() => {
                  unTakeDoubt(doubtDisplay?._id || "");
                }}
              >
                Return Doubt
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
