interface NavigationButtonsProps {
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    onSubmit: () => void;
    disabled: boolean;
    onReject?: () => void;
    reportId?: string;
}

export default function NavigationButtons({ page, setPage , onSubmit , disabled, onReject , reportId}: NavigationButtonsProps) {
    const handlePage = (page: number) => {
        setPage(page)
    }

    return (
            <div className="flex flex-row flex-wrap items-center justify-between">
                <button className="border shadow-lg border-green-500 hover:bg-green-500 hover:text-white text-green-500 font-bold py-3 px-6 rounded border-green-500"
                    disabled={page === 1}
                    onClick={() => { handlePage(page - 1) }}>Back</button>
                    <div className='flex flex-row flex-wrap items-center gap-2'>
                        <button className="border shadow-lg border-green-500 hover:bg-green-500 hover:text-white text-green-500 font-bold py-3 px-6 rounded border-green-500" disabled={page === 3} onClick={() => { handlePage(page + 1) }}>Next</button>
                        <button className="border shadow-lg border-rose-500 bg-rose-500 hover:bg-white hover:text-rose-500 text-white font-bold py-3 px-6 rounded border-rose-500 
                        disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={disabled}
                        onClick={onSubmit}>Submit</button>
                        {reportId && (
                        <button className="border shadow-lg border-rose-500 bg-rose-500 hover:bg-white hover:text-rose-500 text-white font-bold py-3 px-6 rounded border-rose-500 
                        disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={disabled}
                        onClick={onReject}>Reject</button>)}
                    </div>
                    
            </div>
    );
}

