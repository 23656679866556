import { apiRequest } from "(apis)/api-interface/api-handler";
import { Checkbox, Divider, message, Tag } from "antd";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalTable from "shared-components/table/GlobalTable";
import * as Yup from "yup";
import { allCities, allStates } from "./cities-provinces-data";
import ExportCSV from "./exxport-csv";
import SearchFieldsComponent from "./search-fields-component";


const MarketingDownload = () => {
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [BundlesData, setBundlesData] = useState([]);
  const [isExportCSV, setIsExportCSV] = useState(false);
  const [showOnlyFreeUsers, setShowOnlyFreeUsers] = useState(false);
  const [showOnlyPaidUsers, setShowOnlyPaidUsers] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await apiRequest("getAllSKUs");
        if (response?.data?.allSKUs?.length > 0) {
          const formattedData = response?.data?.allSKUs?.map((item) => {
            return {
              label: item?.SKUName,
              value: item?.SKUName,
            };
          });
          const additionialSKU = {
            label: "MY OWN BUNDLE",
            value: "MY OWN BUNDLE",
          };

          formattedData.unshift(additionialSKU);
          setBundlesData(formattedData);
        } else {
          setBundlesData([]);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    };
    fetchData();
  }, []);
  const columns = [
    {
      title: "Name",
      dataIndex: "fullname",
      key: "fullname",
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Province",
      dataIndex: "province",
      key: "province",
    },
    {
      title: "Number",
      dataIndex: "phonenumber",
      key: "phonenumber",
    },
 
 
    {
      title: "Account Created Date",
      dataIndex: "accountcreateddate",
      key: "accountcreateddate",
      render: (text, record) => {
        return record?.accountcreateddate
          ? dayjs(record?.accountcreateddate).format("YYYY-MM-DD hh:mm a")
          : "";
      },
    },
    {
      title: "free User",
      dataIndex: "freeuser",
      key: "freeuser",
    },
  ];
  const otherColumns = [
    {
      title: "features Purchased",
      dataIndex: "featuresPurchased",
      key: "featuresPurchased",
      render: (featuresPurchased, record) => {
    return  featuresPurchased?.[0]?.map((item) => {
          return <Tag color="blue">{item}</Tag>;
        });
      },
    },
    {
      title: "Purchased Plans",
      dataIndex: "PurchasedPlan",
      key: "PurchasedPlan",
      render: (PurchasedPlan, record) => {
        return PurchasedPlan?.map((plan, index) => {
          return (
            <div key={index}>
              <Tag color="blue" className="my-1">
                {plan?.planName} (Start: {dayjs(plan?.subscriptionStartDate)?.format("YYYY-MM-DD")} - End: {dayjs(plan?.subscriptionEndDate)?.format("YYYY-MM-DD")})
              </Tag>
            </div>
          );
        });
      },
    },
      
 
       {
      title: "Category",
      dataIndex: "category",
      key: "category",
    },
    {
      title: "Educational Board",
      dataIndex: "educationalBoard",
      key: "educationalBoard",
    },
    {
      title: "Current Year",
      dataIndex: "whichYear",
      key: "whichYear",
    },

    {
      title: "Country",
      dataIndex: "country",
      key: "country",
    },

    {
      title: "Parent's Number",
      dataIndex: "parentContactNumber",
      key: "parentContactNumber",
    },

    {
      title: "last Onboarding Page Name",
      dataIndex: "lastOnboardingPage",
      key: "info",
    },
    {
      title: "Entity",
      dataIndex: "entity",
      key: "entity",
    },

    {
      title: "Institution",
      dataIndex: "institution",
      key: "institution",
    },

    {
      title: "Reason's for using",
      dataIndex: "reasonsForUsing",
      key: "reasonsForUsing",
    },
  
    {
      title: "Marketing Response",
      dataIndex: "marketingResponse",
      key: "marketingResponse",
    },

   
    {
      title: "Notifications read",
      dataIndex: "notificationsread",
      key: "notificationsread",
    },
    {
      title: "Approach",
      dataIndex: "approach",
      key: "approach",
    },
    {
      title: "feedback Submitted",
      dataIndex: "feedbackSubmitted",
      key: "feedbackSubmitted",
    },
    {
      title: "Education System",
      dataIndex: "educationSystem",
      key: "educationSystem",
    },
    {
      title: "Features",
      dataIndex: "features",
      key: "features",
      render: (features, record) => {
        return features?.map((item) => {
          return <Tag color="blue">{item}</Tag>;
        });
      },
    },
   
  ];
  const oneOfCityOrProvince = Yup.string()
    .nullable()
    .test(
      "one-of-city-or-province",
      "Please select either city or province, not both",
      function (value) {
        const { city, province } = this.parent;
        if (city && province) {
          return (city && !province) || (!city && province);
        } else {
          return true;
        }
      }
    );
  const validationSchema = Yup.object().shape({
    startDate: Yup.string().required("Start date is required"),
    endDate: Yup.string().required("End date is required"),
    city: Yup.string().nullable(),
    province: oneOfCityOrProvince,
  });
  const formik = useFormik({
    initialValues: {
      dateRange: [],
      startDate: null,
      endDate: null,
      city: "",
      category: "",
      planNames: [],
      province: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        const { startDate, endDate } = values;
        const payload = {
          ...values,
          startDate: dayjs(startDate)?.format("YYYY-MM-DDTHH:mm"),
          endDate: dayjs(endDate)?.format("YYYY-MM-DDTHH:mm"),
        };
        const res = await apiRequest("filterUserBYSelectedFields",payload);
        if (res?.data?.isSuccess) {
          if (res?.data?.users?.length > 0) {
            const validUsers = res?.data?.users.filter((user) => user.phonenumber);
            setFilteredUsers(validUsers);
            setIsOpenModal(false);
            resetForm();
          } else {
            setFilteredUsers([]);
            message.error("No data found");
          }
        } else {
          message.error(res?.data?.message);
        }
      } catch (error) {
        message.error(error?.response?.data?.message);
      }
    },
  });
  const cityToStateMap = allCities?.reduce((map, city) => {
    map[city.name] = city.stateCode;
    return map;
  }, {});
  
  // Create a map of state codes to province names
  const stateCodeToProvinceMap = allStates?.reduce((map, state) => {
    map[state.isoCode] = state.name;
    return map;
  }, {});
  
  const formatedData = filteredUsers?.map((item) => {

    const parseFeature = (feature) => {
      try {
        // Try to parse the feature as JSON
        const parsed = JSON.parse(feature);
        return {
          featureName: parsed?.featureName,
        };
      } catch {
        // Return plain text if JSON parsing fails
        return {
          featureName: feature,
        };
      }
    };
    // Format features if they exist
    const formattedFeatures = item?.info?.features?.map(parseFeature) || [];
    const stateCode = cityToStateMap[item?.city];
    const province = item?.province || stateCodeToProvinceMap[stateCode];
    return {
      ...item,
      province:province,
      isOnBoarding:item?.phonenumber? "Completed" : "Not Completed",
      accountcreateddate: item?.accountcreateddate,
      category: item?.selection?.category,
      entity: item?.selection?.entity,
      lastOnboardingPage: item?.info?.lastOnboardingPage,
      institution: item?.info?.institution,
      worldCupParticipation: item?.worldCupParticipation,
      PurchasedPlan: item?.featuresPurchased?.map((feature) => ({
        planName: feature?.planName,
        subscriptionStartDate: feature?.subscriptionStartDate,
        subscriptionEndDate: feature?.subscriptionEndDate,
      })),
      featuresPurchased: item?.featuresPurchased?.map((feature) => feature?.features),
      approach: item?.info?.approach,
      features: formattedFeatures?.map((feature) => feature?.featureName),
      educationSystem: item?.info?.educationSystem,
      freeuser: item?.featuresPurchased?.length > 0  ? "No" : "Yes",
      feedbackSubmitted: item?.feedbackSubmitted ? "Yes" : "No",
      notificationsread: item?.notificationsread?.length,
      subscriptionStartDate: item?.featuresPurchased?.map((feature) => feature?.subscriptionStartDate),
      subscriptionEndDate: item?.featuresPurchased?.map((feature) => feature?.subscriptionEndDate),
    };
  });

  const filteredData = formatedData
  .filter((user) => {
    if (showOnlyFreeUsers && showOnlyPaidUsers) return true;
    if (showOnlyFreeUsers) return user.freeuser === "Yes";
    if (showOnlyPaidUsers) return user.freeuser === "No";
    return true;
  });
  return (
    <div>
      <div className="mx-8">
        <div className="text-2xl font-bold  my-4">Marketing Download</div>
      </div>
      {isExportCSV ? (
        <ExportCSV
          data={filteredData}
          setIsExportCSV={setIsExportCSV}
          columns={[...columns, ...otherColumns]}
        />
      ) : (
        <div className="mx-4">
          <div className="mx-4">
            <SearchFieldsComponent
              BundlesData={BundlesData}
              formik={formik}
              isOpenModal={isOpenModal}
              setOpenModel={setIsOpenModal}
            />
            <Divider />
           
            {/* {filteredData?.length > 0 && ( */}
              <div className="flex justify-between gap-4 mt-4">
                 <Checkbox
              checked={showOnlyFreeUsers}
              onChange={(e) => setShowOnlyFreeUsers(e.target.checked)}
            >
              Show Only Free Users
            </Checkbox>
            <Checkbox
                  checked={showOnlyPaidUsers}
                  onChange={(e) => {
                    setShowOnlyPaidUsers(e.target.checked);
                    // if (e.target.checked) setShowOnlyFreeUsers(false);
                  }}
                >
                  Show Only Paid Users
                </Checkbox>
                <CustomButton
                  disabled={filteredData?.length > 0 ? false : true}
                  text="Export CSV"
                  onClick={() => setIsExportCSV(true)}
                />
              </div>
            {/* // )} */}
          </div>
          <GlobalTable
            isColumnWidth={true}
            columns={columns}
            updatedTableData={showOnlyFreeUsers}
            showOnlyPaidUsers={showOnlyPaidUsers}
            otherColumns={otherColumns}
            data={filteredData}
            loading={formik.isSubmitting}
          />
          <div></div>
        </div>
      )}
    </div>
  );
};

export default MarketingDownload;
