
import { PackagesAPI } from '(apis)/(shared-apis)/packages-apis';
import { useState } from 'react';
import toast from 'react-hot-toast';
import CustomBadges from 'shared-components/ui/CustomBadges';
import { ButtonFill } from 'shared-components/ui/CustomButtons';
import { Input, Label, Switcher } from 'shared-components/ui/CustomForm';
import { EditIcon } from 'shared-components/ui/Icons';
import AddPricingModal from '../add-pricing-modal';
import { EditToggleModal } from '../edit-toggle-modal';

const Overview = ({ packageData, setPackageData, id, fetchPackage }) => {

    const handleInputChange = (name: string, value: string) => {
        setPackageData({
            ...packageData,
            [name]: value
        });
    };

    const UpdatePackage = async () => {
        try {
            let response: any = await PackagesAPI("updatePackage", [id], packageData);
            if (response) {
                toast.success(`Updated Successfully`);
                fetchPackage();
            }
        } catch (error) {
            toast.error("Failed to update package");
            console.log("Update package Error: ", error);
        }
    }


    const [toggleToBeAdded, settoggleToBeAdded] = useState({
        toggleValue: "",
        discountAmount: "",
        endDate: new Date(),
        numberOfMonths: 0
    })

    const AddToggles = async (toggle: any) => {
        if (!toggle.toggleValue || !toggle.discountAmount) {
            toast.error("Please fill all fields");
            return;
        }
        toggle.toggleValue = toggle.toggleValue.trim();
        toggle.discountAmount = toggle.discountAmount.trim();

        // if showDate is true then we will add endDate to the toggle object
        if (showDate) {
            toggle.endDate = new Date(toggle.endDate).toISOString();
            delete toggle.numberOfMonths;
        }
        else {
            toggle.numberOfMonths = toggle.numberOfMonths ? parseInt(toggle.numberOfMonths) : 0;
            delete toggle.endDate;
        }
        try {
            const res = await PackagesAPI("addToggleToPackage", [id], toggle);
            if (res.data.success) {
                toast.success("Toggle Added Successfully");
                fetchPackage();
                settoggleToBeAdded({
                    toggleValue: "",
                    discountAmount: "",
                    endDate: new Date(),
                    numberOfMonths: 0
                })
            }

        } catch (error) {
            toast.error("Failed to add Toggle");
            console.log("Add Toggle Error: ", error);
        }

    }
    const HandleToggleChange = (name: string, value: string) => {
        settoggleToBeAdded({
            ...toggleToBeAdded,
            [name]: value
        })
    }

    const [togglesModal, setTogglesModal] = useState(false);
    const [selectedToggle, setSelectedToggle] = useState(null);


    const [pricingsModal, setPricingsModal] = useState(false);

    const [showDate, setShowDate] = useState(false);

    const [mode, setMode] = useState("add");
    const [selectedSKU, setSelectedSKU] = useState(null);

    const HandlePublish = async (id: string, isPublished: boolean) => {
        const body = {
            skuId: id,
            isPublished: !isPublished
        };
        try {
            let response: any = await PackagesAPI("HandleSKUPublishedStatus", [""], body);
            if (response) {
                toast.success(`Updated Successfully`);
                fetchPackage();
            }
        } catch (error) {
            console.log("Update package Error: ", error);
            toast.error(error.AxiosError.response.data.message);
        }
    }


    return (
        <>
            <div className='border-2 rounded p-5'>
                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700"> Package: {packageData?.boardName}</h2>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mt-5 items-end'>
                    <div className="space-y-2">
                        <Label>Board Name</Label>
                        <Input
                            type="text"
                            placeholder="Enter Board Name"
                            value={packageData?.boardName}
                            name="boardName"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Category</Label>
                        <Input
                            type="text"
                            placeholder="Enter Category"
                            value={packageData?.metadata?.category}
                            name="category"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Entity</Label>
                        <Input
                            type="text"
                            placeholder="Enter Entity"
                            value={packageData?.metadata?.entity}
                            name="entity"
                            disabled={true}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Position</Label>
                        <Input
                            type="number"
                            placeholder="Enter Position"
                            value={packageData?.position}
                            name="position"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className='flex justify-between'>
                        <div className="space-y-2">
                            <Label> Published Status</Label>
                            <Switcher
                                isActive={undefined}
                                for={packageData?._id}
                                onChange={() => {
                                    setPackageData({
                                        ...packageData,
                                        isPublished: !packageData?.isPublished
                                    });
                                }}
                                togglevalue={packageData?.isPublished}
                                size="normal"
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Hidden On Features Page</Label>
                            <Switcher
                                isActive={undefined}
                                for={packageData?._id + packageData?.boardName}
                                onChange={() => {
                                    setPackageData({
                                        ...packageData,
                                        hiddenOnFeatures: !packageData?.hiddenOnFeatures
                                    });
                                }}
                                togglevalue={packageData?.hiddenOnFeatures}
                                size="normal"
                            />
                        </div>
                        <div className="space-y-2">
                            <Label>Is Upgradeable</Label>
                            <Switcher
                                isActive={undefined}
                                for={packageData?._id + packageData?.boardName + "upgradeable"}
                                onChange={() => {
                                    setPackageData({
                                        ...packageData,
                                        isUpgradeable: !packageData?.isUpgradeable
                                    });
                                }}
                                togglevalue={packageData?.isUpgradeable}
                                size="normal"
                            />
                        </div>

                    </div>


                    <div className="space-y-2 flex justify-end">
                        <ButtonFill
                            handleClick={() => {
                                UpdatePackage();
                            }}
                        >
                            Update Package
                        </ButtonFill>
                    </div>



                </div>
            </div>
            <div className='border-2 rounded p-5 mt-4'>

                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700">Toggles </h2>
                    <div className="space-y-2 flex justify-end">
                        <ButtonFill
                            handleClick={() => {
                                AddToggles(toggleToBeAdded);
                            }}
                        >
                            Add Toggle
                        </ButtonFill>
                    </div>

                </div>
                <div className='grid grid-cols-1 md:grid-cols-4 gap-2 mt-5 items-end'>
                    <div className="space-y-2">
                        <Label>Toggle Value </Label>
                        <Input
                            type="text"
                            placeholder="Enter Toggle Value"
                            value={toggleToBeAdded?.toggleValue}
                            name="toggleValue"
                            onChange={HandleToggleChange}
                        />
                    </div>
                    <div className="space-y-2">
                        <Label>Toggle Discount </Label>
                        <Input
                            type="text"
                            placeholder="Enter Discount"
                            value={toggleToBeAdded?.discountAmount}
                            name="discountAmount"
                            onChange={HandleToggleChange}
                        />
                    </div>
                    {/*Choice for either endDate or numberOfMonths */}
                    <div className="space-y-2">
                        <Label>Add End Date Instead of Number of Months</Label>
                        <Switcher
                            isActive={undefined}
                            for={toggleToBeAdded?.endDate}
                            onChange={() => {
                                setShowDate(!showDate);
                            }}
                            togglevalue={showDate}
                            size="normal"
                        />
                    </div>
                    {showDate ?
                        <div className="space-y-2">
                            <Label>End Date </Label>
                            <Input
                                type="date"
                                placeholder="Enter End Date"
                                value={toggleToBeAdded?.endDate}
                                name="endDate"
                                onChange={HandleToggleChange}
                            />
                        </div>
                        :
                        <div className="space-y-2">
                            <Label>Number of Months </Label>
                            <Input
                                type="number"
                                placeholder="Enter Number of Months"
                                value={toggleToBeAdded?.numberOfMonths}
                                name="numberOfMonths"
                                onChange={HandleToggleChange}
                            />
                        </div>
                    }
                </div>

                <div className=" bg-white shadow-md sm:rounded-lg mt-4">
                    <div className="overflow-x-auto">
                        <table className="w-full mb-20 text-sm text-left text-gray-500">
                            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                                <tr>
                                    <th scope="col" className="px-4 py-4">
                                        SR No.
                                    </th>
                                    <th scope="col" className="px-4 py-4">
                                        Toggle Value
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Discount Amount
                                    </th>
                                    <th scope="col" className="px-4 py-4 text-center">
                                        Active
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        End Date
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Position
                                    </th>
                                    <th scope="col" className="px-4 py-3 text-center">
                                        Actions
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {packageData?.togglesAndPricings?.map((toggle, index) => (
                                    <tr className="border-b" key={toggle?._id}>
                                        <td className="px-4 py-4">{index + 1}</td>
                                        <td className="px-4 py-3">{toggle?.toggleValue}</td>
                                        <td className="px-4 py-3 text-center">{toggle?.discountAmount}</td>
                                        <td className="px-4 py-3">
                                            <div className="mx-auto w-fit">
                                                <CustomBadges label={toggle?.active ? "Active" : "Inactive"} type={toggle?.active ? "success" : "error"} showIcon={false} />
                                            </div>
                                        </td>
                                        <td className="px-4 py-3 text-center">{toggle?.endDate ? new Date(toggle?.endDate).toLocaleDateString() : toggle?.numberOfMonths}</td>                                        
                                        <td className="px-4 py-3 text-center">{toggle?.position}</td>
                                        <td className="px-4 py-3 text-center">
                                            <div className="flex items-center justify-center" onClick={() => { setTogglesModal(true); setSelectedToggle(toggle) }}>
                                                <EditIcon size='w-6 h-6' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>

                        <EditToggleModal
                            active={togglesModal}
                            setModal={setTogglesModal}
                            onCancel={() => {
                                setTogglesModal(false);
                            }}
                            onConfirm={() => {
                                setTogglesModal(false);
                            }}
                            selectedToggle={selectedToggle}
                            packageId={id}
                            fetchPackage={fetchPackage}
                        />

                    </div>
                </div>

            </div>
            <div className='border-2 rounded p-5 mt-4'>

                <div className="flex items-center justify-between pb-1 mt-5">
                    <h2 className="text-2xl font-bold text-gray-700">Pricings </h2>

                    <ButtonFill
                        handleClick={() => {
                            setPricingsModal(true);
                            setMode("add");
                            setSelectedSKU(null);
                        }}
                    >
                        Add Pricing
                    </ButtonFill>


                    <AddPricingModal
                        active={pricingsModal}
                        setModal={setPricingsModal}
                        onCancel={() => {
                            setPricingsModal(false);
                        }}
                        onConfirm={() => {
                            setPricingsModal(false);
                        }}
                        packageId={id}
                        fetchPackage={fetchPackage}
                        packageData={packageData}
                        mode={mode}
                        selectedSKU={selectedSKU}
                    />
                </div>
                <div className='grid grid-cols-1 md:grid-cols-3 gap-2 mt-5 items-end'>
                    {packageData?.skus?.map((sku, index) => (
                        <div className='border-2 rounded p-5 mt-4' key={sku?._id}>
                            <div className="flex items-center justify-between pb-1 mt-5">
                                <h2 className="text-2xl font-bold text-gray-700">Pricing: {sku?.SKUName}</h2>
                            </div>
                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2'>
                                <div className="space-y-2">
                                    <Label>SKU Name</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter SKU Name"
                                        value={sku?.SKUName}
                                        name="SKUName"
                                        disabled={true}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label>Tag</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Tag"
                                        value={sku?.Tag}
                                        name="Tag"
                                        disabled={true}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <Label>Amount Of Courses</Label>
                                    <Input
                                        type="number"
                                        placeholder="Enter Amount Of Courses"
                                        value={sku?.amountOfCourses}
                                        name="amountOfCourses"
                                        disabled={true}
                                    />
                                </div>
                                <div className="space-y-2">
                                    <Label>Description</Label>
                                    <Input
                                        type="text"
                                        placeholder="Enter Description"
                                        value={sku?.Description}
                                        name="Description"
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            {sku?.PreMedAccess.length > 0 && <div className="space-y-2 w-full">
                                <Label>PreMed Access</Label>
                                <div className="flex flex-wrap gap-2">
                                    {sku?.PreMedAccess.map((item, index) => (
                                        <CustomBadges label={item} type="success" showIcon={false} />
                                    ))}
                                </div>
                            </div>}



                            <div className='grid grid-cols-1 md:grid-cols-2 gap-2 mt-5 items-end'>
                                <div className="space-y-2 mt-2">
                                    <Label>Is Published</Label>
                                    <Switcher
                                        isActive={undefined}
                                        for={sku?._id}
                                        onChange={() => {
                                            HandlePublish(
                                                sku?._id,
                                                sku?.isPublished
                                            );
                                        }}
                                        togglevalue={sku?.isPublished}
                                        size="normal"
                                    />
                                </div>
                                <div className='flex justify-end'>
                                    <ButtonFill
                                        handleClick={() => {
                                            setMode("edit");
                                            setSelectedSKU(sku);
                                            setPricingsModal(true);
                                        }}
                                    >
                                        Edit Pricing
                                    </ButtonFill>
                                </div>
                            </div>
                        </div>
                    ))}

                </div>

            </div>
        </>
    )

}

export default Overview
