import {
  ButtonFill,
  ButtonOutlined,
} from "../../shared-components/ui/CustomButtons";
import CustomBadges from "../../shared-components/ui/CustomBadges";
import { useState } from "react";
import { SearchIcon } from "../../shared-components/ui/Icons";
import CommissionUserForm from "./components/CommissionUserForm";

export const roleHierarchy = {
  ASSOCIATE: "HEAD",
  HEAD: "MANAGER",
  MANAGER: "PROVINCIAL_DIRECTOR",
  PROVINCIAL_DIRECTOR: "NATIONAL_DIRECTOR",
  NATIONAL_DIRECTOR: "",
};

export const ROlES = {
  ASSOCIATE: "ASSOCIATE",
  HEAD: "HEAD",
  MANAGER: "MANAGER",
  PROVINCIAL_DIRECTOR: "PROVINCIAL_DIRECTOR",
  NATIONAL_DIRECTOR: "NATIONAL_DIRECTOR",
};

export const renderCellColor = (role) => {
  if (role === ROlES.ASSOCIATE) {
    return "bg-gray-200";
  } else if (role === ROlES.HEAD) {
    return "bg-teal-200";
  } else if (role === ROlES.MANAGER) {
    return "bg-amber-200";
  } else if (role === ROlES.PROVINCIAL_DIRECTOR) {
    return "bg-red-200";
  } else if (role === ROlES.NATIONAL_DIRECTOR) {
    return "bg-emerald-200";
  }
};

export const renderChips = (role: string) => {
  if (role === ROlES.ASSOCIATE) {
    return <CustomBadges label={role} type="gray" showIcon={false} />;
  } else if (role === ROlES.HEAD) {
    return <CustomBadges label={role} type="info" showIcon={false} />;
  } else if (role === ROlES.MANAGER) {
    return <CustomBadges label={role} type="warning" showIcon={false} />;
  } else if (role === ROlES.PROVINCIAL_DIRECTOR) {
    return <CustomBadges label={role} type="error" showIcon={false} />;
  } else if (role === ROlES.NATIONAL_DIRECTOR) {
    return <CustomBadges label={role} type="success" showIcon={false} />;
  }
};

export const getUsersOfSpecificRole = (allusers, role) => {
  const filteredUserrs = allusers
    ? allusers?.filter((user) => user?.role === role)
    : [];
  return filteredUserrs;
};

export const CommissionBanner = ({ title, setRefreshData }) => {
  return (
    <div className="flex items-center justify-between pb-1 mt-5">
      <h2 className="text-2xl font-bold text-gray-700">{title}</h2>
      <ButtonOutlined
        handleClick={() => {
          setRefreshData((prev) => prev + 1);
        }}
      >
        Refresh
      </ButtonOutlined>
    </div>
  );
};

export const getTotalAmountEarned = (user) => {
  const orders = user?.orders && user?.orders?.length > 0 ? user?.orders : [];
  let totalAmountEarned = 0;

  orders.map((order) => {
    totalAmountEarned = totalAmountEarned + order?.totalAmountEarned;
  });

  return totalAmountEarned;
};

export const formatNumberWithCommas = (number: string | number): string => {
  if (typeof number === "number") {
    return number.toLocaleString();
  } else if (typeof number === "string" && !isNaN(Number(number))) {
    return parseFloat(number).toLocaleString();
  } else {
    return number;
  }
};

export const REQUEST_STATUS = {
  Pending: "Pending",
  Approved: "Approved",
  Rejected: "Rejected",
};

export const ROLES_OPTION = [
  "ASSOCIATE",
  "HEAD",
  "MANAGER",
  "PROVINCIAL_DIRECTOR",
  "NATIONAL_DIRECTOR",
];

export const getUserIdField = (role: string) => {
  switch (role) {
    case "NATIONAL_DIRECTOR":
      return "provincialDirectorIds";
    case "PROVINCIAL_DIRECTOR":
      return "managerIds";
    case "MANAGER":
      return "headIds";
    case "HEAD":
      return "associateIds";
    default:
      return null;
  }
};

export const transferTypes = [
  {
    name: "Transfer Associates from One Head to Another Head",
    value: "transfer-associates-from-one-head-to-another-head",
    transferringRole: ROlES.ASSOCIATE,
    supervisorRole: ROlES.HEAD,
  },
  {
    name: "Transfer Heads from One Manager to Another Manager",
    value: "transfer-heads-from-one-manger-to-another-manger",
    transferringRole: ROlES.HEAD,
    supervisorRole: ROlES.MANAGER,
  },
  {
    name: "Transfer Associates from Promoted Manager to Head",
    value: "transfer-associates-from-promoted-manager-to-another-head",
  },
];
export const TableTop = ({
  searchQuery,
  handleSearchQueryChange,
  setAllUsers,
  adminUsers,
  reportToUsers,
  allAdminUsers,
  allCommissionUsers,
  label,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
      <div className="w-full md:w-1/2">
        <form className="flex items-center">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <SearchIcon color="text-gray-400" />
            </div>
            <input
              type="text"
              id="simple-search"
              className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-primary-500 focus:border-none outline-none"
              placeholder="Search"
              required={true}
              value={searchQuery}
              onChange={handleSearchQueryChange}
            />
          </div>
        </form>
      </div>
      <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
        <ButtonFill
          handleClick={() => {
            setIsModalOpen(true);
          }}
        >
          Add new user
        </ButtonFill>
      </div>
      <CommissionUserForm
        mode="add"
        active={isModalOpen}
        setModal={setIsModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        onConfirm={() => {
          setIsModalOpen(false);
        }}
        setAllUsers={setAllUsers}
        adminUsers={adminUsers}
        allAdminUsers={allAdminUsers}
        reportToUsers={reportToUsers}
        allCommissionUsers={allCommissionUsers}
        label={label}
      />
    </div>
  );
};
