import { apiRequest } from "(apis)/api-interface/api-handler";
import { CopyOutlined } from "@ant-design/icons";
import { Button, message } from "antd";
import { useFormik } from "formik";
import { useState } from "react";
import CustomButton from "shared-components/form-fields/custom-button";
import CustomImageField from "shared-components/form-fields/custom-image-field";
import CustomPdfField from "shared-components/form-fields/custom-pdf-field";
import CustomSelect from "shared-components/form-fields/custom-select";
import CustomVideoField from "shared-components/form-fields/custom-video-field";
import validationSchema from "./schema";

const GenerateUrl = () => {
  const initialValues = {
    type: "",
    file: null,
  };
  const [generatedUrl, setGeneratedUrl] = useState("");
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    if (generatedUrl) {
      navigator.clipboard
        .writeText(generatedUrl)
        .then(() => {
          setCopied(true);
          setTimeout(() => setCopied(false), 2000);
        })
        .catch((err) => console.error("Failed to copy text: ", err));
    }
  };
  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values: any) => {
      try {
        const formData = new FormData();
        formData.append("file", values.file?.[0]?.originFileObj);
        formData.append("type", values.type);
        const res = await apiRequest("uploadFile", formData);
        if (res?.data?.isSuccess) {
          message.success("Url Generated  successfully");
          setGeneratedUrl(res?.data?.url);
          formik.resetForm();
        }
      } catch (error) {
        message.error(
          error?.response?.data?.message ||
            error?.message ||
            "Something went wrong"
        );
      }
    },
  });

  return (
    <div className="shadow-md px-10 py-2 rounded">
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-10 ">
          <div>
            <CustomSelect
              onChange={(value) => {
                formik.setFieldValue("type", value);
                formik.setFieldValue("file", null);  // Reset file field on type change
                formik.validateForm();
              }}
              label="Select Media Type"
              options={[
                { label: "Image", value: "image" },
                { label: "Video (mp4)", value: "video" },
                { label: "PDF", value: "pdf" },
              ]}
              formik={formik}
              name="type"
            />
          </div>
          <div>
            {formik?.values?.type === "image" && (
              <CustomImageField
                label="Upload Image"
                name="file"
                formik={formik}
                require // Required field
              />
            )}
            {formik?.values?.type === "pdf" && (
              <CustomPdfField
                label="Upload Pdf"
                name="file"
                formik={formik}
                require // Required field
              />
            )}
            {formik?.values?.type === "video" && (
              <CustomVideoField
                label="Upload Video"
                name="file"
                formik={formik}
                require // Required field
              />
            )}
          </div>
          <div className="mt-8">
            <CustomButton
              text="submit"
              onClick={formik.submitForm}
              isLoading={formik?.isSubmitting}
            />
          </div>
        </div>
      </form>
      <div className="flex  justify-center items-center gap-4">
        <div>
          {generatedUrl && (
            <div className="mt-4">
              <a href={generatedUrl} target="_blank" rel="noreferrer">
                {generatedUrl}
              </a>
            </div>
          )}
        </div>
        {generatedUrl && (
          <div>
            <Button
              type="link"
              onClick={handleCopy}
              style={{ marginLeft: "8px" }}
            >
              <CopyOutlined />
            </Button>
            {copied && (
              <span style={{ marginLeft: "8px", color: "green" }}>Copied!</span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default GenerateUrl;
