// import { useSortable } from "@dnd-kit/sortable";
import { Button, Image, message } from "antd";
import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import * as Yup from "yup";

import { apiRequest } from "(apis)/api-interface/api-handler";
import { EditOutlined, PlusOutlined } from "@ant-design/icons";
import { Tag } from "antd";
import CustomButton from "shared-components/form-fields/custom-button";
import GlobalDragableTable from "shared-components/table/global-dragable-table";
import GlobalPublishButton from "shared-components/ui/CustomButtons";
import TagModal from "./tag-modal";

interface TagScreenProps {
  getPlanData: () => void;
  planData: any;
  updateTagApi?: string;
  addTagApi?: string;
  updateTagPublishApi?: string;
}

const TagScreen: React.FC<TagScreenProps> = ({ getPlanData, planData ,updateTagApi="updatePlanTag", addTagApi="addPlanTag", updateTagPublishApi="updatePlanTagPublish"}) => {
  const [isTagAddModel, setIsTagAddModel] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [accessTags, setAccess] = useState([]);
  const [file, setFile] = useState(null);
  const [videoPreviewUrl, setVideoPreviewUrl] = useState(null);

  const GetAccessTags = async () => {
    try {
      const response = await apiRequest("getDistintTags");
      setAccess(response?.data?.tags);
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  useEffect(() => {
    GetAccessTags();
  }, []);

  const initialValues = {
    featureName: "",
    description: "",
    tags: [],
    cardId: null,
    tag: "",
    videoFile: null,
  };

  const validationSchema = Yup.object().shape({
    featureName: isEditMode
      ? Yup.string()
          .required("Name is required")
          .test("unique", "Feature name already exists.", function (value) {
            const existingPlanNameExceptCurrent = planData?.tags?.filter(
              (pricingModel) => pricingModel._id !== values?.cardId
            );
            const existingPlanNames = existingPlanNameExceptCurrent?.map(
              (planNames) => planNames.featureName
            );
            return !existingPlanNames?.includes(value);
          })
      : Yup.string()
          .required("Name is required")
          .test("unique", "Feature name already exists.", function (value) {
            const existingPlanNames = planData?.tags?.map(
              (planNames) => planNames.featureName
            );
            return !existingPlanNames?.includes(value);
          }),
    description: Yup.string()
      .required("Description is required")
      .max(200, "Maximum 200 characters"),

    video: Yup.object().test({
      name: "required-if-video",
      exclusive: true,
      test: function (value: any) {
        if (this.parent.type === "video") {
          const { videoUrl, videoFile } = value;

          if (!videoUrl) {
            return this.createError({
              path: "videoUrl",
              message: "Video file is required when type is video",
            });
          } else if (!videoUrl && !/\.mp4$/.test(videoUrl)) {
            return this.createError({
              path: "video.videoFile",
              message: "Video file must be in mp4 format",
            });
          } else if (!videoUrl && videoFile.size > 100 * 1024 * 1024) {
            return this.createError({
              path: "video.videoFile",
              message: "Video file size must be less than 100MB",
            });
          }
        }
        return true;
      },
    }),
  });

  const dynamicValidationSchema = isEditMode ? null : validationSchema;

  const formik = useFormik({
    initialValues,
    validationSchema: dynamicValidationSchema,
    onSubmit: async (values: any, { resetForm }) => {
      try {
        const { featureName, description, tags, iconLink, videoUrl } = values;
        const formData = new FormData();
        // Loop through each key-value pair in values
        formData.append("featureName", featureName);
        formData.append("tags", tags);
        formData.append("description", description);
        formData.append("iconLink", iconLink);
        if (videoPreviewUrl !== videoUrl) {
          formData.append("videoFile", videoUrl);
        }

        let res;
        if (isEditMode) {
          res = await apiRequest(updateTagApi, formData, [
            planData?._id,
            values?.cardId,
          ]);
        } else {
          res = await apiRequest(addTagApi, formData, [planData?._id]);
        }

        if (res?.status === 200) {
          setIsTagAddModel(false);
          setIsEditMode(false);
          message.success(res?.data?.message);
          getPlanData();
          resetForm();
        }
      } catch (error) {
        message.error(error?.response?.data?.message || error?.message);
      }
    },
  });
  const { values, resetForm, setFieldValue, setValues } = formik;
  const handleTagModel = () => {
    setVideoPreviewUrl(null);
    setIsEditMode(false);
    setFile(null);
    resetForm();
    setIsTagAddModel(true);
  };
  const handleIsPublishToggle = async (cardId: string) => {
    try {
      const res = await apiRequest(updateTagPublishApi, null, [
        planData?._id,
        cardId,
      ]);
      if (res?.status === 200) {
        message.success(res?.data?.message);
        getPlanData();
      }
    } catch (error) {
      message.error(error?.response?.data?.message || error?.message);
    }
  };

  const handleEditCard = async (data) => {
    resetForm();
    const { featureName, tags, description, iconLink, videoUrl } = data;

    setValues({
      featureName: featureName,
      description: description,
      cardId: data?._id,
      tags: tags,
      iconLink: iconLink,
      videoUrl: videoUrl,
    });
    // if (videoUrl) {
    //   setFile(videoUrl);
    // }
    if (iconLink) {
      setFile(iconLink);
    } else {
      setFieldValue("iconLink", "");
      setFile("");
    }

    setIsEditMode(true);
    setIsTagAddModel(true);
  };
  const formatedData = planData?.tags
    ? planData?.tags?.map((data, i) => {
        return {
          ...data,
          SRNO: i + 1,
          key: data?._id,
          featureName: data.featureName,
          description: data.description,
          tags: data.tags,
          iconLink: data.iconLink,
          video: data.videoUrl,
          isActive: data.isPublished,
          videoFile: data?.videoUrl,
        };
      })
    : [];

  const [dataSource, setDataSource] = useState(formatedData);
  const initialOrder = useRef(formatedData?.map((item) => item.key));
  const columns = [
    {
      title: "SR#",
      dataIndex: "SRNO",
      key: "SRNO",
    },
    {
      title: "ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "Feature Name",
      dataIndex: "featureName",
      key: "featureName",
      flex: 1,
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      flex: 1,
    },
    {
      title: "Tags",
      dataIndex: "tags",
      key: "tags",
      flex: 1,
      render: (tags) => (
        <>
          {tags?.map((item) => (
            <Tag color="red" key={item}>
              {item}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: "iconLink Link",
      flex: 1,
      dataIndex: "iconLink",
      key: "iconLink",
      render: (iconLink) => {
        if (iconLink) {
          return (
            <Image
              width={50}
              height={50}
              src={iconLink}
              alt="Profile"
              className="w-10 h-10 rounded-full border border-red-400 cursor-pointer"
            />
          );
        }
        return null;
      },
    },
    {
      title: "Video",
      flex: 1,
      dataIndex: "videoUrl",
      key: "videoUrl",
      render: (videoUrl) => {
        if (videoUrl) {
          return (
            <a
              href={videoUrl}
              target="_blank"
              rel="noreferrer"
              className="text-blue-500"
            >
              Watch Video
            </a>
          );
        }
        return null;
      },
    },
    {
      title: "Is Active",
      dataIndex: "isActive",
      flex: 1,
      key: "isActive",
      render: (isActive, record) => (
        <GlobalPublishButton
          isActive={isActive}
          onChange={() => handleIsPublishToggle(record.key)}
        />
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (data) => (
        <Button icon={<EditOutlined />} onClick={() => handleEditCard(data)} />
      ),
    },
  ];
  return (
    <>
      <div className="mt-4">
      <div className="flex justify-end items-end">
                  <CustomButton
                    text="Add New Tag"
                    onClick={handleTagModel}
                    icon={<PlusOutlined />}
                  />
                </div>
        <div>
        <div className="container px-5 pb-10 pt-5  w-full">
              <div className="flex flex-wrap">
               
                {/* {formatedData.length > 0 && (
                  <div className="flex justify-end items-end w-full my-4">
                    <GlobalBtn
                      text="Update Tags"
                      onClick={updateTags}
                      disabled={!isTagChanged()}
                    />
                  </div>
                )} */}
                {formatedData.length > 0 ? (
                  <GlobalDragableTable
                    columns={columns}
                    data={formatedData}
                    setUpDatedDataSource={setDataSource}
                  />
                ) : (
                  <div className="w-full text-center">No data found</div>
                )}
              </div>
            </div>
        </div>
      </div>

      {isTagAddModel && (
        <TagModal
          formik={formik}
          accessTags={accessTags} // replace with actual tags
          isTagAddModel={isTagAddModel}
          isEditMode={isEditMode}
          setIsTagAddModel={setIsTagAddModel}
          file={file}
          setVideoPreviewUrl={setVideoPreviewUrl}
          videoPreviewUrl={videoPreviewUrl}
          setFile={setFile}
        />
      )}
    </>
  );
};

export default TagScreen;
